import { useMemo } from 'react';
import { css } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';

type StylesElement<T> = T | ((theme: Theme) => T);
type CustomCSS = {[key:string]: any };

const useClasses = <T extends Record<string, CustomCSS>>(stylesElement: StylesElement<T>, props: object = {}):
    Record<keyof T, string> => {
    const theme = useTheme();

    return useMemo(() => {
        const rawClasses: T = typeof stylesElement === 'function'
            ? stylesElement(theme)
            : stylesElement;
        const prepared: Record<keyof T, string> = {} as Record<keyof T, string>;

        Object.entries(rawClasses).forEach(([key, value]) => {
            prepared[key as keyof T] = css(
                typeof value === 'function' ? value(props) : value,
            );
        });

        return prepared;
    }, [stylesElement, theme, props]);
};

export default useClasses;
