import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const loading = keyframes({
        '0%': {
            backgroundPositionX: '-1000px',
        },
        '100%': {
            backgroundPositionX: '100px',
        },
    });

    const cell = css({
        padding: '5px 5px 5px 0px!important',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    });
    const table = css({
        margin: '10px',
    });

    const hcell = css({
        color: `${theme.palette.common.black} !important`,
        '&::after': {
            display: 'none',
        },
        '& div': {
            paddingLeft: '0!important',
        },
    });

    const noLocations = css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        color: theme.palette.secondary[600],
    });

    const filledSelect = css({
        background: theme.palette.primary[50],
    });

    const icon = css({
        color: theme.palette.secondary[600],
        '&:hover': {
            color: theme.palette.secondary[800],
        },
    });

    const caret = css({
        marginRight: '5px',
        color: theme.palette.primary[700],
        fontSize: '30px',
    });

    const loadingSkeleton = css({
        width: '100%',
        height: '35px',
        background: `linear-gradient(90deg, ${theme.palette.secondary[200]} 0%, ${theme.palette.secondary[400]} 25%,\
        ${theme.palette.secondary[200]} 50%, ${theme.palette.secondary[400]} 75%,\
        ${theme.palette.secondary[200]} 100%)`,
        borderRadius: '4px',
        animation: `${loading} 10s ease infinite alternate`,
    });

    const button = css({
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        height: '40px',
        maxWidth: '150px',
        minWidth: '120px',
        borderRadius: '2px',
        textTransform: 'capitalize',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    });

    return {
        cell,
        hcell,
        table,
        noLocations,
        filledSelect,
        icon,
        caret,
        loadingSkeleton,
        button,
    };
};

export default generateStyles;
