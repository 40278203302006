import { useMemo, CSSProperties } from 'react';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

const initRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

export const useCalculatedRectStyle = ({
    childRect,
    childOffsetPercent,
    offsetPx,
    radius,
    customRectSize,
    padding = TOOLTIP_PADDING,
    shouldUpdate = new Date().getTime(),
    screenSize,
}) => {
    return useMemo<CSSProperties>(() => {
        const {
            x: childX,
            y: childY,
            width: childWidth,
            height: childHeight,
        } = childRect || initRect;
        const [childXPercent = 0, childYPercent = 0] = childOffsetPercent || [];
        const [xPxOffset = 0, yPxOffset = 0] = offsetPx || [];

        let calculatedLeft: number = childX - padding;

        let calculatedTop: number = childY - padding;
        const width = (customRectSize ? customRectSize[0] : childWidth) + padding * 2;
        const height = (customRectSize ? customRectSize[1] : childHeight) + padding * 2;

        if (childXPercent) {
            calculatedLeft += childWidth * (childXPercent / 100) - customRectSize[0];
        }
        if (childYPercent) {
            calculatedTop += childHeight * (childYPercent / 100) - customRectSize[1];
        }
        if (xPxOffset) {
            calculatedLeft += xPxOffset;
        }
        if (yPxOffset) {
            calculatedTop += yPxOffset;
        }

        return {
            position: 'fixed',
            zIndex: 10001,
            left: calculatedLeft,
            top: calculatedTop,
            width,
            height,
            background: 'transparent',
            backgroundBlendMode: 'screen',
            borderRadius: radius || '0px',
            transition: 'none',
            cursor: 'pointer',
        };
    }, [childRect, childOffsetPercent, customRectSize, padding, offsetPx, screenSize, shouldUpdate]);
};
