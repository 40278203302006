/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Card from 'shared-components/Card';

const styles = {
    cardTitleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: '#191919',
        opacity: 1,
    },
    cardBody: {
        marginTop: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    descriptionTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    attachmentPicture: {
        width: '240px',
        cursor: 'pointer',
    },
    attachmentPictureOpenModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        boxShadow: '24px',
        p: 4,
    },
};

type Props = {
    accessToChange?: boolean,
    attachmentUrl: string,
    description: string,
    deleteUrl?: string,
    title: string,
}

const PictureCard = ({
    accessToChange = false,
    attachmentUrl,
    description,
    title,
} : Props) => {
    const classes = useClasses(styles);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = () => setOpenModal(!openModal);

    return (
        <Card>
            <div className={classes.cardTitleRow}>
                <div className={classes.cardTitle}>{title}</div>
                {
                    accessToChange && (
                        <IconButton size="small">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )
                }
            </div>
            <div className={classes.cardBody}>
                <div>
                    <div className={classes.descriptionTitle}>Description</div>
                    <div>{description}</div>
                </div>
                <div>
                    <img
                        alt="Attachment"
                        src={attachmentUrl}
                        className={classes.attachmentPicture}
                        onClick={handleClick}
                        onKeyPress={handleClick}
                    />
                    <Modal
                        open={openModal}
                        onClose={handleClick}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <img
                            alt="Attachment"
                            src={attachmentUrl}
                            className={classes.attachmentPictureOpenModal}
                            onClick={handleClick}
                            onKeyPress={handleClick}
                        />
                    </Modal>
                </div>
            </div>
        </Card>
    );
};

export default PictureCard;
