import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import LaneComparison from 'Insights/LaneComparison/LaneComparison';
import Lanes from './Lanes';
import TemperatureSimulation from './Lanes/tabs/TemperatureSimulation';
import AddLane from './AddLane';

const LaneManagementRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management',
        element: <Navigate to="/lane-management/lanes" />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes',
        element: <Lanes />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/add',
        element: <AddLane />,
    },

    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/products',
        element: <div>Lane Products</div>,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lane-comparisons',
        element: <div>Lane Comparisons</div>,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lane-comparison',
        element: <LaneComparison />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/:laneId/temperature-simulation',
        element: <TemperatureSimulation />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/:laneId/definition',
        element: <AddLane />,
    },
];

export default LaneManagementRouter;
