import { ColumnsType } from 'shared-components/Table/dataTypes';
import { TFunction } from 'i18next';

const tableColumns: (t: TFunction) =>ColumnsType[] = (t) => [
    {
        Header: t('COMMON.SITE'),
        accessor: 'name',
        dataType: 'text',
    },
    {
        Header: t('COMMON.CITY'),
        accessor: 'city',
        dataType: 'text',
    },
    {
        Header: t('COMMON.COUNTRY'),
        accessor: 'country',
        dataType: 'text',
    },
];

export default tableColumns;
