import React, {
    useEffect,
    useState,
} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import TitleHeader from 'TitleHeader';
import { getQuestionnaireStat } from 'shared-components/CompanyInfoComponents/lib';
import icons from 'shared-components/icons';
import {
    QuestionnaireItem,
    QuestionnaireItemAnswer,
} from 'shared-components/dataTypes';
import useClasses from 'hooks/useClasses';
import SubCategoryGroup from '../SubCategoryGroup';
import styles from './QuestionGroup.style';

type Props = {
    answers: QuestionnaireItemAnswer[],
    setAnswers: (answers: QuestionnaireItemAnswer[]) => void,
    mainCategory: string,
    mainCategoryStatistic: number,
    statisticsBySubCategory: {
        [key: string]: number,
    },
    subCategories: {
        [key: string]: QuestionnaireItem[],
    },
    subCategoriesLimited: {
        [key: string]: QuestionnaireItemAnswer[],
    },
}

const QuestionGroup = ({
    answers,
    setAnswers,
    mainCategory,
    mainCategoryStatistic,
    statisticsBySubCategory,
    subCategoriesLimited,
    subCategories,
} : Props) => {
    const classes = useClasses(styles);
    const [
        currentSubCategoriesLimited,
        setCurrentSubCategoriesLimited,
    ] = useState(subCategoriesLimited);
    const [progress, setProgress] = useState(mainCategoryStatistic);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const allQuestions = Object.keys(currentSubCategoriesLimited).reduce((data, subCat) => {
            return [...data, ...currentSubCategoriesLimited[subCat]];
        }, []);

        setProgress(getQuestionnaireStat(allQuestions));
    }, [currentSubCategoriesLimited]);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.questionGroupContainer}>
            <TitleHeader onClick={handleClick}>
                <div className={classes.categoryInfoTitle}>
                    <div className={classes.titleWithProgressBar}>
                        <div>
                            {
                                `${mainCategory} ${progress < 100 ? '| ' : ''}`
                            }
                        </div>
                        {
                            progress < 100 && (
                                <LinearProgress
                                    variant="determinate"
                                    color="info"
                                    className={classes.progressBar}
                                    value={progress}
                                />
                            )
                        }
                        <div>
                            {
                                progress < 100
                                    ? `${progress}%`
                                    : (
                                        <img
                                            alt="progressFinishIcon"
                                            className={classes.progressFinishIcon}
                                            src={icons.checked_blue}
                                        />
                                    )
                            }

                        </div>
                    </div>
                    {
                        open
                            ? <img alt="ArrowDown" src={icons.arrow_down_lg} />
                            : <img alt="ArrowForward" src={icons.arrow_right_lg} />
                    }
                </div>
            </TitleHeader>
            <div className={classes.questionGroupGridContainer}>
                {
                    open && Object.keys(subCategories).map((subCategory) => {
                        return (
                            <SubCategoryGroup
                                answers={answers}
                                setAnswers={setAnswers}
                                key={subCategory}
                                statisticsBySubCategory={statisticsBySubCategory[subCategory]}
                                subCategories={subCategories[subCategory]}
                                currentSubCategoriesLimited={currentSubCategoriesLimited}
                                setSubCategoriesLimited={setCurrentSubCategoriesLimited}
                                subCategory={subCategory}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default QuestionGroup;
