import { ProductRelease } from 'dataTypes/SecureBackend/apiResponse';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import {
    DeliveryPointsInfo,
    IconTooltipInfo,
    ProcessEvidenceDeliveryData,
    IconAndMessage,
} from 'shared-components/dataTypes';
import {
    PROCESS_EVIDENCE_STEP_NAME,
    TEMPERATURE_STATUS,
} from 'shared-components/constants';
import {
    getIconByStatus,
    getIconTooltipInfo,
    getDeliveryPointsInfo,
    getProcessEvidenceDeliveryData,
} from 'utils/shipmentsDataProcessing';
import { TFunction } from 'i18next';

export interface ProductReleaseSeparate {
    order: {
        baseLeaseDays: number,
        baseLeaseUntil: string,
        leaseEndTimestamp: string,
        leaseStartTimestamp: string,
        orderNumber: string,
        shipmentStart: string,
        shipmentEnd: string,
        deliveryPointsInfo: DeliveryPointsInfo,
    },
    containerOpenedOn: string,
    containerSerialNumber: string,
    defaultLoggerType: string,
    id: number,
    palletCode: string,
    palletCode1: string,
    palletCode2: string,
    temperatureRangeMin: number,
    temperatureRangeMax: number,
    temperatureExcursionOn: string,
    temperatureReadout: {
        icon: string,
        statusMessage: string,
    },
    sealPartsInfo: ProcessEvidenceDeliveryData,
    packagingPartsInfo: ProcessEvidenceDeliveryData,
    palletPartsInfo: ProcessEvidenceDeliveryData,
    pallet1PartsInfo: ProcessEvidenceDeliveryData,
    pallet2PartsInfo: ProcessEvidenceDeliveryData,
    sealApprovalsIcon: string,
    containerApprovalsIcon: string,
    palletApprovalsIcon: string,
    pallet1ApprovalsIcon: string,
    pallet2ApprovalsIcon: string,
    containerApprovalsIconTooltipInfo: IconTooltipInfo,
    sealApprovalsIconTooltipInfo: IconTooltipInfo,
    temperatureReadoutTooltipInfo: IconTooltipInfo,
    palletApprovalsIconTooltipInfo: IconTooltipInfo,
    pallet1ApprovalsIconTooltipInfo: IconTooltipInfo,
    pallet2ApprovalsIconTooltipInfo: IconTooltipInfo,
    qmApproval: QmApprovalInfo,
}

const getTemperatureStatusMessage = (status: string, temperatureExcursionOn = '', t) => {
    switch (status) {
    case TEMPERATURE_STATUS.IN_RANGE:
        return t('PRODUCT_RELEASE.TEMP_IN_RANGE_MESSAGE');
    case TEMPERATURE_STATUS.WAITING_FOR_DATA:
        return t('PRODUCT_RELEASE.TEMP_WAITING_FOR_DATA_MESSAGE');
    case TEMPERATURE_STATUS.EXCURSION:
        return t('PRODUCT_RELEASE.TEMP_EXCURSION_MESSAGE').replace('#temperatureExcursionOn', temperatureExcursionOn);
    case TEMPERATURE_STATUS.NOT_APPLICABLE:
        return t('PRODUCT_RELEASE.TEMP_NOT_APPLICABLE_MESSAGE');
    case TEMPERATURE_STATUS.UNKNOWN:
        return t('PRODUCT_RELEASE.TEMP_UNKNOWN_MESSAGE');
    default:
        return t('PRODUCT_RELEASE.NO_DATA');
    }
};

export const getTemperatureReadoutDescription = (status: string, t) => {
    switch (status) {
    case TEMPERATURE_STATUS.IN_RANGE:
        return t('PRODUCT_RELEASE.TEMP_IN_RANGE_DESC');
    case TEMPERATURE_STATUS.WAITING_FOR_DATA:
        return t('PRODUCT_RELEASE.TEMP_WAITING_FOR_DATA_DESC');
    case TEMPERATURE_STATUS.EXCURSION:
        return t('PRODUCT_RELEASE.TEMP_EXCURSION_DESC');
    case TEMPERATURE_STATUS.NOT_APPLICABLE:
        return t('PRODUCT_RELEASE.TEMP_NOT_APPLICABLE_DESC');
    case TEMPERATURE_STATUS.UNKNOWN:
        return t('PRODUCT_RELEASE.TEMP_UNKNOWN_DESC');
    default:
        return t('PRODUCT_RELEASE.NO_DATA');
    }
};

export const getTemperatureReadout = (
    temperatureStatus: string,
    temperatureExcursionOn: string,
    t?: TFunction,
): IconAndMessage => {
    return {
        icon: getIconByStatus(temperatureStatus),
        statusMessage: getTemperatureStatusMessage(temperatureStatus, temperatureExcursionOn, t),
    };
};

export const fetchProcessedData = (
    rawData: ProductRelease,
    labels = {},
    users = [],
    t,
): ProductReleaseSeparate => {
    const {
        container = null,
        order = null,
        productRelease = null,
    } = rawData;

    const { defaultLoggerType = '', serialNumber = '' } = container;

    const {
        billing = null,
        leaseEndTimestamp = null,
        leaseStartTimestamp = null,
        orderNumber = '',
        shipmentEnd = null,
        shipmentStart = null,
    } = order || {};

    const { baseLeaseDays, baseLeaseUntilTimestamp } = billing || {};

    const {
        containerStatus,
        palletCode,
        palletCode1,
        palletCode2,
        shipmentEvidenceReceiving,
        shipmentEvidenceSending,
        palletStatus,
        palletStatus1,
        palletStatus2,
        qmApprovalDetails,
        qmApprovalStatus,
        qmApprovalComment,
        qmApprovedBy,
        qmApprovedOn,
        sealStatus,
        temperatureRangeMax,
        temperatureRangeMin,
        temperatureExcursionOn = null,
        temperatureStatus,
        id,
    } = productRelease || {};

    return {
        containerApprovalsIcon: getIconByStatus(containerStatus),
        containerApprovalsIconTooltipInfo: getIconTooltipInfo(containerStatus, labels),
        containerOpenedOn: null,
        containerSerialNumber: serialNumber || '',
        defaultLoggerType,
        id,
        order: {
            baseLeaseDays,
            baseLeaseUntil: baseLeaseUntilTimestamp || null,
            deliveryPointsInfo: getDeliveryPointsInfo(order),
            leaseEndTimestamp,
            leaseStartTimestamp,
            orderNumber,
            shipmentEnd,
            shipmentStart,
        },
        packagingPartsInfo: getProcessEvidenceDeliveryData(
            PROCESS_EVIDENCE_STEP_NAME.CONTAINER,
            shipmentEvidenceSending,
            shipmentEvidenceReceiving,
            users,
            labels,
        ),
        palletApprovalsIcon: getIconByStatus(palletStatus),
        pallet1ApprovalsIcon: getIconByStatus(palletStatus1),
        palletApprovalsIconTooltipInfo: getIconTooltipInfo(palletStatus, labels),
        palletPartsInfo: getProcessEvidenceDeliveryData(
            PROCESS_EVIDENCE_STEP_NAME.PALLET,
            shipmentEvidenceSending,
            shipmentEvidenceReceiving,
            users,
            labels,
        ),
        pallet1ApprovalsIconTooltipInfo: getIconTooltipInfo(palletStatus1, labels),
        pallet1PartsInfo: getProcessEvidenceDeliveryData(
            PROCESS_EVIDENCE_STEP_NAME.PALLET_1,
            shipmentEvidenceSending,
            shipmentEvidenceReceiving,
            users,
            labels,
        ),
        pallet2ApprovalsIcon: getIconByStatus(palletStatus2),
        pallet2ApprovalsIconTooltipInfo: getIconTooltipInfo(palletStatus2, labels),
        pallet2PartsInfo: getProcessEvidenceDeliveryData(
            PROCESS_EVIDENCE_STEP_NAME.PALLET_2,
            shipmentEvidenceSending,
            shipmentEvidenceReceiving,
            users,
            labels,
        ),
        palletCode,
        palletCode1,
        palletCode2,
        qmApproval: {
            qmApprovalComment,
            qmApprovalDetails,
            qmApprovalIcon: getIconByStatus(qmApprovalStatus),
            qmApprovalStatus,
            qmApprovedBy,
            qmApprovedOn,
        },
        sealApprovalsIcon: getIconByStatus(sealStatus),
        sealApprovalsIconTooltipInfo: getIconTooltipInfo(sealStatus, labels),
        sealPartsInfo: getProcessEvidenceDeliveryData(
            PROCESS_EVIDENCE_STEP_NAME.SEAL,
            shipmentEvidenceSending,
            shipmentEvidenceReceiving,
            users,
            labels,
        ),
        temperatureExcursionOn,
        temperatureRangeMax: temperatureRangeMax || null,
        temperatureRangeMin: temperatureRangeMin || null,
        temperatureReadout: getTemperatureReadout(temperatureStatus, temperatureExcursionOn, t),
        temperatureReadoutTooltipInfo: {
            description: getTemperatureReadoutDescription(temperatureStatus, t),
            descriptionTitle: labels[temperatureStatus] || temperatureStatus,
        },
    };
};
