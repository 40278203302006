import React, {
    useCallback, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';

import { PaginationResultNotificationDto } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useAvailableHeight from 'hooks/useAvailableHeight';
import CommonPagination from 'shared-components/CommonPagination';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LayoutContext } from 'Contexts/LayoutContext';
import useClasses from 'hooks/useClasses';
import {
    ClientSideFilter,
    initialClientSideFilter,
    ProcessedNotification,
    getProcessedNotifications,
    getUpdatedNotifications,
} from './lib';
import ControlHeader from './components/ControlHeader';
import NotificationContent from './components/NotificationContent';
import getTableColumns from './getTableColumns';

import NotificationClientSideFilter, {
    STATUS_OPTIONS,
} from './components/NotificationsClientSideFilter/NotificationClientSideFilter';
import styles from './Notifications.style';

// Filtration logic for 'none' option on notificationStatus
export const defaultStatusFilterLogic = (notification: ProcessedNotification) => !notification.hasBeenDeleted;
const PER_PAGE = 50;

const Notifications = () => {
    const classes = useClasses(styles);
    const {
        GetAll: getNotifications,
        FlexibleRequest: updateNotificationInfo,
    } = useSecureBackendEndpoints('notifications').requests;
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useCustomTranslation();
    const {
        GetAll: getNotificationsTrash,
    } = useSecureBackendEndpoints('notifications/trash').requests;
    const { userInfo, updateNotificationsCount } = useCurrentUserContext();

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [notifications, setNotifications] = useState<ProcessedNotification[]>([]);
    const [updatedNotifications, setUpdatedNotifications] = useState<ProcessedNotification[]>([]);
    const [clickedRowNotification, setClickedRowNotification] = useState<ProcessedNotification>(null);
    const [refreshData, setRefreshData] = useState(true);
    const theme = useTheme<SkycellThemeInterface>();
    const [filters, setFilters] = useState<ClientSideFilter>(initialClientSideFilter);
    const availableHeight = useAvailableHeight();
    const {
        setMenuTabs,
        setCustomPageTitle,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                title: 'My Profile',
                link: '/my-profile',
                iconSrc: '',
                activePage: false,
            },
            {
                title: 'Notifications',
                link: '/notifications',
                iconSrc: '',
                activePage: true,
            },
        ]);
        setCustomPageTitle(userInfo.name);
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, []);

    useEffect(() => {
        if (refreshData) {
            updateNotificationsCount();
            (async () => {
                try {
                    const { resultList: nonTrashed = [] }:
                        PaginationResultNotificationDto = (await getNotifications(
                            { page: 0, pageSize: 9999 },
                        )).data;
                    const { resultList: trashed = [] }:
                        PaginationResultNotificationDto = (await getNotificationsTrash(
                            { page: 0, pageSize: 9999 },
                        )).data;

                    setNotifications(getProcessedNotifications([...nonTrashed, ...trashed])
                        .sort((a, b) => b.createdOn - a.createdOn));
                    setRefreshData(false);
                } catch (error) {
                    setNotifications([]);
                }
            })();
        }
    }, [refreshData]);

    useEffect(() => {
        setUpdatedNotifications(getUpdatedNotifications(notifications, selectedIds));
    }, [notifications, selectedIds]);

    const filteredNotifications = useMemo<ProcessedNotification[]>(() => {
        return updatedNotifications
            .map(it => (it.id === clickedRowNotification?.id ? { ...it, isExpanded: true } : it))
            .filter(notification => {
                const { logic } = STATUS_OPTIONS.find(it => it.value === filters.notificationStatus)
                || { logic: defaultStatusFilterLogic };

                return logic(notification);
            })
            .filter(notification => filters.group.includes(notification.group));
    }, [filters, updatedNotifications, clickedRowNotification]);

    useEffect(() => {
        if (clickedRowNotification !== null) {
            (async () => {
                try {
                    const { id, hasBeenRead } = clickedRowNotification;

                    if (!hasBeenRead) {
                        const result = await updateNotificationInfo(
                            'POST', `${id}/action/mark-as-read`, null, {
                                'Content-Type': 'application/json',
                            },
                        );

                        if (result.status === 200) {
                            setNotifications(prev => prev.map(it => (clickedRowNotification.id === it.id ? {
                                ...it,
                                hasBeenRead: true,
                            } : it)));
                            setSelectedIds(prev => prev.filter(it => it !== id));
                        }
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    updateNotificationsCount();
                }
            })();
        }
    }, [clickedRowNotification]);

    const handleRowClick = useCallback(async (data: ProcessedNotification) => {
        setClickedRowNotification(prev => {
            const { id } = prev || {};

            return data.id === id
                ? null
                : data;
        });
        setSelectedIds(prev => prev.filter(it => it !== data.id));
    }, []);

    const NotificationContentItem = useMemo(() => {
        if (clickedRowNotification === null) {
            return null;
        }

        return (
            <NotificationContent
                notification={clickedRowNotification}
                userInfo={userInfo}
            />
        );
    }, [clickedRowNotification, selectedIds]);

    useEffect(() => {
        setClickedRowNotification(null);
        setSelectedIds([]);
    }, [filters]);
    const columns = useMemo(() => {
        return getTableColumns(setSelectedIds,
            setClickedRowNotification,
            theme,
            <ControlHeader
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                setRefreshData={setRefreshData}
                setNotifications={setNotifications}
                notifications={filteredNotifications}
                restoreMode={filters.notificationStatus === 'DELETED'}
            />);
    }, [selectedIds, filteredNotifications, filters.notificationStatus]);

    useEffect(() => {
        setClickedRowNotification(null);
    }, [currentPage]);

    useEffect(() => {
        if (currentPage * PER_PAGE > filteredNotifications.length) {
            setCurrentPage(Math.max(Math.ceil(filteredNotifications.length / PER_PAGE), 1));
        }
    }, [filteredNotifications]);

    return (
        <PageWithFilter>
            <NotificationClientSideFilter
                setFilters={setFilters}
                filters={filters}
                notifications={updatedNotifications}
                filteredNotifications={filteredNotifications}
            />
            <div style={{ display: 'block', width: '100%', maxHeight: availableHeight, padding: '10px' }}>
                <div style={{ height: 'calc(100% - 90px)' }}>
                    <Table
                        columns={columns}
                        data={filteredNotifications.filter((it, i) => {
                            const page = currentPage - 1;

                            return i >= page * PER_PAGE && i < page * PER_PAGE + PER_PAGE;
                        })}
                        tableMaxHeight="100%"
                        onRowClick={handleRowClick}
                        rowExtension={NotificationContentItem}
                        classNames={{
                            rowExtensionClassName: classes.expansionRoot,
                            headerCell: classes.noBars,
                        }}
                        embed
                    />
                </div>
                {
                    filteredNotifications?.length > 0 && (
                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <CommonPagination
                                currentPage={currentPage}
                                shipmentsCount={filteredNotifications?.length}
                                setCurrentPage={setCurrentPage}
                                objectNamePlural={t('COMMON.NOTIFICATIONS')}
                                pageSize={PER_PAGE}
                            />
                        </div>
                    )
                }
            </div>
        </PageWithFilter>
    );
};

export default Notifications;
