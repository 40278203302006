import { css } from '@emotion/css';

const generateStyles = (theme, props: { searchBoxLeft: number }) => {
    const preview = css({
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
        zIndex: 40000,
        transition: '300ms ease',
        width: 'auto',
        maxWidth: `calc(100vw - ${props.searchBoxLeft + 40}px)`,
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
    });

    const open = css({
        opacity: '1!important',
        pointerEvents: 'unset',
    });

    const input = css({
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    });

    const rightArrow = css({
        width: '18px',
        height: '18px',
        marginLeft: '5px',
    });

    const moreSpan = css({
        marginTop: '10px',
        display: 'flex',
        color: theme.palette.secondary[600],
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        width: 'min-content',
    });

    const paddedTable = css({
        padding: '0 10px 10px 10px',
    });

    const clearSearchBtn = css({
        minWidth: '24px',
        padding: '0px',
        marginLeft: '8px',
    });

    return {
        preview,
        open,
        input,
        rightArrow,
        moreSpan,
        paddedTable,
        clearSearchBtn,
    };
};

export default generateStyles;
