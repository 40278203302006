import React, { useMemo } from 'react';
import useClasses from 'hooks/useClasses';
import styles from './LyingSince.style';

type Props = {
    fontSize?: 'small' | 'medium' | 'large',
    minutes: number,
    isGrey?: boolean,
}

export const LyingDiff = ({
    fontSize = 'large',
    minutes = 0,
    isGrey = false,
}: Props) => {
    const classes = useClasses(styles);

    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);

    const value = useMemo(() => {
        if (minutes < 60) {
            return `${minutes}min`;
        } else if (hours < 24) {
            return `${hours}h`;
        } else {
            return `${days}d`;
        }
    }, [minutes]);

    return (
        <div className={`${classes.background} ${days < 7 ? classes.backgroundGreen
            : days < 14 ? classes.backgroundBlue : days < 30 ? classes.backgroundYellow : classes.backgroundRed} 
            ${isGrey ? classes.backgroundGrey : ''}`}
        >
            <span className={`${classes.statusDot} ${days < 7 ? classes.statusDotBackgroundGreen
                : days < 14 ? classes.statusDotBackgroundBlue
                    : days < 30 ? classes.statusDotBackgroundYellow : classes.statusDotBackgroundRed} 
                    ${isGrey ? classes.statusDotBackgroundGrey : ''}`}
            />
            <div className={classes[fontSize]}>
                {value}
            </div>
        </div>
    );
};
