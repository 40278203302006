import React, {
    Dispatch,
    SetStateAction,
} from 'react';
import { RequestStatus } from 'dataTypes/common';
import StatusInfo from 'shared-components/StatusInfo';
import ShipmentsMap from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/ShipmentsMap';
import useClasses from 'hooks/useClasses';
import {
    GenericShipmentData,
} from '../lib';
import styles from './MapView.style';

type Props = {
    error: string,
    shipments: GenericShipmentData[],
    showAirportsInfo: boolean,
    status?: RequestStatus,
    updateMap?: Dispatch<SetStateAction<number>>,
    zoom: number,
    setZoom: (arg: number) => void,

};

const MapView = ({
    error,
    shipments = [],
    showAirportsInfo,
    status = 'SUCCESS',
    updateMap,
    zoom,
    setZoom,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <div style={{ display: 'flex' }}>
            <div className={classes.mapViewContainer}>
                {
                    status === 'PENDING'
                        ? (
                            <StatusInfo
                                error={error}
                                status={status}
                            />
                        ) : (
                            <ShipmentsMap
                                shipments={shipments}
                                showAirportsInfo={showAirportsInfo}
                                updateMap={updateMap}
                                zoom={zoom}
                                setZoom={setZoom}
                            />
                        )
                }
            </div>
        </div>
    );
};

export default MapView;
