const styles = theme => ({
    card: {
        padding: '16px!important',
        marginTop: '10px!important',
    },
    mapCard: {
        marginTop: theme.spacing(1.5),
    },
    loggerTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.secondary[800],
        fontSize: '32px !important',
        letterSpacing: '0.26px',
        marginBottom: '10px',
        lineHeight: 1,
    },
});

export default styles;
