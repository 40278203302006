import React from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import icons from 'shared-components/icons';

import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import styles from './Legend.style';

function Legend() {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (

        <StandaloneSearchBox>

            <UncontrolledTooltip
                description={t('EXPLANATION_TOOLTIPS.MAP_LEGEND')}
                descriptionTitle="ASD"
                placement="top"
                whiteSpace="pre"
            >

                <QuickHelpTooltip tooltipInfo={{
                    order: 4,
                    uid: 'legendShipmentQuickHelp',
                    padding: 0,
                }}
                >
                    <div className={classes.legend}>
                        <div className={classes.title}>Legend</div>
                        <img
                            alt="Current Location"
                            className={classes.dotIcon}
                            src={icons.map_location_via}
                        />
                        <div className={classes.description}>Current Location</div>
                        <img
                            alt="Completed"
                            className={classes.lineIcon}
                            src={icons.legend_completed}
                        />
                        <div className={classes.description}>Completed</div>
                        <img
                            alt="Planned"
                            className={classes.lineIcon}
                            src={icons.legend_planned}
                        />
                        <div className={classes.description}>Planned</div>
                    </div>
                </QuickHelpTooltip>
            </UncontrolledTooltip>

        </StandaloneSearchBox>

    );
}

export default Legend;
