import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

const styles = (theme) => ({
    input: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& $input': {
            paddingLeft: '8px',
        },
    },
    clearSearchBtn: {
        minWidth: '24px',
        padding: '0px',
        marginLeft: '8px',
    },
});

type Props = {
    setQuery?: Dispatch<SetStateAction<string>>,
    query?: string,
}

const TextSearchFilter = ({
    setQuery,
} : Props) => {
    const theme = useTheme();
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [searchString, setSearchString] = useState('');
    const [lastQueryTyped, setLastQueryTyped] = useState('');

    const searchStringChanged = (event) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (searchString && searchString !== lastQueryTyped) {
                setLastQueryTyped(searchString);
            }
            setQuery(searchString);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [searchString, lastQueryTyped]);

    const handleClickClearSearch = useCallback(() => {
        setSearchString('');
        setLastQueryTyped('');
        setQuery('');
    }, []);

    return (
        <div style={{
            display: 'inline-flex',
        }}
        >
            <TextField
                className={classes.input}
                id="TextFieldInput"
                onInput={searchStringChanged}
                value={searchString}
                placeholder={t('COMMON.SEARCH')}
                variant="outlined"
                style={{ flex: 1 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search style={{ color: theme.palette.common.black }} />
                        </InputAdornment>
                    ),
                    className: classes.input,
                }}
            />
            <Button
                className={classes.clearSearchBtn}
                onClick={() => handleClickClearSearch()}
                disabled={!searchString}
            >
                <CloseIcon />
            </Button>

        </div>
    );
};

export default TextSearchFilter;
