const styles = theme => ({
    listBox: {
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        '& .MuiInputBase-root': { // works
            color: 'red!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
    },
    textFieldTextAutocomplete: {
        padding: '0',
        paddingRight: '10px!important',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,
        '& input': {
            padding: '3px 15px 3px 0!important',
        },
    },
    autocompleteClass: {
        marginTop: '20px',
        '& label': {
            color: theme.palette.common.black,
        },
    },
    cardTitleClass: {
        textTransform: 'capitalize',
    },
    checkboxRow: {
        margin: '7px 15px',
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    checkboxStyle: {
        margin: '0 9px',
        padding: '0',
    },
    count: {
        right: '0',
        marginLeft: 'auto',
        textAlign: 'right',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    formControlLabelStyle: {
        whiteSpace: 'nowrap',
    },
    checkboxContainer: {
        display: 'flex',
    },
    checkboxIcon: {
        marginRight: '5px',
        width: '20px',
        height: '20px',
    },
    currentItemInfoContainer: {
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,
    },
    currentItemInfoTitle: {
        margin: '12px 0 5px',
        font: 'normal normal normal 12px/16px Roboto',
        textTransform: 'capitalize',
        letterSpacing: '0.4px',
        color: theme.palette.common.black,
        opacity: 1,
    },
    standardLinkShipmentDetails: {
        marginLeft: '24px',
    },
    activePackaging: {
        borderTop: '1px solid #EDAE49',
        borderRight: '6px solid #EDAE49',
        borderBottom: '1px solid #EDAE49',
        borderLeft: '6px solid #EDAE49',
        padding: '3px',
    },
    packaging: {
        marginBottom: '5px',
    },
});

export default styles;
