import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Polyline } from '@react-google-maps/api';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';

type Props = {
    polylines: any[],
    selected?: any,
    widthPercent: number,
    height: string,
}

const LocationHistoryMap = ({
    polylines = [],
    selected = null,
    widthPercent,
    height,
}: Props) => {
    const theme = useTheme();
    const [gMap, setGMap] = useState(null);
    const [, setBounds] = useState<number[]>([]);

    return (
        <div style={{
            width: `${widthPercent}%`,
            height,
        }}
        >
            <BaseMap
                setBounds={setBounds}
                gMap={gMap}
                setGMap={setGMap}
                mapContainerStyle={{
                    width: '100%',
                    height,
                    zIndex: 1,
                }}
            >
                {
                    polylines.map((line, ind) => {
                        return (
                            <Polyline
                                key={`polyline${ind}_${line.lng}_${line.lng}`}
                                path={line}
                                options={{
                                    strokeColor: theme.palette.primary['deepBlue'],
                                }}
                            />
                        );
                    })
                }
                {selected && (
                    <LoggerMarker
                        key={selected}
                        loggerGroup={[selected]}
                        showLoggerTypes={false}
                        showLastConnection={false}
                        onHover={() => {}}
                        isSelected={false}
                    />
                )}
            </BaseMap>
        </div>
    );
};

export default LocationHistoryMap;
