import { UserInfo, ExtendedProfile } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {},
    email: '',
    emailVerified: null,
    firstName: '',
    lastName: '',
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    attributes: {},
    email: '',
    name: '',
};

export const getUserInfo = (extendedProfile: ExtendedProfile): UserInfo => {
    const {
        assignedRoles = [],
        attributes = null,
        email,
        name,
    } = extendedProfile;

    return {
        assignedRoles,
        attributes,
        email,
        name,
    };
};
