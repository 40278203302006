import { css } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CIRCLE_GROW, CIRCLE_SIZE } from './TooltipCluster';

const generateStyles = (theme: SkycellThemeInterface) => {
    const wrapper = css({
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
    });

    const tooltip = css({
        background: 'white',
        width: `${CIRCLE_SIZE}px`,
        height: `${CIRCLE_SIZE}px`,
        minWidth: `${CIRCLE_SIZE}px`,
        minHeight: `${CIRCLE_SIZE}px`,
        borderRadius: '50%',
        border: `${CIRCLE_SIZE / 10}px solid ${theme.palette.primary.deepBlue}`,
        color: theme.palette.primary.deepBlue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        transition: '200ms ease',
        cursor: 'unset',
    });

    const tooltipWarning = css({
        borderColor: theme.palette.common.red,
        color: theme.palette.common.red,
    });

    const tooltipPredicted = css({
        borderColor: theme.palette.common.yellow,
        color: theme.palette.common.yellow,
    });

    const active = css({
        background: theme.palette.primary.deepBlue,
        color: 'white',
        width: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        height: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minWidth: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minHeight: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        transform: `translate(${-CIRCLE_GROW / 2}px,${-CIRCLE_GROW / 2}px)`,
        [`&.${tooltipWarning}`]: {
            background: theme.palette.common.red,
        },
        [`&.${tooltipPredicted}`]: {
            backgroundColor: theme.palette.common.yellow,
            borderColor: theme.palette.common.yellow,
        },
    });

    const activeWarning = css({
        backgroundColor: theme.palette.common.red,
        color: 'white',
        width: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        height: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minWidth: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minHeight: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        transform: `translate(${-CIRCLE_GROW / 2}px,${-CIRCLE_GROW / 2}px)`,
        [`&.${tooltipWarning}`]: {
            background: theme.palette.common.red,
        },
        [`&.${tooltipPredicted}`]: {
            background: theme.palette.common.yellow,
        },
    });

    const clusterBody = css({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '10px',
        paddingBottom: '150px',
        marginLeft: '-10px',
    });

    const tooltipHeader = css({
        display: 'flex',
        margin: '1px auto',
        alignItems: 'center',
    });

    const headerInfoGroup = css({
        display: 'flex',
        margin: '0',
        alignItems: 'center',
    });

    const headerInfo = css({
        margin: '0 3px',
        alignItems: 'center',
    });

    const headerInfoMessageText = css({
        margin: '0 3px',
        alignItems: 'center',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    });

    const headerInfoWithTextStyle = css({
        margin: '2px 6px',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    });

    const containerIcon = css({
        marginTop: '2px',
        margin: 'auto 2px',
        width: '12px',
        height: '13px',
    });

    const warningIcon = css({
        marginTop: '2px',
        margin: 'auto 2px',
        width: '22px',
        height: '19px',
    });

    return {
        wrapper,
        tooltip,
        tooltipWarning,
        tooltipPredicted,
        active,
        activeWarning,
        clusterBody,
        tooltipHeader,
        headerInfoGroup,
        headerInfo,
        headerInfoMessageText,
        headerInfoWithTextStyle,
        containerIcon,
        warningIcon,
    };
};

export default generateStyles;
