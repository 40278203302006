import React, {
    MouseEvent,
    useEffect,
    useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import {
    ClientSideFilterType,
    ColumnFilterOptions,
    ColumnFilterType,
    EnumFilterOption,
} from 'shared-components/Table/dataTypes';

import { getFilterFieldCount } from '../../lib';
import FilterIcon from './FilterIcon';
import EnumFilter from './filterTypes/EnumFilter';
import DateFilter from './filterTypes/DateFilter';
import NumberFilter from './filterTypes/NumberFilter';
import TextFilter from './filterTypes/TextFilter';

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
};

type Props = {
    columnFilterOptions?: ColumnFilterOptions,
    clientSideFilter: ClientSideFilterType,
    filterKey: string,
    filterType: ColumnFilterType,
    setClientSideFilter: (clientSideFilter: ClientSideFilterType) => void,
}

const ClientSideFilter = ({
    clientSideFilter,
    filterKey,
    columnFilterOptions,
    filterType,
    setClientSideFilter,
} : Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
    const [enumSelectedOptions, setEnumSelectedOptions] = useState<(string)[]>([]);
    const [allEnumFilterOption, setAllEnumFilterOption] = useState<EnumFilterOption[]>([]);
    const [enumFilterOption, setEnumFilterOption] = useState<EnumFilterOption[]>([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [numberMin, setNumberMin] = useState('');
    const [numberMax, setNumberMax] = useState('');
    const [textFieldSample, setTextFieldSample] = useState('');

    const open = Boolean(anchorEl);
    const filterTypeIsEnumOrEnumList = filterType === 'enum' || filterType === 'enumList';

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /** Control of available enum or enumList options, also depends on other filter parts */
    useEffect(() => {
        if (filterTypeIsEnumOrEnumList
            && allEnumFilterOption.length === 0
            && getFilterFieldCount(clientSideFilter) === 0
        ) {
            setAllEnumFilterOption(columnFilterOptions[filterKey] || []);
        }

        if (filterTypeIsEnumOrEnumList && (
            getFilterFieldCount(clientSideFilter) === 0
            || (Object.keys(clientSideFilter).length === 1 && Object.keys(clientSideFilter)[0] === filterKey))
        ) {
            setEnumFilterOption(allEnumFilterOption);
        } else if (filterTypeIsEnumOrEnumList && anchorEl === null) {
            setEnumFilterOption(columnFilterOptions[filterKey] || []);
        }
    }, [
        clientSideFilter,
        columnFilterOptions,
        anchorEl,
    ]);

    /** enum and enumList filter controller, sets selected options */
    useEffect(() => {
        if (enumSelectedOptions.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [filterKey]: unnecessary, ...filtersWithoutCurrentKey } = clientSideFilter;

            setClientSideFilter({ ...filtersWithoutCurrentKey });
        } else {
            setClientSideFilter({
                ...clientSideFilter,
                [filterKey]: enumSelectedOptions,
            });
        }
    }, [enumSelectedOptions, enumSelectedOptions.length]);

    /** date filter controller, sets date limits */
    useEffect(() => {
        const min = dateFrom ? new Date(dateFrom).getTime() : null;
        const max = dateTo ? new Date(dateTo).getTime() : null;

        if (min === null && max === null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [filterKey]: unnecessary, ...filtersWithoutCurrentKey } = clientSideFilter;

            setClientSideFilter({ ...filtersWithoutCurrentKey });
        } else {
            setClientSideFilter({
                ...clientSideFilter,
                [filterKey]: [min, max],
            });
        }
    }, [dateFrom, dateTo]);

    /** number filter controller, sets number limits */
    useEffect(() => {
        const min = Number.isNaN(parseFloat(numberMin)) ? null : parseFloat(numberMin);
        const max = Number.isNaN(parseFloat(numberMax)) ? null : parseFloat(numberMax);

        if (min === null && max === null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [filterKey]: unnecessary, ...filtersWithoutCurrentKey } = clientSideFilter;

            setClientSideFilter({ ...filtersWithoutCurrentKey });
        } else {
            setClientSideFilter({
                ...clientSideFilter,
                [filterKey]: [min, max],
            });
        }
    }, [numberMin, numberMax]);

    /** text filter controller, sets text sample for match */
    useEffect(() => {
        if (textFieldSample.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [filterKey]: unnecessary, ...filtersWithoutCurrentKey } = clientSideFilter;

            setClientSideFilter({ ...filtersWithoutCurrentKey });
        } else {
            setClientSideFilter({
                ...clientSideFilter,
                [filterKey]: [textFieldSample.toLowerCase()],
            });
        }
    }, [textFieldSample]);

    return (
        <div>
            <IconButton
                style={{ padding: '0!important' }}
                size="small"
                onClick={handleClick}
            >
                <FilterIcon isActive={Boolean(clientSideFilter[filterKey])} />
            </IconButton>
            <Popover
                id={`simple-popover-${filterKey}`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                // anchorOrigin={transformOrigin}
                // transformOrigin={anchorOrigin}
                anchorOrigin={filterTypeIsEnumOrEnumList ? anchorOrigin : transformOrigin}
                transformOrigin={filterTypeIsEnumOrEnumList ? transformOrigin : anchorOrigin}
            >
                {
                    (() => {
                        switch (filterType) {
                        case 'date':
                            return (
                                <DateFilter
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    filterOptions={columnFilterOptions[filterKey] || []}
                                    setDateFrom={setDateFrom}
                                    setDateTo={setDateTo}
                                />
                            );
                        case 'enum':
                        case 'enumList':
                            return (
                                <EnumFilter
                                    selectedOptions={enumSelectedOptions}
                                    availableOptions={enumFilterOption}
                                    setSelectedOptions={setEnumSelectedOptions}
                                />
                            );
                        case 'number':
                            return (
                                <NumberFilter
                                    numberMin={numberMin}
                                    numberMax={numberMax}
                                    filterOptions={columnFilterOptions[filterKey] || []}
                                    setNumberMin={setNumberMin}
                                    setNumberMax={setNumberMax}
                                />
                            );
                        case 'text':
                            return (
                                <TextFilter
                                    textFieldSample={textFieldSample}
                                    setTextFieldSample={setTextFieldSample}
                                />
                            );
                        default:
                            return null;
                        }
                    })()
                }

            </Popover>
        </div>
    );
};

export default ClientSideFilter;
