import { useMemo } from 'react';
import { TIME_IN_MS } from 'shared-components/constants';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import { dateToNumber } from 'utils/timeUtils';

type Props = {
    temperatureData: SensorDataItem[],
    dateTimeFrom: string,
    dateTimeTo: string,
}
const useChartLimits = ({
    temperatureData = [],
    dateTimeFrom,
    dateTimeTo,
}: Props) => {
    return useMemo(() => {
        const itemStep = 10 * TIME_IN_MS.minute;

        const globalFromMillis = dateToNumber(dateTimeFrom, false);
        const globalToMillis = dateToNumber(dateTimeTo, false);

        if (!temperatureData || temperatureData.length === 0) {
            return {
                xMin: globalFromMillis,
                xMax: globalToMillis,
            };
        }

        const sensorDataFirstDate = dateToNumber(temperatureData[0].t, false);
        const sensorDataLastDate = dateToNumber(temperatureData.at(-1).t, false);

        const xMin = (globalFromMillis + itemStep < sensorDataFirstDate)
            ? globalFromMillis
            : sensorDataFirstDate;

        const xMax = (sensorDataLastDate + itemStep < globalToMillis)
            ? globalToMillis
            : sensorDataLastDate;

        return {
            xMin, xMax: xMax + TIME_IN_MS.minute,
        };
    }, [
        temperatureData,
        dateTimeFrom,
        dateTimeTo,
    ]);
};

export default useChartLimits;
