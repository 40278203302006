import useClasses from 'hooks/useClasses';
import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import ProposedValueTooltip from '../../tooltips/ProposedValueTooltip';
import { styles } from './NumberSpinnerInput.style';

type Props = {
    value: any,
    onChange: Function,
    inputClass: string,
    proposedValue: number
}

const minMax = (val) => Math.max(-100, Math.min(Number(val), 100));
const incDecNumber = (value, direction) => {
    const newVal = Number(value) + (direction ? 1 : -1);

    return minMax(newVal);
};

const NumberSpinnerInput = ({
    value,
    onChange,
    inputClass,
    proposedValue,
}: Props) => {
    const classes = useClasses(styles);
    const [proposedValueTooltip, setProposedValueTooltip] = useState(false);
    const [arrowActivated, setArrowActivated] = useState({
        up: false,
        down: false,
    });

    const activateArrow = useCallback((bool) => {
        setArrowActivated(prevState => ({
            ...prevState,
            [bool ? 'up' : 'down']: true,
        }));
        setTimeout(() => {
            setArrowActivated(prevState => ({
                ...prevState,
                [bool ? 'up' : 'down']: false,
            }));
        }, 100);
    }, [arrowActivated, setArrowActivated]);

    const onUse = useCallback(() => {
        setProposedValueTooltip(false);
        onChange(proposedValue);
    }, [setProposedValueTooltip, onChange, proposedValue]);

    const onKeyDown = useCallback((event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            activateArrow(event.key === 'ArrowUp');
            event.preventDefault();
            onChange(incDecNumber(value, event.key === 'ArrowUp'));
        }
    }, [value]);

    useEffect(() => {
        const event = () => {
            setProposedValueTooltip(false);
        };

        document.addEventListener('click', event);
        return () => {
            document.removeEventListener('click', event);
        };
    }, []);

    return (
        <ProposedValueTooltip
            open={!!proposedValue && proposedValueTooltip}
            onUse={proposedValue === value ? null : onUse}
            proposedValue={proposedValue}
            proposedValueText={`${proposedValue} °C`}
            title="Proposed Temperature: "
            bottomTitle="Proposed Temperature: "
        >
            <TextField
                className={`${inputClass}`}
                onFocus={() => {
                    setProposedValueTooltip(true);
                }}
                onClick={e => {
                    e.stopPropagation();
                }}
                onBlur={() => setProposedValueTooltip(false)}
                value={value || '0'}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        padding: '0',
                    },
                    endAdornment: (
                        <InputAdornment position="start" style={{ marginRight: 0 }}>
                            <div className={classes.celsius}>°C</div>
                            <div className={classes.arrowsContainer}>
                                <div
                                    className={[classes.arrow,
                                        arrowActivated.up ? classes.arrowActive : ''].join(' ')}
                                    onClick={() => onChange(incDecNumber(value, true))}
                                >
                                    <ArrowUpward />
                                </div>
                                <div
                                    className={[classes.arrow,
                                        arrowActivated.down ? classes.arrowActive : ''].join(' ')}
                                    onClick={() => onChange(incDecNumber(value, false))}
                                >
                                    <ArrowDownward />
                                </div>
                            </div>

                        </InputAdornment>
                    ),
                }}
                onKeyDown={onKeyDown}
                onChange={ev => {
                    const newValue = ev.target.value;

                    if (Number.isNaN(Number(newValue))) return;

                    onChange(newValue !== null ? minMax(newValue).toString() : '');
                }}
            />

        </ProposedValueTooltip>

    );
};

export default NumberSpinnerInput;
