import useAvailableHeight from 'hooks/useAvailableHeight';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React from 'react';
import useClasses from 'hooks/useClasses';
import { PICTURES } from '../constants';
import styles from './NoAccessRights.style';

type Props = {
    text?: string,
}

const NoAccessRights = ({
    text,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const maxHeight = useAvailableHeight();

    return (
        <div style={{
            maxHeight,
            paddingRight: '20px',
            paddingLeft: '20px',
        }}
        >
            <div className={classes.title}>Ouuups! It seems you do not have access.</div>
            <div className={classes.text}>
                {text || t('COMMON.CONTACT_ADMIN')}
            </div>
            <img
                className={classes.picture}
                src={PICTURES.noAccessRightsWithout404Bubble}
                alt="No Access Rights"
                style={{
                    maxHeight,
                    minHeight: '400px',
                    height: '60vh',
                    width: 'auto',
                }}
            />
        </div>
    );
};

export default NoAccessRights;
