import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import ApprovalModal from 'shared-components/ApprovalModal';
import ApprovalReviseModal from 'shared-components/ApprovalReviseModal';
import { APPROVAL_STATUS } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ApprovalSummary.style';

type Props = {
    orderNumber: string,
    evidenceId: number,
    qmApproval: QmApprovalInfo,
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
}

const ButtonsContainer = ({
    dataUpdateTrigger,
    evidenceId,
    orderNumber,
    qmApproval = {
        qmApprovalStatus: '',
        qmApprovalIcon: '',
        qmApprovalComment: '',
        qmApprovedBy: '',
        qmApprovedOn: '',
        qmApprovalDetails: [],
    },
} : Props) => {
    const classes = useClasses(styles);
    const [openApprovalModal, setOpenApprovalModal] = useState(false);
    const [openApprovalReviseModal, setOpenApprovalReviseModal] = useState(false);
    const { t } = useCustomTranslation();

    return (
        <div
            key="buttonsContainer"
            className={classes.buttonsContainer}
        >
            {
                qmApproval.qmApprovalStatus === APPROVAL_STATUS.NOT_CHECKED
                    ? (
                        <div>
                            <div className={classes.qmapproveRejectButtonsContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => setOpenApprovalModal(true)}
                                >
                                    {t('COMMON.APPROVE_REJECT')}
                                </Button>
                            </div>
                            <ApprovalModal
                                open={openApprovalModal}
                                setOpen={setOpenApprovalModal}
                                shipmentNumber={orderNumber}
                                productReleaseId={evidenceId}
                                dataUpdateTrigger={dataUpdateTrigger}
                            />
                        </div>
                    )
                    : (
                        <div>
                            <div>
                                <div className={classes.qmStatusContainer}>
                                    <img
                                        key={`qmApprovalIcon${evidenceId}`}
                                        className={classes.qmApprovalIcon}
                                        src={qmApproval.qmApprovalIcon}
                                        alt="QmApproval icon"
                                    />
                                    <div className={classes.qmApprovalItem}>{t('PRODUCT_RELEASE.QM_APPROVAL')}</div>
                                </div>
                                <div className={classes.qmApprovalField}>
                                    {qmApproval.qmApprovedBy}
                                </div>
                                <div className={classes.qmApprovalField}>
                                    {qmApproval.qmApprovedOn}
                                </div>
                            </div>
                            <div className={classes.qmButtonsContainer}>
                                <div className={classes.buttonBlock}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => setOpenApprovalReviseModal(true)}
                                    >
                                        {t('PRODUCT_RELEASE.REVISE')}
                                    </Button>
                                </div>
                                <ApprovalReviseModal
                                    open={openApprovalReviseModal}
                                    setOpen={setOpenApprovalReviseModal}
                                    shipmentNumber={orderNumber}
                                    productReleaseId={evidenceId}
                                    qmApproval={qmApproval}
                                    dataUpdateTrigger={dataUpdateTrigger}
                                />
                            </div>
                        </div>
                    )
            }
        </div>
    );
};

export default ButtonsContainer;
