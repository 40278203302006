import Users from 'Administration/Users';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import EditUser from 'Administration/Users/tabs/EditUser';
import CompanyInfoOverview from './CompanyInfo/tabs/Overview';

import Sites from './Sites';
import AddUser from './Users/tabs/AddUser';
import AddPharmaSite from './Sites/tabs/AddPharmaSite';
import EditPharmaSite from './Sites/tabs/EditPharmaSite';
import LocationCapabilities from './Sites/tabs/Capabilities';
import LocationQuestionnaire from './Sites/tabs/Questionnaire';
import LocationGallery from './Sites/tabs/Gallery';
import Requests from './AccessRequests';

const MyCompanyRouter: RouteItem[] = [
    {
        necessaryRoles: [],
        path: '/my-company',
        element: <Navigate to="/my-company/company/overview" />,
    },
    {
        necessaryRoles: [],
        path: '/my-company/company',
        element: <Navigate to="/my-company/company/overview" />,
    },
    {
        necessaryRoles: [],
        path: '/my-company/company/overview',
        element: <CompanyInfoOverview />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        path: '/my-company/company/capabilities',
        element: <div>Capabilities</div>,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        path: '/my-company/company/questionnaire',
        element: <div>Questionnaire</div>,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        path: '/my-company/company/monitoring',
        element: <div>Monitoring</div>,
    },
    {
        sufficientRoles: [
            userRoles.SITE_MANAGEMENT,
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/sites',
        element: <Sites />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users',
        element: <Users isAdmin={false} />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users/new',
        element: <AddUser />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users/:userId',
        element: <EditUser />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/new',
        element: <AddPharmaSite />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:siteId/overview',
        element: <EditPharmaSite />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/capabilities',
        element: <LocationCapabilities />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/questionnaire',
        element: <LocationQuestionnaire />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/gallery',
        element: <LocationGallery />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/documents',
        element: <div>Documents</div>,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/information-sharing',
        element: <div>Information Sharing</div>,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/lanes',
        element: <div>Lanes</div>,
    },
    {
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests',
        element: <Navigate to="/my-company/access-requests/outgoing-requests" />,
    },
    {
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests/outgoing-requests',
        element: <Requests type="outgoing" />,
    },
    {
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests/incoming-requests',
        element: <Requests type="incoming" />,
    },

];

export default MyCompanyRouter;
