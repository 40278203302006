import React, { MouseEventHandler } from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { MapElement } from 'TrackAndTrace/Assets/lib';

type Props = {
    mapElement: MapElement,
    onHover: MouseEventHandler<HTMLDivElement>,
    isSelected: boolean,
}
const AreaMarker = ({
    mapElement,
    onHover,
    isSelected = false,
}: Props) => {
    const position = {
        lat: mapElement.geolocation.latitude,
        lng: mapElement.geolocation.longitude,
    };

    return (
        <OverlayViewF
            position={position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            zIndex={5}
        >
            <div onMouseEnter={onHover}>
                <PolygonMarker
                    count={mapElement?.count}
                    polygonIconType="hexa"
                    theme="light"
                    iconDiameter={35}
                    polygonIconColor="blue"
                    filled={isSelected}
                />
            </div>
        </OverlayViewF>
    );
};

export default AreaMarker;
