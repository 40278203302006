import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    menuBar: {
        background: `linear-gradient(90deg, ${theme.palette.primary.darkBlue} 0%,\
${theme.palette.primary.deepBlue} 100%)`,
        display: 'flex',
        transition: 'height 200ms ease',
        overflow: 'visible',
        justifyContent: 'space-between',
        boxShadow: `0px 2px 4px ${theme.palette.secondary[300]}`,
        [theme.breakpoints.up('sm')]: {
            height: 'auto!important',
        },
        alignItems: 'center',
    },
    rightContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        width: '100%',
        padding: `${theme.spacing(0.65)} ${theme.spacing(3)}`,
        position: 'relative',
    },
    userWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: '14px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        marginLeft: theme.spacing(5),
        transition: 'opacity 200ms ease',
        [theme.breakpoints.up('sm')]: {
            opacity: '1!important',
        },
    },
    logoutBtn: {
    // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        // @ts-ignore
        border: `1px solid ${theme.palette.common.menuTextWhite}`,
        marginLeft: theme.spacing(2),
        borderRadius: '6px',
        textTransform: 'uppercase',
        padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
        fontSize: '13px',
        cursor: 'pointer',
        transition: 'opacity 200ms ease',
        [theme.breakpoints.up('sm')]: {
            opacity: '1!important',
        },
        height: 'max-content',
    },
    fixedPosition: {
        position: 'fixed',
        width: '100%',
        zIndex: 9,
        top: '0px',
    },
});

export default styles;
