import useClasses from 'hooks/useClasses';
import React, { useMemo } from 'react';
import { styles } from 'LaneManagement/AddLane/StepsWrapper/Location/Location.style';
import LocationInfo from 'LaneManagement/AddLane/Cards/AddLocation/LocationInfo';
import { AddressDTO } from 'shared-components/dataTypes';

type Props = {
    milestoneId?: any,
    originLocation: AddressDTO,
    destinationLocation: AddressDTO,
    startLocationClicked?: any,
    endLocationClicked?: any,
    wrongOrigin?: boolean,
    wrongDestination?: boolean,
    twoLocations: boolean
}

const Location = ({
    originLocation,
    destinationLocation,
    startLocationClicked,
    endLocationClicked,
    wrongOrigin = false,
    wrongDestination = false,
    twoLocations,
    milestoneId = '',
}: Props) => {
    const classes = useClasses(styles);
    const components = useMemo(() => {
        const array = [];
        const origin = (
            <div
                key={`originLocation_${milestoneId}`}
                className={classes.location}
            >
                <LocationInfo
                    location={originLocation}
                    short
                    onClick={startLocationClicked}
                    error={wrongOrigin}
                />
            </div>
        );
        const destination = (
            <div
                key={`destinationLocation_${milestoneId}`}
                className={classes.location}
            >
                <LocationInfo
                    location={destinationLocation}
                    short
                    onClick={endLocationClicked}
                    error={wrongDestination}
                />
            </div>
        );

        const divider = (order) => (
            <div
                key={`locationDivider_${milestoneId}_${order}`}
                className={classes.locationDivider}
            />
        );

        if (twoLocations) {
            array.push(origin);
            array.push(divider(1));
            array.push(destination);
        } else {
            array.push(divider(2));
            array.push(origin);
            array.push(divider(3));
        }

        if (array) return array;
    }, [
        twoLocations,
        originLocation,
        destinationLocation,
        startLocationClicked,
        endLocationClicked,
        wrongOrigin,
        wrongDestination,
        milestoneId,
    ]);

    return (
        <>
            {components}
        </>
    );
};

export default Location;
