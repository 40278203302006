import { StepValidation } from 'LaneManagement/AddLane/AddLane';
import React from 'react';
import { Milestone, AddressDTO } from 'shared-components/dataTypes';

export enum CardType {
    MILESTONE,
    SUMMARY_LEFT,
    SUMMARY_RIGHT,
    PRODUCTS,
    FLIGHTS,
    SKYCELL_INFO,
    LOADING,
    IN_TRANSIT,
    UNLOADING,
}
export type EditingCardInfo = {
    id: number,
    type: CardType,
}
interface LaneCardsContextInterface {
    steps: Milestone[],
    setSteps: Function,
    pickupLocation?: AddressDTO,
    deliveryLocation?: AddressDTO,
    stepValidation: StepValidation[],
    updateMilestones?: Function,
    deleteMilestone?: Function,
    updateMilestone?: Function,
    setEditingCard: Function,
    editingCard: EditingCardInfo,
    setMilestonesLoading: (arg: boolean) => void,
    shouldUpdateLane?: number,
    updateLane?: Function,

}

const initialState = {
    steps: [],
    setSteps: () => {},
    pickupLocation: { },
    deliveryLocation: { },
    stepValidation: [],
    setMilestonesLoading: () => {},
    updateMilestones: () => {},
    deleteMilestone: () => {},
    updateMilestone: () => {},
    setEditingCard: () => {},
    editingCard: null,

};
const LaneCardsContext = React.createContext<LaneCardsContextInterface>(initialState);

export default LaneCardsContext;
