/* eslint-disable camelcase */
import { useContext, useState, useEffect, useMemo } from 'react';
import { CacheContext } from 'Contexts/CacheContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getGeocode } from 'use-places-autocomplete';
import { GOOGLE_LOCATION_INFO } from 'shared-components/constants';
import { Location } from 'shared-components/dataTypes';

const GEOCODE_TITLE = 'geocodeTitle';
const PEAK_INTERVAL = 0.3 * 1000;

export const useGetGeocode = (location: Location) => {
    const {
        getCacheValue,
        setCacheValue,
    } = useContext(CacheContext);
    const { t } = useCustomTranslation();
    const [currentTimeoutId, setCurrentTimeoutId] = useState<any>(null);

    useEffect(() => {
        if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
        }
        const loadLocationGeocode = async () => {
            try {
                const locationId = `${location?.lng}_${location?.lat}`;
                const geocodeCache = getCacheValue<object>(GEOCODE_TITLE) || {};

                if (geocodeCache[locationId]) {
                    return geocodeCache[locationId];
                }
                const response = await getGeocode({ location }) || [];

                // Adjusted to access geometry.location
                const point = response.find((item) => (
                    item.geometry.location.lat() === location.lat && item.geometry.location.lng() === location.lng
                )) || (response.length > 0 && response[0]) || null;
                const { address_components = [] } = point;

                const cityComponent = address_components.find((item) => (
                    item.types.includes(GOOGLE_LOCATION_INFO.locality))) || null;
                const countryComponent = address_components.find((item) => (
                    item.types.includes(GOOGLE_LOCATION_INFO.country))) || null;

                const { long_name: city = '' } = cityComponent || {};
                const { long_name: country = '' } = countryComponent || {};

                const result = city && country
                    ? `${city}, ${country}`
                    : city || country;

                setCacheValue(GEOCODE_TITLE,
                    { ...geocodeCache, [locationId]: result || t('PRODUCT_RELEASE.NO_DATA') });
            } catch (error) {
                global.console.log(error);
            }
        };

        const timeoutId = setTimeout(loadLocationGeocode, PEAK_INTERVAL);

        setCurrentTimeoutId(timeoutId);
    }, [location]);

    return useMemo(() => {
        return getCacheValue(GEOCODE_TITLE)?.[`${location?.lng}_${location?.lat}`] || '';
    }, [location, getCacheValue]);
};
