const styles = (theme) => {
    return ({
        title: {
            textAlign: 'left',
            font: 'normal normal normal 12px/14px Roboto',
            letterSpacing: '0.4px',
            color: theme.palette.secondary[500],
            opacity: 1,
        },
        textFieldLabel: {
            textAlign: 'left',
            font: 'normal normal normal 16px/19px Roboto',
            letterSpacing: '0.49px',
            color: theme.palette.secondary[400],
            opacity: 0.5,
        },
        container: {
            display: 'flex',
        },
        icon: {
            marginRight: '5px',
        },
        checkboxStyle: {
            margin: '0 9px',
            padding: '0',
        },
        formControlLabelStyle: {
            whiteSpace: 'nowrap',
        },
    });
};

export default styles;
