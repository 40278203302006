export const styles = (theme) => ({
    arrowsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '4px',
    },
    arrow: {
        display: 'flex',
        cursor: 'pointer',
        transition: '100ms ease',
        boxShadow: `inset 0px 0px 1px ${theme.palette.common.black}`,
        '&:active': {
            boxShadow: `inset 0px 0px 2px ${theme.palette.common.black}`,
        },
        background: theme.palette.common.white,
        '& svg': {
            fontSize: '13px',
        },
    },
    arrowActive: {
        boxShadow: `inset 0px 0px 2px ${theme.palette.common.black}`,
    },
    celsius: {

    },
});
