import React, { MouseEventHandler, KeyboardEventHandler } from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import { AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';

const getPixelPositionOffset = () => ({
    x: -12,
    y: -12,
});

type Props = {
    airportInfo: AirportExtended,
    isOpen?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>,
    onKeyDown?: KeyboardEventHandler<HTMLDivElement>
}

const AirportMarker = ({
    airportInfo,
    isOpen = false,
    onClick,
    onKeyDown,
}: Props) => {
    const {
        geolocation = null,
        code,
    } = airportInfo || {};

    const { latitude: lat, longitude: lng } = geolocation || {};

    return (
        <OverlayViewF
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
            zIndex={1000}
        >
            <div
                onClick={onClick}
                onKeyDown={onKeyDown}
            >
                <div
                    style={{
                        top: '17px',
                        right: '17px',
                        padding: '0px 2px',
                        position: 'absolute',
                        background: '#B16D00 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        font: 'normal normal normal 10px/13px Roboto',
                        letterSpacing: '0.31px',
                        color: '#F0DEBF',
                        zIndex: 5,
                        opacity: 1,
                    }}
                >
                    {code}
                </div>
                <img
                    alt="Airport marker"
                    src={icons.airport_icon_orange}
                    style={{
                        width: '24px',
                        height: '24px',
                        objectFit: 'unset',
                        position: 'relative',
                        cursor: 'pointer',
                        ...(
                            isOpen
                                ? {
                                    boxShadow: '0px 3px 6px #00000029',
                                    border: '1px solid #EDAE49',
                                    backgroundColor: '#F0DEBF',
                                    borderRadius: '3px',
                                }
                                : {}
                        ),
                    }}
                />
            </div>
        </OverlayViewF>
    );
};

export default AirportMarker;
