export enum AccessRequestStatus {
    PENDING= 'PENDING',
    APPROVED= 'APPROVED',
    REJECTED= 'REJECTED'
}

export type AccessRequestDTO = {
    id: number,
    dataOwnerCompanyId: number,
    dataOwnerCompanyName: string,
    siteId: number,
    siteName: string,
    geofenceId: number,
    geofenceName: string,
    status: AccessRequestStatus,
}
export type AccessRequestsResponse = {
    totalElements: number;
    resultList: AccessRequestDTO[];
}

export type FilterFields = 'dataOwnerCompanyName' | 'statuse';

export const initialAccessRequestsFilterOptions: { [optionsGroupKey: string]: string[] } = {
    dataOwnerCompanyNames: [],
    statuses: [],
};
