const styles = (theme) => ({
    cardTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: `${theme.palette.common.black}!important`,
        opacity: 1,
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    fieldTitle: {
        marginTop: '15px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    fieldInfo: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0',
        color: theme.palette.secondary[600],
        opacity: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    InfoContainer: {
        marginLeft: '0',
        marginRight: 'auto',
    },
    statusesContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '7px',
    },
    icon: {
        marginRight: '10px',
        width: '21px',
        height: '21px',
    },
    approvalItem: {
        textAlign: 'center',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    buttonsContainer: {
        marginRight: '0',
        alignItems: 'center',
        marginTop: '30px',
    },
    qmButtonsContainer: {
        display: 'block',
        alignItems: 'center',
        marginTop: '12px',
        marginLeft: '31px',
    },
    qmapproveRejectButtonsContainer: {
        display: 'block',
        alignItems: 'center',
        marginTop: '30px',
    },
    buttonBlock: {
        alignItems: 'center',
    },
    button: {
        textTransform: 'uppercase',
        width: '150px',
        height: '35px',
    },
    qmStatusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    qmApprovalIcon: {
        marginLeft: '8px',
        marginRight: '10px',
        width: '13px',
        height: '14px',
    },
    qmApprovalItem: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    qmApprovalField: {
        marginLeft: '31px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
});

export default styles;
