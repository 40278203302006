import Switch from '@mui/material/Switch';
import React, { useMemo } from 'react';
import icons from 'shared-components/icons';
import { COMPANY_TYPE_LABELS } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './CompanyCard.style';

type Props = {
    currentCompanyLogo?: string,
    currentCompanyName?: string,
    currentCompanyType?: string,
    currentWebsite?: string,
    setAuthorizesDataSharing?: (arg?: boolean) => void,
    currentAuthorizesDataSharing?: boolean,
}

const ReadMode = ({
    currentCompanyLogo = icons.placeholder_company_logo,
    currentCompanyName = '',
    currentCompanyType = '',
    currentWebsite = '',
    setAuthorizesDataSharing = null,
    currentAuthorizesDataSharing = null,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const imageVersion = useMemo(() => new Date().getTime(), []);

    return (
        <>
            <img
                alt="Company Logo"
                className={classes.readModeCompanyLogo}
                src={`${currentCompanyLogo}?version=${imageVersion}`}
            />
            <div className={classes.fieldTitle}>{t('COMMON.COMPANY_NAME')}</div>
            <div className={classes.filedInfo}>{currentCompanyName}</div>
            <div className={classes.fieldTitle}>{t('COMMON.WEBSITE')}</div>
            <div className={classes.filedInfo}>{currentWebsite}</div>
            <div className={classes.fieldTitle}>{t('COMMON.COMPANY_TYPE')}</div>
            <div className={classes.filedInfo}>{COMPANY_TYPE_LABELS(t)[currentCompanyType] || currentCompanyType}</div>
            {
                setAuthorizesDataSharing && (
                    <div className={classes.switchItemContainerStyle}>
                        <Switch
                            className={classes.blocked}
                            checked={currentAuthorizesDataSharing}
                            color="primary"
                            disabled
                            size="small"
                        />
                        <div>{t('ADMINISTRATION.AUTHORIZES_DATA_SHARING')}</div>
                    </div>
                )
            }
        </>
    );
};

export default ReadMode;
