import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, {
    useState,
    useMemo,
    ReactNode,
    CSSProperties,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import TableComponent from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLinkPathByTemplate, getBodyCellValue } from './lib';
import {
    ClientSideFilterType,
    ColumnsType,
    ColumnFilterOptions,
    TableMaxHeight,
    ClassNames,
} from './dataTypes';

import BodyCell from './components/BodyCell';
import HeaderCell from './components/HeaderCell';
import LinkedTableRow from './components/LinkedTableRow';
import styles from './Table.style';

type Props = {
    clientSideFilter: ClientSideFilterType,
    columns: ColumnsType[],
    columnFilterOptions?: ColumnFilterOptions,
    data: any[],
    equalColumnWidths?: boolean,
    lastRowRef?: (node: any) => void,
    maskForHighlight?: string,
    moveColumn?: (dragIndex: number, hoverIndex: number) => void,
    onRowClick?: (...args: any) => any,
    onRowHover?: (...args: any) => any,
    prepareRowLinkTemplate?: (rowData: any) => string,
    rowLinkTemplate?: string,
    rowExtension?: ReactNode,
    rowExtensionIndex?: number,
    setClientSideFilter: (clientSideFilter: ClientSideFilterType) => void,
    tableMaxHeight?: TableMaxHeight,
    tableMinHeight?: string,
    disableTableHeader?: boolean,
    classNames?: ClassNames,
    title?: string,
    embed?: boolean,
    style?: CSSProperties,
    onSort?: (columnId: string, direction: 'asc' | 'desc') => void,
    currentSort?: {columnId: string, direction: 'asc' | 'desc'},
    clickedRowIndexExternal?: number,
    afterTable?: ReactNode,
}

const Table = ({
    clientSideFilter,
    columns,
    columnFilterOptions,
    data,
    equalColumnWidths = false,
    lastRowRef = null,
    maskForHighlight = '',
    moveColumn,
    onRowClick = null,
    prepareRowLinkTemplate = null,
    rowLinkTemplate = null,
    rowExtension = null,
    rowExtensionIndex = null,
    setClientSideFilter,
    tableMaxHeight = '80vh',
    tableMinHeight = 'unset',
    disableTableHeader,
    onRowHover = () => {},
    classNames = null,
    title = null,
    embed = false,
    style = { },
    onSort = null,
    currentSort = null,
    clickedRowIndexExternal = null,
    afterTable = null,
}: Props) => {
    const classes = useGeneratedStyles(styles, {
        title,
    });
    const navigate = useNavigate();
    const [clickedRowIndex, setClickedRowIndex] = useState<number>(null);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    const equalColumnWidth = useMemo(() => (`${100 / columns.length}%`), [columns.length]);

    const preparedRows = useMemo(() => {
        if (!rows) {
            return [];
        }

        return rows.map((row, rowIndex: number) => {
            prepareRow(row);
            const skippedCells = [0];
            const handleRowClick = () => {
                if (rowLinkTemplate) {
                    const linkPath = getLinkPathByTemplate(rowLinkTemplate, row.original);

                    navigate(linkPath, { replace: true });
                } else if (prepareRowLinkTemplate) {
                    const linkPath = prepareRowLinkTemplate(row.original);

                    navigate(linkPath);
                } else if (onRowClick) {
                    onRowClick(row?.original || {}, rowIndex);
                    setClickedRowIndex(rowIndex);
                }
            };
            const props = {
                onMouseEnter: () => onRowHover({
                    original: row?.original || {},
                    state: true,
                }),
                onMouseLeave: () => onRowHover({
                    original: row?.original || {},
                    state: false,
                }),
                ...row.getRowProps(),
            };

            return (
                <LinkedTableRow
                    key={`LinkedTableRow-${rowIndex}`}
                    lastRowRef={(rows.length === rowIndex + 1) ? lastRowRef : null}
                    linkedTableRowProps={props}
                    onClick={handleRowClick}
                    isCursorPointer={rowLinkTemplate !== null}
                    className={[
                        classNames?.linkedTableRowClassName || '',
                        clickedRowIndexExternal === rowIndex ? classNames.clickedRowExternalClassName : '',
                    ].join(' ')}
                >
                    {
                        row.cells.map((cell, cellIndex: number) => {
                            const colSpan = (cell.column.dataType === 'stretchedCell'
                                && cell.value.colSpan) || null;

                            const requiredSkip = cellIndex < skippedCells[skippedCells.length - 1];

                            if (requiredSkip) {
                                return null;
                            }

                            if (colSpan) {
                                skippedCells.push(cellIndex + cell.value.colSpan);
                            }

                            const linkPath = getLinkPathByTemplate(cell.column.bodyCellLinkTemplate, row.original);
                            const handleBodyCellClick = () => navigate(linkPath);

                            return (
                                <BodyCell
                                    className={classNames.bodyCell}
                                    bodyCellProps={cell.getCellProps()}
                                    bodyData={getBodyCellValue(cell.column.dataType, cell.value)}
                                    colSpan={colSpan}
                                    customCellStyle={cell.column.customCellStyle}
                                    dataType={cell.column.dataType}
                                    key={`BodyCell-${cellIndex}`}
                                    maskForHighlight={cell.column.overrideHighlight || maskForHighlight}
                                    onClick={linkPath ? handleBodyCellClick : null}
                                />
                            );
                        })
                    }
                </LinkedTableRow>
            );
        });
    }, [rows, clickedRowIndexExternal]);

    const rowsWithExtension = useMemo(() => {
        if (preparedRows.length === 0) {
            return [];
        }

        if (!rowExtension) {
            return preparedRows;
        }

        const rowIndex = rowExtensionIndex || clickedRowIndex;
        const { linkedTableRowClassName = '', rowExtensionClassName = '' } = classNames || {};

        return [
            ...preparedRows.slice(0, rowIndex + 1),
            <LinkedTableRow
                key={`LinkedTablerowExtension-${rowIndex}`}
                className={[linkedTableRowClassName, rowExtensionClassName].join(' ')}
            >
                <BodyCell
                    className={classNames.bodyCell}
                    key={`BodyCellSpannedExtention-${rowIndex}`}
                    bodyData={rowExtension}
                    colSpan={columns.length}
                    dataType="custom"
                />
            </LinkedTableRow>,
            ...preparedRows.slice(rowIndex + 1),
        ];
    }, [
        preparedRows,
        clickedRowIndex,
        rowExtension,
        rowExtensionIndex,
    ]);

    return (
        <div
            className={[
                classes.tableContainer,
                classNames?.tableContainerClassName || '',
                embed ? '' : classes.boxShadow].join(' ')}
            style={{ maxHeight: tableMaxHeight, minHeight: tableMinHeight, ...style }}
        >
            {
                title && (
                    <div className={classes.tableTitle}>
                        {title}
                    </div>
                )
            }
            <TableComponent
                className={[
                    classes.tableComponent,
                    classNames.tableClassName || '',
                ].join(' ')}
                {...getTableProps()}
            >
                {
                    !disableTableHeader && (
                        <TableHead className={[
                            classes.tableHeader,
                            classNames?.tableHeaderClassName || '',
                        ].join(' ')}
                        >
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <TableRow
                                        key={`TableHeadRow-${index}`}
                                        className={[
                                            classes.reactTableTableRow,
                                            classNames?.tableRowClassName || '',
                                        ].join(' ')}
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            headerGroup.headers.map((column, index) => {
                                                const hasCustomSort = currentSort?.columnId === column.id;
                                                const isCustomSorted = hasCustomSort && currentSort?.direction !== null;
                                                const customSortedDesc = hasCustomSort
                                                    && currentSort?.direction === 'desc';

                                                return (
                                                    <HeaderCell
                                                        className={[
                                                            classNames.headerCell, column.noBar ? classes.noBar : '',
                                                        ].join(' ')}
                                                        clientSideFilter={clientSideFilter}
                                                        columnWidth={
                                                            column.customWidth
                                                            || (equalColumnWidths && equalColumnWidth) || null
                                                        }
                                                        customHeaderStyle={column.customHeaderStyle}
                                                        columnFilterOptions={columnFilterOptions}
                                                        setClientSideFilter={setClientSideFilter}
                                                        filterKey={column.filterKey || column.id}
                                                        filterType={column.filterType}
                                                        headerData={column.render('Header')}
                                                        headerProps={
                                                            onSort
                                                                ? null
                                                                : column.getHeaderProps(
                                                                    column.getSortByToggleProps({ title: undefined }),
                                                                )
                                                        }
                                                        onClick={onSort ? () => {
                                                            if (column.disableSortBy) return;
                                                            const direction = currentSort?.columnId !== column.id
                                                                ? 'asc' : (currentSort?.direction === null
                                                                    ? 'asc' : (currentSort?.direction === 'asc'
                                                                        ? 'desc' : null));

                                                            onSort(column.id, direction);
                                                        } : null}
                                                        index={index}
                                                        isSorted={isCustomSorted || column.isSorted}
                                                        isSortedDesc={customSortedDesc || column.isSortedDesc}
                                                        key={`HeaderCell-${index}`}
                                                        moveColumn={moveColumn}
                                                    />
                                                );
                                            })
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableHead>

                    )
                }
                <TableBody {...getTableBodyProps()}>
                    {
                        rowsWithExtension
                    }
                </TableBody>
            </TableComponent>
            {afterTable}
        </div>
    );
};

export default Table;
