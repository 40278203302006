import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

const useGeneratedStyles = <T>(generator: (theme: any, props: object) => T, props: object = {}): T => {
    const theme = useTheme();

    return useMemo(() => {
        return generator(theme, props);
    }, [theme, generator, props]);
};

export default useGeneratedStyles;
