import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';

const tableColumns: (t) => ColumnsType[] = (t) => [
    {
        Header: t('ORDER_DETAILS.REFERENCE_PO_NUMBER'),
        accessor: 'customerReference',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.MAWB'),
        accessor: 'mawbNumber',
        dataType: 'highlightedText',
        customWidth: 125,
    },
    {
        Header: t('TRACK_AND_TRACE.HAWB'),
        accessor: 'hawbNumber',
        dataType: 'highlightedText',
        customWidth: 125,
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
        dataType: 'custom',
    },
    {
        Header: t('COMMON.PACKAGINGS'),
        accessor: (row) => row?.cargo.map(item => item.packaging.serialNumber)?.join(', '),
        dataType: 'highlightedText',
        customCellStyle: {
            whiteSpace: 'normal',
            maxWidth: '230px',
        },
    },
    {
        Header: t('COMMON.JYPID'),
        accessor: (row) => row?.cargo.map(item => item.packaging.jypId)?.join(', '),
        dataType: 'highlightedText',
        customCellStyle: {
            whiteSpace: 'normal',
            maxWidth: '230px',
        },
    },
    {
        Header: t('COMMON.FROM'),
        accessor: 'originAirport',
        dataType: 'highlightedText',
    },
    {
        Header: t('COMMON.TO'),
        accessor: 'destinationAirport',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.ORDER'),
        accessor: 'externalId',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.STATUS'),
        accessor: 'status',
        dataType: 'text',
    },
];

export default tableColumns;
