import useStatusState from 'hooks/useStatusState';

const useStatusStateProcessOptions = () => {
    const { setStatusState } = useStatusState();

    const setErrorStatus = (message: string, relatedStatuses: number[] = [], single = false): void => {
        setStatusState({
            color: 'error',
            message: message || 'Something went wrong',
            relatedStatuses,
            single,
        });
    };

    const setProgressStatus = (message: string, relatedStatuses: number[] = [], single = false): number => {
        const time = Date.now();

        setStatusState({
            color: 'info',
            message,
            time,
            relatedStatuses,
            single,
        });

        return time;
    };

    const setSuccessStatus = (message: string, relatedStatuses: number[] = [], single = false): void => {
        setStatusState({
            color: 'success',
            message,
            relatedStatuses,
            single,
        });
    };

    return {
        setErrorStatus,
        setProgressStatus,
        setSuccessStatus,
    };
};

export default useStatusStateProcessOptions;
