import { TFunction } from 'i18next';
import { TABLE_INPUT_TYPE } from '../CommonStepTable/CommonTableTypes';

export const commonFields = (t:TFunction) => [
    {
        id: 'ambientControl',
        title: t('COMMON.AMBIENT'),
        inputType: TABLE_INPUT_TYPE.SELECT,
        selectData: [
            {
                value: 'CONTROLLED',
                render: t('LANE_MANAGEMENT.CONTROLLED'),
            },
            {
                value: 'NOT_CONTROLLED',
                render: t('COMMON.AMBIENT'),
            },
        ],
        required: false,
        width: '100px',
    },
    {
        id: 'temperatureSummer',
        title: t('COMMON.SUMMER'),
        inputType: TABLE_INPUT_TYPE.TEMPERATURE,
        required: true,
        width: '40px',
    },
    {
        id: 'temperatureWinter',
        title: t('COMMON.WINTER'),
        inputType: TABLE_INPUT_TYPE.TEMPERATURE,
        required: true,
        width: '40px',
    },
    {
        id: 'duration',
        title: t('COMMON.DURATION'),
        inputType: TABLE_INPUT_TYPE.TIME_24,
        required: true,
    },

];

export const staticRestData = {
    temperatureSummer: 0,
    temperatureWinter: 0,
    duration: '00:00',
};
