import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Chip, FormControl, MenuProps, SelectChangeEvent, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const PropsForMenu: Partial<MenuProps> = {
    sx: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        maxWidth: '200px',
        scrollbarWidth: '5px',
    },
};

type Props = {
    selectedIds: number[];
    data: {
        id?: number;
        [key: string]: any;
    }[];
    onChange: (event: SelectChangeEvent<number[]>) => void;
    handleDelete: (id: number) => void;
    labelId?: string;
    titlePlural?: string;
    loading?: boolean;
};

const MultiSelect = ({
    selectedIds,
    data,
    onChange,
    handleDelete,
    labelId = 'name',
    titlePlural = 'Items',
    loading = false,
}: Props) => {
    const theme = useTheme();

    return (
        <FormControl variant="standard" sx={{ m: 0, width: '100%' }}>
            <span style={{
                letterSpacing: '0.15px',
                fontSize: '12px',
            }}
            >
                {titlePlural}
            </span>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}>
                    <CircularProgress size={24} />
                </Box>
            ) : (
                <Select
                    id="geofenceschipselector"
                    multiple
                    value={selectedIds}
                    onChange={onChange}
                    placeholder={`Select ${titlePlural}`}
                    renderValue={(selected) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                                event.stopPropagation();
                            }}
                            zIndex={99999999}
                        >
                            {selected.map(id => data.find(it => it.id === id)).map((value) => (
                                <Chip
                                    key={`${value.id}_${value.name}_chip`}
                                    label={value.name}
                                    sx={{
                                        margin: '2px 4px',
                                        backgroundColor: '#F4F4F4',
                                        color: '#000000DE',
                                        height: '24px',
                                        ':hover': {
                                            backgroundColor: '#F4F4F4',
                                        },
                                    }}
                                    onDelete={() => handleDelete(value.id)}
                                    deleteIcon={(
                                        <Close sx={{
                                            color: '#707070',
                                            fontSize: '14px!important',
                                        }}
                                        />
                                    )}
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={PropsForMenu}
                >
                    {data.map((item) => (
                        <MenuItem
                            key={`${item.id}_${item[labelId]}_chip`}
                            value={item.id}
                            sx={{
                                backgroundColor: selectedIds.includes(item.id)
                                    ? `${theme.palette.primary[100]}!important` : '',
                                color: selectedIds.includes(item.id)
                                    ? `${theme.palette.common.white}!important` : '',
                            }}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export default MultiSelect;
