import useClasses from 'hooks/useClasses';
import React, { ReactElement, useMemo } from 'react';
import { uniqueId } from 'utils/stringTool';
import { styles } from './Division.style';

type Props = {
    flex: any[],
    children: ReactElement[]
}
const Division = ({
    flex,
    children,
}: Props) => {
    const classes = useClasses(styles);
    const uid = useMemo(() => uniqueId(),
        []);

    return (
        <div className={classes.division}>
            {
                children.map((it, index) => {
                    return (
                        <div
                            key={`${uid}_${index}`}
                            style={typeof flex[index] === 'object' ? flex[index] : {
                                flex: flex?.[index] || 'unset',
                            }}

                        >
                            {it}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Division;
