const borderRadius = '30px';

export const styles = () => ({
    wrapper: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: '1px solid #4b4b4b',
        borderRadius,
        marginBottom: '13px',
    },
    option: {
        display: 'flex',
        textAlign: 'center',
        padding: '5px 8px',
        cursor: 'pointer',
        transition: '300ms ease',
        textTransform: 'capitalize',
        fontSize: '12px',
        letterSspacing: '0.01em',
    },
    active: {
        background: 'rgb(0 156 207 / 12%)',
        color: '#009CCF',
        borderRadius,
        outline: '1px solid #009CCF',
    },
});
