import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ApprovalSummary.style';

type Props = {
    containerSerialNumber?: string,
    orderNumber?: string,
}

const InfoContainer = ({
    containerSerialNumber = '',
    orderNumber = '',
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.InfoContainer}>
            <div key="containerSerialNumber">
                <div className={classes.fieldTitle}>{t('COMMON.PACKAGING')}</div>
                <div className={classes.fieldInfo}>{containerSerialNumber}</div>
            </div>
            <div key="orderNumber">
                <div className={classes.fieldTitle}>{t('COMMON.SHIPMENT')}</div>
                <div className={classes.fieldInfo}>{orderNumber}</div>
            </div>
        </div>
    );
};

export default InfoContainer;
