import { Chip } from '@mui/material';
import React from 'react';
import { AccessRequestStatus } from 'MyCompany/AccessRequests/lib';
import icons from 'shared-components/icons';
import { TFunction } from 'i18next';

type StatusChipProps = {
    status: AccessRequestStatus,
    t: TFunction,
}

const statusColorMap = {
    [AccessRequestStatus.PENDING]: {
        color: '#DFDFDF',
        icon: icons.dots_round_grey,
    },
    [AccessRequestStatus.APPROVED]: {
        color: '#EAF9EF',
        icon: icons.checked_green,
    },
    [AccessRequestStatus.REJECTED]: {
        color: '#FFE5E5',
        icon: icons.red_cross_circled,
    },
};

const StatusChip = ({
    status,
    t,
}: StatusChipProps) => {
    return (
        <div>
            <Chip
                label={(
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <span style={{ marginRight: 4 }}>{t(`ACCESS_REQUEST_STATUS.${status}`)}</span>
                        <img alt={status} src={statusColorMap[status].icon} style={{ height: 22, width: 22 }} />
                    </div>
                )}
                sx={{
                    '.MuiChip-label': {
                        padding: 0,
                    },
                    backgroundColor: statusColorMap[status].color,
                    borderRadius: 16,
                    color: '#000000DE',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    font: 'normal normal normal 14px/17px Roboto',
                    height: '27px',
                    padding: '0',
                    paddingLeft: '13px',
                    paddingRight: '6px',
                    width: 'max-content',
                }}
            />
        </div>
    );
};

export default StatusChip;
