import React from 'react';
import IconButton from '@mui/material/IconButton';

import { ArrowBackIosNew } from '@mui/icons-material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './Footer.style';

type Props = {
    handleFooterOnClick: () => void,
    sidebarIsOpen: boolean,
}

const Footer = ({
    handleFooterOnClick,
    sidebarIsOpen,
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.footerContainer}>
            <IconButton
                size="small"
                onClick={handleFooterOnClick}
                className={classes.iconButton}

            >
                <div className={classes.iconWrapper}>
                    {
                        sidebarIsOpen
                            ? <ArrowBackIosNew className={classes.arrowIcon} />
                            : <ArrowBackIosNew className={[classes.arrowIcon, classes.rotateIcon].join(' ')} />
                    }
                </div>
                { sidebarIsOpen ? t('COMMON.HIDE_MENU') : ''}
            </IconButton>
        </div>
    );
};

export default Footer;
