import useClasses from 'hooks/useClasses';
import React from 'react';
import { styles } from './Heading.style';

type Props = {
    text: string,
}
const Heading = ({
    text,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.heading}>
            {text}
        </div>
    );
};

export default Heading;
