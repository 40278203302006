import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Address } from 'dataTypes/SecureBackend/apiResponse';
import Card from 'shared-components/Card';
import Map from 'shared-components/Map';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './PharmaSite.style';

const getMapConfig = (location) => ({
    center: {
        lat: location.latitude,
        lng: location.longitude,
    },
    containerStyle: {
        height: '18vh',
        width: '100%',
    },
    zoom: 5,
    backgroundColor: 'unset',
});

type Props = {
    address: Address,
    name: string,
    link: string,
}

const PharmaSite = ({
    address,
    name,
    link,
} : Props) => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const handleClick = useCallback(() => navigate(link), [navigate, link]);

    const mapConfig = useMemo(() => getMapConfig(address?.geolocation),
        [address?.geolocation]);

    return (
        <div className={classes.contentData}>
            <Card
                className={classes.card}
                onClick={handleClick}
                title={name}
            >
                <div className={classes.fieldTitle}>{t('COMMON.ADDRESS')}</div>
                <div className={classes.filedInfo}>{address.addressLine1}</div>
                <div className={classes.filedInfo}>{address.addressLine2}</div>
                <div className={classes.filedInfo}>{address.addressLine3}</div>
                <div className={classes.filedInfo}>{`${address.zipCode} ${address.city}`}</div>
                <div className={classes.filedInfo}>{address.country.name}</div>
            </Card>
            <div className={classes.mapContainer}>
                <Map
                    mapConfig={mapConfig}
                    separateMarker={{
                        lat: address.geolocation.latitude,
                        lng: address.geolocation.longitude,
                    }}
                />
            </div>
        </div>
    );
};

export default PharmaSite;
