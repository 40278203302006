import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useCacheContext from 'hooks/useCacheContext';
import TitleWithSwitch from 'shared-components/TitleWithSwitch';
import { QuickHelpTooltipInfo } from '../../Contexts/QuickHelpContext/QuickHelpTooltip';

type Props = {
    isTableView: boolean,
    pathname: string,
    title?: string,
    tooltipInfo?: QuickHelpTooltipInfo,
}

const TitleWithMapTableSwitch = ({
    isTableView,
    pathname,
    title = null,
    tooltipInfo,
} : Props) => {
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const { setCacheValue } = useCacheContext();

    const handleChange = (event) => {
        if (event.target.checked) {
            setCacheValue('VIEW_TYPE', 'table');
            navigate({
                pathname,
                search: '?view=table',
            });
        } else {
            setCacheValue('VIEW_TYPE', 'map');
            navigate({
                pathname,
                search: '?view=map',
            });
        }
    };

    return (
        <TitleWithSwitch
            leftSwitchPositionTitle={t('TRACK_AND_TRACE.MAP_VIEW')}
            rightSwitchPositionTitle={t('TRACK_AND_TRACE.TABLE_VIEW')}
            title={title}
            isRightSwitchPosition={isTableView}
            onChange={handleChange}
            tooltipInfo={tooltipInfo || {
                text: isTableView
                    ? t('QUICK_HELP.TRACK_AND_TRACE.MAP_SWITCH')
                    : t('QUICK_HELP.TRACK_AND_TRACE.TABLE_SWITCH'),
                order: 1,
                position: 'left',
                uid: pathname,
            }}
        />
    );
};

export default TitleWithMapTableSwitch;
