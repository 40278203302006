import React, { useMemo } from 'react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { useTheme } from '@mui/material/styles';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';

export const styles = () => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
});

type Props = {
    temperatureCheckStatus: string,
    size?: string,
}

const TemperatureCheckIcon = ({
    temperatureCheckStatus = null,
    size = '20px',
}: Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const classes = useClasses(styles);
    const {
        t,
    } = useCustomTranslation();

    const colorMap = useMemo(() => ({
        IN_RANGE: theme.palette.primary.deepBlue,
        IN_RANGE_PARTIAL_DATA: theme.palette.primary.deepBlue,
        POTENTIAL_EXCURSION: theme.palette.common.yellow,
        IN_RANGE_ONGOING: theme.palette.primary.deepBlue,
        NOT_APPLICABLE: theme.palette.primary.deepBlue,
        UNKNOWN: theme.palette.primary.deepBlue,
        IN_RANGE_NOT_COMPLETED: theme.palette.primary.deepBlue,
        EXCURSION: theme.palette.common.red,
        NO_DATA: theme.palette.secondary[600],
        WAITING_FOR_DATA: theme.palette.secondary[600],
    }), [temperatureCheckStatus, theme]);

    const icon = useMemo(() => {
        if (!temperatureCheckStatus) return null;

        if (temperatureCheckStatus === 'IN_RANGE_PARTIAL_DATA') {
            return (
                <div
                    style={{ fontSize: size }}
                    className={classes.wrapper}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Group_11388" data-name="Group 11388" transform="translate(-729 -1001)">
                            <path
                                id="Path_3935"
                                data-name="Path 3935" d="M17703.75-14707.5" transform="translate(-16967.102 15716)"
                                fill="none" stroke="#009ccf" strokeWidth="8"
                            />
                            <circle
                                id="Ellipse_3020" data-name="Ellipse 3020" cx="8" cy="8"
                                r="8" transform="translate(729 1001)" fill="#009ccf"
                            />
                            <line
                                id="Line_8816" data-name="Line 8816" y2="16" transform="translate(733 1001)"
                                fill="none" stroke="#fff" strokeWidth="2"
                            />
                            <line
                                id="Line_8817" data-name="Line 8817" y2="16" transform="translate(737 1001)"
                                fill="none" stroke="#fff" strokeWidth="2"
                            />
                            <line
                                id="Line_8818" data-name="Line 8818" y2="16" transform="translate(741 1001)"
                                fill="none" stroke="#fff" strokeWidth="2"
                            />
                        </g>
                    </svg>
                </div>
            );
        }

        return (
            <div
                style={{ fontSize: size }}
                className={classes.wrapper}
            >
                <svg
                    width="1em" height="1em" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="100" cy="100" r="95" fill={colorMap[temperatureCheckStatus] || 'transparent'}
                    />
                </svg>
            </div>
        );
    }, [temperatureCheckStatus]);

    if (temperatureCheckStatus === null) return <></>;

    return (
        <UncontrolledTooltip
            description={(
                <div style={{
                    color: theme.palette.common.black,
                }}
                >
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                    }}
                    >
                        <span style={{
                            display: 'inline-block',
                            width: '20px',
                            height: '20px',
                            margin: '6px 3px',
                        }}
                        >
                            {icon}
                        </span>

                        {t(`TEMPERATURE_STATUS.${temperatureCheckStatus}`)}
                    </span>
                    {t(`TEMPERATURE_STATUS_TOOLTIP_DESCRIPTION.${temperatureCheckStatus}`)}
                </div>
            )}
        >
            {icon}
        </UncontrolledTooltip>

    );
};

export default TemperatureCheckIcon;
