/* eslint-disable no-multi-str */
import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const loadingAnimation = keyframes({
        '0%': { backgroundPositionX: '-468px' },
        '100%': { backgroundPositionX: '468px' },
    });

    const cardRow = css({
        display: 'grid',
        gridGap: '14px',
        marginBottom: '14px',
    });

    const first = css({
        gridTemplateColumns: 'repeat(6, 1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    });

    const cardRowWithFourColumns = css({
        gridTemplateColumns: 'repeat(6, 1fr)',
    });

    const second = css({
        gridTemplateColumns: '1fr 4fr 1fr',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    });

    const fullHeight = css({
        height: '100%',
    });

    const cardBody = css({
        paddingTop: '14px',
    });

    const createButton = css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '20px 5px',
        gridColumn: 'span 2',
    });

    const flightsCard = css({
        gridColumn: 'span 1',
    });

    const flightsCardContent = css({
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        height: '100%',
    });

    const flightsCardRoot = css({
        height: '100%',
    });

    const noLocation = css({
        display: 'flex',
        height: '100%',
        alignItems: 'flex-start',
        textAlign: 'left',
        width: '100%',
        color: theme.palette.common.black,
        font: '14px Roboto',
    });

    const nonPaddedCard = css({
        '& > div': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    });

    const customPad = css({
        paddingLeft: '16px',
        paddingRight: '16px',
    });

    const centered = css({
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    });

    const cardBodyDivided = css({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
    });

    const textField = css({
        width: '100%',
        '& label': {
            color: theme.palette.common.black,
        },
    });

    const textFieldText = css({
        textAlign: 'left',
        font: 'normal normal normal 14px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,
    });

    const division = css({
        flex: 1,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '10px',
        gridAutoRows: '40px',
    });

    const selectText = css({
        color: theme.palette.common.black,
    });

    const expandSecond = css({
        gridAutoRows: '40px auto',
    });

    const simpleText = css({
        color: theme.palette.common.black,
        font: 'Roboto',
        fontSize: '14px',
    });

    const select = css({
        color: theme.palette.common.black,
        '& label': {
            color: theme.palette.common.black,
        },
    });

    const tempIcon = css({
        marginLeft: '4px',
        marginRight: '6px',
        filter: 'drop-shadow(0px -1px 0px white) \
 drop-shadow(-1px 0px 0px white)\
 drop-shadow(0px 1px 0px white)\
 drop-shadow(1px 0px 0px white)',
    });

    const button = css({
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        height: '35px',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    });

    const spaced = css({
        marginLeft: '8px',
        marginRight: '8px',
    });

    const buttonText = css({
        marginLeft: '10px',
        marginRight: '10px',
    });

    const imgWhite = css({
        filter: 'brightness(3.5)',
        height: '24px',
    });

    const iconWhite = css({
        filter: 'brightness(3.5)',
        height: '22px',
    });

    const buttonContainer = css({
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    });

    const buttonRow = css({
        paddingTop: '35px',
        paddingBottom: '35px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    });

    const topText = css({
        color: theme.palette.common.black,
        fontSize: '12px',
        letterSpacing: '0.4px',
        fontFamily: 'Roboto',
    });

    const stepCard = css({
        transition: '200ms ease',
    });

    const draggedCard = css({
        boxShadow: `0 8px 14px ${theme.palette.secondary[400]}, 0 10px 10px ${theme.palette.secondary[400]}`,
        transform: 'scale(1.01)',
    });

    const stepCardRoot = css({
        padding: '0!important',
        minHeight: '120px',
        backgroundColor: theme.palette.primary[30],
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) 3fr 2fr',
        gridGap: '14px',
        '&.color0': {
            backgroundColor: theme.palette.primary[30],
        },
        '&.color1': {
            backgroundColor: theme.palette.primary[50],
        },
        '&.color2': {
            backgroundColor: theme.palette.primary[100],
        },
        '&.color3': {
            backgroundColor: theme.palette.primary[50],
        },
    });

    const mandatory = css({
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    });

    const stepCardLoading = css({
        backgroundColor: `${theme.palette.secondary[100]}!important`,
        '& *': {
            color: `${theme.palette.secondary[50]}!important`,
            borderColor: `${theme.palette.secondary[50]}!important`,
            backgroundColor: `${theme.palette.secondary[50]}!important`,
        },
        '& span': {
            backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary[100]}, ${theme.palette.secondary[200]}, \
             ${theme.palette.secondary[100]}, ${theme.palette.secondary[200]}, ${theme.palette.secondary[100]})\
             !important`,
            backgroundSize: '400% 400%!important',
            animation: `${loadingAnimation} 2s ease infinite alternate`,
            color: `${theme.palette.common.white}!important`,
            fontSize: '0px',
        },
        '& img': {
            opacity: 0,
        },
    });
    const checked = css({});

    const radio = css({
        color: theme.palette.secondary[400],
        [`&.${checked}`]: {
            color: '#61C6E9',
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    });

    const label = css({
        fontSize: '12px',
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontWeight: 400,
        lineHeight: '1.4375em',
    });

    const valueText = css({
        fontSize: '14px',
        marginTop: '2px',
        color: theme.palette.common.black,
        minHeight: '14px',
        '& label span:last-child': {
            color: theme.palette.common.black,
        },
    });

    const hoverIconsWrapper = css({
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '5px',
    });

    const hoverIconsRow = css({
        display: 'flex',
        transition: '200ms ease-in-out',
        pointerEvents: 'none',
        opacity: 0,
        whiteSpace: 'nowrap',
    });

    const icon = css({
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '20px',
        height: '20px',
        '&:hover': {
            color: theme.palette.secondary[500],
        },
    });

    const visible = css({
        opacity: 1,
        pointerEvents: 'auto',
    });

    const loading = css({
        transition: '300ms ease',
        filter: 'blur(2px)',
        pointerEvents: 'none',
    });

    const loadingMilestones = css({
        transition: '200ms ease',
        filter: 'blur(2px)',
        pointerEvents: 'none',
    });

    const summaryCardsWrapper = css({
        display: 'grid',
        gridTemplateColumns: 'calc(50% - 7px) calc(50% - 7px)',
        gridGap: '14px',
        gridColumn: 'span 2',
    });

    const summary = css({
        display: 'grid',
        gridTemplateColumns: '1fr',
    });

    const radioRow = css({
        '& label span:last-child': {
            color: theme.palette.common.black,
        },
    });

    return {
        cardRow,
        first,
        cardRowWithFourColumns,
        second,
        fullHeight,
        cardBody,
        createButton,
        flightsCard,
        flightsCardContent,
        flightsCardRoot,
        noLocation,
        nonPaddedCard,
        customPad,
        centered,
        cardBodyDivided,
        textField,
        textFieldText,
        division,
        selectText,
        expandSecond,
        simpleText,
        select,
        tempIcon,
        button,
        spaced,
        buttonText,
        imgWhite,
        iconWhite,
        buttonContainer,
        buttonRow,
        topText,
        stepCard,
        draggedCard,
        stepCardRoot,
        mandatory,
        stepCardLoading,
        loadingAnimation,
        radio,
        checked,
        label,
        valueText,
        hoverIconsWrapper,
        hoverIconsRow,
        icon,
        visible,
        loading,
        loadingMilestones,
        summaryCardsWrapper,
        summary,
        radioRow,
    };
};

export default generateStyles;
