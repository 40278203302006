import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    pageContent: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 3fr',
        padding: '12px',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    pageContentButtons: {
        display: 'grid',
        gridTemplateColumns: '3fr 3fr',
        gridGap: '12px',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    approveButton: {
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        height: '35px',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    },
    rejectButton: {
        background: theme.palette.common.white,
        color: theme.palette.primary.deepBlue,
        textTransform: 'uppercase',
        border: '1px solid',
        height: '35px',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    },
});

export default styles;
