import React, { SetStateAction, useCallback, useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import DraftsIcon from '@mui/icons-material/Drafts';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { RestoreFromTrash, Delete } from '@mui/icons-material';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { ProcessedNotification } from 'Notifications/lib';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useClasses from 'hooks/useClasses';
import useCurrentUserContext from 'hooks/useCurrentUserContext/index';
import styles from '../Notifications.style';

type Props = {
    selectedIds: number[];
    setRefreshData: (prev: SetStateAction<boolean>) => void;
    setSelectedIds: (prev: SetStateAction<number[]>) => void;
    restoreMode: boolean,
    setNotifications: any;
    notifications: ProcessedNotification[];
};

const ControlHeader = ({
    selectedIds = [],
    setSelectedIds,
    restoreMode = false,
    setNotifications,
    notifications = [],
}: Props) => {
    const isMarkAsReadDisabled = useMemo(() => {
        return selectedIds.length === 0
        || notifications.filter(n => selectedIds.includes(n.id)).every(n => n.hasBeenRead);
    }, [selectedIds, notifications]);

    const isMarkAsUnreadDisabled = useMemo(() => {
        return selectedIds.length === 0
        || notifications.filter(n => selectedIds.includes(n.id)).every(n => !n.hasBeenRead);
    }, [selectedIds, notifications]);

    const classes = useClasses(styles);
    const { FlexibleRequest: updateNotificationInfo } = useSecureBackendEndpoints('notifications')
        .requests;
    const { t } = useCustomTranslation();
    const [openDeleteconfirmation, setOpenDeleteConfirmation] = useState(false);
    const { updateNotificationsCount } = useCurrentUserContext();

    const handleClickMarkAsRead = async () => {
        if (selectedIds.length > 0) {
            try {
                const result = await Promise.all(
                    selectedIds.map(id => {
                        return updateNotificationInfo(
                            'POST',
                            `${id}/action/mark-as-read`,
                            null,
                            {
                                'Content-Type': 'application/json',
                            },
                        );
                    }),
                );

                const successIds = result
                    .map(({ status }, i) => (status === 200 ? selectedIds[i] : null))
                    .filter(it => it);

                setNotifications(prev => prev.map(it => (successIds.includes(it.id) ? {
                    ...it,
                    hasBeenRead: true,
                } : it)));
            } catch (error) {
                global.console.log(error);
            } finally {
                setSelectedIds([]);
                updateNotificationsCount();
            }
        }
    };

    const handleClickMarkAsUnread = async () => {
        if (selectedIds.length > 0) {
            try {
                const result = await Promise.all(
                    selectedIds.map(id => {
                        return updateNotificationInfo(
                            'POST',
                            `${id}/action/mark-as-unread`,
                            null,
                            {
                                'Content-Type': 'application/json',
                            },
                        );
                    }),
                );

                const successIds = result
                    .map(({ status }, i) => (status === 200 ? selectedIds[i] : null))
                    .filter(it => it);

                setNotifications(prev => prev.map(it => (successIds.includes(it.id) ? {
                    ...it,
                    hasBeenRead: false,
                } : it)));
            } catch (error) {
                global.console.log(error);
            } finally {
                setSelectedIds([]);
                updateNotificationsCount();
            }
        }
    };
    const handleCloseDeleteDialog = useCallback(() => {
        setOpenDeleteConfirmation(false);
    }, []);
    const handleOpenDeleteDialog = useCallback(() => {
        setOpenDeleteConfirmation(true);
    }, []);

    const handleTrash = async (toTrash) => {
        if (selectedIds.length > 0) {
            try {
                const result = await updateNotificationInfo(
                    'POST',
                    toTrash ? 'action/move-to-trash' : 'action/restore-from-trash',
                    selectedIds.map(id => ({ id })),
                    {
                        'Content-Type': 'application/json',
                    },
                );

                if (result?.status === 200) {
                    setNotifications(prev => prev.map(it => (selectedIds.includes(it.id) ? {
                        ...it,
                        hasBeenDeleted: toTrash,
                    } : it)));
                }
                if (toTrash) handleCloseDeleteDialog();
            } catch (error) {
                global.console.log(error);
            } finally {
                setSelectedIds([]);
                updateNotificationsCount();
            }
        }
    };

    const handleCheckboxChange = useCallback(() => {
        if ((selectedIds || []).length === 0) {
            setSelectedIds(notifications.map(it => it.id));
        } else {
            setSelectedIds([]);
        }
    }, [notifications, selectedIds]);

    return (
        <div className={classes.controlHeaderTableCell}>
            <Dialog
                open={openDeleteconfirmation}
                onClose={handleCloseDeleteDialog}
                classes={{
                    paper: classes.dialogContainer,
                    paperWidthSm: classes.dialogWidthSm,
                }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {t('NOTIFICATIONS.DELETE_NOTIFICATIONS_REQUEST')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogText}>
                        {t('NOTIFICATIONS.DELETE_ALERT')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDeleteDialog}
                        color="primary"
                        variant="contained"
                        className={[classes.button, classes.buttonGhost].join(' ')}
                    >
                        {t('COMMON.CANCEL')}
                    </Button>
                    <Button
                        onClick={handleTrash.bind(null, true)}
                        color="primary"
                        variant="contained"
                        className={[classes.button, classes.buttonPrimary].join(' ')}
                    >
                        {t('COMMON.DELETE')}
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={classes.flexContainer}>
                <Checkbox
                    size="small"
                    checked={false}
                    onClick={handleCheckboxChange}
                    className={classes.checkboxStyle}
                    classes={{
                        root: classes.root,
                        checked: classes.checked,
                        indeterminate: classes.checked,
                    }}
                    indeterminate={selectedIds.length > 0}
                />
                {
                    !restoreMode && (
                        <Button
                            className={classes.controlHeaderButton}
                            disabled={selectedIds.length === 0}
                            onClick={handleOpenDeleteDialog}
                        >
                            <UncontrolledTooltip
                                description={t('COMMON.DELETE')}
                                shrinked
                                placement="bottom"
                            >
                                <Delete
                                    fontSize="small"
                                    style={{
                                        margin: '0 9px',
                                    }}
                                    className={`${classes.headerIcon} ${
                                selectedIds.length === 0
                                    ? classes.inactiveHeaderIcon
                                    : classes.activeHeaderIcon
                            }`}
                                />
                            </UncontrolledTooltip>

                        </Button>
                    )
                }
                {
                    restoreMode && (
                        <Button
                            className={classes.controlHeaderButton}
                            disabled={selectedIds.length === 0}
                            onClick={handleTrash.bind(null, false)}
                        >
                            <UncontrolledTooltip
                                description={t('COMMON.RESTORE_FROM_TRASH')}
                                shrinked
                                placement="bottom"
                            >
                                <RestoreFromTrash
                                    fontSize="small"
                                    style={{
                                        margin: '0 9px',
                                    }}
                                    className={`${classes.headerIcon} ${
                                selectedIds.length === 0
                                    ? classes.inactiveHeaderIcon
                                    : classes.activeHeaderIcon
                            }`}
                                />
                            </UncontrolledTooltip>
                        </Button>
                    )
                }
                <Button
                    className={classes.controlHeaderButton}
                    disabled={isMarkAsReadDisabled}
                    onClick={handleClickMarkAsRead}
                >
                    <DraftsIcon
                        fontSize="small"
                        className={`${classes.headerIcon} ${
                            isMarkAsReadDisabled
                                ? classes.inactiveHeaderIcon
                                : classes.activeHeaderIcon
                        }`}
                    />
                </Button>
                <Button
                    className={classes.controlHeaderButton}
                    disabled={isMarkAsUnreadDisabled}
                    onClick={handleClickMarkAsUnread}
                >
                    <MarkAsUnreadIcon
                        fontSize="small"
                        className={`${classes.headerIcon} ${
                            isMarkAsUnreadDisabled
                                ? classes.inactiveHeaderIcon
                                : classes.activeHeaderIcon
                        }`}
                    />
                </Button>
            </div>
            {/* <div className={classes.flexContainer}>
                <FormControl variant="standard" className={classes.dropdownContainer}>
                    <InputLabel id="filter-dropdown-label">Filter</InputLabel>
                    <Select
                        labelId="filter-dropdown-label"
                        id="filter-dropdown"
                        value={
                            showOnlyUnreadNotifications
                                ? 'unread'
                                : showOnlyDeletedNotifications
                                    ? 'deleted'
                                    : 'All'
                        }
                        onChange={event => {
                            setSelectedIds([]);
                            setShowOnlyUnreadNotifications(
                                event.target.value === 'unread',
                            );
                            setShowOnlyDeletedNotifications(
                                event.target.value === 'deleted',
                            );
                        }}
                        className={classes.selectDropdown}
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                    >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="unread">Only Unread</MenuItem>
                        <MenuItem value="deleted">Only Deleted</MenuItem>
                    </Select>
                </FormControl>
            </div> */}
        </div>
    );
};

export default ControlHeader;
