import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import icons from 'shared-components/icons';
import ParentCompany from 'shared-components/CompanyInfoComponents/ParentCompany';
import { TFunction } from 'i18next';
import { PharmaSite } from 'dataTypes/SecureBackend/apiResponse';

const tableColumns: (t: TFunction) => ColumnsType[] = (t) => [
    {
        Header: t('ADMINISTRATION.PARENT_COMPANY'),
        accessor: (row: PharmaSite) => {
            return (
                <ParentCompany
                    companyName={row.pharmaCompanyName}
                    srcImage={row.logoImagePath || icons.placeholder_company_logo}
                />
            );
        },
        dataType: 'custom',
        sideFilterKey: 'pharmaCompanyName',
        sideFilterType: 'enum',
    },
    {
        Header: t('COMMON.PHARMA_SITE'),
        accessor: 'name',
        dataType: 'highlightedText',
        sideFilterKey: 'name',
        sideFilterType: 'text',
    },
    {
        Header: t('COMMON.CITY'),
        accessor: 'city',
        dataType: 'highlightedText',
        sideFilterKey: 'city',
        sideFilterType: 'text',
    },
    {
        Header: t('COMMON.COUNTRY'),
        accessor: 'countryName',
        dataType: 'text',
        sideFilterKey: 'countryName',
        sideFilterType: 'enum',
    },
];

export default tableColumns;
