import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, {
    MouseEventHandler,
    MutableRefObject,
} from 'react';
import Button from '@mui/material/Button';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { useTheme } from '@emotion/react';
import generateStyles from 'TrackAndTrace/commonComponents/PreviewTable/PreviewTable.style';

type Props = {
    columns: ColumnsType[],
    data: { [key: string]: any }[],
    isCardOpen?: boolean,
    maskForHighlight: string,
    onCardClick: MouseEventHandler<HTMLDivElement>,
    onClickButtonClearSearch: MouseEventHandler<HTMLButtonElement>,
    onClickButtonShowResults: MouseEventHandler<HTMLButtonElement>,
    rowLinkTemplate: string,
    textRef: MutableRefObject<any>
}

const PreviewTable = ({
    columns,
    data,
    isCardOpen = false,
    maskForHighlight,
    onCardClick,
    onClickButtonClearSearch,
    onClickButtonShowResults,
    rowLinkTemplate = '',
    textRef,
} : Props) => {
    const theme = useTheme();
    const classes = useGeneratedStyles(generateStyles, {
        searchBoxLeft: textRef.current?.getBoundingClientRect()?.x || 0,
    });
    const { t } = useCustomTranslation();

    return (
        <Card
            className={[isCardOpen ? classes.open : '', classes.preview].join(' ')}
            zeroSidePadding
            onClick={onCardClick}
            title={t('COMMON.SEARCH_RESULTS')}
        >
            <Table
                columns={columns}
                data={(data || [])}
                embed
                maskForHighlight={maskForHighlight}
                rowLinkTemplate={rowLinkTemplate}
                tableMaxHeight="30vh"
                classNames={{
                    tableContainerClassName: classes.paddedTable,
                }}
            />
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.clearSearchButton}
                    onClick={onClickButtonClearSearch}
                    style={{
                        color: theme.palette.primary.deepBlue,
                        textTransform: 'none',
                        marginRight: '16px',
                    }}
                    variant="text"
                >
                    Clear Search
                </Button>
                <Button
                    className={classes.btn}
                    disabled={data?.length === 0 || data?.length > 20}
                    onClick={onClickButtonShowResults}
                    style={{
                        background: theme.palette.primary.deepBlue,
                        textTransform: 'none',
                        borderRadius: '0',
                        minHeight: '32px',
                        color: 'white',
                    }}
                    variant="contained"
                >
                    Show Results
                </Button>
            </div>
        </Card>
    );
};

export default PreviewTable;
