import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import NotificationCount from 'shared-components/NotificationCount';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import { Menu, Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useClasses from 'hooks/useClasses';
import { CurrentUserContext } from 'Contexts/CurrentUserContext';
import UserInfoMenuEntries from './UserInfoMenuEntries';
import styles from './UserInfo.style';

const UserInfo = () => {
    const { Get: getCompany } = useSecureBackendEndpoints('companies').requests;
    const [open, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const classes = useClasses(styles);
    const {
        company,
        companyInitialState,
        userInfo,
        setCompany,
        logout,
    } = useCurrentUserContext();
    const theme = useTheme<SkycellThemeInterface>();

    const {
        notificationsCount,
    } = useContext(CurrentUserContext);
    const handleClickOnEmail = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const {
        checkScreenType,
    } = useScreenSize();

    useEffect(() => {
        const click = () => {
            setOpen(false);
        };

        document.addEventListener('click', click);
        return () => document.removeEventListener('click', click);
    }, []);

    useEffect(() => {
        if (userInfo?.attributes?.companyId && !company.id) {
            (async () => {
                try {
                    const companyResponse = await getCompany(userInfo?.attributes?.companyId);

                    setCompany(companyResponse?.data || companyInitialState);
                } catch (error) {
                    setCompany(companyInitialState);
                }
            })();
        }
    }, [userInfo?.attributes?.companyId]);

    return (
        userInfo?.name ? (
            <>
                <div className={classes.container}>
                    <div className={classes.row}>
                        {/* {
                            sidebarIsOpen && (
                                <div className={classes.company}>{company.name}</div>
                            )
                        } */}

                    </div>
                    {
                        checkScreenType.up(ScreenType.XS) && (
                            <div style={{ display: 'flex' }}>
                                <div className={classes.notificationsContainer}>
                                    {
                                        notificationsCount > 0 && (
                                            <Link to="/notifications" className={classes.a}>
                                                <NotificationCount count={notificationsCount} icon="email" />
                                            </Link>
                                        )
                                    }
                                </div>
                                <Button
                                    ref={buttonRef}
                                    key="email"
                                    className={classes.userInfoMenuEntriesEmail}
                                    onClick={handleClickOnEmail}
                                >
                                    <div
                                        className={classes.name}
                                        onClick={handleClickOnEmail}
                                    >
                                        {userInfo.name}
                                    </div>
                                    <ArrowDropDownIcon style={{
                                        transform: open ? 'scaleY(-1)' : 'scaleY(1)',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                    />
                                </Button>
                            </div>
                        )
                    }
                    {
                        checkScreenType.down(ScreenType.XS) && (
                            <>
                                {
                                    !open && (
                                        <Menu
                                            style={{
                                                color: theme.palette.common.white,
                                                height: '100%',
                                            }}
                                            onClick={handleClickOnEmail}
                                        />
                                    )
                                }
                                {
                                    open && (
                                        <Close
                                            style={{
                                                color: theme.palette.common.white,
                                                height: '100%',
                                            }}
                                            onClick={handleClickOnEmail}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <UserInfoMenuEntries
                    logout={(ev) => {
                        ev.stopPropagation();
                        logout();
                    }}
                    open={open}
                    notificationCount={notificationsCount}
                    setOpen={setOpen}
                    width={buttonRef?.current?.scrollWidth || 0}
                />
            </>
        ) : null
    );
};

export default UserInfo;
