import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    input: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& $input': {
            paddingLeft: '8px',
        },
    },
    rightArrow: {
        width: '18px',
        height: '18px',
        marginLeft: '5px',
    },
    moreSpan: {
        marginTop: '10px',
        display: 'flex',
        color: theme.palette.secondary[600],
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        width: 'min-content',
    },
    paddedTable: {
        padding: '0 10px 10px 10px',
    },
    clearSearchBtn: {
        minWidth: '24px',
        padding: '0px',
        marginLeft: '8px',
    },
    noInputElement: {
        '&:after': {
            content: '"Please enter at least 3 characters"',
            position: 'relative',
            display: 'block',
            width: 'max-content',
            height: '0px',
            bottom: '0',
            fontSize: '13px',
            left: '0',
            color: theme.palette.secondary[500],
            transition: 'height 0.3s',
            overflow: 'hidden',
        },
    },
    noInputEnabled: {
        '&:after': {
            height: '15px!important',
        },
    },

});

export default styles;
