import React, { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { LatLngTimeTimestamp } from 'dataTypes/common';
import { SensorDataItem, SensorDataResponse } from 'dataTypes/SecureBackend/apiResponse';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useLoadedData from 'shared-components/ApexTemperatureChart/hooks/useLoadedData';

import useChartLimits from 'shared-components/ApexTemperatureChart/useChartLimits';
import { CustomAnnotation } from 'shared-components/dataTypes';
import NoSensorInfo from 'shared-components/NoSensorInfo';
import RectangleContentLoader from 'shared-components/RectangleContentLoader';
import useGetGatewaysByGatewayNumbers from 'hooks/useGetGatewaysByGatewayNumbers/index';

import ApexTemperatureChart from './ApexTemperatureChart';
import styles from './ApexTemperatureChart.style';
import {
    FixedTooltip,
    getCoordinates,
    getDataLimitIndexes,
    getDataTypeIndexes,
    getLocationSeries,
    getSensorLabels,
    getTemperatureData,
} from './lib';

const emptySensorLabels = {
    dataTypes: [],
    loggerTypes: [],
    positions: [],
};

type Props = {
    cargoId?: number,
    entityId?: number,
    customAnnotations?: CustomAnnotation[],
    /** Required format: 'YYYY-MM-DDTHH:mm' */
    dateTimeFrom: string,
    /** Required format: 'YYYY-MM-DDTHH:mm' */
    dateTimeTo: string,
    shipmentNumber?: string,
    disableToolbar?: boolean,
    disableXaxisInTooltip?: boolean,
    errorPictureSize?: 'small' | 'medium' | 'large',
    fixedTooltip?: FixedTooltip,
    getSensorDataBySerialNumber?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    height?: number,
    inLocalTimeZone?: boolean,
    requestType?: 'packagings' | 'loggers' | 'assets',
    requestOptions: SensorDataRequestBody,
    serialNumber?: string,
    setCoordinates?: (coordinates: LatLngTimeTimestamp[]) => void,
    setMouseMoveDataIndex?: (dataIndex: number) => void,
    setZoomedDataLimits?: (limits: { min: number, max: number }) => void,
    showMarkers?: boolean,
    showMeasuredOnly?: boolean,
    showTempRange?: boolean,
    shipmentId?: string,
    showMapInTooltip?: boolean,
    temperatureExcursionOn?: string,
    temperatureRangeMax?: number,
    temperatureRangeMin?: number,
    tempRangeMinimalisticStyle?: boolean,
    width?: number,
    excursionRange?: boolean,
    isPredictedExcursion?: boolean,
    handleChange?: (values: any) => void,
    isLogger?: boolean,
    errorMessage?: string,
    hiddenSeries?: { [key:string]: boolean },
    setHiddenSeries?: (hiddenSeries: { [key:string]: boolean }) => void,
    exportPng?: (base64: string, aspectRatio: number) => void,
    setChartRef?: (ref: RefObject<any>) => void,
    setIsSensorDataComplete?: Dispatch<SetStateAction<{
        [packagingNumber: string]: boolean,
    }>>;
    asAdmin?: boolean,
}

const ApexTemperatureChartContainer = ({
    customAnnotations = [],
    dateTimeFrom = null,
    dateTimeTo = null,
    disableToolbar = false,
    setIsSensorDataComplete,
    errorPictureSize = 'medium',
    getSensorDataBySerialNumber = () => {},
    height = 300,
    inLocalTimeZone = false,
    requestOptions = {
        dataTypes: [],
        loggers: [],
        positions: [],
    },
    requestType = 'packagings',
    shipmentNumber,
    serialNumber = '',
    setMouseMoveDataIndex = null,
    setCoordinates = null,
    setZoomedDataLimits = null,
    showMarkers = false,
    showMeasuredOnly = false,
    showTempRange = false,
    showMapInTooltip = false,
    shipmentId = null,
    temperatureExcursionOn = null,
    temperatureRangeMax = null,
    temperatureRangeMin = null,
    width = null,
    fixedTooltip = {
        enabled: false,
        offsetX: 0,
        offsetY: 0,
        position: 'topRight',
    },
    // handleChange = null,
    disableXaxisInTooltip = false,
    tempRangeMinimalisticStyle = false,
    entityId,
    cargoId,
    excursionRange = false,
    isPredictedExcursion = false,
    isLogger = false,
    errorMessage = '',
    hiddenSeries = null,
    setHiddenSeries = null,
    exportPng = null,
    setChartRef = null,
    asAdmin = false,
}: Props) => {
    const classes = useClasses(styles);
    const [temperatureData, setTemperatureData] = useState<SensorDataItem[]>([]);
    const [temperatureLabels, setTemperatureLabels] = useState(emptySensorLabels);
    const [doorInfoData, setDoorInfoData] = useState<SensorDataItem[]>([]);
    const [doorInfoLabels, setDoorInfoLabels] = useState(emptySensorLabels);
    const [locationSeries, setLocationSeries] = useState<ApexOptions['series']>([]);
    const [measuredDataLength, setRealDataLength] = useState(0);
    const [currentDate, setCurrentDate] = useState(Date.now());
    const { t } = useCustomTranslation();
    const updateTime = useCallback(() => {
        setCurrentDate(Date.now());
    }, []);
    const theme = useTheme();
    const defaultErrorMessage = t('SENSOR_DATA.NO_DATA_FOR_PERIOD');

    const {
        status,
        isError,
        rawSensorData,
    } = useLoadedData({
        requestOptions,
        dateTimeFrom,
        dateTimeTo,
        currentDate,
        serialNumber,
        shipmentId,
        cargoId,
        shipmentNumber,
        entityId,
        requestType,
        showMeasuredOnly,
        isPredictedExcursion,
        isGeneric: cargoId !== null,
        asAdmin,
    });

    const sensorDataResponse = useMemo<SensorDataResponse>(() => {
        return rawSensorData && rawSensorData.data?.length > 0 ? rawSensorData : null;
    }, [rawSensorData]);

    const gatewayNumbers = useMemo<string[]>(() => {
        const gatewayNumberIndex = sensorDataResponse?.dataTypes?.indexOf('GATEWAY_NUMBER');

        if (gatewayNumberIndex === -1 || !sensorDataResponse) return [];

        return Array.from(new Set(sensorDataResponse.data.map(({ d }) => d[gatewayNumberIndex])));
    }, [sensorDataResponse]);
    const {
        gateways: gatewaysFetched = [],
        status: gatewayFetchStatus,
    } = useGetGatewaysByGatewayNumbers({
        query: {
            gatewayImeiMac: gatewayNumbers,
        },
        enabled: gatewayNumbers.length !== 0,
    });

    const gateways = useMemo(() => gatewaysFetched, [gatewayFetchStatus]);

    useEffect(() => {
        // handleChange(rawSensorData);
        if (sensorDataResponse !== null) {
            try {
                const {
                    dataTypes = [],
                    loggerTypes = [],
                    positions = [],
                    data = [],
                } = sensorDataResponse || {};

                const temperatureIndexes = getDataTypeIndexes(dataTypes, 'TEMPERATURE');
                const temperaturePredictedIndexes = isPredictedExcursion
                    ? getDataTypeIndexes(dataTypes, 'TEMPERATURE_PREDICTED')
                    : [];

                // const doorIndexes = getDataTypeIndexes(dataTypes, 'DOOR');

                if (temperatureIndexes.length > 0 && data.length > 0) {
                    const dataLimitIndexes = getDataLimitIndexes(data, temperatureIndexes);
                    const temperatureData = getTemperatureData(data, temperatureIndexes, dataLimitIndexes);
                    const temperatureLabels = getSensorLabels(temperatureIndexes, dataTypes, loggerTypes, positions);
                    const measuredDataLength = temperatureData?.reduce((acc, { d }, index) => {
                        if (d[1] !== null) {
                            return index;
                        }
                        return acc;
                    }, 0);

                    setRealDataLength(measuredDataLength);
                    if (temperaturePredictedIndexes.length > 0 && data.length > 0) {
                        const dataPredictedLimitIndexes = getDataLimitIndexes(data, temperaturePredictedIndexes);
                        const temperaturePredictedData = getTemperatureData(
                            data, temperaturePredictedIndexes, dataPredictedLimitIndexes,
                        );

                        const temperaturePredictedLabels = getSensorLabels(
                            temperaturePredictedIndexes, dataTypes, loggerTypes, positions,
                        );

                        setTemperatureData(temperatureData.concat(temperaturePredictedData));
                        setTemperatureLabels(temperaturePredictedLabels);
                        getSensorDataBySerialNumber(serialNumber, temperatureData.concat(temperaturePredictedData), {
                            dataTypes,
                            loggerTypes,
                            positions,
                        });
                    } else {
                        setTemperatureData(temperatureData);
                        setTemperatureLabels(temperatureLabels);
                        getSensorDataBySerialNumber(serialNumber, data, {
                            dataTypes,
                            loggerTypes,
                            positions,
                        });
                    }

                    /*  if (doorIndexes.length > 0) {
                        const doorData = getDoorData(data, doorIndexes);
                        const doorLabels = getSensorLabels(doorIndexes, dataTypes, loggerTypes, positions);

                        setDoorInfoData(doorData);
                        setDoorInfoLabels(doorLabels);
                    } else {
                        setDoorInfoData([]);
                        setDoorInfoLabels(emptySensorLabels);
                    } */
                } else {
                    setTemperatureData([]);
                    setTemperatureLabels(emptySensorLabels);
                    getSensorDataBySerialNumber(serialNumber, [], null);
                    setDoorInfoData([]);
                    setDoorInfoLabels(emptySensorLabels);
                }
            } catch (error) {
                setTemperatureData([]);
                setTemperatureLabels(emptySensorLabels);
                setDoorInfoData([]);
                setDoorInfoLabels(emptySensorLabels);
            }
        } else {
            setTemperatureData([]);
            setTemperatureLabels(emptySensorLabels);
            getSensorDataBySerialNumber(serialNumber, [], null);
            setDoorInfoData([]);
            setDoorInfoLabels(emptySensorLabels);
        }
    }, [sensorDataResponse]);

    useEffect(() => {
        if (setCoordinates !== null) {
            if (sensorDataResponse !== null && temperatureData.length > 0) {
                setCoordinates(getCoordinates(sensorDataResponse));
            } else {
                setCoordinates([]);
            }
        }
    }, [temperatureData]);

    const chartLimits = useMemo(() => {
        if (!temperatureData) {
            return {
                min: 0,
                max: 0,
            };
        }
        const sensorFlatData = temperatureData.reduce((data, { d }) => {
            const filteredData = d.filter((value) => (
                !data.includes(value) && typeof value === 'number'
            ));

            return [...data, ...filteredData];
        }, []);

        return {
            min: Math.floor(Math.min(...sensorFlatData, temperatureRangeMin)) - 2,
            max: Math.ceil(Math.max(...sensorFlatData, temperatureRangeMax)) + 2,
        };
    }, [
        temperatureData,
        temperatureRangeMin,
        temperatureRangeMax,
    ]);

    const isSensorDataComplete = useMemo(() => sensorDataResponse?.data?.every(({ d }) => d[0] != null && d[1] != null),
        [sensorDataResponse?.data]);

    useEffect(() => {
        if (setIsSensorDataComplete) {
            setIsSensorDataComplete(prev => ({
                ...prev,
                [cargoId]: isSensorDataComplete,
            }));
        }
    }, [isSensorDataComplete]);

    useEffect(() => {
        if (sensorDataResponse !== null && sensorDataResponse?.dataTypes?.includes('GATEWAY_NUMBER')) {
            const {
                dataTypes = [],
                data = [],
            } = sensorDataResponse || {};

            if (!gateways || gateways.length === 0) return;

            const locationIndex = dataTypes.indexOf('GATEWAY_NUMBER');
            const historicalIndex = dataTypes.indexOf('IS_HISTORICAL');

            if (locationIndex !== -1 && historicalIndex !== -1) {
                const sensorFlatData = temperatureData.reduce((data, { d }) => {
                    const filteredData = d.filter((value) => (
                        !data.includes(value) && typeof value === 'number'
                    ));

                    return [...data, ...filteredData];
                }, []);

                const newChartLimits = {
                    min: Math.floor(Math.min(...sensorFlatData, temperatureRangeMin)) - 2,
                    max: Math.ceil(Math.max(...sensorFlatData, temperatureRangeMax)) + 2,
                };

                const constantValue = newChartLimits.min;

                setLocationSeries(
                    getLocationSeries(
                        {
                            rawData: data,
                            gateways,
                            locationIndex,
                            historicalIndex,
                            constantValue,
                            theme,
                            trans: t,
                            inLocalTimeZone,
                        },
                    ),
                );
            }
        } else {
            setLocationSeries([]);
        }
    }, [
        sensorDataResponse,
        gateways,
        dateTimeFrom,
        dateTimeTo,
        temperatureData,
        temperatureRangeMin,
        temperatureRangeMax,
    ]);

    const {
        xMin, xMax,
    } = useChartLimits({
        temperatureData,
        dateTimeFrom,
        dateTimeTo,
    });

    const showChart = useMemo(() => {
        return temperatureData.length > 0 || doorInfoData.length > 0 || locationSeries.length > 0;
    }, [temperatureData, doorInfoData]);

    const locationHintInTooltip = useMemo(() => rawSensorData?.dataTypes?.includes('LOCATION_LATITUDE'),
        [rawSensorData]);

    return (
        <div className={classes.chartOptions}>
            {
                status === 'idle' || status === 'loading'
                    ? (
                        <RectangleContentLoader height={height} />
                    )
                    : (
                        showChart
                            ? (
                                <ApexTemperatureChart
                                    chartLimits={chartLimits}
                                    customAnnotations={customAnnotations}
                                    dateTimeTemperatureExcursion={temperatureExcursionOn}
                                    disableToolbar={disableToolbar}
                                    disableXaxisInTooltip={disableXaxisInTooltip}
                                    doorInfoData={doorInfoData}
                                    doorInfoLabels={doorInfoLabels}
                                    entityId={`${serialNumber || ''}${shipmentNumber || ''}${entityId || ''}`}
                                    excursionRange={excursionRange}
                                    exportPng={exportPng}
                                    fixedTooltip={fixedTooltip}
                                    height={height}
                                    hiddenSeries={hiddenSeries}
                                    inLocalTimeZone={inLocalTimeZone}
                                    isAsset={requestType === 'assets'}
                                    isLogger={isLogger}
                                    isPredictedExcursion={isPredictedExcursion}
                                    locationHintInTooltip={locationHintInTooltip}
                                    locationSeries={locationSeries}
                                    measuredDataLength={measuredDataLength}
                                    onRefresh={updateTime}
                                    serialNumber={serialNumber}
                                    setChartRef={setChartRef}
                                    setHiddenSeries={setHiddenSeries}
                                    setMouseMoveDataIndex={setMouseMoveDataIndex}
                                    setZoomedDataLimits={setZoomedDataLimits}
                                    shipmentNumber={shipmentNumber}
                                    showMap={showMapInTooltip}
                                    showMarkers={showMarkers}
                                    showTempRange={showTempRange}
                                    temperatureData={temperatureData}
                                    temperatureLabels={temperatureLabels}
                                    temperatureRangeMax={temperatureRangeMax}
                                    temperatureRangeMin={temperatureRangeMin}
                                    tempRangeMinimalisticStyle={tempRangeMinimalisticStyle}
                                    width={width}
                                    xMax={xMax}
                                    xMin={xMin}
                                />
                            )
                            : (
                                <NoSensorInfo
                                    key="noSensorInfo"
                                    text={isError ? defaultErrorMessage : ''
                                        || errorMessage || t('SENSOR_DATA.NO_SENSOR_DATA_FOR_LOGGER_OR_ASSET')}
                                    pictureSize={errorPictureSize}
                                />
                            )
                    )
            }
        </div>
    );
};

export default ApexTemperatureChartContainer;
