import React from 'react';
import icons from 'shared-components/icons';
import LocationInfo from 'LaneManagement/AddLane/Cards/AddLocation/LocationInfo';
import { AddressDTO } from 'shared-components/dataTypes';
import useClasses from 'hooks/useClasses';
import styles from './DeliveryPointInfo.style';

type Props = {
    title?: string,
    className?: string,
    // point: {
    //     locationName: string,
    //     locationIcon: string,
    //     address: string,
    //     contacts: {
    //         person: string,
    //         personIcon: string,
    //         contact: string,
    //     }[],
    // },
    label: string,
    value: string | AddressDTO,
}

// const fields = {
//     location: {
//         origin: 'Handover Point',
//         destination: 'Collection / Drop-off Point',
//     },
//     person: {
//         origin: 'Handover Point Contact(s)',
//         destination: 'Collection Point / Drop-off Contact(s)',
//     },
// };

const DeliveryPointInfo = ({
    title,
    // point,
    label = '',
    value = '',
}: Props) => {
    const classes = useClasses(styles);

    return (
        <>
            <div className={classes.fieldTitle}>{label}</div>
            <div className={classes.fieldInfoAndIconContainer}>
                { value ? (
                    <img
                        className={classes.icon}
                        src={icons.location_type.ADDRESS}
                        alt={`location Icon ${title}`}
                    />
                )
                    : null}
                {
                    typeof value === 'string' ? <div className={classes.fieldInfo}>{value}</div>
                        : <LocationInfo location={value} emptyText=" " short noIcon />
                }
            </div>
            {/* {
                point.locationName && (
                    <div>
                        <div className={classes.fieldTitle}>{fields.location[title]}</div>
                        <div className={classes.fieldInfoAndIconContainer}>
                            <div className={classes.fieldInfo}>{point.locationName}</div>
                            <img
                                className={classes.icon}
                                src={point.locationIcon}
                                alt={`location Icon ${title}`}
                            />
                        </div>
                        <div className={classes.fieldInfoLineBreak}>{point.address}</div>
                    </div>
                )
            }
            {
                point?.contacts?.length > 0 && (
                    <div>
                        <div className={classes.fieldTitle}>{fields.person[title]}</div>
                        {
                            point.contacts.map((info, index) => (
                                <div key={`contact${index + 1}`} className={classes.contactItem}>
                                    <div className={classes.fieldInfoAndIconContainer}>
                                        <div className={classes.fieldInfo}>{info.person}</div>
                                        <img
                                            className={classes.icon}
                                            src={info.personIcon}
                                            alt={`person Icon ${title}`}
                                        />
                                    </div>
                                    <div className={classes.fieldInfoLineBreak}>
                                        {info.contact}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            } */}
        </>
    );
};

export default DeliveryPointInfo;
