import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    packagingContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '12px',
        marginBottom: '24px',
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
    },
    seasonInfoContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
        gridTemplateRows: 'auto',
        gridGap: '12px',
        marginBottom: '24px',
    },
    simulationSummaryTitle: {
        marginLeft: '8px',
        marginBottom: '16px',
    },
    transportEmbedded: {
        padding: 0,
        paddingBottom: '16px!important',
    },
    button: {
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        height: '35px',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    },
});

export default styles;
