import React, { useContext, useEffect } from 'react';
import TitleHeader from 'TitleHeader';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import Card from 'shared-components/Card';

import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import AmbientTemperatureCard from 'LaneManagement/Lanes/tabs/TemperatureSimulation/components/AmbientTemperatureCard';
import useClasses from 'hooks/useClasses';
import ProfileCard from './components/ProfileCard';
import TemperatureChart from './components/TemperatureChart';
import SimulationModelValidation from './components/SimulationModelValidation';
import SimulationSummary from './components/SimulationSummary';
import PackagingsTable from './components/PackagingsTable';
import styles from './TemperatureSimulation.style';

export type PackagingType = {
    certifiedOn: string,
    documentUrl: string,
    packagingPictureUrl: string,
    provider: string,
    type: string,
    summerProfile: boolean | {
        data: string,
        colSpan: number,
    },
    summerReserve?: string,
    winterProfile?: string,
    winterReserve?: string,
    technology: string,
    temperatureSpecificName: string,
    temperatureRange?: string,
    validationReport: {
        certifiedOn: string,
        documentUrl: string,
    },
    supportedTemperatureRanges?: {
        temperatureRange: string,
        temperatureSpecificName: string,
    }[],
    isImmutable?:boolean,
}

export type ProfileData = {
    duration?: number,
    temperatureSummer?: { data: number[] },
    tempRangeSummer?: {
        max: number,
        min: number,
    },
    temperatureWinter?: { data: number[] },
    tempRangeWinter?: {
        max: number,
        min: number,
    },
}

export type SimulationData = {
    calculationSummary?: {
        averageAmbientTemperature: number,
        timeLeftHours: number,
        timeLeftMinutes : number,
    },
    simulationData: {
        isExcursion: boolean,
        maxTemperature: number,
        minTemperature: number,
        remainingEnergyLevel: number,
        simulatedTemperature: number,
        time: number,
        timestamp: string,
    }[]
}
export type SimulationDataHolder = {
    summer?: SimulationData,
    winter?: SimulationData
}

const TemperatureSimulation = () => {
    const classes = useClasses(styles);
    const { lane } = useContext(LaneTemperatureContext);
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { t } = useCustomTranslation();

    useEffect(() => {
        setBreadcrumbData('LANE_NAME', lane?.laneNumber);
    }, [lane]);
    return (
        <div style={{ padding: '12px' }}>
            <TitleHeader>{t('COMMON.PACKAGING')}</TitleHeader>
            <div className={classes.packagingContainer}>
                <PackagingsTable />
                {
                    false && <SimulationModelValidation />
                }
            </div>
            <TitleHeader>{t('COMMON.SUMMER')}</TitleHeader>
            <div className={classes.seasonInfoContainer}>
                <ProfileCard
                    type="summer"
                />
                <AmbientTemperatureCard type="summer" lane={lane} />

                <SimulationSummary type="summer" />
                <Card title={t('LANE_MANAGEMENT.INTERNAL_TEMPERATURE_IN_C')}>
                    <TemperatureChart
                        key="InternalTemperatureSummer"
                        type="summer"
                        timeInterval={5}
                    />
                </Card>
            </div>
            <TitleHeader>{t('COMMON.WINTER')}</TitleHeader>
            <div className={classes.seasonInfoContainer}>
                <ProfileCard
                    type="winter"
                />
                <AmbientTemperatureCard type="winter" lane={lane} />
                <SimulationSummary type="winter" />
                <Card title={t('LANE_MANAGEMENT.INTERNAL_TEMPERATURE_IN_C')}>
                    <TemperatureChart
                        key="InternalTemperatureSummer"
                        type="winter"
                        timeInterval={5}
                    />
                </Card>
            </div>
        </div>
    );
};

export default TemperatureSimulation;
