import DataPaginationContext from 'Contexts/DataPaginationContext';
import React, { ChangeEvent, useCallback, useContext } from 'react';
import MuiPagination from '@mui/material/Pagination';
import { ORDERS_IN_PAGE } from 'shared-components/constants';

import useClasses from 'hooks/useClasses';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import Legend from '../Legend';
import styles from './Pagination.style';

const Pagination = () => {
    const classes = useClasses(styles);

    const {
        page,
        setPage,
        totalElements,
    } = useContext(DataPaginationContext);
    const pagesCount = Math.ceil(totalElements / ORDERS_IN_PAGE);

    const infoLabelFromTo = ` ${(page - 1) * ORDERS_IN_PAGE + 1}
        to ${page * ORDERS_IN_PAGE < totalElements
        ? page * ORDERS_IN_PAGE : totalElements} `;
    const infoLabelTotalNumber = `of ${totalElements}`;

    const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);

    return (

        <div className={classes.legendWrapper}>
            <Legend />

            <QuickHelpTooltip tooltipInfo={{
                order: 4,
                uid: 'paginationShipmentQuickHelp',
                padding: 0,
            }}
            >
                <div className={classes.pagination} aria-disabled>
                    { totalElements > 0 && (
                        <>
                            <div className={classes.label}>
                                Showing Shipments
                                <div className={classes.labelBlue}>{infoLabelFromTo}</div>
                                {infoLabelTotalNumber}
                            </div>
                            <div className={classes.muiPagination}>
                                <MuiPagination
                                    classes={{ root: classes.root }}
                                    count={pagesCount}
                                    onChange={handleChange}
                                    inputMode="numeric"
                                    size="small"
                                    variant="outlined"
                                    shape="rounded"
                                    page={page || 1}
                                />
                            </div>
                        </>
                    )}
                    { totalElements === 0 && (
                        <>
                            <div style={{
                                opacity: '0.6',
                                fontSize: '14px',
                                width: 320,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            >
                                No options
                            </div>
                        </>
                    )}
                </div>
            </QuickHelpTooltip>
        </div>

    );
};

export default Pagination;
