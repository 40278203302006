import useClasses from 'hooks/useClasses';
import React, { useMemo } from 'react';
import PolygonIcon from 'shared-components/PolygonMarker/PolygonIcon';
import PolygonMarker from 'shared-components/PolygonMarker';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import EntityView from 'shared-components/StandalonePagination/EntityView';
import { Link } from 'react-router-dom';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { styles } from '../../Assets.style';

type Props = {
    withoutGeolocationCount?: number,
    showWithoutGeolocation?: boolean,
};
const LegendPanel = ({
    withoutGeolocationCount,
    showWithoutGeolocation = true,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const withoutGeolocationText = useMemo(() => {
        // I18n templates to be used with interpolation
        const text = withoutGeolocationCount === 1
            ? t('TRACK_AND_TRACE.ASSET_WITHOUT_GEOLOCATION_NOTICE')
            : t('TRACK_AND_TRACE.ASSETS_WITHOUT_GEOLOCATION_NOTICE');

        return text.replace('#count', `${withoutGeolocationCount}`);
    }, [withoutGeolocationCount]);

    const {
        checkScreenType,
    } = useScreenSize();
    const iconSizes = useMemo(() => (checkScreenType.down(ScreenType.LG) ? 25 : 35), [checkScreenType]);

    return (
        <div>

            <div className={classes.legendContainer}>
                <QuickHelpTooltip tooltipInfo={{
                    order: 4,
                    uid: 'paginationShipmentQuickHelp',
                    padding: 0,
                }}
                >
                    <div className={`${classes.legend} ${classes.legendPoints}`}>
                        <span className={classes.legendText}>{t('COMMON.LEGEND')}</span>
                        <div className={classes.legendPointsWrapper}>

                            <div className={classes.legendPoint}>
                                <PolygonIcon
                                    className={classes.legendIcon}
                                    type="hexa"
                                    theme="light"
                                    iconDiameter={iconSizes}
                                />
                                {t('TRACK_AND_TRACE.ASSET')}
                            </div>
                            <div className={classes.legendPoint}>
                                <PolygonMarker
                                    className={classes.legendIcon}
                                    polygonIconType="hexa"
                                    theme="light"
                                    count={999}
                                    iconDiameter={iconSizes}
                                />
                                {t('TRACK_AND_TRACE.ASSET_GROUP')}
                            </div>
                            <div className={classes.legendPoint}>
                                <PolygonMarker
                                    className={classes.legendIcon}
                                    polygonIconType="hexa"
                                    theme="light"
                                    filled
                                    iconDiameter={iconSizes}
                                />
                                {t('TRACK_AND_TRACE.SELECTED_ASSET')}
                            </div>
                        </div>
                    </div>
                </QuickHelpTooltip>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
                >
                    {
                        showWithoutGeolocation && withoutGeolocationCount > 0 && (
                            <div>
                                <Link
                                    to="?view=table" style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <div className={`${classes.legend} ${classes.noGeolocation}`}>
                                        {withoutGeolocationText}
                                    </div>
                                </Link>

                            </div>
                        )
                    }
                    <EntityView withoutGeoLocation={withoutGeolocationCount} />
                </div>

            </div>

        </div>
    );
};

export default LegendPanel;
