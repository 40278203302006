import React, {
    useEffect,
    useState,
} from 'react';
import useStatusState from 'hooks/useStatusState';
import { TIME_IN_MS } from 'shared-components/constants';

import useClasses from 'hooks/useClasses';
import AlertIcon from './components/AlertIcon';
import StatusMessage from './components/StatusMessage';
import styles from './StatusMessages.style';

const standardDelay = 5 * TIME_IN_MS.second;
const errorDelay = 10 * TIME_IN_MS.second;

const StatusMessages = () => {
    const { statusState, shouldFlush, alerts, setAlerts } = useStatusState();
    const classes = useClasses(styles);
    const [alertToDelete, setAlertToDelete] = useState<number>(null);

    useEffect(() => {
        if (statusState.message) {
            setAlerts((prev) => {
                const alert = {
                    message: statusState.message,
                    alertColor: statusState.color,
                    alertTime: statusState.time,
                    single: statusState.single,
                    icon: <AlertIcon color={statusState.color} />,
                };

                return statusState.single ? [alert] : ([
                    alert,
                    ...prev,
                ]);
            });

            statusState?.relatedStatuses?.forEach((item) => {
                if (item) {
                    setAlertToDelete(item);
                }
            });

            if (statusState.color !== 'info_static') {
                setTimeout(() => {
                    setAlertToDelete(statusState.time);
                }, statusState.color === 'error' ? errorDelay : standardDelay);
            }
        }
    }, [statusState]);

    useEffect(() => {
        if (alertToDelete) {
            setAlerts((prev) => prev.filter((alert) => alert.alertTime !== alertToDelete));
        }
    }, [alertToDelete]);
    useEffect(() => {
        if (shouldFlush !== 0) setAlerts([]);
    }, [shouldFlush]);

    return (
        <div className={classes.statusMessagesContainer}>
            <div className={classes.statusMessagesInnerContainer}>
                {
                    alerts.map((item, index) => {
                        return (
                            <StatusMessage
                                key={`StatusMessage-${index}`}
                                message={item.message}
                                alertColor={item.alertColor}
                                alertTime={item.alertTime}
                                icon={item.icon}
                                setAlertToDelete={setAlertToDelete}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default StatusMessages;
