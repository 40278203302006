import React from 'react';
import Map from 'shared-components/Map';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './AddressCard.style';

const getMapConfig = (location) => ({
    center: {
        lat: location?.latitude,
        lng: location?.longitude,
    },
    containerStyle: {
        height: '18vh',
        width: '100%',
    },
    zoom: 2,
    backgroundColor: 'unset',
});

type Props = {
    currentAddressLine1?: string,
    currentAddressLine2?: string,
    currentAddressLine3?: string,
    currentCity?: string,
    currentZipCode?: string,
    currentCountry?: string,
    currentCountryCode?: string,
    currentGeolocation?: {
        latitude?: number,
        longitude?: number,
    },
}

const ReadMode = ({
    currentAddressLine1 = '',
    currentAddressLine2 = '',
    currentAddressLine3 = '',
    currentCity = '',
    currentZipCode = '',
    currentCountry = '',
    currentGeolocation = { latitude: null, longitude: null },
}: Props) => {
    const classes = useClasses(styles);

    const { t } = useCustomTranslation();

    return (
        <div className={classes.contentData}>
            <div>
                <div className={classes.fieldTitle}>{t('COMMON.ADDRESS')}</div>
                <div className={classes.filedInfo}>{currentAddressLine1}</div>
                <div className={classes.filedInfo}>{currentAddressLine2}</div>
                <div className={classes.filedInfo}>{currentAddressLine3}</div>
                <div className={classes.filedInfo}>{`${currentZipCode} ${currentCity}`}</div>
                <div className={classes.filedInfo}>{currentCountry}</div>
            </div>
            <Map
                mapConfig={getMapConfig(currentGeolocation)}
                separateMarker={{
                    lat: currentGeolocation?.latitude,
                    lng: currentGeolocation?.longitude,
                }}
            />
        </div>
    );
};

export default ReadMode;
