import React, { useCallback, useState } from 'react';
import { APPROVAL_STATUS } from 'shared-components/constants';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import CustomModal from 'shared-components/CustomModal';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ApprovalModal.style';

type Props = {
    /**
     * Shipment(order) number, required for update request
     */
    shipmentNumber: string,
    /**
     * Evidence Id, required for update request
     */
    productReleaseId: number,
    /**
     * Describes the state of the modal window (open/close)
     * @default false
     */
    open: boolean,
    /**
     * Controls the state of the modal window (open/close)
     */
    setOpen: (isOpen: boolean) => void,
    /**
     * Required to get from BE actual data and update state of the above components.
     */
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
}

/**
 * UI modal component to approve QMApproval for first time (without revision and history)
 */
const ApprovalModal = ({
    shipmentNumber,
    productReleaseId,
    open = false,
    setOpen,
    dataUpdateTrigger,
}: Props) => {
    const classes = useClasses(styles);
    const [qmApprovalComment, setQmApprovalComment] = useState('');
    const { FlexibleRequest: updateQMApproval } = useSecureBackendEndpoints('skyship').statusNotificationRequests;
    const updateQuery = `shipment/${shipmentNumber}/product-release/${productReleaseId}/qm-approval`;
    const { t } = useCustomTranslation();

    const handleApproveClick = useCallback(async () => {
        await updateQMApproval('PATCH', updateQuery, {
            qmApprovalComment,
            qmApprovalStatus: APPROVAL_STATUS.APPROVED,
        });

        setQmApprovalComment('');
        setOpen(false);
        dataUpdateTrigger(true);
    }, [updateQMApproval, qmApprovalComment]);

    const handleRejectClick = useCallback(async () => {
        await updateQMApproval('PATCH', updateQuery, {
            qmApprovalComment,
            qmApprovalStatus: APPROVAL_STATUS.REJECTED,
        });

        setQmApprovalComment('');
        setOpen(false);
        dataUpdateTrigger(true);
    }, [updateQMApproval, qmApprovalComment]);

    const handleTextInputChange = (event) => {
        setQmApprovalComment(event.target.value);
    };

    const handleClose = () => setOpen(false);

    return (
        <CustomModal
            key={`Dialog${productReleaseId}`}
            open={open}
            onClose={handleClose}
            title={t('ORDER_DETAILS.APPROVAL_REJECTION')}
        >
            <DialogContent>
                <DialogContentText
                    className={classes.dialogContentText}
                >
                    {t('ORDER_DETAILS.APPROVAL_REJECTION_MESSAGE')}
                </DialogContentText>
            </DialogContent>
            <TextField
                id={`comment${productReleaseId}`}
                className={classes.textField}
                label={t('COMMON.COMMENT')}
                value={qmApprovalComment}
                onChange={handleTextInputChange}
                multiline
                inputProps={{
                    className: classes.textFieldText,
                }}
            />
            <DialogActions className={classes.dialogActions}>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.approveButton}
                    onClick={handleApproveClick}
                    autoFocus
                >
                    {t('ORDER_DETAILS.APPROVE')}
                </Button>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.rejectButton}
                    onClick={handleRejectClick}
                >
                    {t('ORDER_DETAILS.REJECT')}
                </Button>
            </DialogActions>
        </CustomModal>
    );
};

export default ApprovalModal;
