import React from 'react';
import useClasses from 'hooks/useClasses';
import theme from 'themes/skycellThemeInterface';
import styles from './DotWithDash.style';

export const DotWithDash = ({ row, index }) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.dashWrapper}>
            <div className={[classes.dot, index === 0 ? classes.dotFirst : {}].join(' ')} />
            {index !== row.arrLength - 1 && (
                <svg
                    className={classes.line}
                    width="10"
                    height="56"
                >
                    <rect
                        x="5"
                        y="-4"
                        width="10"
                        height="100"
                        stroke={theme.palette.primary.deepBlue}
                        strokeWidth="1"
                        strokeDasharray="5 3"
                        fill="transparent"
                    />
                </svg>
            )}
        </div>
    );
};
