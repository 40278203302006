import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Commodities from 'shared-components/CompanyInfoComponents/Commodities';
import RowWithIcon from 'shared-components/CompanyInfoComponents/RowWithIcon';
import TextArray from 'shared-components/CompanyInfoComponents/TextArray';
import icons from 'shared-components/icons';
import {
    BROWSER_LANGUAGE,
    SERVICE_PROVIDER_FILTER_DATA_T,
} from 'shared-components/constants';
import { QuestionnaireItem } from 'shared-components/dataTypes';

import AnswerUnit from 'shared-components/CompanyInfoComponents/AnswerUnit';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './QuestionDetails.style';

type Props = {
    questionnaire: QuestionnaireItem,
}

const QuestionDetails = ({ questionnaire }: Props) => {
    const classes = useClasses(styles);
    const [open, setOpen] = useState(false);
    const { t } = useCustomTranslation();
    const { facilities } = SERVICE_PROVIDER_FILTER_DATA_T(t);

    const handleClick = () => setOpen(!open);

    return (
        <>
            <div onClick={handleClick} onKeyPress={handleClick}>
                <span className={classes.tooltipIcon}>?</span>
            </div>
            <Modal
                open={open}
                onClose={handleClick}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.modalBody}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderText}>{t('MY_COMPANY.QUESTION_DETAILS')}</div>
                    </div>
                    <div className={classes.modalData}>
                        <div className={classes.questionDetailsData}>
                            {
                                (Boolean(questionnaire?.services?.length)
                                || Boolean(questionnaire?.commodities?.length)) && (
                                    <div className={classes.grid3Columns}>
                                        <div>
                                            {t('MY_COMPANY.APPLICABLE_BECAUSE_LOCATION_OFFERS_SERVICES')}
                                        </div>
                                        {
                                            Boolean(questionnaire?.services?.length) && (
                                                <div>
                                                    <div className={classes.title}>{t('COMMON.SERVICES')}</div>
                                                    <Commodities
                                                        services={questionnaire.services}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            Boolean(questionnaire?.commodities?.length) && (
                                                <div>
                                                    <div className={classes.title}>{t('COMMON.COMMODITIES')}</div>
                                                    <Commodities
                                                        commodities={questionnaire.commodities}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            <div className={classes.grid3Columns}>
                                <div>
                                    {t('MY_COMPANY.THIS_QUESTION_IS_PART_OF_THE_FOLLOWING_PROFILE')}
                                </div>
                                <div>
                                    <RowWithIcon
                                        customIconClass={classes.profileIcon}
                                        icon={
                                            questionnaire.questionnaireProfile === 'EXTENDED'
                                                ? icons.star
                                                : icons.checked_blue
                                        }
                                        label={questionnaire.questionnaireProfile}
                                    />
                                </div>
                            </div>
                            {
                                questionnaire?.relatedTo === 'CAPABILITY' && (
                                    <div className={classes.grid3Columns}>
                                        <div>
                                            {t('MY_COMPANY.AFFECTS_THE_FOLLOWING_CAPABILITY')}
                                        </div>
                                        <RowWithIcon
                                            customIconClass={classes.profileIcon}
                                            icon={icons.checked_blue}
                                            label={questionnaire.capability}
                                        />
                                    </div>
                                )
                            }
                            {
                                questionnaire?.relatedTo === 'CERTIFICATE' && (
                                    <div className={classes.grid3Columns}>
                                        <div>
                                            {t('MY_COMPANY.AFFECTS_THE_FOLLOWING_CERTIFICATE')}
                                        </div>
                                        <TextArray
                                            textItems={[questionnaire.certificate]}
                                        />
                                    </div>
                                )
                            }
                            {
                                questionnaire?.relatedTo === 'FACILITY' && (
                                    <div className={classes.grid3Columns}>
                                        <div>
                                            {t('MY_COMPANY.AFFECTS_THE_FOLLOWING_FACILITY')}
                                        </div>
                                        <div className={classes.container}>
                                            <img
                                                alt={facilities[questionnaire.facility]?.label}
                                                className={classes.icon}
                                                src={facilities[questionnaire.facility]?.icon}
                                            />
                                            <div>{facilities[questionnaire.facility]?.label}</div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                Boolean(questionnaire?.answerHistory?.length) && (
                                    <div className={classes.grid2Columns}>
                                        <div>
                                            {t('MY_COMPANY.FOLLOWING_PEOPLE_ADDED_ANSWERS')}
                                        </div>
                                        <div>
                                            {
                                                questionnaire.answerHistory.map((step, index) => {
                                                    const answerDate = new Date(step.answeredOn)
                                                        .toLocaleString(BROWSER_LANGUAGE);

                                                    return (
                                                        <div
                                                            className={classes.grid4Columns}
                                                            key={`answer-${index}`}
                                                        >
                                                            <div>
                                                                <ul className={classes.progress}>
                                                                    <li className={classes.divider} />
                                                                    <li className={classes.node} />
                                                                    <li className={classes.divider} />
                                                                </ul>
                                                            </div>

                                                            <div>
                                                                <div className={classes.title}>
                                                                    {t('MY_COMPANY.ANSWER')}
                                                                </div>
                                                                <AnswerUnit
                                                                    amount={questionnaire.amount}
                                                                    availableAnswerOptions={
                                                                        questionnaire.availableAnswerOptions
                                                                    }
                                                                    disabled
                                                                    selectedAnswerOptions={step.selectedAnswerOptions}
                                                                    question={questionnaire.question}
                                                                    questionType={questionnaire.questionType}
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className={classes.title}>
                                                                    {t('COMMON.TYPE')}
                                                                </div>
                                                                <div className={classes.answerType}>
                                                                    {step.answerType.toLowerCase()}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={classes.title}>
                                                                    {t('COMMON.DATE_TIME')}
                                                                </div>
                                                                <div>{answerDate}</div>
                                                            </div>
                                                            <div>
                                                                <div className={classes.title}>
                                                                    {t('COMMON.BY')}
                                                                </div>
                                                                <div>
                                                                    {
                                                                        `${
                                                                            step.answeredBy.firstName
                                                                        } ${step.answeredBy.lastName}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default QuestionDetails;
