import { css } from '@emotion/css';

const generateStyles = (theme, props?: { title?: string }) => {
    const TABLE_PADDING = 20;

    const tableTitle = css({
        fontSize: '32px',
        margin: `12px ${TABLE_PADDING}px`,
    });

    const tableComponent = css({
        color: theme.palette.secondary[600],
        border: 'none',
        font: 'normal normal 500 1rem Roboto',
        textDecoration: 'none',
        backgroundColor: 'transparent !important',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            wordBreak: 'unset',
        },
        width: props?.title ? `calc(100% - ${TABLE_PADDING * 2}px)` : '100%',
        margin: 'auto',
    });

    const noBar = css({
        '&::after': {
            background: 'transparent!important',
        },
    });

    const tableContainer = css({
        overflow: 'auto',
        scrollSnapType: 'y mandatory',
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.common.white,
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: theme.palette.secondary[100],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
        background: theme.palette.common.white,
        borderRadius: 6,
        paddingBottom: '20px',
    });

    const boxShadow = css({
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    });

    const tableHeader = css({
        background: theme.palette.common.white,
        top: '0',
        position: 'sticky',
        zIndex: 29,
        boxShadow: '0 0.6px rgba(0, 0, 0, 0.1)',
        paddingLeft: '12px',
    });

    const reactTableTableRow = css({
        width: '100%',
        minHeight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
    });

    const rowWrapper = css({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    });

    const dateFilterContainer = css({
        display: 'flex',
        margin: '5px',
        alignItems: 'center',
        padding: '10px',
        border: '2px solid #61C6E9',
        borderRadius: 4,
    });

    const dateFilterContainerText = css({
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    });

    const noData = css({
        display: 'flex',
        width: '100%',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary[600],
    });

    const datePicker = css({
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            color: '#61C6E9!important',
        },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
    });

    const datePickerTextField = css({
        width: '120px',
    });

    return {
        tableTitle,
        tableComponent,
        noBar,
        tableContainer,
        boxShadow,
        tableHeader,
        reactTableTableRow,
        rowWrapper,
        dateFilterContainer,
        dateFilterContainerText,
        noData,
        datePicker,
        datePickerTextField,
    };
};

export default generateStyles;
