import React, { useContext, useEffect, useState, CSSProperties, useMemo } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCacheContext from 'hooks/useCacheContext';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import parseQueryParams from 'utils/parsePathQueries';
import { TimeRange } from 'dataTypes/common';
import { PICTURES } from 'shared-components/constants';
import ShortPackagingInfo from 'shared-components/ShortPackagingInfo';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import Card from 'shared-components/Card';
import useGetGatewaysByGatewayNumbers,
{ extractLatestSensorDataWithUniqueGatewayNumbersHistorical }
    from 'hooks/useGetGatewaysByGatewayNumbers/useGetGatewaysByGatewayNumbers';
import BackToLink from 'shared-components/BackToLink';
import useGetCommonData from 'hooks/useGetCommonData';
import { Asset } from 'dataTypes/SecureBackend/apiResponse';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
} from './dataTypes';
import styles from './LocationHistory.style';
import Options from './components/Options';
import {
    initializeTimeRange,
    initializeRequestBody, fetchAssets, initializeEntityList,
    getTimeDiffMinutes,
} from './lib';
import useLoadSensorData from './hooks/useLoadSensorData';
import LocationHistoryTable from './components/LocationHistoryTable';
import LocationHistoryMap from './components/LocationHistoryMap';

const LocationHistory = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const location = useLocation();
    const { getCacheValue } = useCacheContext();
    const { entityNumber: entityNumberInPath = '' } = useParams() as unknown as PathParams;
    const queryParams = parseQueryParams(location.search);
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');
    const [isCurrentTime, setIsCurrentTime] = useState(true);

    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(true, queryParams, false, true));

    const [localTimezone, setLocalTimezone] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );
    const [assetListAll, setAssetListAll] = useState(initializeEntityList(entitySelectorItem, 'assets'));

    const {
        setMenuTabs,
        setCustomPageTitle,
        availableHeight,
    } = useContext(LayoutContext);

    const { entityType } = entitySelectorItem ?? {};

    useEffect(() => {
        setMenuTabs([
            {
                title: t('MENU_ITEMS.LOCATION_HISTORY'),
                link: `/asset-management/assets/location/${entitySelectorItem.entityNumber}`,
                iconSrc: '',
                activePage: true,
            },
            {
                title: t('COMMON.TEMPERATURE'),
                link: `/asset-management/assets/readout/${entitySelectorItem.entityNumber}`,
                iconSrc: '',
                activePage: false,
            },
            {
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
                link: `/asset-management/assets/pairings/${entitySelectorItem.entityNumber}`,
                iconSrc: '',
                activePage: false,
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [entitySelectorItem]);

    const {
        isFetchComplete,
        assetList,
        assetRequestStatus,
    } = useLoadSensorData(entitySelectorItem, 'assets', entityNumberInPath);

    useEffect(() => {
        if (
            entityNumberInPath
            && entitySelectorItem.entityNumber !== entityNumberInPath
        ) {
            if (assetList.length > 0) {
                const asset = assetList.find(item => item.entityNumber === entityNumberInPath) || null;

                if (asset) {
                    setEntitySelectorItem(asset);
                }
            }
        }
    }, [
        assetList,
    ]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            navigate({
                pathname: `/assets/location/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true, queryParams, false, true));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    const assetsFetched = assetRequestStatus === 'SUCCESS';

    const wrapperHeightNum = Number(availableHeight.replaceAll('px', '')) - 140;

    const loadingWrapperStyle: CSSProperties = useMemo(() => ({
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        height: wrapperHeightNum,
    }), [assetsFetched, availableHeight]);

    const {
        Get: getLoggerSensorData,
    } = useSecureBackendEndpoints('assets').requests;
    const {
        data: sensorData,
    } = useCachedQueryRequest({
        key: `${entitySelectorItem?.entityId}_sensor_data_from=${timeRange.from}&to=${timeRange.to}`,
        request: getLoggerSensorData.bind(null, `${
            entitySelectorItem?.entityId
        }/sensor-data?from=${timeRange.from}&to=${timeRange.to}`),
        options: {
            enabled: !!(entitySelectorItem?.entityId && timeRange.from && timeRange.to),
            retry: false,
        },
    });

    const {
        data: rawAssets,
    } = useGetCommonData<Asset[]>('assets/search', {
        postProcess: it => it.resultList,
        enabled: !!entitySelectorItem?.entityNumber,
        query: entitySelectorItem?.entityNumber ? {
            q: entitySelectorItem?.entityNumber,
        } : {},
    });

    const {
        data: rawAssetsAll,
    } = useGetCommonData<Asset[]>('assets/search', {
        postProcess: it => it.resultList,
        enabled: true,
        query: {
            page: 0,
            pageSize: 1000,
        },
    });

    useEffect(() => {
        if (rawAssetsAll) {
            setAssetListAll(fetchAssets(rawAssetsAll, assetTypeLabels));
        }
    }, [rawAssetsAll]);

    const uniqueLastSensorData = useMemo(() => {
        return extractLatestSensorDataWithUniqueGatewayNumbersHistorical(sensorData);
    }, [sensorData]);

    const uniqueGatewayNumbers = useMemo(() => [
        ...new Set(
            uniqueLastSensorData.map(it => it.gatewayNumber,
            ))],
    [uniqueLastSensorData]);

    const {
        gateways = [],
    } = useGetGatewaysByGatewayNumbers({
        query: {
            gatewayImeiMac: uniqueGatewayNumbers,
        },
        enabled: uniqueLastSensorData.length !== 0,
    });

    const uniqueSensorDataWithGateways = useMemo(() => uniqueLastSensorData.map(it => {
        const gateway = gateways?.find(gw => gw?.gatewayImeiMac?.toLowerCase() === it?.gatewayNumber?.toLowerCase());

        return {
            ...it,
            gateway,
        };
    }), [uniqueLastSensorData, gateways]);

    const rows = useMemo(() => {
        if (uniqueSensorDataWithGateways?.length) {
            const preparedRows = uniqueSensorDataWithGateways.map((it, ind, arr) => {
                const areaTimeDiffMinutes = getTimeDiffMinutes({ it, ind, arr, sensorData });
                const locationTimeDiffMinutes = getTimeDiffMinutes({ it, ind, arr, sensorData });

                const timeData = {
                    time: it.sensorData?.t,
                    gateway: it.isHistorical ? null : it.gateway,
                    localTimezone,
                };

                let locationName = it.isHistorical ? t('SENSOR_DATA.NOT_CONNECTED') : `${it.gateway?.iataCode || ''}\
${it.gateway?.iataCode ? ' | ' : ''}${it.gateway?.locationName || ''}`;

                if (
                    ind !== 0
                    && arr[ind - 1].gateway?.iataCode === it.gateway?.iataCode
                    && arr[ind - 1].gateway?.locationName === it.gateway?.locationName
                    && arr[ind - 1].isHistorical === it.isHistorical
                ) {
                    locationName = ' ';
                }

                return {
                    locationName,
                    area: it.isHistorical ? 'N/A' : it.gateway?.area,
                    areaTimeDiffMinutes,
                    locationTimeDiffMinutes,
                    timeData,
                    arrLength: uniqueSensorDataWithGateways?.length,
                    isHistorical: it.isHistorical,
                };
            });

            preparedRows.forEach((it, ind, arr) => {
                const nextEntry = arr[ind + 1];

                if (!nextEntry) return;

                if (it.locationName !== ' ' && nextEntry.locationName === ' ') {
                    for (let i = ind + 1; i < arr.length; i++) {
                        if (arr[i].locationName === ' ') {
                            it.locationTimeDiffMinutes += arr[i].locationTimeDiffMinutes;
                            arr[i].locationTimeDiffMinutes = null;
                        } else {
                            break;
                        }
                    }
                }
            });

            return preparedRows;
        }

        if (rawAssets?.length) {
            const [asset] = rawAssets;

            if (!asset?.lastMeasuredData) return [];

            const locationName = `${asset.lastMeasuredData?.iataCode || ''}\
${asset.lastMeasuredData?.iataCode ? ' | ' : ''}${asset.lastMeasuredData?.locationName || ''}`;

            const areaTimeDiffMinutes = null; // only one entry - no time diff

            const timeData = {
                time: asset.lastMeasuredData?.temperatureGeolocationTimestamp,
                gateway: asset.lastMeasuredData?.geolocation,
                localTimezone,
            };

            return [{
                locationName,
                area: asset.lastMeasuredData?.area,
                areaTimeDiffMinutes,
                timeData,
                arrLength: 1,
            }];
        }

        return [];
    }, [uniqueSensorDataWithGateways, localTimezone, rawAssets]);

    const lines = useMemo(() => uniqueSensorDataWithGateways.map((it, ind, arr) => {
        if (ind === arr.length - 1) {
            return null;
        }

        const nextEntry = arr[ind + 1];

        return [
            {
                lat: it.gateway?.latitude,
                lng: it.gateway?.longitude,
            },
            {
                lat: nextEntry.gateway?.latitude,
                lng: nextEntry.gateway?.longitude,
            },
        ];
    }).filter(Boolean), [uniqueSensorDataWithGateways]);

    const selected = useMemo(() => {
        if (selectedIndex === null) return null;

        return {
            loggerFamily: 'MR_810T',
            lastMeasuredLatitude: rows[selectedIndex]?.timeData?.gateway?.latitude,
            lastMeasuredLongitude: rows[selectedIndex]?.timeData?.gateway?.longitude,
        };
    }, [uniqueSensorDataWithGateways, selectedIndex]);

    const assetTypeLabel = !entitySelectorItem?.additionalData?.assetType
        ? ''
        : t(`ASSET_TYPE_LABEL.${entitySelectorItem.additionalData.assetType}`);

    const widthPercent = 100;
    const heightTable = `calc(${wrapperHeightNum}px * 0.5)`;
    const heightMap = `calc(${wrapperHeightNum}px * 0.48)`;

    return (
        <div style={{
            padding: '0 12px 12px 12px',
        }}
        >
            <BackToLink
                to={`/asset-management/assets?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
                title={t('COMMON.ASSETS_LIST')}
                marginRight={12}
                marginLeft={0}
                marginBottom={10}
                marginTop={10}
            />
            <div className={classes.contentContainer}>
                <Card>
                    <h3 className={classes.title}>
                        {
                            `${assetTypeLabel} ${entitySelectorItem
                                ?.entityNumber} | ${t('MENU_ITEMS.LOCATION_HISTORY')}`
                        }
                    </h3>
                    <div style={loadingWrapperStyle}>
                        <LocationHistoryTable
                            selectedIndex={selectedIndex}
                            rows={rows}
                            onRowClick={(_, i) => setSelectedIndex(i)}
                            widthPercent={widthPercent}
                            height={heightTable}
                        />
                        <LocationHistoryMap
                            polylines={lines.every(Boolean) ? lines : []}
                            selected={selected}
                            widthPercent={widthPercent}
                            height={heightMap}
                        />
                    </div>
                </Card>
                <div className={classes.contentRightColumn}>
                    <Options
                        entitySelectorItem={entitySelectorItem}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setTimeRange={setTimeRange}
                        assetList={assetListAll}
                        disabled={entityType === null}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        setRequestBody={setRequestBody}
                        setLocalTimezone={setLocalTimezone}
                        setShowMap={() => {}}
                        timeRange={timeRange}
                        isCurrentTime={isCurrentTime}
                        setIsCurrentTime={setIsCurrentTime}
                    />
                    {
                        (entityType === 'assets') && (
                            <ShortPackagingInfo
                                model={
                                    entitySelectorItem.additionalData.assetType === 'CONTAINER'
                                        ? t('ASSET_TYPE_LABEL.CONTAINER')
                                        : t('ASSET_TYPE_LABEL.AIRPORT_PALLET')
                                }
                                picture={
                                    entitySelectorItem.additionalData.assetType === 'CONTAINER'
                                        ? PICTURES.uld
                                        : PICTURES.airport_pallet
                                }
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default LocationHistory;
