import React, { ChangeEvent } from 'react';
import Switch from '@mui/material/Switch';
import useClasses from 'hooks/useClasses';
import styles from './CompanyRolesCard.style';

type Props = {
    checked?: boolean,
    disabled?: boolean,
    blocked?: boolean,
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    roleCode?: string,
    conflictingRoles?: string[],
    setRoles?: (role) => void,
    title?: string,
}

const SwitchItem = ({
    checked = false,
    disabled = false,
    blocked = false,
    // onChange,
    roleCode,
    conflictingRoles = [],
    setRoles = () => {},
    title,
} : Props) => {
    const classes = useClasses(styles);
    const handleChange = () => {
        setRoles(prev => {
            const remove = prev.includes(roleCode);

            if (remove) {
                return prev.filter(code => code !== roleCode);
            } else {
                return [
                    ...(
                        conflictingRoles.includes(roleCode)
                            ? prev.filter(
                                it => !conflictingRoles.includes(it) || it === roleCode,
                            ) : prev
                    ),
                    roleCode,
                ];
            }
        });
    };

    return (
        <div className={classes.switchItemContainerStyle}>
            <Switch
                // className={blocked ? classes.blocked : ''}
                checked={roleCode === 'BASIC_ACCESS' || roleCode === 'SKYCELL_ADMIN' ? true : checked}
                color={disabled ? 'secondary' : 'primary'}
                disabled={roleCode === 'BASIC_ACCESS' || roleCode === 'SKYCELL_ADMIN' ? true : disabled || blocked}
                onChange={blocked ? () => {} : handleChange}
                size="small"
            />
            <div>{title}</div>
        </div>
    );
};

export default SwitchItem;
