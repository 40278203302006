import icons from 'shared-components/icons';
import {
    CommonTableItem,
    TABLE_INPUT_TYPE,
} from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable/CommonTableTypes';
import { formTitle } from 'LaneManagement/AddLane/tools';
import { TFunction } from 'i18next';
import { commonFields, staticRestData } from '../commonTableConstants';

export const tableMetaData: (t: TFunction) => CommonTableItem[] = (t) => [
    {
        id: 'index',
        inputType: TABLE_INPUT_TYPE.PK,
    },
    {
        id: 'name',
        title: formTitle(icons.stepTypes.air, t('LANE_MANAGEMENT.MILESTONE_CARD.FLIGHT')),
        inputType: TABLE_INPUT_TYPE.TEXT,
        required: true,
    },
    ...commonFields(t),
];

export const locationsTableDefaults = {
    name: '',
    ambientControl: 'CONTROLLED',
    ...staticRestData,
};
