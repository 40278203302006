import useGeneratedStyles from 'hooks/useGeneratedStyles/useGeneratedStyles';
import React, { useCallback, useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import SideFilter, {
    AccordionOptionsGroup,
    FilterBlock,
} from 'shared-components/SideFilter';
import { useTheme } from '@mui/material/styles';
import { ProcessedNotification, ClientSideFilter } from 'Notifications/lib';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import RegularSelect from 'shared-components/RegularSelect';
import { defaultStatusFilterLogic } from 'Notifications/Notifications';
import EnumFilter from 'shared-components/Table/TableSideFilter/filterTypes/EnumFilter';
import { EnumFilterOption } from 'shared-components/Table/dataTypes';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';

type Props = {
    filters: ClientSideFilter,
    setFilters: any,
    notifications: ProcessedNotification[],
    filteredNotifications: ProcessedNotification[],
}

export const STATUS_OPTIONS = [
    {
        value: 'UNREAD',
        logic: (data:ProcessedNotification) => {
            return (!data.hasBeenRead && !data.hasBeenDeleted) || data.isExpanded;
        },
    },
    {
        value: 'DELETED',
        logic: (data:ProcessedNotification) => {
            return data.hasBeenDeleted;
        },
    },
];

const NotificationClientSideFilter = ({
    filters,
    setFilters,
    notifications,
    filteredNotifications,
} : Props) => {
    const { t } = useCustomTranslation();
    const classes = useGeneratedStyles(generateStyles);
    const theme = useTheme();
    const updateFilters = useCallback((key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value,
        }));
    }, []);
    const statusCount = useMemo(() => {
        const { logic } = STATUS_OPTIONS
            .find(it => it.value === filters.notificationStatus) || { logic: defaultStatusFilterLogic };

        return filteredNotifications.filter(it => logic(it)).length;
    }, [filters, filteredNotifications]);
    const notificationsGroupOptions = useMemo(() => notifications
        .map(it => it.group)
        .reduce((acc: EnumFilterOption[], str: string) => {
            const found = acc.find(item => item.value === str);

            if (found) {
                found.count += 1;
            } else {
                acc.push({ value: str, count: 1 });
            }
            return acc;
        }, []), [notifications]);

    return (
        <SideFilter id="NotificationsPageClientSideFilter">
            <FilterBlock postDivider={false} height={20}>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
            </FilterBlock>

            {
                false && (
                    <FilterBlock>
                        <TextField
                            id="TextFieldInput"
                            onInput={(event) => {
                                updateFilters('sample', event.target['value']);
                            }}
                            value={filters.sample}
                            variant="standard"
                            placeholder={t('COMMON.SEARCH')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: theme.palette.common.black }} />
                                    </InputAdornment>
                                ),
                                className: classes.input,
                            }}
                        />

                    </FilterBlock>
                )
            }
            <FilterBlock>
                <AccordionOptionsGroup
                    title={`${t('TRACK_AND_TRACE.TITLE_NOTIFICATION_STATUS')} (${statusCount})`}
                    alwaysOpen
                >
                    <RegularSelect
                        value={filters.notificationStatus}
                        options={STATUS_OPTIONS.map(it => ({ value: it.value }))}
                        onChange={val => updateFilters('notificationStatus', val)}
                        onClear={() => updateFilters('notificationStatus', '')}
                        placeholder={t('COMMON.SELECT_NOTIFICATION_STATUS')}
                    />

                </AccordionOptionsGroup>
            </FilterBlock>
            {
                notificationsGroupOptions.length > 0 && (
                    <EnumFilter
                        key="notifications_filter_notification_group"
                        column={{
                            sideFilterKey: 'group',
                            Header: t('NOTIFICATIONS.NOTIFICATION_GROUP'),
                            poeLabelMask: 'NOTIFICATION_GROUP',
                        }}
                        skipOptionsUpdate
                        options={notificationsGroupOptions}
                        updateFilters={updateFilters}
                    />
                )
            }

        </SideFilter>
    );
};

export default NotificationClientSideFilter;
