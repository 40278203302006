import useGeneratedStyles from 'hooks/useGeneratedStyles';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import generateStyles from 'LaneManagement/AddLane/AddLane.style';
import { Button, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import { LaneObject } from 'shared-components/dataTypes';
import { useNavigate } from 'react-router-dom';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { CardType } from 'Contexts/LaneCardsContext/LaneCardsContext';
import useGetEntity from 'hooks/useGetEntity';
import { extractId } from 'hooks/useGetEntity/useGetEntity';
import { bakeLane, fieldsLeft, fieldsRight } from 'LaneManagement/AddLane/Cards/SummaryCard/lib';
import { fieldMapper } from './fieldMapper';

type Props = {
    laneId: number,
    laneData: LaneObject,
    setLaneData: Function,
    setError: Function,
}

function SummaryCard({
    laneId,
    laneData,
    setLaneData,
    setError,
}: Props) {
    const classes = useGeneratedStyles(generateStyles);
    const navigate = useNavigate();
    const { Get: getLaneById } = useSecureBackendEndpoints('lanes').requests;
    const {
        Create: postLane,
        FlexibleRequest: updateLane,
    } = useSecureBackendEndpoints('lanes').statusNotificationRequests;
    const {
        replaceWithAddressDTO,
        getEntity,
        entityFromAddressId,
        dataLoaded,
    } = useGetEntity();
    const theme = useTheme();
    const { company } = useCurrentUserContext();
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const {
        editingCard,
        setEditingCard,
        setMilestonesLoading,
        shouldUpdateLane,
    } = useContext(LaneCardsContext);
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const { t } = useCustomTranslation();

    const [backup, setBackup] = useState<LaneObject>(null);
    const [cardHover, setCardHover] = useState(false);
    const [loading, setLoading] = useState(false);

    const editRight = useMemo(() => {
        return !laneId || editingCard?.type === CardType.SUMMARY_RIGHT;
    }, [editingCard, laneId]);
    const editLeft = useMemo(() => {
        return !laneId || editingCard?.type === CardType.SUMMARY_LEFT;
    }, [editingCard, laneId]);

    const setEdit = useCallback((bool = false, card: 'left' | 'right' = 'left') => {
        const selectedCardType = (card === 'left' ? CardType.SUMMARY_LEFT : CardType.SUMMARY_RIGHT);

        if (editingCard
            && editingCard.type !== selectedCardType
            && bool) return;
        setEditingCard(bool ? {
            type: selectedCardType,
        } : null);
    }, [setEditingCard, editingCard]);

    useEffect(() => {
        setBreadcrumbData('LANE_NAME', laneData?.laneNumber);
    }, [laneData]);

    useEffect(() => {
        if (laneId && dataLoaded) {
            setMilestonesLoading(true);
            (async () => {
                setLoading(true);
                let result;

                try {
                    result = await getLaneById(laneId);
                } catch (error) {
                    setError({
                        code: 400,
                    });
                    setBreadcrumbData('LANE_NAME', '-');
                    setLoading(false);
                    return;
                } finally {
                    setMilestonesLoading(false);
                    setLoading(false);
                }
                const receivedLane:LaneObject = result?.data;
                const newLaneObject = bakeLane(receivedLane, replaceWithAddressDTO, getEntity, entityFromAddressId);

                setLaneData(newLaneObject);
            })();
        }
    }, [
        dataLoaded,
        shouldUpdateLane,
        replaceWithAddressDTO,
        getEntity,
        entityFromAddressId,
    ]);
    const processedLaneData = useMemo(() => {
        const processed = { ...laneData };

        if (company.companyType === 'PHARMA') {
            processed['pharmaCompanyId'] = company.id;
        }
        return processed;
    }, [laneData, company]);

    const uploadLane = useCallback(() => {
        (async () => {
            setLoading(true);
            const constructedLaneData = {
                ...processedLaneData,
            };

            if (laneId) {
                delete constructedLaneData.fourthPartyLogistics;

                const result = editRight
                    ? await updateLane('PATCH', `${laneId}/summary-data`, constructedLaneData)
                    : await updateLane(
                        'PATCH',
                         `${laneId}/4pl-company-site`,
                         { id: constructedLaneData.fourthPartyLogisticsId },
                    );

                setLoading(false);
                if (result?.status === 200) {
                    global.console.log('Lane updated');
                    setEdit(false, 'right');
                } else {
                    setErrorStatus(result?.response?.data?.errorMessage);
                }
            } else {
                constructedLaneData.fourthPartyLogisticsId = extractId(constructedLaneData.fourthPartyLogistics);
                delete constructedLaneData.fourthPartyLogistics;
                const result: AxiosResponse = await postLane(constructedLaneData);
                const newLaneLocation = result?.headers?.location;

                setLoading(false);
                if (!newLaneLocation) return;
                const newLaneId = newLaneLocation.match(/\d+$/)?.[0];

                global.console.log('Lane created');
                global.console.log(`New Lane: ${newLaneLocation}`);
                global.console.log(result);
                setEdit(false, 'right');
                navigate(`/lane-management/lanes/${newLaneId}/definition`);
            }
        })();
    }, [processedLaneData, laneId, editLeft, editRight]);

    const reset = useCallback((card: 'left' | 'right') => {
        setLaneData(backup);
        setBackup(null);
        setEdit(false, card);
    }, [backup, setBackup]);

    const updateField = useCallback((field, data) => {
        setLaneData((prev) => ({
            ...prev,
            [field]: data,
        }));
    }, [setLaneData]);

    useEffect(() => {
        if (editRight || editLeft) {
            setBackup(laneData);
        } else {
            setBackup(null);
        }
    }, [editRight, editLeft]);

    const fieldsFilled = useMemo(() => {
        return !!laneData.fourthPartyLogistics && !!company;
    }, [laneData]);

    return (
        <div className={classes.summaryCardsWrapper}>
            <div
                onMouseEnter={() => setCardHover(true)}
                onMouseLeave={() => setCardHover(false)}
            >
                <CardWithEditableContent
                    title={t('LANE_MANAGEMENT.KEY_DATA')}
                    className={(loading && !dataLoaded) ? classes.loading : ''}
                    style={{
                        overflow: 'visible',
                    }}
                    disabled={editingCard && !editLeft}
                    highlight={editLeft && Boolean(laneId)}
                    fullHeight
                    onClickEditButton={setEdit.bind(null, true, 'left')}
                    onClickSaveButton={uploadLane}
                    onClickUndoButton={reset.bind(null, 'left')}
                    showEditButton={cardHover && !editLeft && !laneData?.fourthPartyLogisticsId}
                    showLock={laneData?.fourthPartyLogisticsId && !editLeft}
                    showSaveButton={laneId && editLeft}
                    showUndoButton={laneId && !!backup && editLeft}
                >
                    <div className={[classes.cardBody, classes.cardBodyDivided, classes.summary].join(' ')}>
                        {
                            fieldsLeft(
                                classes,
                                t,
                                laneId,
                                company,
                                laneData,
                            ).map(field => fieldMapper({
                                classes,
                                field,
                                value: processedLaneData[field.id],
                                editable: editLeft,
                                onChange: updateField,
                                theme,
                            }))
                        }
                    </div>
                </CardWithEditableContent>

            </div>
            <div
                className={classes.summary}
                onMouseEnter={() => setCardHover(true)}
                onMouseLeave={() => setCardHover(false)}
            >
                <CardWithEditableContent
                    title={t('LANE_MANAGEMENT.GENERAL_INFORMATION')}
                    className={loading ? classes.loading : ''}
                    style={{
                        overflow: 'visible',
                    }}
                    disabled={editingCard && !editRight}
                    highlight={editRight && Boolean(laneId)}
                    fullHeight
                    onClickEditButton={setEdit.bind(null, true, 'right')}
                    onClickSaveButton={uploadLane}
                    onClickUndoButton={reset.bind(null, 'right')}
                    showEditButton={cardHover && !editRight}
                    showSaveButton={laneId && editRight}
                    showUndoButton={laneId && !!backup && editRight}
                >
                    <div className={[classes.cardBody, classes.cardBodyDivided, classes.summary].join(' ')}>
                        {
                            fieldsRight(classes, t).map(field => fieldMapper({
                                classes,
                                field,
                                value: processedLaneData[field.id],
                                editable: editRight,
                                onChange: updateField,
                                theme,
                            }))
                        }
                    </div>
                </CardWithEditableContent>
            </div>
            {
                !laneId && (
                    <div
                        className={[classes.createButton].join(' ')}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={uploadLane}
                            className={classes.button}
                            style={{ width: 'min-content' }}
                            disabled={!fieldsFilled}
                        >
                            Create
                        </Button>
                    </div>
                )
            }

        </div>
    );
}

export default SummaryCard;
