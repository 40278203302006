import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    icon: {
        width: '31px',
        height: '26px',
    },
    a: {
        textDecoration: 'none',
    },
    listItemStyle: {
        height: '42px',
        padding: '8px!important',
    },
    activeItem: {
        background: `${theme.palette.primary.darkBlue}!important`,
    },
    activeListItemStyle: {
        height: '42px',
        padding: '8px!important',
        position: 'relative',
    },
    listItemTextStyle: {
        marginLeft: '15px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        textTransform: 'uppercase',
        opacity: 1,
    },
    homeListItem: {
        background: `${theme.palette.primary[600]} 0% 0% no-repeat padding-box`,
        opacity: 1,
        height: '36px',
        padding: '8px!important',
    },
    homeListItemText: {
        marginLeft: '15px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        opacity: 1,
    },
    block: {
        display: 'block',
    },
});

export default styles;
