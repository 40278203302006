import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PageWithFilter } from 'Layout';
import { MAP_CONFIG } from 'shared-components/constants';
import { FacetDTO } from 'shared-components/dataTypes';
import { styles } from 'TrackAndTrace/Assets/Assets.style';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO, GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import mapGatewaysToLocations from 'utils/mapGatewaysToLocations';
import Table from 'shared-components/Table';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import AddAssets from 'TrackAndTrace/Assets/components/AddAssets';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import StandalonePagination from 'shared-components/StandalonePagination';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import useGetAirports from 'hooks/useGetAirports';
import useStatusState from 'hooks/useStatusState';
import { facetsConvert } from 'utils/stringTool';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { getOptionsFromFacets } from 'shared-components/common';
import AlertIcon from 'StatusMessages/components/AlertIcon';
import { extractAssetData, ExtractedAssetData, initialAssetFilterOptions } from './lib';

import AssetFilterPanel from './components/AssetFilters';

import MapView from './MapView';
import tableColumns from './tableColumns';

const PAGE_SIZE_TABLE = 40;
const PAGE_SIZE_MAP = 1000;

export const ZOOM_THRESHOLD = 13;

const FILTERS_WITH_NULL = ['iataCodes', 'areas'];
const Assets = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const view = useGetViewTypeFromQuery();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const { getFilter, setFilter } = useFilter(PAGES.ASSETS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [areaLyingSinceTimestamp, setAreaLyingSinceTimestamp] = useState<number | null>(null);
    const [locationLyingSinceTimestamp, setLocationLyingSinceTimestamp] = useState<number | null>(null);
    const availableHeight = useAvailableHeight();
    const {
        setOnboardingTooltipInfo,
        setHasOnboardingTour,
        enableTooltips,
    } = useContext(QuickHelpContext);

    const {
        setAlerts,
        flushStatusState,
    } = useStatusState();
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const rawAirports = useGetAirports();
    const PAGE_SIZE = useMemo(() => (view === 'table' ? PAGE_SIZE_TABLE : PAGE_SIZE_MAP), [view]);
    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialAssetFilterOptions);

    const isAirportShowingMode = useMemo(() => zoom < ZOOM_THRESHOLD, [zoom]);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
                ...(areaLyingSinceTimestamp !== null ? { areaLyingSinceTimestamp } : {}),
                ...(locationLyingSinceTimestamp !== null ? { locationLyingSinceTimestamp } : {}),
            },
            queryWrap: false,
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
        });

    const {
        data: gateways = [],
    } = useGetCommonData<GatewayInfoDTO[]>('gateways/search', {
        postProcess: (data) => data.resultList,
    });

    const airports = useMemo(() => {
        if (!rawAirports || !rawFacets) {
            return [];
        }
        const facetsAirports = rawFacets.find(facet => facet.filterName === 'iataCodes')?.counts;

        if (!facetsAirports) {
            return [];
        }
        return rawAirports.filter(
            airport => facetsAirports.some(facetAirport => facetAirport.filterValue === airport.code),
        )
            .map(airport => {
                const facetAirport = facetsAirports.find(facetAirport => facetAirport.filterValue === airport.code);

                return {
                    ...airport,
                    count: facetAirport?.count || 0,
                };
            });
    }, [rawAirports, rawFacets]);

    const areas = useMemo(() => {
        if (!rawFacets) return [];
        const facetsAreas = rawFacets.find(facet => facet.filterName === 'areas')?.counts || [];

        if (!facetsAreas) {
            return [];
        }

        const mappedGateways = mapGatewaysToLocations(gateways, rawFacets, rawAirports);

        return mappedGateways.map(gateway => ({
            ...gateway,
            count: gateway.count,
            code: gateway.gatewayImeiMac,
            geolocation: {
                longitude: gateway.longitude,
                latitude: gateway.latitude,
            },
        }));
    }, [gateways, rawFacets, rawAirports]);

    const mapElements = useMemo(() => (isAirportShowingMode ? airports
        .filter(airport => filterOptions['iataCodes'].includes(airport.code))
        .concat(
            (filterOptions['iataCodes'].includes('null')) ? areas
                .filter(area => area.isAirport === false)
                .filter(area => area.iataCode === null) : [],
        ) : areas), [isAirportShowingMode, airports, areas, filterOptions]);

    const {
        data: rawAssets, status: assetsStatus,
    } = useGetCommonData<AssetsDTO>('assets/search', {
        cache: true,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            ...(areaLyingSinceTimestamp !== null ? { areaLyingSinceTimestamp } : {}),
            ...(locationLyingSinceTimestamp !== null ? { locationLyingSinceTimestamp } : {}),
        },
        enabled: facetsStatus === 'SUCCESS',
        queryWrap: false,
    });

    const handleAreaLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setAreaLyingSinceTimestamp(null);
        } else {
            setAreaLyingSinceTimestamp(days);
        }
    }, [setAreaLyingSinceTimestamp]);

    const handleLocationLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setLocationLyingSinceTimestamp(null);
        } else {
            setLocationLyingSinceTimestamp(days);
        }
    }, [setLocationLyingSinceTimestamp]);

    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('geofences', { query: {} });

    const [assets, setAssets] = useState<ExtractedAssetData[]>([]);
    const [areaLyingTime, setAreaLyingTime] = useState(filter?.areaLyingTime || 'All');
    const [locationLyingTime, setLocationLyingTime] = useState(filter?.locationLyingTime || 'All');
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);

    useEffect(() => {
        setHasOnboardingTour(view === 'map');
        return () => {
            setHasOnboardingTour(false);
        };
    }, [view]);

    useEffect(() => {
        if (assetsStatus === 'SUCCESS' && rawAssets?.resultList) {
            const allAssets = extractAssetData(rawAssets?.resultList);

            setTotalElements(rawAssets.totalElements);
            setAssets(allAssets);
        }
    }, [assetsStatus, rawAssets]);

    const [highlightedText, setHighlightedText] = useState('');
    const changeHighlightedText = (newHighlightedText) => {
        setHighlightedText(newHighlightedText);
    };

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets) {
            setFilterOptions({
                assetTypeCodes: getOptionsFromFacets(rawInitialFacets, 'assetTypeCodes'),
                iataCodes: getOptionsFromFacets(rawInitialFacets, 'iataCodes'),
                areas: getOptionsFromFacets(rawInitialFacets, 'areas'),
                uldOwnerCodes: getOptionsFromFacets(rawInitialFacets, 'uldOwnerCodes'),
                uldTypes: getOptionsFromFacets(rawInitialFacets, 'uldTypes'),
                pairingStatuses: getOptionsFromFacets(rawInitialFacets, 'pairingStatuses'),
            });
        }
    }, [rawInitialFacets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setOnboardingTooltipInfo({
            title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
            text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
            order: 5,
            position: 'auto',
            padding: 0,
            core: ONBOARDING_TYPE.ASSET,
            uid: 'supportTicketCreationQuickHelpAsset',
        });
        return () => {
            setOnboardingTooltipInfo(null);
        };
    }, []);

    useEffect(() => {
        setFilter({
            page,
            totalElements,
            query,
            filterOptions,
            sort,
            showAirportsInfo,
            areaLyingTime,
            locationLyingTime,
        });
    }, [
        page,
        totalElements,
        query,
        filterOptions,
        sort,
        showAirportsInfo,
        areaLyingTime,
        locationLyingTime,
    ]);

    useEffect(() => {
        if (view === 'map') {
            setPage(1);
        }
        setZoom(MAP_CONFIG.zoom);
    }, [view]);

    const nullFilterUpdate = useMemo(() => FILTERS_WITH_NULL.map(it => filterOptions[it])
        .some(it => it?.includes('null')), [filterOptions]);
    const pairingStatusFilterToUpdate = useMemo(() => filterOptions?.['pairingStatuses']?.includes('NOT_PAIRED'),
        [filterOptions]);

    useEffect(() => {
        const alerts = [];

        const isNotNullNotPairedAlert = filterOptions['pairingStatuses'].includes('NOT_PAIRED')
            || FILTERS_WITH_NULL.some(v => filterOptions[v].includes('null'));

        if (isNotNullNotPairedAlert && view === 'map' && !enableTooltips) {
            alerts.push({
                id: 'notNullNotPairedAlert',
                alertColor: 'info_static',
                message: t('TRACK_AND_TRACE.NOT_PAIRED_AND_NULL_ONLY_TABLE'),
                alertTime: Date.now(),
                single: true,
                icon: <AlertIcon color="info_static" />,
            });
        }

        setAlerts(alerts);
    }, [nullFilterUpdate, pairingStatusFilterToUpdate, enableTooltips, view, t]);

    useEffect(() => {
        return () => flushStatusState();
    }, []);
    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: assetsStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <AssetFilterPanel
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    setShowAirportsInfo={setShowAirportsInfo}
                    changeHighlightedText={changeHighlightedText}
                    isTableView={view === 'table'}
                    facets={rawFacets}
                    initialFacets={rawInitialFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    showAirports={showAirportsInfo}
                    query={query}
                    setQuery={setQuery}
                    areaLyingTime={areaLyingTime}
                    setAreaLyingTime={setAreaLyingTime}
                    onAreaLyingTimeChange={handleAreaLyingTimeChange}
                    locationLyingTime={locationLyingTime}
                    setLocationLyingTime={setLocationLyingTime}
                    onLocationLyingTimeChange={handleLocationLyingTimeChange}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight: availableHeight,
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <AddAssets />
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname="/asset-management/assets"
                        tooltipInfo={{
                            title: t('ONBOARDING.ASSET_MANAGEMENT.LEGEND'),
                            text: t('ONBOARDING.ASSET_MANAGEMENT.LEGEND_DESC'),
                            order: 4,
                            isMain: true,
                            position: 'center',
                            core: ONBOARDING_TYPE.ASSET,
                            uid: 'ASSET_FilterSearch',
                        }}

                    />
                    {
                        view === 'table'
                            ? (
                                <Table
                                    columns={columns}
                                    data={assets}
                                    tableMaxHeight="100%"
                                    maskForHighlight={highlightedText}
                                    rowLinkTemplate="/asset-management/assets/location/:assetNumber"
                                    title={t('MENU_ITEMS.TRACK_AND_TRACE_ASSETS')}
                                    classNames={{
                                        tableContainerClassName: classes.filteredTable,
                                    }}
                                    currentSort={sort}
                                    onSort={updateSort}
                                />
                            )
                            : (
                                <MapView
                                    mapElements={mapElements}
                                    isAirportShowingMode={isAirportShowingMode}
                                    showAirportsInfo={showAirportsInfo}
                                    geofences={geofences}
                                    zoom={zoom}
                                    setZoom={setZoom}
                                />
                            )
                    }
                    {
                        view === 'table' && <StandalonePagination detached entity="Assets" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Assets;
