const zIndexValue: any = '100!important';
const styles = theme => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    chart: {
        width: '100%',
        height: '575px',
    },
    chartPrinting: {
        width: '1020px',
        height: '550px',
    },
    fullscreen: {
        position: 'absolute',
        height: '85%',
        width: '100%',
    },
    overlay: {
        flexGrow: 1,
        display: 'flex',
        zIndex: zIndexValue,
        width: '90%',
        height: '75%',
        backgroundColor: theme.palette.common.white,
        alignItems: 'center',
        justifyContent: 'center',
    },
    noData: {
        backgroundColor: theme.palette.common.white,
    },
    msg: {
        display: 'flex',
        alignItems: 'center',
    },
    middle: {
        margin: '0px',
    },
    chartOptions: {
        '& .apexcharts-zoom-icon.apexcharts-selected': {
            '&>svg': {
                fill: `${theme.palette.primary[400]}!important`,
            },
        },
        '& .apexcharts-pan-icon.apexcharts-selected': {
            '&>svg': {
                stroke: `${theme.palette.primary[400]}!important`,
            },
        },
        '& .apexcharts-legend-series': {
            cursor: 'pointer!important',
        },
        position: 'relative',
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
    switchLabel: {
        marginRight: '13px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    switchOption: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    disabledSwitchOption: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[400],
        opacity: 1,
    },
    refreshIcon: {
        marginTop: '5px',
        transform: 'scale(1.1)',
    },
    downloadIcon: {
        '& svg': {
            fill: '#6e8192',
        },
        '&:hover svg': {
            fill: theme.palette.common.black,
        },
        minWidth: '20px',
        width: 'unset',

    },
    howToZoomPic: {
        '@media (max-width: 700px)': {
            width: 'calc(100vw - 16px)',
        },
    },
    tooltip: {
        transition: 'none!important',
        borderRadius: '4px!important',
        overflow: 'hidden!important',
        border: '1px solid #e3e3e3!important',
        background: `${theme.palette.common.white}!important`,
    },
    sizeOverrideContainer: {
        visibility: 'hidden',
        position: 'relative',
        maxHeight: '300px',
        maxWidth: '400px',
        '&::after': {
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: theme.palette.common.white,
        },
    },
});

export default styles;
