const styles = {
    root: {
        flexGrow: 1,
    },
    gridContainer: {
        marginBottom: '10px',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    mobileCard: {
        marginBottom: '18px',
    },
    mobileCardContents: {
        overflowX: 'auto',
    },
};

export default styles;
