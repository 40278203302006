import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { PageWithFilter } from 'Layout';
import Table, { useTableSideFilter } from 'shared-components/Table';
import tableColumns from './tableColumns';

const PharmaSites = () => {
    const { t } = useCustomTranslation();
    const [pharmaSites, setPharmaSites] = useState([]);
    const [filteredPharmaSites, setFilteredPharmaSites] = useState([]);
    const { GetAll: getAllPharmaSites } = useSecureBackendEndpoints('pharma-sites').requests;

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllPharmaSites();

                setPharmaSites(response.data.resultList || []);
            } catch (error) {
                setPharmaSites([]);
            }
        })();
    }, []);

    const updatedPharmaSitesInfo = useMemo(() => {
        if (pharmaSites.length === 0) {
            return [];
        }

        return pharmaSites.map(company => {
            const { address, ...restInfo } = company;

            return {
                ...restInfo,
                city: address?.city,
                countryName: address?.country?.name || '',
            };
        });
    }, [pharmaSites]);

    const columns = useMemo(() => tableColumns(t), []);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        tableId: 'PharmaSitesTableFilter',
        data: updatedPharmaSitesInfo,
        setFilteredData: setFilteredPharmaSites,
    });

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <Table
                    data={filteredPharmaSites}
                    columns={columns}
                    maskForHighlight={`${(selectedFilterOptions?.name?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.name?.[0])
                    || (selectedFilterOptions?.city?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.city?.[0])}`}
                    tableMaxHeight="100%"
                    equalColumnWidths
                    title={t('COMMON.PHARMA_SITES')}
                    rowLinkTemplate="/administration/pharma-sites/:id"
                />
            </div>
        </PageWithFilter>
    );
};

export default PharmaSites;
