import React from 'react';
import Line from 'shared-components/Line';
import { getIconByStatus } from 'utils/shipmentsDataProcessing';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ApprovalReviseModal.style';

type Props = {
    qmApprovalStatus: string,
    qmApprovalStatusLabel: string,
    qmApprovalComment: string,
    qmApprovedBy: string,
    qmApprovedOn: string,
}

const QmApprovalHistoryItem = ({
    qmApprovalStatus = '',
    qmApprovalStatusLabel = '',
    qmApprovalComment = '',
    qmApprovedBy = '',
    qmApprovedOn = '',
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.qmApprovalDetailContainer}>
            <div className={classes.flexContainer}>
                <div className={classes.decisionContainer}>
                    <div className={classes.fieldTitle}>{t('PRODUCT_RELEASE.DECISION')}</div>
                    <div className={classes.qmStatusContainer}>
                        <img
                            className={classes.icon}
                            src={getIconByStatus(qmApprovalStatus)}
                            alt="QM Approval icon"
                        />
                        <div className={classes.fieldInfo}>
                            {qmApprovalStatusLabel || qmApprovalStatus}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.fieldTitle}>{t('COMMON.DATE_TIME')}</div>
                    <div className={classes.fieldInfo}>
                        {qmApprovedOn}
                    </div>
                </div>
                <div className={classes.byContainer}>
                    <div className={classes.fieldTitle}>{t('COMMON.BY')}</div>
                    <div className={classes.fieldInfo}>
                        {qmApprovedBy}
                    </div>
                </div>
            </div>
            <div className={classes.commentContainer}>
                <div className={classes.fieldTitle}>{t('COMMON.COMMENT')}</div>
                <div className={classes.fieldInfo}>{qmApprovalComment}</div>
            </div>
            <Line className={classes.line} />
        </div>
    );
};

export default QmApprovalHistoryItem;
