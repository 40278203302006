import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Address } from 'dataTypes/SecureBackend/apiResponse';
import Card from 'shared-components/Card';
import Map from 'shared-components/Map';
import Certificates from 'shared-components/CompanyInfoComponents/Certificates';
import PercentageScale from 'shared-components/PercentageScale';
import Offerings from 'shared-components/CompanyInfoComponents/AdministrationCompany/OfferingsCard/ReadMode';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ServiceProviderSite.style';

const getMapConfig = (location) => ({
    center: {
        lat: location.latitude,
        lng: location.longitude,
    },
    containerStyle: {
        height: '18vh',
        width: '100%',
    },
    zoom: 5,
    backgroundColor: 'unset',
});

type Props = {
    address: Address,
    certificates: string[],
    name: string,
    link: string,
}

const ServiceProviderSite = ({
    address,
    certificates = [],
    name,
    link,
} : Props) => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { t } = useCustomTranslation();

    const handleClick = () => navigate(link);

    return (
        <div className={classes.contentData}>
            <Card
                className={classes.card}
                onClick={handleClick}
                title={name}
            >
                <div className={classes.internalCardContentData}>
                    <div>
                        <div className={classes.fieldTitle}>{t('COMMON.ADDRESS')}</div>
                        <div className={classes.filedInfo}>{address.addressLine1}</div>
                        <div className={classes.filedInfo}>{address.addressLine2}</div>
                        <div className={classes.filedInfo}>{address.addressLine3}</div>
                        <div className={classes.filedInfo}>{`${address.zipCode} ${address.city}`}</div>
                        <div className={classes.filedInfo}>{address.country.name}</div>
                    </div>
                    <div className={classes.marginColumn} style={{ display: 'none' }}>
                        <PercentageScale
                            value={57}
                            label={`${57}%`}
                            title={t('COMMON.CORE_CAPABILITIES_ANSWERED')}
                        />
                        <PercentageScale
                            value={62}
                            label={`${62}%`}
                            title={t('COMMON.ALL_CAPABILITIES_ANSWERED')}
                        />
                    </div>
                    <div style={{ display: 'none' }}>
                        <div className={classes.fieldTitle}>{t('MY_COMPANY.CERTIFICATIONS')}</div>
                        <Certificates certificatesFlat={certificates} />
                    </div>
                    <div style={{ display: 'none' }}>
                        <div className={classes.fieldTitle}>{t('COMMON.FACILITIES')}</div>
                    </div>
                    <div>
                        <Offerings currentServices={[]} currentCommodities={[]} />
                    </div>
                </div>
            </Card>
            <div className={classes.mapContainer}>
                <Map
                    mapConfig={getMapConfig(address.geolocation)}
                    separateMarker={{
                        lat: address.geolocation.latitude,
                        lng: address.geolocation.longitude,
                    }}
                />
            </div>
        </div>
    );
};

export default ServiceProviderSite;
