import React, {
    useEffect,
    useState,
} from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Card from 'shared-components/Card';
import { getQuestionnaireStat } from 'shared-components/CompanyInfoComponents/lib';
import icons from 'shared-components/icons';
import {
    QuestionnaireItem,
    QuestionnaireItemAnswer,
} from 'shared-components/dataTypes';
import AnswerUnit from 'shared-components/CompanyInfoComponents/AnswerUnit';
import useClasses from 'hooks/useClasses';
import QuestionDetails from '../QuestionDetails';
import styles from './SubCategoryGroup.style';

type Props = {
    answers: QuestionnaireItemAnswer[],
    setAnswers: (answers: QuestionnaireItemAnswer[]) => void,
    statisticsBySubCategory: number,
    subCategories: QuestionnaireItem[],
    currentSubCategoriesLimited: {
        [key: string]: QuestionnaireItemAnswer[],
    },
    setSubCategoriesLimited: (currentSubCategoriesLimited : { [key: string] : QuestionnaireItemAnswer[] }) => void,
    subCategory: string,
}

const SubCategoryGroup = ({
    answers,
    setAnswers,
    subCategory,
    statisticsBySubCategory,
    subCategories,
    currentSubCategoriesLimited,
    setSubCategoriesLimited,
} : Props) => {
    const classes = useClasses(styles);
    const [progress, setProgress] = useState(statisticsBySubCategory);

    useEffect(() => {
        if (progress < 100) {
            let updateRequired = false;

            const updatedQuestionArr = currentSubCategoriesLimited[subCategory].map((item) => {
                const newUpdate = answers.find((answer) => item.question === answer.question);

                if (newUpdate) {
                    updateRequired = true;
                }
                return newUpdate || item;
            });

            if (updateRequired) {
                setProgress(getQuestionnaireStat(updatedQuestionArr));
                const updatedAllSubCategoriesLimited = Object.keys(currentSubCategoriesLimited)
                    .reduce((data, subCat) => {
                        return {
                            ...data,
                            [subCat]: subCat === subCategory
                                ? updatedQuestionArr : currentSubCategoriesLimited[subCat],
                        };
                    }, {});

                setSubCategoriesLimited(updatedAllSubCategoriesLimited);
            }
        }
    }, [answers.length]);

    return (
        <Card contentClass={classes.cardContent}>
            <div className={classes.progressBarGridContainer}>
                <div className={classes.title}>{subCategory}</div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '15%' }}>
                        <Typography variant="body2" color="text.secondary" className={classes.percent}>
                            {`${progress}%`}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '85%', mr: progress === 100 ? 0.5 : 1 }}>
                        <LinearProgress
                            classes={{ barColorPrimary: classes.barroot }}
                            className={classes.progressBar}
                            variant="determinate"
                            value={progress}
                        />
                    </Box>
                    {
                        progress === 100 && (
                            <img
                                alt="progress completed"
                                className={classes.completedProgressIcon}
                                src={icons.checked_blue}
                            />
                        )
                    }
                </Box>
            </div>
            {
                subCategories.map((questionUnit) => {
                    return (
                        <div
                            className={classes.questionUnitContainer}
                            key={questionUnit.question}
                        >
                            <div className={classes.questionText}>
                                {questionUnit.question}
                            </div>
                            <div className={classes.answerItemAndDetails}>
                                <AnswerUnit
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    amount={questionUnit.amount}
                                    availableAnswerOptions={questionUnit.availableAnswerOptions}
                                    selectedAnswerOptions={questionUnit.selectedAnswerOptions}
                                    question={questionUnit.question}
                                    // @ts-ignore
                                    questionType={questionUnit.questionType}
                                />
                                <div className={classes.questionDetailsContainer}>
                                    <QuestionDetails questionnaire={questionUnit} />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </Card>
    );
};

export default SubCategoryGroup;
