import React, {
    useState,
} from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import styles from './ServiceProviderSiteCard.style';

type Props = {
    currentCompanyLogo?: string,
    currentSiteName?: string,
    currentEditedCard?: string,
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setFilter?: (filter) => void,
    type?: 'new' | 'editable' | 'readOnly' | 'newMyCompany',
}

const cardName = 'ServiceProviderSiteCard';

const ServiceProviderSiteCard = ({
    currentCompanyLogo = '',
    currentSiteName = '',
    currentEditedCard = null,
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setFilter = () => {},
    type = 'readOnly',
}: Props) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkInProgress, setCheckInProgress] = useState(false);
    const { t } = useCustomTranslation();
    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setFilter({
            name: currentSiteName,
        });
        setEditMode(false);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            loadingIcon={checkInProgress}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode && !checkInProgress}
            showUndoButton={type === 'editable' && editMode}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('COMMON.SITE_INFORMATION')}
        >
            {
                type === 'new' || type === 'newMyCompany' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            siteName={currentSiteName}
                            setFilter={setFilter}
                            companyLogo={currentCompanyLogo}
                            type={type}
                            setCheckInProgress={setCheckInProgress}
                        />
                    )
                    : (
                        <ReadMode
                            companyLogo={currentCompanyLogo}
                            siteName={currentSiteName}
                        />
                    )
            }
        </CardWithEditableContent>
    );
};

export default ServiceProviderSiteCard;
