import React from 'react';
import useClasses from 'hooks/useClasses';
import { ProcessedNotification } from '../lib';
import styles from '../Notifications.style';

type Props = {
    notification: ProcessedNotification,
}

const NotificationSubject = ({ notification }: Props) => {
    const classes = useClasses(styles);
    const { hasBeenRead, subject } = notification;

    return (
        <div className={[classes.formControlLabelStyle,
            hasBeenRead ? '' : classes.formControlLabelStyleSelected].join(' ')}
        >
            {subject}

        </div>
    );
};

export default NotificationSubject;
