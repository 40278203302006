/* eslint-disable camelcase */
import useClasses from 'hooks/useClasses';
import React from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import { Location } from 'shared-components/dataTypes';
import convertLatLngDMS from 'utils/convertLatLngDMS';
import { CircularProgress } from '@mui/material';
import { useGetGeocode } from 'shared-components/Map/components/LocationInfoTooltip/hooks/useGetGeocode';

const styles = {
    locationInfoTooltipContainer: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #999',
        border: '0 1px 1px 1px solid #E3E3E3',
        borderRadius: '5px',
        opacity: 1,
        textAlign: 'left',
        fontSize: '12px',
        fontFamily: 'Roboto, serif',
        letterSpacing: '0.33px',
        width: 'max-content',
        color: '#000000de',
    },
    locationInfoTooltipHeader: {
        padding: '6px 10px',
        background: '#ECEFF1 0% 0% no-repeat padding-box',
        borderBottom: '1px solid #DDD',
        borderRadius: '5px 5px 0px 0px',
        width: '100%',
        opacity: 1,
    },
    locationInfoTooltipBody: {
        width: 'max-content',
        padding: '4px 10px',
    },
    title: {
        textAlign: 'left',
        fontWeight: 500,
        letterSpacing: '0.33px',
        color: '#747474',
        width: '100%',
    },
};

const getPixelPositionOffset = () => ({
    x: 20,
    y: 20,
});

type Props = {
    location: Location,
    time?: string,
}
const LocationInfoTooltip = ({
    location,
    time,
} : Props) => {
    const classes = useClasses(styles);
    const locationDMS = convertLatLngDMS(location.lat, location.lng);
    const locationTitle = useGetGeocode(location);

    return (
        <OverlayViewF
            mapPaneName={OverlayView.FLOAT_PANE}
            position={location}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <div className={classes.locationInfoTooltipContainer}>
                <div className={classes.locationInfoTooltipHeader}>
                    <div>{time.split('T').join(' ')}</div>
                </div>
                <div className={classes.locationInfoTooltipBody}>
                    <div className={classes.title}>{locationTitle || <CircularProgress size={14} />}</div>
                    <div>{locationDMS}</div>
                </div>
            </div>
        </OverlayViewF>
    );
};

export default LocationInfoTooltip;
