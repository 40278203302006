const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            marginTop: '10px',

        },
    },
    dateWrapper: {
        display: 'grid',
        width: '100%',
        gridGap: '5px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },

    },
    space: {
        flex: 1,
    },
    periods: {
        display: 'flex',
        alignItems: 'center',
    },
    periodsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        color: theme.palette.secondary[600],
        '& > span': {
            marginRight: '19px',
        },
    },
    selectButton: {},
    customPeriod: {
        minWidth: '40px',
        width: 'auto',
        cursor: 'pointer',
        padding: '1px 3px',
        textAlign: 'center',
        '&:hover': {
            borderRadius: '40px',
            color: theme.palette.primary[50],

            background: theme.palette.primary[500],
        },
    },
    active: {
        background: theme.palette.primary[700],
        color: theme.palette.primary[30],
        borderRadius: 40,
    },
    selectors: {},
    dateInput: {},
    dateText: {
        '& input': {
            cursor: 'pointer',
        },
        '& label': {
            color: 'rgb(183, 183, 183)',
        },
    },
    invalid: {
        '& .MuiInput-underline:before': {
            borderColor: 'red',
        },
    },
    datePicker: {
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            color: '#61C6E9!important',
        },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
    },
});

export default styles;
