import { useTheme } from '@mui/material/styles';
import useClasses from 'hooks/useClasses';
import React, { useCallback } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import useHasAccess from 'hooks/useHasAccess';
import {
    getRejectedStatuses,
} from
    '../../../../GenericShipments/components/ShipmentsMap/components/TooltipCluster/ClusterBodyItem/PackagingInfo/lib';
import { styles } from '../PackagingHealth.style';

type Props = {
    packaging: GenericPackaging,
    cargo?: GenericCargo,
    energyLevel?: number,
    hasBreach?: boolean,
}
const PackagingBox = ({
    packaging = null,
    cargo = null,
    energyLevel = null,
    hasBreach = false,
}: Props) => {
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const { serialNumber } = packaging;
    const { t } = useCustomTranslation();
    const theme = useTheme<SkycellThemeInterface>();
    const {
        temperatureCheckResult,
        skyCoreProductRelease,
    } = cargo || {};
    const {
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    } = skyCoreProductRelease || {};

    const rejectedStatuses = getRejectedStatuses({
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    });
    const {
        temperatureStatus,
    } = temperatureCheckResult || {};

    const getTempStatusColor = useCallback(() => {
        switch (temperatureStatus) {
        case TEMPERATURE_STATUS.POTENTIAL_EXCURSION:
        case TEMPERATURE_STATUS.PREDICTED_EXCURSION:
            return theme.palette.common.yellow;
        case TEMPERATURE_STATUS.IN_RANGE_PARTIAL_DATA:
            return `repeating-linear-gradient(90deg,${
                theme.palette.primary['deepBlue']
            },${theme.palette.primary['deepBlue']} 3px,${
                theme.palette.common.white
            } 3px,${
                theme.palette.common.white
            } 5px)`;
        case 'EXCURSION':
            return theme.palette.common.red;
        case 'NO_DATA':
            return theme.palette.secondary[400];
        default:
            return theme.palette.primary.deepBlue;
        }
    }, [cargo]);

    return (
        packaging.serialNumber === 'JNN-52236'
            ? (
                <div className={classes.packagingBox}>
                    <div
                        className={classes.box} style={{ background: theme.palette.common['yellow'] }}
                    />

                    <div className={classes.textInfo}>
                        <div
                            className={
                                classes.serialNumber
                            }
                        >
                            {serialNumber}
                        </div>
                        <div className={classes.status}>
                            <div>{t('TRACK_AND_TRACE.PREDICTED_EXCURSION')}</div>
                        </div>
                    </div>
                </div>
            )
            : (
                <div className={classes.packagingBox}>
                    <div
                        className={classes.box} style={{
                            background: getTempStatusColor(),
                        }}
                    />
                    <div className={classes.textInfo}>
                        <div
                            className={
                                TEMPERATURE_STATUS.EXCURSION
                                    ? classes.serialNumber : classes.serialNumberWithPadding
                            }
                        >
                            {serialNumber}
                        </div>
                        { temperatureStatus === TEMPERATURE_STATUS.EXCURSION && (
                            <div className={classes.status}>
                                <div>{t('TRACK_AND_TRACE.TEMPERATURE_EXCURSION')}</div>
                            </div>
                        ) }
                        { (temperatureStatus === TEMPERATURE_STATUS.POTENTIAL_EXCURSION
                            || temperatureStatus === TEMPERATURE_STATUS.PREDICTED_EXCURSION) && (
                            <div className={classes.status}>
                                <div>{t('TEMPERATURE_STATUS.POTENTIAL_EXCURSION')}</div>
                            </div>
                        ) }
                        {
                            rejectedStatuses.length > 1 && (
                                <div className={classes.status}>
                                    <div>
                                        {t('DAMAGE_STATUS.SEVERAL_DAMAGES')}
                                        !
                                    </div>
                                </div>
                            )
                        }
                        {
                            rejectedStatuses.length === 1 && (
                                <div className={classes.status}>
                                    <div>
                                        {t(`DAMAGE_STATUS.${rejectedStatuses[0]}`)}
                                        !
                                    </div>
                                </div>
                            )
                        }
                        {
                            hasAccess('INTELLIGENT_MONITORING')
                            && hasBreach && (
                                <div className={classes.status}>
                                    <div>
                                        {`${t('COMMON.LOW_ENERGY_LEVEL')}: 
                                        ${((energyLevel / 1) * 100).toFixed(0)}%`}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
    );
};

export default PackagingBox;
