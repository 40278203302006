import React, { useState, FocusEventHandler } from 'react';
import { CancelSharp } from '@mui/icons-material';
import { Classes } from 'jss';
import { Moment } from 'moment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

type Props = {
    classes: Classes,
    value: Moment,
    onChange: (arg) => void,
    placeholder?: string,
    invalid?: boolean,
    flushable?: boolean,
    mandatory?: boolean,
    minWidth?: string | number,
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
}
const DateTimePickerWithFlush = ({
    classes = {},
    value = null,
    onChange,
    placeholder,
    invalid = false,
    flushable = false,
    mandatory = false,
    minWidth = 'unset',
    onFocus = () => {},

}: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <DesktopDateTimePicker
            value={value}
            format="DD.MM.YYYY HH:mm"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onAccept={(date) => {
                onChange(date);
                setOpen(false);
            }}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            slotProps={{
                textField: {
                    placeholder: placeholder || '',
                    onClick: (event) => {
                        event.stopPropagation();
                        setOpen((prev) => !prev);
                    },
                    className: `${classes.input} ${invalid ? classes.invalidField : ''} ${mandatory
                        ? classes.mandatory : ''}`,
                    style: {
                        minWidth,
                    },
                    title: value ? value.format('DD.MM.YYYY HH:mm') : '',
                    variant: 'outlined',
                    onFocus,
                    onKeyDown: (ev) => ev.preventDefault(),
                    InputProps: {
                        endAdornment: flushable ? (
                            <CancelSharp
                                className={`${classes.flushIcon} ${classes.flushIconShift}`}
                                style={{
                                    opacity: open && value ? 1 : 0,
                                    pointerEvents: open && value ? 'all' : 'none',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onChange(null);
                                }}
                            />
                        ) : null,
                        classes: {
                            adornedEnd: classes.adornedEnd,
                        },
                    },
                },
            }}
        />
    );
};

export default DateTimePickerWithFlush;
