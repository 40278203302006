import React, { MouseEventHandler } from 'react';
import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import { LatLng } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import { OverlayView } from '@react-google-maps/api';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { MapElement } from 'TrackAndTrace/Assets/lib';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';

type Props = {
    mapElements: MapElement[],
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    position: LatLng,
}

const styles = (theme: SkycellThemeInterface) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '40px',
        minWidth: '100%',
        width: 'max-content',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '4px 8px',
        gap: '10px',
        color: 'black',
        fontSize: '14px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '66px',
        width: '100%',
        zIndex: 1000,
    },
    infoIcon: {
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    loggerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        position: 'relative',
        borderRadius: '4px',
        zIndex: 100,
        backgroundColor: theme.palette.common.white,
        minWidth: '100%',
        width: 'max-content',
    },
    airportCount: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        padding: '4px 8px',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        color: 'black',
        fontSize: '26px',
        userSelect: 'none',
        height: '50px',
        width: '100%',
    },
    headerText: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        textDecoration: 'underline',
        minWidth: '100%',
        width: 'max-content',
        fontSize: '32px',
        padding: '8px 30px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        color: theme.palette.common.black,
    },
    body: {},
    division: {
        padding: '14px 14px 30px 14px',
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
    },
});

const AreaTooltip = ({
    mapElements = [],
    onMouseLeave,
    position,
}: Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();

    return (
        <OverlayView
            mapPaneName={OverlayView.FLOAT_PANE}
            zIndex={100}
            position={position}
        >
            <div
                className={classes.root}
                style={{
                    transform: 'translateY(-74px)',
                }}
                onMouseLeave={onMouseLeave}
            >
                <div
                    className={classes.info}
                >
                    <div className={classes.infoIcon}>
                        <InfoIcon style={{
                            color: theme.palette.primary.deepBlue,
                            fontSize: '30px',
                        }}
                        />
                    </div>
                    <div style={{
                        whiteSpace: 'wrap',
                    }}
                    >
                        Showing the number of
                        <br />
                        assets per location.
                        <br />
                        View full list in table view.
                    </div>
                </div>

                {
                    mapElements.map(({ code, count, iataCode, locationName }) => (
                        <div
                            className={classes.airportCount}
                            key={`assets_map_airport_${code}`}
                        >
                            <div>
                                <UncontrolledTooltip
                                    description={code.length > 3 ? iataCode || locationName : code}
                                    shrinked
                                    placement="left"
                                    enabled={code.length > 10}
                                >
                                    <span
                                        style={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            maxWidth: '200px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {code.length > 3 ? iataCode || locationName : code}
                                    </span>
                                </UncontrolledTooltip>
                            </div>
                            <div>
                                {count}
                            </div>
                        </div>

                    ))
                }
            </div>
        </OverlayView>

    );
};

export default AreaTooltip;
