import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useClasses from 'hooks/useClasses';
import React, { ReactElement } from 'react';
import { styles } from './SelectField.style';

type Props = {
    items: {
        value: number | string,
        render: number | string | ReactElement
    }[],
    label?: string,
    id?: string,
    defaultValue?: number | string,
    value?: number | string,
    onChange?: (value: string, id: string) => any,
    emptyValue?: boolean,
    className?: string

}

const SelectField = ({
    items,
    label,
    id,
    value,
    onChange,
    defaultValue,
    emptyValue = true,
    className,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <FormControl fullWidth variant="standard" className={className || ''}>
            {label && (
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {label}
                </InputLabel>
            )}
            <Select
                className={classes.itemText}
                defaultValue={emptyValue ? '' : defaultValue}
                value={value || ''}
                onChange={(ev: SelectChangeEvent) => onChange(ev.target.value, id)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                inputProps={{
                    name: id,
                    id: `${id}_controlled_native_select`,
                }}
            >
                {
                    items.map(item => {
                        return <MenuItem key={`${id}_${item.value}`} value={item.value}>{item.render}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );
};

export default SelectField;
