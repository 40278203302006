import React from 'react';
import Card from 'shared-components/Card';
import LastConnect from 'shared-components/LastConnect';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip/QuickHelpTooltip';
import useClasses from 'hooks/useClasses';
import styles from './CurrentTemperature.style';
import EnergyLevel from '../EnergyLevel';

type Props = {
    lastMeasuredTempInternal?: number,
    lastMeasuredOnUtcInternal?: number,
    energyLevel?: number,
}

const CurrentTemperature = ({
    lastMeasuredTempInternal = null,
    lastMeasuredOnUtcInternal = null,
    energyLevel = null,
    ...rest
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <Card title={t('SENSOR_DATA.CURRENT_TEMPERATURE')} {...rest}>
            <div className={classes.tempValue}>
                {
                    lastMeasuredTempInternal
                        ? `${lastMeasuredTempInternal.toFixed(1)}°C`
                        : '_'
                }
            </div>

            <QuickHelpTooltip
                tooltipInfo={{
                    order: 4,
                    text: t('QUICK_HELP.SENSOR_DATA.MEASURED_DATE'),
                    uid: 'lastMeasuredDateTooltip',
                }}
            >
                <div className={classes.lastConnectContainer}>
                    <LastConnect
                        fullText
                        timestamp={lastMeasuredOnUtcInternal}
                    />
                </div>
            </QuickHelpTooltip>
            <div className={classes.energyLevel}>
                {t('COMMON.CURRENT_BATTERY_LEVEL')}
                <EnergyLevel energyLevel={energyLevel} threshold />
            </div>
        </Card>
    );
};

export default CurrentTemperature;
