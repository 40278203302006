import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme:SkycellThemeInterface) => ({
    pageWithFilter: {
        display: 'flex',
        height: '100%',
        maxHeight: (props) => props['availableHeight'],
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            overflow: 'unset',
        },
    },
});

export default styles;
