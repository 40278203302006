import { css } from '@emotion/css';

const generateStyles = (theme, props: { searchBoxLeft: number }) => {
    const open = css({
        opacity: '1!important',
        pointerEvents: 'unset',
    });

    const preview = css({
        '&&': {
            position: 'absolute',
        },
        opacity: 0,
        pointerEvents: 'none',
        zIndex: 200,
        transition: '100ms ease',
        width: 'auto',
        maxWidth: `calc(100vw - ${props.searchBoxLeft + 40}px)`,
        marginTop: '-40px',
        marginLeft: '270px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
        [`&.${open}`]: {
            pointerEvents: 'unset',
        },
    });

    const paddedTable = css({
        padding: '0 10px 10px 10px',
    });

    const buttonContainer = css({
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 10px 0 0',
    });

    const clearSearchButton = css({
        textTransform: 'none',
    });
    const btn = css({
        '&:disabled': {
            opacity: 0.4,
            pointerEvents: 'none',
        },
    });

    return {
        btn,
        preview,
        open,
        paddedTable,
        buttonContainer,
        clearSearchButton,
    };
};

export default generateStyles;
