import React, { useCallback, ReactChild } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import useClasses from 'hooks/useClasses';
import styles from './CardDashboard.style';

type Props = {
    title: string,
    info: string,
    iconSrc: string,
    link?: string,
    component?: ReactChild,
    externalLink?: string,
}

const CardDashboard = ({
    title,
    info,
    iconSrc,
    link = null,
    externalLink = null,
    component: Component = null,
} : Props) => {
    const classes = useClasses(styles);

    const navigate = useNavigate();

    const navigateToLink = useCallback(() => {
        if (externalLink) {
            window.location.href = externalLink;
            return;
        }

        navigate(link);
    }, [
        externalLink,
        navigate,
        link,
    ]);

    return (
        <div
            className={classes.container}
            onMouseDown={navigateToLink}
        >
            <div className={classes.topWrapper}>
                <div className={classes.iconWrapper}>
                    <img
                        className={classes.topWrapperIcon}
                        src={iconSrc}
                        alt={title}
                    />
                </div>
                {
                    Component
                }
            </div>
            <div className={classes.bottomWrapper}>
                <Typography
                    variant="h3"
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography variant="body1" className={classes.info}>
                    {info}
                </Typography>
            </div>
        </div>
    );
};

export default CardDashboard;
