import { ReactNode } from 'react';

export type RequestStatus = 'SUCCESS' | 'PENDING' | 'FAILURE' | 'INIT'

export interface TimeRange {
    from: string,
    to: string,
}

export interface LatLng {
    lat: number,
    lng: number,
}

export interface PolylineAndStepStatus {
    path: LatLng[],
    stepStatus?: string,
}

export interface LatLngAndTime { location: LatLng, time: string }

export interface LatLngTimeTimestamp {
    time: string,
    timeStamp: number,
    location: LatLng,
}

export interface CompositePolyline {
    path: LatLng[],
    options: {
        strokeColor: string,
        geodesic: boolean,
        strokeOpacity: number,
        strokeWeight?: number,
        icons?: {
            icon: {
                path: string,
                strokeOpacity: number,
                scale: number,
            },
            offset: string,
            repeat: string,
        }[],
    },
}

export type Period = '24h' | '7d' | '14d' | 'Manual'

export const PERIODS = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
    Manual: 0,
};

export const DAY_TO_PERIOD = {
    1: '24h',
    7: '7d',
    14: '14d',
};

export type RouteItem = {
    necessaryRoles?: string[],
    sufficientRoles?: string[],
    path?: string | string[],
    element?: ReactNode,
    docPath?: string,
}

export const SIDEBAR_IS_OPEN_KEY_LS = 'sidebarIsOpen';
