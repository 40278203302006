const styles = (theme) => ({
    twoColumns: {
        display: 'grid',
        marginTop: '8px',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '6px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    answerContainer: {
        display: 'flex',
        marginTop: '12px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '12px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitButton: {
        height: '35px',
        margin: '9px',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'left',
        font: 'normal normal 500 12px/14px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
    historyItemContainer: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
    },
    historyItemIcon: {
        width: '21px',
        height: '21px',
        marginRight: '8px',
        opacity: 1,
    },
    historyItemMessage: {
        margin: '6.5px 0',
    },
    divider: {
        height: '13px',
        width: '1px',
        marginLeft: '9.5px',
        backgroundColor: '#707070',
    },
    previousChangesButton: {
        marginLeft: '9px',
        height: '16px',
        background: '#8BD1E8 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        font: 'normal normal 500 12px/14px Roboto',
        letterSpacing: '1.07px',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        opacity: 1,
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
});

export default styles;
