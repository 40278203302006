import React from 'react';
import Grid from '@mui/material/Grid';
import Card from 'shared-components/Card';
import { ProcessEvidenceDeliveryData } from 'shared-components/dataTypes';
import useClasses from 'hooks/useClasses';
import PartsDeliveryInfoContainer from './components/PartsDeliveryInfoContainer';
import StatusContainer from './components/StatusContainer';
import styles from '../../ProductReleaseDetails.style';

export const piCardWidth = 400;

type Props = {
    titles: string[],
    packagingPartsInfo: ProcessEvidenceDeliveryData,
    mobileView?: boolean,
}

const PartsDeliveryInfo = ({
    titles = [],
    packagingPartsInfo = {
        comparisonMatchingInfo: [],
        receivingParts: [],
        sendingParts: [],
    },
    mobileView = false,
} : Props) => {
    const classes = useClasses(styles);

    const [
        titleLoading = '',
        titleUnloading = '',
        titleApprovals = '',
    ] = titles;

    const {
        comparisonMatchingInfo = [],
        receivingParts = [],
        sendingParts = [],
    } = packagingPartsInfo || {};

    if (mobileView) {
        return (
            <Card
                fullHeight
                className={classes.mobileCard}
                contentClass={classes.mobileCardContents}
            >
                <PartsDeliveryInfoContainer
                    title={titleLoading}
                    partsInfo={sendingParts}
                    horizontal
                />
                <PartsDeliveryInfoContainer
                    title={titleUnloading}
                    partsInfo={receivingParts}
                    horizontal
                />
                <StatusContainer
                    title={titleApprovals}
                    comparisonMatchingInfo={comparisonMatchingInfo}
                    horizontal
                />
            </Card>
        );
    }

    return (
        <Grid
            container
            spacing={2}
            className={classes.gridContainer}
        >
            <Grid item xs={4}>
                <PartsDeliveryInfoContainer
                    title={titleLoading}
                    partsInfo={sendingParts}
                />
            </Grid>
            <Grid item xs={4}>
                <PartsDeliveryInfoContainer
                    title={titleUnloading}
                    partsInfo={receivingParts}
                />
            </Grid>
            <Grid item xs={4}>
                <StatusContainer
                    title={titleApprovals}
                    comparisonMatchingInfo={comparisonMatchingInfo}
                />
            </Grid>
        </Grid>
    );
};

export default PartsDeliveryInfo;
