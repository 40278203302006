import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, {
    Dispatch,
    SetStateAction,
    useMemo, useState, useEffect,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import {
    FacetDTO,
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption,
} from 'shared-components/SideFilter';
import TextSearchFilter from 'shared-components/SideFilter/components/TextSearchFilter';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';

type Props = {
    companiesDictionary: {
        id: number,
        label: string,
    }[],
    countsLoading: boolean,
    facets: FacetDTO[],
    filterOptions: { [optionsGroupKey: string]: string[] },
    initialFacets: FacetDTO[],
    query?: string,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    setQuery: Dispatch<SetStateAction<string>>
    requestType?: 'outgoing' | 'incoming',
}

const AccessRequestFilterPanel = ({
    companiesDictionary,
    countsLoading,
    facets,
    filterOptions,
    initialFacets,
    query,
    setFilterOptions,
    setQuery,
}: Props) => {
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const classes = useGeneratedStyles(generateStyles);

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                options: getFieldCheckboxData({
                    dictionary: companiesDictionary,
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'dataOwnerCompanyIds',
                }),
                optionsGroupKey: 'dataOwnerCompanyIds',
                title: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'statuses',
                    t,
                    tgroup: 'ACCESS_REQUEST_STATUS',
                }),
                optionsGroupKey: 'statuses',
                title: t('COMMON.STATUS'),
            },
        ];
    }, [loadedFacets, initialFacets, companiesDictionary, t]);

    return (
        <SideFilter
            id="AccessRequestsSideFilter"
        >
            <FilterBlock>
                <div className={classes.title}>{t('ONBOARDING.TRACK_AND_TRACE.FILTER')}</div>
                <TextSearchFilter
                    query={query}
                    setQuery={setQuery}
                />
            </FilterBlock>

            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: initialFacets.find(it => it.filterName === optionsGroupKey)
                                        .counts.filter(it => it.filterValue !== null)
                                        .map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        ?.sort((a, b) => b.count - a.count)
                                        .map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    count={option.count}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    key={option.value}
                                                    label={option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                    countsLoading={countsLoading}
                                                    shrinkNames={checkboxGroup.showFully}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
        </SideFilter>
    );
};

export default AccessRequestFilterPanel;
