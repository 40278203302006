import useClasses from 'hooks/useClasses';
import React, { useCallback, ChangeEvent } from 'react';
import MuiPagination from '@mui/material/Pagination';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from './CommonPagination.style';

type Props = {
    currentPage?: number,
    shipmentsCount: number,
    setCurrentPage: (newPage: number) => void,
    objectNamePlural?: string,
    pageSize?: number,
}

const CommonPagination = ({
    currentPage = 1,
    shipmentsCount = 0,
    setCurrentPage,
    objectNamePlural,
    pageSize = 10,
}: Props) => {
    const classes = useClasses(styles);
    const pagesCount = Math.ceil(shipmentsCount / pageSize);
    const { t } = useCustomTranslation();
    const infoLabelFromTo = ` ${(currentPage - 1) * pageSize + 1}
        to ${currentPage * pageSize < shipmentsCount
        ? currentPage * pageSize : shipmentsCount} `;
    const infoLabelTotalNumber = `of ${shipmentsCount}`;
    const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    }, []);

    return (
        <div className={classes.pagination} aria-disabled>
            <div className={classes.label}>
                Showing
                {' '}
                {objectNamePlural || t('COMMON.SHIPMENTS')}
                <div className={classes.labelBlue}>{infoLabelFromTo}</div>
                {infoLabelTotalNumber}
            </div>
            <div className={classes.muiPagination}>
                <MuiPagination
                    classes={{ root: classes.root }}
                    count={pagesCount}
                    onChange={handleChange}
                    inputMode="numeric"
                    size="small"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage || 1}
                />
            </div>
        </div>
    );
};

export default CommonPagination;
