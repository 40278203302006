import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import { DeliveryPoint } from 'shared-components/dataTypes';
import Card from 'shared-components/Card';
import useClasses from 'hooks/useClasses';
import styles from './DeliveryPointInfo.style';

type Props = {
    option: 'LoadingInfo' | 'UnloadingInfo',
    point: DeliveryPoint,
    handoverDate?: string,
    leaseDate: string,
    shipmentDate: string,
    geolocation: Geolocation,
}

const DeliveryPointInfo = ({
    option = 'LoadingInfo',
    point,
    handoverDate = '',
    leaseDate = null,
    shipmentDate = null,
    geolocation = null,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const {
        address = '',
        contacts = [],
        locationName = null,
        locationIcon = '',
    } = point || {};

    const {
        timeZoneStr: timeZoneLeaseStartEnd = '',
        gmtOffsetInMs: gmtOffsetInMsLeaseStartEnd = 0,
    } = useGetTimeZoneInfoByGeolocation(geolocation, leaseDate);

    const {
        timeZoneStr: timeZoneShipmentStartEnd = '',
        gmtOffsetInMs: gmtOffsetInMsShipmentStartEnd = 0,
    } = useGetTimeZoneInfoByGeolocation(geolocation, shipmentDate);

    const leaseStartEndInfo = leaseDate
        ? `${moment(leaseDate).add(gmtOffsetInMsLeaseStartEnd, 'milliseconds')
            .format('DD.MM.YYYY HH:mm')} ${timeZoneLeaseStartEnd}`
        : option === 'LoadingInfo'
            ? t('COMMON.LEASE_HAS_NOT_YET_STARTED')
            : t('COMMON.LEASE_HAS_NOT_YET_FINISHED');

    const shipmentStartEndInfo = shipmentDate
        ? `${moment(shipmentDate).add(gmtOffsetInMsShipmentStartEnd, 'milliseconds')
            .format('DD.MM.YYYY HH:mm')} ${timeZoneShipmentStartEnd}`
        : '';

    const leaseStartEndTitle = option === 'LoadingInfo'
        ? t('COMMON.LEASE_START')
        : t('COMMON.LEASE_END');

    const cardTitle = option === 'LoadingInfo'
        ? t('COMMON.LOADING_INFO')
        : t('COMMON.UNLOADING_INFO');

    const locationTitle = option === 'LoadingInfo'
        ? t('COMMON.ORIGIN_LOCATION')
        : t('COMMON.DESTINATION_LOCATION');

    const personTitle = option === 'LoadingInfo'
        ? t('PRODUCT_RELEASE.HANDOVER_POINT_CONTACTS')
        : t('PRODUCT_RELEASE.COLLECTION_POINT_DROP_OFF_CONTACTS');

    const handoverDateTitle = option === 'LoadingInfo'
        ? t('PRODUCT_RELEASE.PICK_UP_DATE')
        : t('PRODUCT_RELEASE.DELIVERY_DATE');

    return (
        <Card
            fullHeight
            contentClass={classes.fullHeightImplicitly}
            title={cardTitle}
        >
            <div className={classes.cardContents}>
                <div className={[classes.flexContainer, classes.minHeight].join(' ')}>
                    {
                        locationName && (
                            <div className={classes.alignLeft}>
                                <div className={classes.fieldTitle}>{locationTitle}</div>
                                <div className={classes.fieldInfoAndIconContainer}>
                                    <div className={classes.fieldInfo}>{locationName}</div>
                                    <div className={classes.iconHolder}>
                                        <img
                                            className={classes.icon}
                                            src={locationIcon}
                                            alt={`location Icon ${option}`}
                                        />
                                    </div>

                                </div>
                                <div className={classes.fieldInfoLineBreak}>{address}</div>
                            </div>
                        )
                    }
                    {
                        contacts.length > 0 && (
                            <div className={classes.alignLeft}>
                                <div className={classes.fieldTitle}>{personTitle}</div>
                                {
                                    contacts.map((info, index) => (
                                        <div key={`contact${index + 1}`} className={classes.contactItem}>
                                            <div className={classes.fieldInfoAndIconContainer}>
                                                <div className={classes.fieldInfo}>
                                                    {info.person}
                                                </div>
                                                <div className={classes.iconHolder}>
                                                    <img
                                                        className={classes.icon}
                                                        src={info.personIcon}
                                                        alt={`person Icon ${option}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldInfoLineBreak}>
                                                {info.contact}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
                {
                    handoverDate && (
                        <div>
                            <div className={classes.fieldTitle}>{handoverDateTitle}</div>
                            <div className={classes.fieldInfoLineBreak}>{handoverDate}</div>
                        </div>
                    )
                }
                <div className={classes.blockHalfFlexWrapper}>
                    <div className={classes.blockHalfFlex}>
                        <div className={classes.fieldTitle}>{leaseStartEndTitle}</div>
                        <div className={classes.fieldInfoLineBreak}>{leaseStartEndInfo}</div>
                    </div>
                    <div className={classes.blockHalfFlex}>
                        <div className={classes.fieldTitle}>{t('COMMON.SHIPMENT_START')}</div>
                        <div className={classes.fieldInfoLineBreak}>
                            {shipmentStartEndInfo}
                        </div>
                    </div>
                </div>
                <div className={classes.space} />
            </div>
        </Card>
    );
};

export default DeliveryPointInfo;
