const styles = {
    topCards: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridGap: '12px',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '38px',
    },
    submitButton: {
        width: '170px',
        height: '40px',
        margin: '9px',
    },
};

export default styles;
