import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6,1fr)',
        gridTemplateRows: 'auto',
        padding: '12px',
        gridGap: '12px',
        gridTemplateAreas: `
            "company    address     address     contact     Table    Table"
            "company    address     address     contact     Table    Table"
        `,
    },
    company: {
        gridArea: 'company',
    },
    address: {
        gridArea: 'address',
    },
    contact: {
        gridArea: 'contact',
    },
    pcsTable: {
        gridArea: 'Table',
    },
    spsTable: {
        gridArea: 'Table',
    },
    belTable: {
        margin: '0 12px 12px 12px',
        maxWidth: '30%',
        [theme.breakpoints.down(1920)]: {
            maxWidth: '50%',
        },
        [theme.breakpoints.down(1600)]: {
            maxWidth: '100%',
        },
    },
});

export default styles;
