import Switch from '@mui/material/Switch';
import React, {
    useState,
    useEffect,
} from 'react';
import {
    COMPANY_TYPE_LABELS,
    WEBSITE_PATTERN,
} from 'shared-components/constants';
import RadioButtonGroup from 'shared-components/RadioButtonGroup';
import TextField from '@mui/material/TextField';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import FileUpload from 'shared-components/FileUpload';
import ControlledTooltip from 'shared-components/ControlledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import styles from './CompanyCard.style';

const companyTypeOptions = ['SERVICE_PROVIDER', 'PHARMA'];

type Props = {
    currentCompanyName?: string,
    currentCompanyType?: string,
    currentWebsite?: string,
    setFilter: (filter) => void,
    typeSelectable?: boolean,
    setLogoFormData: (formData: FormData) => void,
    setNameCheckInProgress: (bool: boolean) => void,
    setAuthorizesDataSharing?: (arg?: boolean) => void,
    currentAuthorizesDataSharing?: boolean,
    authorizesDataSharing?: boolean,
}

const EditMode = ({
    currentCompanyName = '',
    currentCompanyType = '',
    currentWebsite = '',
    setFilter,
    setLogoFormData,
    typeSelectable = false,
    setNameCheckInProgress = () => {
    },
    setAuthorizesDataSharing = null,
    currentAuthorizesDataSharing = false,
    authorizesDataSharing = null,
}: Props) => {
    const classes = useClasses(styles);
    const { FlexibleRequest: isNameUnique } = useSecureBackendEndpoints('').requests;
    const [isCorrectCompanyName, setIsCorrectCompanyName] = useState(true);
    const [companyName, setCompanyName] = useState(currentCompanyName);
    const [companyType, setCompanyType] = useState(currentCompanyType);
    const [website, setWebsite] = useState(currentWebsite);
    const { t } = useCustomTranslation();
    const [timerId, setTimerId] = useState(null);
    const hasAccess = useHasAccess();

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleWebsite = (event) => {
        setWebsite(event.target.value);
    };

    useEffect(() => {
        if (companyName && companyName !== currentCompanyName) {
            setNameCheckInProgress(true);
            (async () => {
                try {
                    clearTimeout(timerId);
                    const timerid = setTimeout(async () => {
                        const url = `companies/name-is-free?companyName=${companyName}`;
                        const isCorrectName = (await isNameUnique('GET', url) || {})?.data?.value || false;

                        setIsCorrectCompanyName(isCorrectName || companyName === currentCompanyName);
                        setFilter(previous => (
                            {
                                ...previous,
                                companyName: (isCorrectName || companyName === currentCompanyName)
                                    ? companyName
                                    : '',
                            }
                        ));
                        setNameCheckInProgress(false);
                    }, 1500);

                    setTimerId(timerid);
                } catch (error) {
                    setNameCheckInProgress(false);
                    global.console.log(error);
                }
            })();
        } else {
            setFilter(previous => (
                {
                    ...previous,
                    companyName,
                }
            ));
        }
    }, [companyName]);

    useEffect(() => {
        setFilter(previous => (
            {
                ...previous,
                companyType,
                website,
            }
        ));
    }, [companyType, website]);

    return (
        <>
            <div className={classes.fieldTitle}>{t('COMMON.COMPANY_NAME')}</div>
            { hasAccess(userRoles.SKYCELL_ADMIN)
                ? (
                    <ControlledTooltip
                        borderColor="red"
                        description={t('MY_COMPANY.THIS_NAME_IS_ALREADY_TAKEN')}
                        open={!isCorrectCompanyName}
                        placement="top-end"
                    >
                        <TextField
                            className={isCorrectCompanyName ? classes.mandatory : classes.error}
                            fullWidth
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            variant="standard"
                            size="small"
                            inputProps={{
                                className: classes.textFieldText,
                            }}
                        />
                    </ControlledTooltip>
                )
                : <div className={classes.filedInfo}>{companyName}</div>}
            <ControlledTooltip
                borderColor="red"
                description={t('MY_COMPANY.THIS_WEBSITE_DOES_NOT_HAVE_A_VALID_FORMAT')}
                open={Boolean(website && !WEBSITE_PATTERN.test(website))}
                placement="top-end"
            >
                <TextField
                    className={`${classes.textField} ${
                        (website && !WEBSITE_PATTERN.test(website) && classes.error) || ''
                    }`}
                    label={t('COMMON.WEBSITE')}
                    value={website}
                    onChange={handleWebsite}
                    variant="standard"
                    size="small"
                    inputProps={{
                        className: classes.textFieldText,
                    }}
                />
            </ControlledTooltip>
            <div className={classes.fieldTitle}>{t('MY_COMPANY.LOGO')}</div>
            <FileUpload setFormData={setLogoFormData} />
            <div className={classes.fieldTitle}>{t('COMMON.COMPANY_TYPE')}</div>
            <div className={typeSelectable ? classes.mandatory : classes.disabledRadio}>
                <RadioButtonGroup
                    availableAnswerOptions={companyTypeOptions}
                    labels={COMPANY_TYPE_LABELS(t)}
                    defaultValue={companyType}
                    setAnswerUpdate={setCompanyType}
                    disabled={!typeSelectable}
                />
            </div>
            {
                setAuthorizesDataSharing && (
                    <div className={classes.switchItemContainerStyle}>
                        <Switch
                            id="dataSharingSwitch"
                            className={currentAuthorizesDataSharing ? classes.blocked : ''}
                            defaultChecked={currentAuthorizesDataSharing}
                            color="primary"
                            disabled={currentAuthorizesDataSharing}
                            onChange={() => setAuthorizesDataSharing(!authorizesDataSharing)}
                            size="small"
                        />
                        <label htmlFor="dataSharingSwitch">{t('ADMINISTRATION.AUTHORIZES_DATA_SHARING')}</label>
                    </div>
                )
            }
        </>
    );
};

export default EditMode;
