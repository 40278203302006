import React, { MouseEventHandler, KeyboardEventHandler, ReactElement } from 'react';

import useClasses from 'hooks/useClasses';
import styles from './TitleHeader.style';

type Props = {
    children: ReactElement | string,
    onClick?: MouseEventHandler<HTMLDivElement>,
    onKeyPress?: KeyboardEventHandler<HTMLDivElement>,
}

const TitleHeader = ({
    children = null,
    onClick = null,
    onKeyPress = null,
} : Props) => {
    const classes = useClasses(styles);

    return (
        <div
            className={`${classes.title} ${onClick ? classes.cursorPointer : ''}`}
            onClick={onClick}
            onKeyPress={onKeyPress}
        >
            {children}
        </div>
    );
};

export default TitleHeader;
