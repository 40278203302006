import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme:SkycellThemeInterface) => ({
    card: {
        boxShadow: '0px 1px 3px #00000033',
    },
    root: {
        display: 'grid',
        gridTemplateColumns: '5fr 1fr',
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    message: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary[400],
        font: 'normal normal 100 36px/43px Roboto',
        letterSpacing: '0.46px',
    },
    error: {
        color: theme.palette.common.red,
    },
    description: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.46px',
    },
    buttonWrapper: {

    },
    button: {
        height: '36px',
        margin: '9px',
        background: '#8BD1E8 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
});
