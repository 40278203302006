const styles = (theme) => ({
    container: {

    },
    selectorRows: {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
    },
    multipleSelectionInput: {
        '& ::placeholder': {
            color: theme.palette.secondary[900],
            fontSize: '11px',
            overflow: 'visible',
        },
    },
    chips: {
        display: 'flex',
        flexDirection: 'column',
        margin: '16px 1px 8px 1px',
        gap: '4px',
    },
    chipLabel: {
        fontSize: '12px',
        letterSpacing: '0.02em',
        color: theme.palette.secondary[900],
    },
    chipRoot: {
        backgroundColor: '#F4F4F4!important',
        width: 'min-content',
        maxWidth: '100%',
        overflow: 'hidden',
    },
    deleteIcon: {
        color: `${theme.palette.secondary[600]}!important`,
    },
    selectorCol: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: '50%',
        '& > span': {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: '12px',
            letterSpacing: '0.01em',
            textAlign: 'left',
            color: theme.palette.common.black,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '20px',
        },
    },
    arrow: {
        color: theme.palette.primary[400],
        transform: 'scaleY(0.5)',
    },
});

export default styles;
