/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useMemo } from 'react';

import {
    getLineStyle,
    getNumberWidth,
    maxRangeStyle,
    minRangeStyle,
    getTempMinStyle,
    getTempMaxStyle,
    getTempRulerStyle,
} from 'shared-components/TemperatureCheck/lib';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import TemperatureCheckIcon from 'shared-components/TemperatureCheckIcon';
import { TEMPERATURE_STATUS } from '../constants';

type Props = {
    temperatureMax: number,
    temperatureMin: number,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    grid?: boolean,
    temperatureCheckStatus?: string,
}

const TemperatureCheck = ({
    temperatureMax,
    temperatureMin,
    temperatureRangeMax,
    temperatureRangeMin,
    grid = true,
    temperatureCheckStatus = null,
} : Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const gradation = useMemo(() => {
        const lineCount = temperatureRangeMax - temperatureRangeMin === 6 ? 7 : 11;

        return [...Array(lineCount).keys()];
    }, [temperatureRangeMax, temperatureRangeMin]);

    const itemsCountSeparatedByLines = temperatureRangeMax - temperatureRangeMin === 6 ? 6 : 10;
    const scale = 150 / itemsCountSeparatedByLines;
    const temperatureMinOffset = temperatureMin === null
        ? -50
        : Math.min(((temperatureRangeMin - temperatureMin) * scale), scale * 2);
    const offsetToLeft = -180 - getNumberWidth(temperatureMin) - temperatureMinOffset;
    const temperatureMaxOffset = temperatureMax === null
        ? -50
        : Math.min(((temperatureMax - temperatureRangeMax) * scale), scale * 2);
    const width = 150 + temperatureMinOffset + temperatureMaxOffset;

    const mainStyle = useMemo<CSSProperties>(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        position: 'relative',
        ...(grid && {
            left: `${offsetToLeft}px`,
            marginRight: `-${width + 6}px`,
        }),
        ...(!grid && {
            width: '100%',
        }),
    }), [offsetToLeft, width]);
    const noData = useMemo(() => temperatureCheckStatus === 'NO_DATA', [temperatureCheckStatus]);
    const isPotential = useMemo(() => temperatureCheckStatus === TEMPERATURE_STATUS.POTENTIAL_EXCURSION
        || temperatureCheckStatus === TEMPERATURE_STATUS.PREDICTED_EXCURSION, [temperatureCheckStatus]);

    return (
        <>
            {
                grid && (
                    <div style={{ display: 'flex', paddingLeft: '80px', overflow: 'hidden' }}>
                        <div style={minRangeStyle}>{`${temperatureRangeMin?.toFixed(1)}°C`}</div>
                        {
                            gradation.map((_, i) => (
                                <div
                                    key={`line-${i}`}
                                    style={getLineStyle(gradation.length, gradation.length === i + 1)}
                                />
                            ))
                        }
                        <div style={maxRangeStyle}>{`${temperatureRangeMax?.toFixed(1)}°C`}</div>
                    </div>
                )
            }
            <div
                style={mainStyle}
            >
                <TemperatureCheckIcon temperatureCheckStatus={temperatureCheckStatus || 'NO_DATA'} />
                <div style={getTempMinStyle(
                    {
                        tempMin: temperatureMin,
                        tempMinRange: temperatureRangeMin,
                        tempMaxRange: temperatureRangeMax,
                        noData,
                        isPotential,
                        theme,
                    },
                )}
                >
                    {temperatureMin === null ? 'n/a' : `${temperatureMin?.toFixed(1)}°C`}
                </div>
                <div style={
                    getTempRulerStyle(
                        {
                            tempMin: temperatureMin,
                            tempMax: temperatureMax,
                            tempMinRange: temperatureRangeMin,
                            tempMaxRange: temperatureRangeMax,
                            width,
                            grid,
                            noData,
                            theme,
                            isPotential,
                        },
                    )
                }
                />
                <div style={
                    getTempMaxStyle({
                        tempMax: temperatureMax,
                        tempMaxRange: temperatureRangeMax,
                        tempMinRange: temperatureRangeMin,
                        noData,
                        isPotential,
                        theme,
                    })
                }
                >
                    {temperatureMax === null ? 'n/a' : `${temperatureMax?.toFixed(1)}°C`}
                </div>
            </div>
        </>
    );
};

export default TemperatureCheck;
