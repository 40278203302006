import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import IconList from 'shared-components/CompanyInfoComponents/IconList';
import TextArray from 'shared-components/CompanyInfoComponents/TextArray';
import TableCellFacilities from 'shared-components/CompanyInfoComponents/TableCellFacilities';
import { TFunction } from 'i18next';

const tableColumns: (t:TFunction) => ColumnsType[] = (t) => [
    {
        Header: t('COMMON.SITE'),
        accessor: 'name',
        dataType: 'text',
    },
    {
        Header: t('COMMON.CERTIFICATES'),
        accessor: (row) => {
            const { certificates = [] } = row;

            return <TextArray textItems={certificates} />;
        },
        dataType: 'custom',
    },
    {
        Header: t('COMMON.SERVICES'),
        accessor: (row) => {
            return <IconList iconList={row.services} />;
        },
        dataType: 'custom',
    },
    {
        Header: t('COMMON.COMMODITIES'),
        accessor: (row) => {
            return <IconList iconList={row.commodities} />;
        },
        dataType: 'custom',
    },
    {
        Header: t('COMMON.FACILITIES'),
        accessor: (row) => {
            const { facilities = [] } = row;

            return <TableCellFacilities facilities={facilities} />;
        },
        dataType: 'custom',
    },
];

export default tableColumns;
