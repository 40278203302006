import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import EnergyLevel from 'shared-components/EnergyLevel';
import TemperatureCheckRelative from 'shared-components/TemperatureCheckRelative';
import { GenericShipmentData } from './lib';

const tableColumns: (
    t,
    minMaxTempArray: [number, number],
    hasAccessToEnergy: boolean
) => ColumnsType[] = (
    t,
    minMaxTempArray = [0, 0],
    hasAccessToEnergy = false,
) => {
    return [
        {
            id: 'customerReference',
            Header: t('ORDER_DETAILS.REFERENCE_PO_NUMBER'),
            accessor: 'customerReference',
            dataType: 'highlightedText',
        },
        {
            Header: t('TRACK_AND_TRACE.MAWB'),
            accessor: 'mawbNumber',
            dataType: 'highlightedText',
            customWidth: 140,
        },
        {
            Header: t('TRACK_AND_TRACE.HAWB'),
            accessor: 'hawbNumber',
            dataType: 'highlightedText',
            customWidth: 140,
        },
        {
            Header: t('COMMON.PACKAGINGS'),
            accessor: (row) => row?.cargo.map(item => item.packaging.serialNumber)?.join('\n\n'),
            dataType: 'highlightedText',
            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
            disableSortBy: true,
        },
        hasAccessToEnergy && {
            id: 'remainingEnergyLevel',
            Header: t('COMMON.ENERGY'),
            accessor: ({ cargo }: GenericShipmentData) => cargo.map((packaging) => {
                return (
                    <EnergyLevel
                        energyLevel={Number(
                            ((packaging?.energyLevelCheckResult?.remainingEnergyLevel / 1) * 100)
                                .toFixed(0),
                        )}
                        energyLevelStatus={packaging?.energyLevelCheckResult?.energyLevelStatus}
                    />
                );
            }),
            dataType: 'text',
            disableSortBy: true,
            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
        },
        {
            Header: t('COMMON.JYPID'),
            accessor: (row) => row?.cargo.map(item => item.packaging.jypId)?.join('\n\n'),
            dataType: 'highlightedText',
            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
            disableSortBy: true,
        },
        {
            id: 'temperatureRange',
            Header: t('COMMON.RANGE_C'),
            accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
            dataType: 'custom',
            sortType: (rowA, rowB) => {
                const { original: originalA } = rowA || {};
                const { original: originalB } = rowB || {};
                const { temperatureRange: temperatureRangeA = '' } = originalA || {};
                const { temperatureRange: temperatureSpecifics = '' } = originalB || {};

                return temperatureRangeA.localeCompare(temperatureSpecifics);
            },
        },
        {
            Header: `${t('PRODUCT_RELEASE.TEMPERATURE_STATUS')}`,
            customWidth: '320px',
            accessor: ({ packagings }) => packagings.map((packaging) => {
                const {
                    temperatureStatus,
                    temperatureMax,
                    temperatureRangeMax,
                    temperatureRangeMin,
                    temperatureMin,
                } = packaging.temperatureCheckResult || {};

                return (
                    <div style={{
                        paddingRight: '10px',
                        paddingBottom: '10px',
                    }}
                    >
                        <TemperatureCheckRelative
                            temperatureMax={temperatureMax}
                            temperatureMin={temperatureMin}
                            temperatureRangeMax={temperatureRangeMax}
                            temperatureRangeMin={temperatureRangeMin}
                            temperatureCheckStatus={temperatureStatus}
                            grid={false}
                            bounds={minMaxTempArray}
                        />
                    </div>
                );
            }),
            dataType: 'custom',
            disableSortBy: true,
        },
        {
            Header: t('COMMON.FROM'),
            accessor: 'originAirport',
            dataType: 'text',
        },
        {
            Header: t('COMMON.TO'),
            accessor: 'destinationAirport',
            dataType: 'text',
        },
        {
            Header: t('TRACK_AND_TRACE.ORDER'),
            accessor: 'externalId',
            dataType: 'highlightedText',
        },
        {
            Header: t('TRACK_AND_TRACE.SHIPMENT_STATUS'),
            accessor: 'status',
            dataType: 'text',
        },
    ].filter(Boolean); // This filters out any falsy values in the array
};

export default tableColumns;
