import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import icons from 'shared-components/icons';
import { STATUS } from 'Administration/Shipments/lib';

const InternalDataCheck = ({ cargos, marginBottom: marginBottomProp }) => {
    const { t } = useCustomTranslation();

    return (
        <>
            {cargos.map((cargo, i) => {
                const marginBottom = i !== cargos.length - 1 ? marginBottomProp : 0;
                const status = t(`INTERNAL_DATA_CHECK_STATUS.${cargo.internalDataCompleteStatus}`);

                if (cargo.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE) {
                    return (
                        <div
                            key={cargo.id}
                            style={{
                                marginBottom,
                                color: '#B92C2C',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img alt="tasks" src={icons.warning} style={{ marginRight: 5 }} />
                            {' '}
                            {status}
                        </div>
                    );
                }

                const percentage = parseInt(cargo.internalDataCompletePercentage, 10);

                return (
                    <div
                        key={cargo.id} style={{
                            marginBottom,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {cargo.internalDataCompleteStatus === STATUS.NOT_COMPLETE && (
                            <img alt="tasks" src={icons.warning_triangle} style={{ marginRight: 5 }} />
                        )}
                        {`${status} (${percentage} %)`}
                    </div>
                );
            })}
        </>
    );
};

export default InternalDataCheck;
