import { FacetDTO } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import { LatLng, PolylineAndStepStatus } from 'dataTypes/common';

export interface ActivePackaging {
    serialNumber: string,
    timestamp: number,
}

export interface ProcessedPackagingData {
    serialNumber: string,
    temperatureRange: string,
    packagingPicture: string,
    type: string,
    customerReference: string,
    currentShipment: string,
    lastMeasuredGeolocationTimestamp: number,
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTempInternal: number,
    lastMeasuredTempAmbient: number,
    lastMeasuredTempInternalTimestamp: number,
    lastMeasuredTempAmbientTimestamp: number,
}

export interface GenericProcessedPackagingData {
    serialNumber: string,
    jypId: string,
    packagingTypeCode: string,
}

export enum EnergyLevelStatus {
    ENERGY_LEVEL_BREACH = 'ENERGY_LEVEL_BREACH'
}
export const ringMarkerIconObj = {
    url: icons.polylinePathOrangeRingIcon,
    scaledSize: { width: 10, height: 10 },
    origin: { x: 0, y: 0 },
    anchor: { x: 5, y: 5 },
};

export const crossMarkerIconObj = {
    url: icons.hex_with_cross,
    scaledSize: { width: 20, height: 20 },
    origin: { x: 0, y: 0 },
    anchor: { x: 10, y: 10 },
};

export const filterByPackagingShipmentStatus = (options: string[], currentShipment): boolean => {
    return (options.includes('IN_SHIPMENT') && currentShipment !== null)
        || (options.includes('NOT_IN_SHIPMENT') && currentShipment === null);
};

export const getMapDataItem = (packaging: ProcessedPackagingData) => {
    return {
        type: 'Feature',
        properties: {
            marker: true,
            data: packaging,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                packaging.lastMeasuredLongitude,
                packaging.lastMeasuredLatitude,
            ],
        },
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom = 10) => {
    return {
        points: mapData,
        bounds,
        zoom,
        options: {
            radius: 75,
            maxZoom: 5,
            generateId: true,
            map: (item) => {
                return {
                    type: item.data.type,
                    lastMeasuredTempInternalTimestamp: item.data.lastMeasuredTempInternalTimestamp,
                };
            },
            reduce: (data, props) => {
                const { packagingTypes = [], lastMeasuredTimes = [] } = data;

                if (!packagingTypes.includes(props.type)) {
                    data.packagingTypes = [...packagingTypes, props.type];
                }

                if (!lastMeasuredTimes.includes(props.lastMeasuredTempInternalTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTempInternalTimestamp];
                }
                return data;
            },
        },
    };
};

export const emptyFunction = () => {};

export const getCombinedShipmentPolylines = (
    shipmentPolylines: PolylineAndStepStatus[] = [],
    polylinesFromSensorData: LatLng[] = [],
    currentMarkerPosition: LatLng,
): PolylineAndStepStatus[] => {
    if (polylinesFromSensorData.length === 0) {
        return shipmentPolylines;
    }
    const packagingTraveledPath: PolylineAndStepStatus[] = polylinesFromSensorData.length === 0
        ? []
        : [{
            stepStatus: 'CLOSED',
            path: [
                ...polylinesFromSensorData,
                currentMarkerPosition,
            ],
        }];

    const plannedShipmentPath = shipmentPolylines.filter(item => item.stepStatus !== 'CLOSED');

    if (plannedShipmentPath.length > 0) {
        const [firstShipmentPolylineLocation] = plannedShipmentPath[0].path;

        const shipmentPolylinePath: PolylineAndStepStatus[] = [
            ...packagingTraveledPath,
            {
                stepStatus: 'NOT_STARTED',
                path: [
                    currentMarkerPosition,
                    firstShipmentPolylineLocation,
                ],
            },
            ...plannedShipmentPath,
        ];

        return shipmentPolylinePath;
    }

    return packagingTraveledPath;
};

const isSamePositions = (first: LatLng, second: LatLng): boolean => {
    return first.lat === second.lat && first.lng === second.lng;
};

export const getPolylineMarkers = (polyline: PolylineAndStepStatus[], currentMarkerPosition: LatLng) => {
    return polyline.reduce((data, item, index) => {
        const { path } = item;

        if (path.length === 0) {
            return data;
        }
        const [firstPosition] = path;
        const lastPosition = path[path.length - 1];

        if (index === 0) {
            return [
                ...(isSamePositions(firstPosition, currentMarkerPosition) ? [] : [firstPosition]),
                ...(isSamePositions(lastPosition, currentMarkerPosition) ? [] : [lastPosition]),
            ];
        } else if (isSamePositions(lastPosition, currentMarkerPosition)) {
            return [
                ...data,
                lastPosition,
            ];
        }

        return data;
    }, []);
};

export type CustomSort = { columnId: string, direction: 'asc' | 'desc' };
export const trimQuery = (
    filterOptions: { [optionsGroupKey: string]: string[] },
    query: string | object,
    allFacets: FacetDTO[],
    sort: CustomSort = null,
    currentFacets?: FacetDTO[],
) => {
    const queryObj: object = Object.keys(filterOptions).reduce((acc, key) => {
        const allSelected = allFacets
            ?.find(it => it.filterName === key)
            ?.counts
            ?.filter(it => it.filterValue !== null)?.length === filterOptions?.[key]?.length;

        if (filterOptions[key].length > 0 && !allSelected) {
            const facet = currentFacets?.find(it => it.filterName === key)?.counts;

            const queryOptions = filterOptions[key];

            if (!currentFacets) {
                acc[key] = queryOptions.map(it => encodeURIComponent(it));
            } else if (facet) {
                acc[key] = queryOptions.filter(it => facet?.find(facetItem => facetItem.filterValue === it))
                    .map(it => encodeURIComponent(it));
            }
        }
        return acc;
    }, { });

    if (sort && sort?.columnId && sort?.direction) {
        queryObj['sortBy'] = sort.columnId;
        queryObj['asc'] = sort.direction === 'asc';
    }
    if (query) {
        if (typeof query === 'object') {
            Object.keys(query).forEach(key => {
                if (query[key] !== '') {
                    queryObj[key] = query[key];
                }
            });
        } else {
            queryObj['q'] = query;
        }
    }
    return queryObj;
};

export const isWithinBounds = (point, bnds) => point.latitude >= bnds.sw.lat
    && point.latitude <= bnds.ne.lat
    && point.longitude >= bnds.sw.lng
    && point.longitude <= bnds.ne.lng;
