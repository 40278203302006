import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useParams } from 'react-router-dom';
import BackToLink from 'shared-components/BackToLink';
import useClasses from 'hooks/useClasses';
import DataCompleteCheck from 'Administration/Shipments/components/DataCompleteCheck';
import { PathParams } from 'Administration/Shipments/lib';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import { RequestStatus } from 'dataTypes/common';
import StatusInfo from 'shared-components/StatusInfo';
import styles from './ShipmentAdmin.style';

const ShipmentAdmin = () => {
    const { t } = useCustomTranslation();
    const { id } = useParams<PathParams>();
    const classes = useClasses(styles);

    const {
        setMenuTabs,
        setCustomPageTitle,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                title: t('MENU_ITEMS.DETAILS'),
                link: `/administration/shipments/${id}`,
                iconSrc: '',
                activePage: false,
            },
            {
                title: t('MENU_ITEMS.ADMIN'),
                link: `/administration/shipments/${id}/admin`,
                iconSrc: '',
                activePage: true,
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.SHIPMENTS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [id]);

    const { Get: getShipment } = useSecureBackendEndpoints('admin/v2/shipments').requests;
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [shipment, setShipment] = useState<ShipmentDetailsInterface>(null);

    useEffect(() => {
        (async () => {
            try {
                setStatus('PENDING');
                const response = (await getShipment(id)).data;

                if (response) {
                    setShipment(response);
                    setStatus('SUCCESS');
                }
            } catch (error) {
                setStatus('FAILURE');
                global.console.log(error);
            }
        })();
    }, []);

    return status === 'SUCCESS'
        ? (
            <>
                <BackToLink isGeneric to="/administration/shipments" />
                <div className={classes.wrapper}>
                    <div className={classes.cardContentData}>
                        <DataCompleteCheck
                            externalId={shipment.externalId}
                            isNotClosed={shipment.status !== 'CLOSED'}
                        />
                    </div>
                </div>
            </>
        )
        : <StatusInfo status={status} />;
};

export default ShipmentAdmin;
