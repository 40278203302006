import React from 'react';
import Card from 'shared-components/Card';
import icons from 'shared-components/icons';
import TempRange from 'shared-components/TempRange';
import useClasses from 'hooks/useClasses';
import styles from './ShortPackagingInfo.style';

type Props = {
    model: string,
    picture: string,
    tempRange?: string,
}

const ShortPackagingInfo = ({
    model = '',
    picture = icons.default_packaging_icon,
    tempRange,
} : Props) => {
    const classes = useClasses(styles);

    if (!picture) return null;
    return (
        <Card title={model}>
            <div className={classes.container}>
                <img
                    className={classes.picture}
                    src={picture}
                    alt="Packaging"
                />
            </div>
            {
                tempRange && (
                    <div className={classes.container}>
                        <TempRange tempRange={tempRange} />
                    </div>
                )
            }
        </Card>
    );
};

export default ShortPackagingInfo;
