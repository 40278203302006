import React, {
    useState,
    useEffect,
} from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { CircularProgress } from '@mui/material';
import useClasses from 'hooks/useClasses';
import {
    extractOnlyNotNullFields,
    isReadyAddUserRequestBody,
} from 'Administration/lib';
import UserInfoCard from 'Administration/Users/components/UserInfoCard';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import styles from './AddUser.style';

const AddUser = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const {
        FlexibleRequest: createUser,
    } = useSecureBackendEndpoints(
        'admin/companies',
        { customSuccessMessage: t('COMMON.USER_CREATED') },
    ).statusNotificationRequests;
    const [companyId, setCompanyId] = useState(null);
    const [userInfo, setUserInfo] = useState<AddUserInfo>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const [requestBody, setRequestBody] = useState({});
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const [companyRoles, setCompanyRoles] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const handleClickCreate = async () => {
        setLoading(true);
        const result = await createUser('POST', `/${companyId}/user`, requestBody);

        setLoading(false);

        if (result) {
            navigate('/administration/users');
        }
    };

    const handleClickCancel = () => {
        navigate('/administration/users');
    };

    useEffect(() => {
        if (userInfo !== null) {
            setRequestBody({ ...extractOnlyNotNullFields(userInfo), roles });
        }
    }, [roles, userInfo]);

    useEffect(() => {
        setReadyToSubmission(isReadyAddUserRequestBody(userInfo));
    }, [userInfo]);

    useEffect(() => {
        setRoles([]);
    }, [companyId]);

    return (
        <>
            <div className={classes.pageContent}>
                <UserInfoCard
                    setUserInfo={setUserInfo}
                    setCompanyId={setCompanyId}
                    setCompanyRoles={setCompanyRoles}
                    type="new"
                    admin
                />
                <CompanyRolesCard
                    roles={roles}
                    availableRoles={companyRoles}
                    setRoles={setRoles}
                    type="new"
                />
            </div>
            <div className={classes.pageContentButtons}>
                <div className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        autoFocus
                        disabled={!readyToSubmission || loading}
                        onClick={handleClickCreate}
                        className={classes.approveButton}
                    >
                        {
                            loading
                                ? <CircularProgress size={18} style={{ marginRight: 6 }} color="secondary" />
                                : t('MY_COMPANY.CREATE')
                        }
                    </Button>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={handleClickCancel}
                        className={classes.rejectButton}
                        disabled={loading}
                    >
                        {t('COMMON.CANCEL')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddUser;
