import React from 'react';
import { Button } from '@mui/material';
import useClasses from 'hooks/useClasses';

type CustomButtonProps = {
    children: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
};

const styles = (theme) => ({
    title: {
        marginBottom: '18px',
    },
    button: {
        borderRadius: '0px!important',
        minHeight: '33px',
        margin: '0 9px',
        font: 'normal normal 500 12px/14px Roboto!important',
        letterSpacing: '1.07px',
        color: theme.palette.common.white,
        background: '#009CCF',
        textTransform: 'capitalize',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    disabled: {
        color: `${theme.palette.common.white} !important`,
    },
});

const CustomButton = ({ children, disabled, onClick }: CustomButtonProps) => {
    const classes = useClasses(styles);

    return (
        <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
            className={[classes.button, disabled ? classes.disabled : ''].join(' ')}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
