const styles = (theme) => ({
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '6px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    buttonContainer: {
        marginTop: '31px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    auditButton: {
        width: '130px',
        height: '35px',
        margin: '9px',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'left',
        font: 'normal normal 500 12px/14px Roboto',
        letterSpacing: '1.07px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
    rejectButton: {
        background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.primary[200],
        textTransform: 'uppercase',
    },
});

export default styles;
