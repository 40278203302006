import React, { useMemo, MouseEventHandler } from 'react';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { PolygonIconType } from 'shared-components/PolygonMarker/PolygonIcon';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    loggerGroup: ExtractedLoggerData[],
    showLoggerTypes?: boolean,
    showLastConnection?: boolean,
    onHover: MouseEventHandler<HTMLDivElement>,
    isSelected: boolean,
}
const SKYMIND_LOGGER_TYPES = [
    'CARTASENSE',
    'CARTASENSE_M',
    'CL_BUTTERFLY',
    'CL_TT_7100',
    'SKYCELL_SAVY_BLE',
    'MR_810T',
    'MR_812P',
    'MR_813',
];

const isSkymindType = (family) => {
    return SKYMIND_LOGGER_TYPES.includes(family) || family.startsWith('MR_');
};

const LoggerMarker = ({
    loggerGroup = [],
    showLoggerTypes = false,
    showLastConnection = false,
    onHover,
    isSelected = false,
}: Props) => {
    const [firstEntry] = loggerGroup;
    const { t } = useCustomTranslation();
    const {
        lastMeasuredLatitude: lat,
        lastMeasuredLongitude: lng,
        lastMeasuredTimestamp,
    } = firstEntry || {};

    const icon = useMemo<PolygonIconType>(() => {
        const {
            loggerFamily,
        } = firstEntry || {};
        const type = isSkymindType(loggerFamily)
            ? 'hexa' : 'tetra';

        return loggerGroup.length > 1 ? 'hexa' : type;
    }, [firstEntry, loggerGroup]);

    const loggerTypes = useMemo<string>(() => {
        const loggerTypesSet = Array.from(new Set(loggerGroup.map(({ loggerFamilyLabel }) => loggerFamilyLabel)));

        return loggerTypesSet.length === 1 ? loggerTypesSet[0] : t('COMMON.VARIOUS');
    }, [loggerGroup]);

    return (
        <OverlayViewF
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div onMouseEnter={onHover}>
                <PolygonMarker
                    count={loggerGroup.length > 1 ? loggerGroup.length : 1}
                    polygonIconType={icon}
                    lastConnected={
                        showLastConnection
                            ? lastMeasuredTimestamp
                            : null
                    }
                    theme="light"
                    iconDiameter={35}
                    polygonIconColor="blue"
                    filled={isSelected}
                    lastConnectedIsVarious={loggerGroup.length > 1}
                    itemType={showLoggerTypes
                        ? loggerTypes : null}

                />

            </div>
        </OverlayViewF>
    );
};

export default LoggerMarker;
