import { useTheme } from '@mui/material/styles';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useClasses from 'hooks/useClasses';
import tableColumns from './tableColumns';
import styles from './SearchPreviewTable.style';

type Props = {
    setQuery: Dispatch<SetStateAction<string>>
    isTableView: boolean,
    tooltipInfo?: QuickHelpTooltipInfo,
    query: string,
}

const SearchPreviewPackages = ({
    query,
    setQuery,
    tooltipInfo = {},
    isTableView,
} : Props) => {
    const textRef = useRef(null);
    const classes = useClasses(styles);
    const theme = useTheme();
    const [lastQueryTyped, setLastQueryTyped] = useState(query || '');
    const [open, setOpen] = useState(false);

    const {
        data,
    } = useGetCommonData('v2/shipments/search', {
        query: {
            q: lastQueryTyped,
            page: 0,
            perPage: 10,
        },
        enabled: !isTableView && lastQueryTyped?.length > 2,
        postProcess: it => it.resultList,
    });
    const { t } = useCustomTranslation();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (query && query !== lastQueryTyped) {
                setLastQueryTyped(query);
            }

            setOpen(query?.length > 2);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [query, lastQueryTyped, isTableView]);

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const handleFocus = () => {
        setOpen(prev => prev || query?.length > 0 || data?.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const handleClickShowResults = () => {
        setOpen(false);
    };

    const queryChanged = useCallback((event) => {
        setQuery(event.target.value);
        if (!isTableView) {
            setOpen(event.target.value.length !== 0);
        }
    }, []);

    const handleClickClearSearch = useCallback(() => {
        setOpen(false);
        setQuery('');
        setLastQueryTyped('');
        setQuery('');
    }, []);

    const columns = useMemo(() => tableColumns(t), [t]);

    const translatedShipments = useMemo(() => {
        return data.map((shipment) => {
            return {
                ...shipment,
                status: t(`SHIPMENT_STATUS.${shipment.status}`),
            };
        });
    }, [data, t]);

    return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}
            >
                <QuickHelpTooltip
                    tooltipInfo={tooltipInfo}
                >

                    <TextField
                        className={classes.input}
                        id="TextFieldInput"
                        onInput={queryChanged}
                        value={query}
                        variant="outlined"
                        placeholder={t('COMMON.SEARCH')}
                        ref={textRef}
                        onFocus={handleFocus}
                        onClick={handleClick}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: theme.palette.common.black }} />
                                </InputAdornment>
                            ),
                            className: classes.input,
                        }}
                    />

                </QuickHelpTooltip>
                <Button
                    className={classes.clearSearchBtn}
                    onClick={() => handleClickClearSearch()}
                    disabled={!query && data.length === 0}
                >
                    <CloseIcon />
                </Button>
            </div>
            {
                !isTableView && (
                    <PreviewTable
                        data={translatedShipments}
                        columns={columns}
                        maskForHighlight={query}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewPackages;
