import React, {
    useMemo,
    useState,
} from 'react';
import Button from '@mui/material/Button';
import CustomModal from 'shared-components/CustomModal';
import Map from 'shared-components/Map';
import { Geolocation } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from '../GoogleAddress.style';

type Props = {
    open: boolean,
    setOpen: (open: boolean) => void,
    geolocation: Geolocation,
    setGeolocation: (geolocation: Geolocation) => void,
    backdrop?: boolean
}

const defaultStartPositon = { // Zurich
    lat: 47.373878,
    lng: 8.545094,
};

const AddGeolocation = ({
    open,
    setOpen,
    geolocation,
    setGeolocation,
    backdrop = true,
}: Props) => {
    const classes = useClasses(styles);
    const [geolocationFromMap, setGeolocationFromMap] = useState<Geolocation>(null);
    const { t } = useCustomTranslation();
    const handleClose = () => setOpen(false);

    const handleClickAdd = () => {
        setGeolocation(geolocationFromMap);
        setOpen(false);
    };

    const startPostion = useMemo(() => {
        return geolocation?.latitude && geolocation?.longitude
            ? {
                lat: geolocation.latitude,
                lng: geolocation.longitude,
            }
            : defaultStartPositon;
    }, [
        geolocation.latitude,
        geolocation.longitude,
    ]);

    const defaultMapConfig = useMemo(() => {
        return {
            center: startPostion,
            containerStyle: {
                height: '50vh',
                width: '100%',
            },
            zoom: 5,
            backgroundColor: 'unset',
        };
    }, [
        startPostion.lat,
        startPostion.lng,
    ]);

    const draggableMarker = {
        startPostion,
        setGeolocationFromMap,
    };

    return (
        <CustomModal
            backdrop={backdrop}
            className={classes.modalWidth}
            open={open}
            onClose={handleClose}
            title={t('MY_COMPANY.ADD_GEOLOCATION')}
        >
            <div className={classes.addGeolocationDescription}>
                <img
                    alt="icon"
                    className={classes.addGeolocationDescriptionIcon}
                    src={icons.map_location_destination}
                />
                {t('MY_COMPANY.PLACE_THE_PIN')}
            </div>
            <Map
                draggableMarker={draggableMarker}
                mapConfig={defaultMapConfig}
            />
            <div className={classes.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    autoFocus
                    className={classes.approveButton}
                    disabled={!geolocationFromMap}
                    onClick={handleClickAdd}
                >
                    {t('MY_COMPANY.ADD')}
                </Button>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.rejectButton}
                    onClick={handleClose}
                >
                    {t('COMMON.CANCEL')}
                </Button>
            </div>
        </CustomModal>
    );
};

export default AddGeolocation;
