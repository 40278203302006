import React, { useMemo, CSSProperties, forwardRef } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import useClasses from 'hooks/useClasses';

const styles = theme => ({
    a: {
        textDecoration: 'none',
        '&:not(:last-child)': {
            marginRight: '18px',
        },
    },
    appBarButton: {
        height: '36px',
        padding: '0 22px',
        font: 'normal normal medium 14px/19px Roboto',
        letterSpacing: '0.84px',
        color: theme.palette.common['menuTextWhite'],
        opacity: 1,
        textTransform: 'capitalize',
        background: '#FFFFFF4D 0% 0% no-repeat padding-box',
        borderRadius: '5px 5px 0px 0px',
        fontWeight: 500,
    },
    functionalButton: {
        height: '36px',
        padding: '0 22px',
        font: 'normal normal medium 14px/19px Roboto',
        letterSpacing: '0.84px',
        color: theme.palette.common['menuTextWhite'],
        opacity: 1,
        textTransform: 'capitalize',
        background: '#FFFFFF4D 0% 0% no-repeat padding-box',
        borderRadius: '5px 5px 0px 0px',
    },
    activeAppBarButton: {
        background: '#F7F7F7 0% 0% no-repeat padding-box',
        color: theme.palette.primary.light,
        '&:hover': {
            background: '#F7F7F7 0% 0% no-repeat padding-box',
        },
    },
    appBarOneButton: {
        font: 'normal normal 300 14px/17px Roboto',
        borderRadius: '0!important',
        letterSpacing: '1.25px',
        background: 'none',
        textTransform: 'uppercase',
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '15px',
        height: '15px',
        marginRight: '8px',
    },
    buttonTitle: {
        marginTop: '1px',
    },
});

type Props = {
    active: boolean,
    iconSrc?: string,
    link: string,
    onClick?: Function,
    iconStyle?: CSSProperties,
    title: string,
    isOneButton?: boolean,
    buttonClass?: string,
}

const AppBarButton = forwardRef<HTMLButtonElement, Props>(({
    active,
    iconSrc = null,
    link,
    onClick = null,
    title,
    iconStyle = {},
    isOneButton = false,
    buttonClass = '',
} : Props, ref) => {
    const classes = useClasses(styles);

    const ButtonComponent = useMemo(() => {
        return (
            <Button
                className={[
                    onClick ? classes.functionalButton : classes.appBarButton,
                    isOneButton ? classes.appBarOneButton : '',
                    !isOneButton && active ? classes.activeAppBarButton : '',
                    buttonClass].join(' ')}
                onClick={onClick ? () => onClick() : null}
                ref={ref}
            >
                <div className={classes.contentContainer}>
                    {
                        iconSrc && (
                            <img
                                alt={title}
                                className={classes.icon}
                                src={iconSrc}
                                style={iconStyle}
                            />
                        )
                    }
                    <div className={classes.buttonTitle}>{title}</div>
                </div>
            </Button>
        );
    }, [classes, active, iconSrc, title, isOneButton, onClick]);

    return link ? (
        <Link to={link} className={classes.a}>{ButtonComponent}</Link>
    ) : ButtonComponent;
});

export default AppBarButton;
