import useClasses from 'hooks/useClasses';
import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from '../../ClusterBodyItem.style';

type Props = {
    temperatureMin: number,
    temperatureRangeMin: number,
    temperatureMax: number,
    temperatureRangeMax: number,
}
const TemperatureInfoBlock = ({
    temperatureMin,
    temperatureRangeMin,
    temperatureMax,
    temperatureRangeMax,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);

    return (
        <>
            {
                temperatureMin !== null && (
                    <div className={
                        temperatureMin < temperatureRangeMin
                            ? classes.redTemperatureInfo
                            : ''
                    }
                    >
                        <span className={classes.temperatureInfoLabel}>
                            {`${t('COMMON.MIN')}`}
                        </span>
                        {`: ${temperatureMin?.toFixed(1)}°C`}
                    </div>
                )
            }
            {
                temperatureMin !== null && temperatureMax !== null && (
                    <div className={classes.slash}>/</div>
                )
            }
            {
                temperatureMax !== null && (
                    <div className={
                        temperatureMax > temperatureRangeMax
                            ? classes.redTemperatureInfo
                            : ''
                    }
                    >
                        <span className={classes.temperatureInfoLabel}>
                            {`${t('COMMON.MAX')}`}
                        </span>
                        {`: ${temperatureMax?.toFixed(1)}°C`}
                    </div>
                )
            }
        </>
    );
};

export default TemperatureInfoBlock;
