import { piCardWidth } from '../../PartsDeliveryInfo';

const styles = (theme) => ({
    title: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: theme.palette.secondary[900],
        opacity: 1,
        marginBottom: '10px',
    },
    comparisonContainer: {
        height: '206px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: piCardWidth,
            boxShadow: 'inset 0px 0px 20px 0px #dbdbdb',
            borderRadius: '13px',
            marginRight: '5px',
        },
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '36px',
        height: '36px',
        paddingTop: '68px',
        marginBottom: '15px',
        opacity: 1,
        boxSizing: 'unset',
    },
    statusMessage: {
        textAlign: 'center',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    horizontalFlex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: 'max-content',
    },
});

export default styles;
