const styles = (theme) => ({
    staticTextStyle: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '13px',
        letterSpacing: '0.005em',
        color: theme?.palette?.common?.black,
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
});

export default styles;
