import moment from 'moment';
import { Asset, Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';

export interface MapElement {
    count: number,
    code: string,
    geolocation: Geolocation,
    longitude?: number,
    latitude?: number,
    iataCode?: string,
    locationName?: string,
    isAirport?: boolean,
}

export interface ExtractedAssetData {
    assetNumber: string,
    assetTypeCode: string,
    uldOwnerCode: string,
    uldType?: string,
    iataCode: string,
    id: number,
    lastMeasuredLongitude: number,
    lastMeasuredLatitude: number,
    locationName: string,
    area: string,
    lastMeasuredTimestamp?: number,
    pairingStatus?: 'PAIRED' | 'NOT_PAIRED',
    areaLyingSinceTimestamp?: number,
    locationLyingSinceTimestamp?: number,
    loggerFamily?: string,
    loggerFamilyLabel?: string,
    loggerNumber?: string,
    temperature: number,
    batteryLevelThreshold?: number,
    batteryLevel?: number,
}

export const extractAssetData = (rawData: Asset[]): ExtractedAssetData[] => {
    return rawData
        .map((item) => {
            const {
                id,
                lastMeasuredData = null,
                assetNumber = '',
                assetTypeCode,
                uldType,
                uldOwnerCode,
                areaLyingSinceTimestamp,
                locationLyingSinceTimestamp,
                loggerNumber,
                pairingStatus,
            } = item;

            const {
                temperatureGeolocationTimestamp,
                geolocation = null,
                locationName = null,
                iataCode,
                area,
                temperature,
                batteryLevel,
            } = lastMeasuredData || {};

            const {
                longitude = null,
                latitude = null,
            } = geolocation || {};

            return {
                id,
                iataCode,
                batteryLevel,
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                assetNumber,
                uldOwnerCode,
                uldType,
                area,
                pairingStatus,
                locationName,
                assetTypeCode,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? moment(temperatureGeolocationTimestamp).utc(true).valueOf()
                    : null,
                areaLyingSinceTimestamp,
                locationLyingSinceTimestamp,
                loggerNumber,
                temperature,
            };
        });
};

export interface ActiveAsset {
    assetNumber: string,
    timestamp: number,
}

export const crossMarkerIconObj = {
    url: icons.hex_with_cross,
    scaledSize: { width: 20, height: 20 },
    origin: { x: 0, y: 0 },
    anchor: { x: 10, y: 10 },
};

export const getMapDataItem = (asset: ExtractedAssetData) => {
    return {
        type: 'Feature',
        properties: {
            marker: true,
            data: asset,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                asset.lastMeasuredLongitude,
                asset.lastMeasuredLatitude,
            ],
        },
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom) => {
    return {
        points: mapData,
        bounds,
        zoom,
        options: {
            radius: 75,
            maxZoom: 15,
            generateId: true,
            map: (item) => {
                return {
                    lastMeasuredTimestamp: item.data.lastMeasuredTimestamp,
                };
            },
            reduce: (data, props) => {
                const { lastMeasuredTimes = [] } = data;

                if (!lastMeasuredTimes.includes(props.lastMeasuredTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTimestamp];
                }
                return data;
            },
        },
    };
};

export type FilterFields = 'assetTypeCode' | 'iataCode' | 'area' | 'uldOwnerCode';

export const getExistedOptions = (
    assets: ExtractedAssetData[], field: FilterFields,
): string[] => {
    return assets.reduce((data, { [field]: currentKey }) => {
        return !currentKey || data.includes(currentKey)
            ? data
            : [...data, currentKey];
    }, []);
};

export const initialAssetFilterOptions: { [optionsGroupKey: string]: string[] } = {
    assetTypeCodes: [],
    iataCodes: [],
    areas: [],
    uldOwnerCodes: [],
    areaLyingSinceTimestamp: [],
    pairingStatuses: [],
    uldTypes: [],
};
