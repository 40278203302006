import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, {
    useCallback,
    useEffect,
    useState,
    ReactNode,
    CSSProperties,
} from 'react';
import {
    ClientSideFilterType,
    ColumnFilterOptions,
    ColumnsType,
    TableMaxHeight,
    ClassNames,
} from './dataTypes';
import {
    getColumnFilterOptions,
    getFilteredData,
    getFilterFieldCount,
} from './lib';
import Table from './Table';
import generateStyles from './Table.style';

const moveElement = (array: ColumnsType[], initialIndex: number, finalIndex: number) => {
    const arrayCopy = [...array];

    arrayCopy.splice(finalIndex, 0, arrayCopy.splice(initialIndex, 1)[0]);
    return arrayCopy;
};

type Props = {
    columns: ColumnsType[],
    data: { [key: string]: any }[],
    equalColumnWidths?: boolean,
    lastRowRef?: (node: any) => void,
    maskForHighlight?: string,
    onRowClick?: (...args: any) => any,
    prepareRowLinkTemplate?: (rowData: any) => string,
    rowLinkTemplate?: string,
    rowExtension?: ReactNode,
    rowExtensionIndex?: number,
    tableMaxHeight?: TableMaxHeight,
    noDataText?: string,
    disableTableHeader?: boolean,
    classNames?: ClassNames,
    title?: string,
    embed?: boolean,
    onRowHover?: (arg: {
        original?: any,
        state?: boolean,
    }) => any,
    style?: CSSProperties,
    onSort?: (columnId: string, direction: 'asc' | 'desc') => void,
    currentSort?: {columnId: string, direction: 'asc' | 'desc'},
}

const TableContainer = ({
    columns,
    data = [],
    equalColumnWidths = false,
    lastRowRef = null,
    maskForHighlight = '',
    onRowClick,
    prepareRowLinkTemplate = null,
    rowLinkTemplate = null,
    rowExtension = null,
    rowExtensionIndex = null,
    tableMaxHeight = '80vh',
    noDataText = null,
    onRowHover = () => {},
    disableTableHeader = false,
    classNames = { },
    title = null,
    embed = false,
    style = {},
    onSort = null,
    currentSort = null,
}: Props) => {
    const [clientSideFilter, setClientSideFilter] = useState<ClientSideFilterType>({});
    const [columnFilterOptions, setColumnFilterOptions] = useState<ColumnFilterOptions>({});
    const [customColumns, setCustomColumns] = useState(columns);
    const [filteredData, setFilteredData] = useState(data);

    const classes = useGeneratedStyles(generateStyles);
    /** Drag&Drop */
    const moveColumn = useCallback((dragIndex: number, hoverIndex: number) => {
        setCustomColumns(moveElement(customColumns, dragIndex, hoverIndex));
    }, [customColumns]);

    useEffect(() => {
        setCustomColumns(columns);
    }, [columns]);

    /** resetting the filter after receiving a new dataset */
    useEffect(() => {
        setFilteredData(data);
        setClientSideFilter({});
    }, [data]);

    /** initial selection of available filter options */
    useEffect(() => {
        setColumnFilterOptions(getColumnFilterOptions(columns, data));
    }, [columns, data]);

    /** selection of available filter options based on filtered data */
    useEffect(() => {
        setColumnFilterOptions(getColumnFilterOptions(columns, filteredData));
    }, [columns, filteredData]);

    /** filtration */
    useEffect(() => {
        if (getFilterFieldCount(clientSideFilter) === 0) {
            setFilteredData(data);
        } else {
            const filteredData = getFilteredData(data, columns, clientSideFilter, true);

            setFilteredData(filteredData);
        }
    }, [clientSideFilter]);

    if (noDataText && data.length === 0) {
        return (
            <div className={[classes.noData, classNames?.noDataContainer || ''].join(' ')}>
                {noDataText}
            </div>
        );
    }

    return (
        <Table
            clientSideFilter={clientSideFilter}
            columns={customColumns}
            columnFilterOptions={columnFilterOptions}
            equalColumnWidths={equalColumnWidths}
            data={filteredData}
            maskForHighlight={maskForHighlight}
            lastRowRef={lastRowRef}
            moveColumn={moveColumn}
            onRowClick={onRowClick}
            prepareRowLinkTemplate={prepareRowLinkTemplate}
            rowLinkTemplate={rowLinkTemplate}
            rowExtension={rowExtension}
            rowExtensionIndex={rowExtensionIndex}
            setClientSideFilter={setClientSideFilter}
            tableMaxHeight={tableMaxHeight}
            disableTableHeader={disableTableHeader}
            classNames={classNames}
            title={title}
            embed={embed}
            style={style}
            onRowHover={onRowHover}
            onSort={onSort}
            currentSort={currentSort}
        />
    );
};

export default TableContainer;
