import React, { ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import useClasses from 'hooks/useClasses';
import styles from './CustomModal.style';

type Props = {
    backdrop?: boolean,
    children: ReactNode,
    open: boolean,
    onClose: () => void,
    title?: string,
    className?: string,
}

const CustomModal = ({
    backdrop = true,
    children = null,
    open,
    onClose = null,
    title = '',
    className = '',
}: Props) => {
    const classes = useClasses(styles);
    const getContainer = () => document.getElementById('content');

    return (
        <Modal
            container={getContainer}
            open={open}
            onClose={onClose}
            BackdropProps={{ style: { zIndex: 41, opacity: backdrop ? '1' : '0' } }}
        >
            <div style={{ zIndex: 42 }} className={`${classes.modalBody} ${className}`}>
                <div className={classes.modalHeader}>
                    <div className={classes.modalHeaderText}>{ title }</div>
                </div>
                <div className={classes.modalData}>
                    { children }
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
