import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme: SkycellThemeInterface) => ({
    root: {
        display: 'flex',
        gap: '10px',
        width: '100%',
        minHeight: '54px',
        borderRadius: '6px',
        background: '#fff',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '16px 16px',
        [theme.breakpoints.down(1500)]: {
            flexDirection: 'column',
        },
    },
    textInfo: {

    },
    divider: {
        flexBasis: '2px',
        background: theme.palette.secondary[300],
        marginInlineStart: '12px',
        marginInlineEnd: '12px',
    },
    options: {
        flex: 2,
        display: 'flex',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    title: {
        width: '144px',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '18px',
        letterSpacing: '0.01em',
        color: theme.palette.secondary[800],
        whiteSpace: 'nowrap',
    },
    switches: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    switch: {
        display: 'flex',
        gap: '12px',
        maxHeight: '20px',
        fontSize: '12px',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
        },
        '& label': {
            userSelect: 'none',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
        },
    },
});
