import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import ProductReleaseDetails from 'ProductReleaseDetails';

import ProductRelease from './ProductRelease';

const ProductReleaseRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
        path: '/product-release',
        element: <ProductRelease />,
    },
    {
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
        path: '/product-release/:shipmentNumber/:productReleaseId',
        element: <ProductReleaseDetails />,
    },
];

export default ProductReleaseRouter;
