import { useTheme } from '@mui/material/styles';
import useAvailableHeight from 'hooks/useAvailableHeight';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Polyline, Marker } from '@react-google-maps/api';
import { LatLng, LatLngAndTime, PolylineAndStepStatus } from 'dataTypes/common';
import { ORDER_STEP_TYPE } from 'shared-components/constants';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import Pagination from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/components/Pagination';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';
import polylineType from 'shared-components/Map/polylineType';
import { crossMarkerIconObj, getCombinedShipmentPolylines, isWithinBounds } from 'TrackAndTrace/lib';
import LocationInfoTooltip from 'shared-components/Map/components/LocationInfoTooltip';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useGetCommonData from 'hooks/useGetCommonData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getQuickHelpAsset } from 'shared-components/icons';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import {
    Geofence,
} from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import CustomPolygon from 'TrackAndTrace/Assets/components/CustomPolygon';
import TooltipCluster from './components/TooltipCluster';
import { GenericShipmentData, getClustersInfo, ClusterInfo } from '../../lib';

type Props = {
    shipments: GenericShipmentData[],
    updateMap: Dispatch<SetStateAction<number>>,
    showAirportsInfo: boolean,
    zoom: number,
    setZoom: (arg: number) => void,
}

export type ActiveShipment = {
    externalId: string,
    timestamp: number
}

const ShipmentsMap = ({
    shipments = [],
    zoom,
    setZoom,
    showAirportsInfo,
}: Props) => {
    const [activeCluster, setActiveCluster] = useState({
        clusterId: null,
        timestamp: Date.now(),
        shipmentNumbers: [],
    });
    const {
        enableTooltips,
        currentStep,
    } = useContext(QuickHelpContext);
    const [mapContainerRef, setMapContainerRef] = useState(null);

    const { t } = useCustomTranslation();
    const theme = useTheme();
    const availableHeight = useAvailableHeight();
    const [activeShipment, setActiveShipment] = useState<ActiveShipment>({
        externalId: null,
        timestamp: Date.now(),
    });
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });
    const [polylineMarkerInfo, setPolylineMarkerInfo] = useState<LatLngAndTime>(null);
    const [polylinePath, setPolylinePath] = useState<LatLng[]>([]);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);

    const [clusters, setClusters] = useState<ClusterInfo[]>([]);
    const [shipmentsBluePolyline, setShipmentsBluePolyline] = useState<PolylineAndStepStatus[][]>([]);
    const [activeShipmentOrangePolyline, setActiveShipmentOrangePolyline] = useState<PolylineAndStepStatus[]>([]);

    const airportsList = useGetAirports();
    const airports = getAirportsInPassedBoundsZone(airportsList, bounds);

    useEffect(() => {
        setPolylinePath([]);
        setPolylineMarkerInfo(null);
    }, [activeShipment]);

    useEffect(() => {
        setActiveShipment({
            externalId: null,
            timestamp: Date.now(),
        });
    }, [shipments]);

    const closeActiveTooltip = useCallback(() => {
        const now = Date.now();

        if (activeShipment.externalId !== null) {
            setActiveShipment({
                externalId: null,
                timestamp: Date.now(),
            });
        }
        if (activeAirportInfo.code !== null) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [
        activeCluster,
        activeShipment,
        activeAirportInfo,
    ]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    const isTour = useMemo(() => currentStep === 3 && enableTooltips, [currentStep, enableTooltips]);

    useEffect(() => {
        if (shipments.length > 0) {
            setClusters(getClustersInfo(shipments));
        } else {
            setClusters([]);
        }
    }, [shipments, activeShipment]);

    useEffect(() => {
        if (activeShipment.externalId !== null && shipments.length > 0 && activeCluster.clusterId !== null) {
            const shipment = shipments.find(item => item.externalId === activeShipment.externalId) || null;
            const { polylines: shipmentPolylines = [] } = shipment || {};

            if (shipmentPolylines.length > 0) {
                setActiveShipmentOrangePolyline(
                    getCombinedShipmentPolylines(shipmentPolylines, polylinePath, shipment.marker),
                );
            }
        } else {
            setActiveShipmentOrangePolyline([]);
        }
    }, [activeShipment, shipments, polylinePath, activeCluster]);

    useEffect(() => {
        if (activeShipment.externalId === null && shipments.length > 0 && clusters.length > 0) {
            const shipmentsInClusterArray: GenericShipmentData[][] = clusters.reduce((data, cluster) => {
                const shipment = shipments.filter(item => cluster.externalIds.includes(item.externalId)) || null;

                return shipment !== null
                    ? [...data, shipment]
                    : data;
            }, []);

            const polylineGroups: PolylineAndStepStatus[][] = shipmentsInClusterArray
                .map(shipmentsCluster => shipmentsCluster
                    .map(({ polylines }) => polylines))
                .flat();

            if (polylineGroups.length > 0) {
                setShipmentsBluePolyline(polylineGroups);
            } else {
                setShipmentsBluePolyline([]);
            }
        } else {
            setShipmentsBluePolyline([]);
        }
    }, [
        activeShipment,
        clusters,
        shipments,
    ]);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);
    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('geofences');

    const filteredGeofences = useMemo(() => {
        if (zoom < 8) return [];

        const expansionInKm = 20;
        const latitudeAdjust = expansionInKm / 111;
        const midLatitude = (bounds[1] + bounds[3]) / 2;
        const longitudeAdjust = expansionInKm / (111 * Math.cos((midLatitude * Math.PI) / 180));

        const expandedBounds = {
            sw: { lat: bounds[1] - latitudeAdjust, lng: bounds[0] - longitudeAdjust },
            ne: { lat: bounds[3] + latitudeAdjust, lng: bounds[2] + longitudeAdjust },
        };

        return geofences.filter(geofence => {
            return geofence.coordinates.some(point => isWithinBounds(point, expandedBounds));
        });
    }, [bounds, geofences, zoom]);

    return (
        <QuickHelpTooltip
            tooltipInfo={{
                order: 3,
                title: t('ONBOARDING.TRACK_AND_TRACE.MAP_ELEMENTS'),
                text: t('ONBOARDING.TRACK_AND_TRACE.MAP_ELEMENTS_DESC'),
                backgroundOpacity: 0.2,
                image: getQuickHelpAsset('map_elements_shipments.gif'),
                isMain: true,
                padding: 0,
                radius: '50%',
                childOffsetPercent: [50, 50],
                customRectSize: [75, 75],
                offsetPx: [55, 57],
                positionDelta: [-20, 0],
                explicitChildRef: mapContainerRef,
                uid: 'shipmentMapTooltip',
            }}
        >

            <BaseMap
                setBounds={setBounds}
                onContainerRefLoad={(ref) => {
                    setMapContainerRef(ref);
                }}
                onClick={closeActiveTooltip}
                gMap={gMap}
                setZoom={setZoom}
                setGMap={setGMap}
                mapContainerStyle={{
                    width: '100%',
                    height: `calc(${availableHeight} - 60px)`,
                    zIndex: 1,
                }}
            >
                {
                    !isTour && clusters.map((cluster, clusterIndex) => {
                        return (
                            <TooltipCluster
                                key={`cluster-${clusterIndex}-${cluster.externalIds.join('_')}`}
                                activeCluster={activeCluster}
                                setActiveCluster={setActiveCluster}
                                shipments={shipments}
                                cluster={cluster}
                                closeActiveTooltip={closeActiveTooltip}
                                activeShipment={activeShipment}
                                setActiveShipment={setActiveShipment}
                                position={cluster.position}
                            />
                        );
                    })
                }
                {
                    isTour && (
                        <TooltipCluster position={{
                            lat: Number.NaN,
                            lng: Number.NaN,
                        }}
                        />
                    )
                }
                {
                    !isTour && polylineMarkerInfo && (
                        <Marker
                            position={polylineMarkerInfo.location}
                            icon={crossMarkerIconObj as google.maps.Icon}
                        />
                    )
                }
                {
                    !isTour && polylineMarkerInfo && (
                        <LocationInfoTooltip
                            location={polylineMarkerInfo.location}
                            time={polylineMarkerInfo.time}
                        />
                    )
                }
                {
                    !isTour && activeShipmentOrangePolyline.length === 0
                    && shipmentsBluePolyline.map(bluePolylineGroup => {
                        return (
                            bluePolylineGroup.map((polyline, polylineIndex) => {
                                return (
                                    <Polyline
                                        key={`polyline${polylineIndex}${
                                        polyline.stepStatus}${polyline
                                            .path.map(p => `${p.lng}_${p.lng}`).join('_')}Blue`}
                                        path={polyline.path}
                                        options={{
                                            ...(polylineType(
                                                polyline.stepStatus !== ORDER_STEP_TYPE.COMPLETED,
                                            )),
                                            strokeColor: theme.palette.primary['deepBlue'],
                                        }}
                                    />
                                );
                            }));
                    })
                }
                {
                    !isTour && activeShipmentOrangePolyline.length !== 0
                && activeShipmentOrangePolyline.map((polyline, polylineIndex) => {
                    return (
                        <Polyline
                            key={`polyline${polylineIndex}${
                                polyline.stepStatus}${polyline
                                    .path.map(p => `${p.lng}_${p.lng}`).join('_')}Orange`}
                            path={polyline.path}
                            options={{
                                ...(polylineType(
                                    polyline.stepStatus !== ORDER_STEP_TYPE.COMPLETED,
                                )),
                                strokeColor: '#EDAE49',
                            }}
                        />
                    );
                })
                }
                {
                    !isTour && showAirportsInfo && airports.map(airport => {
                        const handleClickAirportsInfo = () => {
                            setActiveAirportInfo(prev => {
                                if (Date.now() - prev.timestamp < 300) return prev;
                                return ({
                                    code: prev.code === airport.code
                                        ? null
                                        : airport.code,
                                    timestamp: Date.now(),
                                });
                            });
                        };

                        return (
                            <AirportMarker
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickAirportsInfo();
                                }}
                                isOpen={activeAirportInfo.code === airport.code}
                                airportInfo={airport}
                                key={airport.code}
                            />
                        );
                    })
                }
                {
                    showedAirportInfo !== null && (
                        <AirportInfoTooltip
                            airportInfo={showedAirportInfo}
                            cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                        />
                    )
                }
                {
                    filteredGeofences.map(({ name, coordinates, type }) => {
                        const latLngCoordinates = coordinates
                            .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));

                        const geokey = `geofence-${
                        coordinates.map(({
                            latitude, longitude,
                        }) => `${latitude}-${longitude}`).join('-')}`;

                        return (
                            <CustomPolygon
                                map={gMap}
                                isNameVisible={zoom > 15}
                                systemGeofence={type === 'AIRPORT'}
                                key={geokey}
                                name={name}
                                paths={latLngCoordinates}
                                position={latLngCoordinates[0]}
                            />
                        );
                    })
                }

                <Pagination />
            </BaseMap>
        </QuickHelpTooltip>
    );
};

export default ShipmentsMap;
