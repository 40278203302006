import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

import useClasses from 'hooks/useClasses';
import styles from './Header.style';

type Props = {
    isDashboardPage: boolean,
    toggleBar: any
}

const Header = ({ isDashboardPage, toggleBar }: Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.wrapper}>
            <div className={clsx(
                classes.logoContainer,
                isDashboardPage && classes.mainPage,
            )}
            >
                <div className={classes.burgerHolder}>
                    <Button onClick={toggleBar}>
                        <MenuIcon
                            className={classes.burger}
                        />
                    </Button>
                </div>
                <div className={classes.iconContainer}>
                    <Link to="/welcome">
                        <img
                            className={classes.bigLogo}
                            alt="logo"
                            width="150%"
                            src="/assets/images/logo/logo_white.svg"
                        />
                        <img
                            className={classes.smallLogo}
                            alt="logo"
                            width="100%"
                            src="/assets/images/logo/logo_symbol_white.svg"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Header;
