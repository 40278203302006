import useClasses from 'hooks/useClasses';
import React, {
    ChangeEvent,
    useEffect,
    useState,
} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = theme => ({
    radio: {
        color: theme.palette['mode'] === 'dark' ? `${theme.palette.secondary[200]}!important` : '#61C6E9',
        '&$checked': {
            color: '#61C6E9',
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    },
    checked: {},
    buttonLabel: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    radioItem: {
        padding: '0 9px!important',
    },
});

type Props = {
    availableAnswerOptions: string[],
    defaultValue?: string,
    disabled?: boolean,
    name?: string,
    row?: boolean,
    setAnswerUpdate?: (answerUpdate: string[]) => void,
}

const RadioButtonType = ({
    availableAnswerOptions = [],
    defaultValue = null,
    disabled = false,
    name = 'defaultName',
    row = false,
    setAnswerUpdate = null,
} : Props) => {
    const classes = useClasses(styles);
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (value) {
            setTimeout(() => {
                setAnswerUpdate([value]);
            }, 100);
        }
    }, [value]);

    return (
        <RadioGroup
            aria-labelledby={`${name}-group`}
            name={name}
            value={value}
            onChange={handleChange}
            row={row}
        >
            {
                availableAnswerOptions.map((option) => {
                    return (
                        <FormControlLabel
                            disabled={disabled}
                            control={(
                                <Radio
                                    classes={{
                                        root: classes.radio,
                                        checked: classes.checked,

                                    }}
                                    className={classes.radioItem}
                                    color="default"
                                    disableRipple
                                />
                            )}
                            key={`${name}-${option}`}
                            label={(
                                <div className={classes.buttonLabel}>
                                    {option}
                                </div>
                            )}
                            value={option}
                        />
                    );
                })
            }
        </RadioGroup>
    );
};

export default RadioButtonType;
