import React, { useMemo } from 'react';
import Card from 'shared-components/Card';
import { ContainerPartsDelivery } from 'shared-components/dataTypes';
import { Empty } from 'utils/screenWrap';
import useClasses from 'hooks/useClasses';
import styles from './PartsDeliveryInfoContainer.style';

type Props = {
    partsInfo: ContainerPartsDelivery[],
    title: string,
    horizontal?: boolean
}

const PartsDeliveryInfoContainer = ({
    partsInfo = [],
    title = '',
    horizontal = false,
} : Props) => {
    const classes = useClasses(styles);

    const Wrapper = useMemo(() => {
        return horizontal ? Empty : Card;
    }, [horizontal]);

    return (
        <Wrapper fullHeight>
            <div className={classes.cardTitle}>{title}</div>
            <div className={horizontal ? classes.horizontalFlex : ''}>
                {
                    partsInfo.map((part, partIndex) => (
                        <div key={`${title}-${partIndex}`} className={classes.row}>
                            <img
                                className={classes.picture}
                                src={part.picture}
                                alt={`seal number ${title}`}
                            />
                            <div className={classes.textData}>
                                <div className={classes.fieldTitle}>Picture Timestamp</div>
                                <div className={classes.fieldInfo}>{part.pictureTimestamp}</div>
                                <div className={classes.fieldTitle}>Taken By</div>
                                <div className={classes.fieldInfo}>{part.takenBy}</div>
                                {
                                    part.side && (
                                        <div>
                                            <div className={classes.fieldTitle}>Side</div>
                                            <div className={classes.fieldInfo}>{part.side}</div>
                                        </div>
                                    )
                                }
                                {
                                    part.comment && (
                                        <div>
                                            <div className={classes.fieldTitle}>Comment</div>
                                            <div className={classes.fieldInfo}>{part.comment}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </Wrapper>
    );
};

export default PartsDeliveryInfoContainer;
