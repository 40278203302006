export const styles = (theme) => ({
    commonStepTable: {
        tableLayout: 'fixed',
        '& th, & td': {
            padding: '7px 9px',
        },
        transition: 'filter 200ms ease',
    },
    commonStepTableRow: {
        '& th': {
            color: theme.palette.secondary[600],
            font: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
        },
        '& td': {
            color: theme.palette.secondary[600],
            font: 'Roboto',
            fontSize: '16px',
            letterSpacing: 0.49,
        },

        '&:hover $icon': {
            visibility: 'visible',
        },
        '& $icon': {
            visibility: 'hidden',
        },
        '& $icon$active': {
            visibility: 'visible',
        },
    },
    invalid: {
        opacity: 0.5,
        cursor: 'not-allowed!important',
    },
    active: {

    },
    tableLoading: {
        filter: 'blur(2px)',
        pointerEvents: 'none',
    },
    commonStepTableParent: {
        height: '100%',
    },
    plusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectShade: {
        background: theme.palette.primary[30],
    },
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[800],
        },
    },
    plus: {
        padding: '10px',
        width: 'min-content',
    },
    buttonsBottom: {
        marginBottom: '10px',
    },

});
