import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LyingSince from 'shared-components/LyingSince/LyingSince';
import LastConnect from 'shared-components/LastConnect';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import EnergyLevel from 'shared-components/EnergyLevel';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';

const tableColumns: (t) => ColumnsType<ExtractedAssetData>[] = (t) => [
    {
        Header: t('TRACK_AND_TRACE.ASSET'),
        accessor: 'assetNumber',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.ASSET_TYPE'),
        accessor: ({ assetTypeCode }) => (assetTypeCode ? t(`ASSET_TYPE_LABEL.${assetTypeCode}`) : ''),
        id: 'assetTypeCode',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.ULD_TYPE'),
        accessor: 'uldType',
        id: 'uldType',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.ULD_OWNER_CODE'),
        accessor: 'uldOwnerCode',
        id: 'uldOwnerCode',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LOCATION_NAME'),
        accessor: (row) => (
            <UncontrolledTooltip
                description={row.locationName}
                enabled={row.locationName?.length >= 21}
                shrinked
                placement="right"
            >
                <div style={{
                    textAlign: 'left',
                    font: 'normal normal normal 12px/14px Roboto',
                    letterSpacing: '0.43px',
                    color: '#000',
                    opacity: 1,
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                >
                    {row.locationName?.length > 21 ? `${row.locationName.slice(0, 21)}...` : row.locationName}
                </div>
            </UncontrolledTooltip>
        ),
        id: 'locationName',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LOCATION_LYING_TIME'),
        accessor: (row) => (!row.locationLyingSinceTimestamp ? ' '
            : <LyingSince timestamp={row.locationLyingSinceTimestamp} />),
        dataType: 'custom',
        id: 'locationLyingSinceTimestamp',
        sortType: (rowA, rowB) => rowB.original.locationLyingSinceTimestamp - rowA.original.locationLyingSinceTimestamp,
    },
    {
        Header: t('TRACK_AND_TRACE.AREA'),
        accessor: (row) => (
            <UncontrolledTooltip
                description={row.area}
                enabled={row.area?.length >= 21}
                shrinked
                placement="right"
            >
                <div style={{
                    textAlign: 'left',
                    font: 'normal normal normal 12px/14px Roboto',
                    letterSpacing: '0.43px',
                    color: '#000',
                    opacity: 1,
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                >
                    {row.area?.length > 21 ? `${row.area.slice(0, 21)}...` : row.area}
                </div>
            </UncontrolledTooltip>
        ),
        id: 'area',
        dataType: 'custom',
    },
    {
        Header: t('TRACK_AND_TRACE.AREA_LYING_TIME'),
        accessor: (row) => (!row.areaLyingSinceTimestamp ? ' '
            : <LyingSince timestamp={row.areaLyingSinceTimestamp} />),
        dataType: 'custom',
        id: 'areaLyingSinceTimestamp',
        sortType: (rowA, rowB) => rowB.original.areaLyingSinceTimestamp - rowA.original.areaLyingSinceTimestamp,
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => (row.temperature ? `${Number(row?.temperature?.toFixed(1))}°C`
            : row.temperature === 0 ? '0°C' : ''),
        dataType: 'text',
        id: 'temperature',
    },
    {
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => (!row.lastMeasuredTimestamp ? ' '
            : <LastConnect timestamp={row.lastMeasuredTimestamp} />),
        dataType: 'custom',
        id: 'temperatureGeolocationTimestamp',
        sortType: (rowA, rowB) => rowB.original.lastMeasuredTimestamp - rowA.original.lastMeasuredTimestamp,
    },
    {
        id: 'paringStatuses',
        Header: t('COMMON.PAIRING_STATUS'),
        accessor: (row) => t(`PAIRING_STATUS.${row.pairingStatus}`),
        dataType: 'highlightedText',
        disableSortBy: true,
    },
    {
        Header: t('COMMON.CURRENT_LOGGER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
    {
        id: 'batteryLevel',
        Header: t('COMMON.LOGGER_BATTERY'),
        accessor: (row) => (
            (
                row.batteryLevel >= 0 && row.batteryLevel <= 100 && row.batteryLevel !== null ? (
                    <EnergyLevel
                        threshold
                        energyLevel={Number(((row.batteryLevel) * 100).toFixed(0))}
                    />
                ) : ''
            )),
        dataType: 'custom',
        customCellStyle: {
            whiteSpace: 'pre-wrap',
            alignItems: 'center',
            maxWidth: '230px',
        },
    },
];

export default tableColumns;
