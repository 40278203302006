import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StandardItem } from 'shared-components/menuItems';
import { ArrowBackIosNew } from '@mui/icons-material';
import generateStyles from './MenuItemFloatingChild.style';

type Props = {
    childLinks: StandardItem[],
    currentLink: string,
    title: string,
    setChildLinkFocused: any,
}

const MenuItemFloatingChildren = ({
    childLinks,
    currentLink,
    title,
    setChildLinkFocused,
}:Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const navigate = useNavigate();
    const childClicked = useCallback((link) => {
        navigate(`/${link.link}`);
        setTimeout(() => setChildLinkFocused(false), 100);
    }, []);

    return (
        <div
            className={classes.floatingMenu}
            onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
            onMouseLeave={() => setChildLinkFocused(false)}
        >
            <div className={classes.title}>
                {title}
            </div>
            {
                childLinks.map((link, index) => {
                    const isActive = link.link === currentLink;

                    return (
                        <div
                            className={[classes.floatingItem].join(' ')}
                            style={{
                                animationDelay: `${index * 70 + 100}ms`,
                            }}
                            key={`link_to_${link.link}`}
                            onClick={() => childClicked(link)}
                        >
                            {link.title}
                            {
                                isActive && (
                                    <span>
                                        <ArrowBackIosNew />
                                    </span>
                                )
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

export default MenuItemFloatingChildren;
