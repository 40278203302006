const styles = (theme) => ({
    capabilities: {
        display: 'grid',
        gridAutoColumns: 'minmax(0, 1fr)',
        gridAutoFlow: 'column',
        gridGap: '12px',

        [theme.breakpoints.down(1400)]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
            gridGap: '12px',
            gridAutoColumns: 'unset',
            gridAutoFlow: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
        },
    },
    summary: {
        gridArea: 'summary',
    },
    categorySummary: {
        gridArea: 'categorySummary',
    },
    categorySummary1: {
        gridArea: 'categorySummary1',
    },
    categorySummary2: {
        gridArea: 'categorySummary2',
    },
    categorySummary3: {
        gridArea: 'categorySummary3',
    },
    categorySummary4: {
        gridArea: 'categorySummary4',
    },
    categorySummary5: {
        gridArea: 'categorySummary5',
    },
    overviewFooter: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
});

export default styles;
