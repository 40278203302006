import useClasses from 'hooks/useClasses';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddCircle from '@mui/icons-material/AddCircle';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { PharmaSite } from 'dataTypes/SecureBackend/apiResponse';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from 'MyCompany/Sites/Sites.style';
import tableColumns from './tableColumns';

type Props = {
    className?: string,
    pharmaCompanyId?: number | unknown,
}

const PharmaCompanySites = forwardRef<HTMLDivElement, Props>(({ className = '' }: Props, ref) => {
    const { GetAll: getPharmaSites } = useSecureBackendEndpoints('pharma-sites').requests;

    const [data, setData] = useState([]);
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);

    useEffect(() => {
        (async () => {
            try {
                const response = (await getPharmaSites()).data;
                const pharmaSites: PharmaSite[] = response?.resultList || [];
                const updatedData = pharmaSites.map(pharmaSite => ({
                    id: pharmaSite.id,
                    name: pharmaSite.name,
                    city: pharmaSite.address.city,
                    country: pharmaSite.address.country.name,
                }));

                setData(updatedData);
            } catch (error) {
                setData([]);
                global.console.log(error);
            }
        })();
    }, []);

    return (
        <Card
            className={className}
            title={t('COMMON.SITES')}
            ref={ref}
            zeroSidePadding
        >
            <Table
                data={data}
                columns={tableColumns(t)}
                rowLinkTemplate="/my-company/sites/:id/overview"
                tableMaxHeight="300px"
                embed
                classNames={{
                    bodyCell: classes.cell,
                    headerCell: classes.hcell,
                }}

            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '11px 13px 0',
                }}
            >
                <Link to="/my-company/sites/new">
                    <AddCircle fontSize="small" color="action" />
                </Link>
            </div>
        </Card>
    );
});

export default PharmaCompanySites;
