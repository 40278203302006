import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import Companies from './Companies';
import AddCompany from './Companies/tabs/AddCompany';
import EditCompany from './Companies/tabs/EditCompany';
import ServiceProviderSites from './ServiceProviderSites';
import AddServiceProviderSite from './ServiceProviderSites/tabs/AddServiceProviderSite';
import EditServiceProviderSite from './ServiceProviderSites/tabs/EditServiceProviderSite';
import PharmaSites from './PharmaSites';
import AddPharmaSite from './PharmaSites/tabs/AddPharmaSite';
import EditPharmaSite from './PharmaSites/tabs/EditPharmaSite';
import Users from './Users';
import AddUser from './Users/tabs/AddUser';
import EditUser from './Users/tabs/EditUser';
import Shipments from './Shipments';
import ShipmentDetail from './Shipments/tabs/ShipmentDetail';
import ShipmentAdmin from './Shipments/tabs/ShipmentAdmin';

const base = '/administration';

const AdministrationRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: base,
        element: <Navigate to="/administration/companies" />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies`,
        element: <Companies />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies/add`,
        element: <AddCompany />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies/:companyId`,
        element: <EditCompany />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites`,
        element: <ServiceProviderSites />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites/add`,
        element: <AddServiceProviderSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites/:serviceProviderSiteId`,
        element: <EditServiceProviderSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites`,
        element: <PharmaSites />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites/add`,
        element: <AddPharmaSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites/:pharmaSiteId`,
        element: <EditPharmaSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users`,
        element: <Users />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users/add`,
        element: <AddUser />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users/:userId`,
        element: <EditUser admin />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments`,
        element: <Shipments />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments/:id`,
        element: <ShipmentDetail />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments/:id/admin`,
        element: <ShipmentAdmin />,
    },
];

export default AdministrationRouter;
