import { IconButton, TextField, useTheme } from '@mui/material';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, {
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Clear } from '@mui/icons-material';
import { containsSpecificLetters, highlight, replaceSpecificLetters } from 'utils/stringTool';
import useScreenSize from 'hooks/useScreenSize';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import generateStyles from './SelectFieldSearchClient.style';

type Props = {
    label?: string,
    value?: number | string,
    onChange?: (value: string, id?: string) => void,
    className?: string,
    filterName: string,
    url: string,
    readOnly?: boolean,
    filterList?: string[],
    font?: 'small' | 'medium',
    mandatory?: boolean,
}

const SelectFieldSearchClient = ({
    label,
    value,
    onChange,
    className,
    url,
    filterName,
    readOnly = false,
    filterList,
    font = null,
    mandatory = false,
}: Props) => {
    const { GetAll: getDataRequest } = useSecureBackendEndpoints(url).requests;

    const { data = [], isLoading: listLoading } = useCachedQueryRequest({
        key: url,
        request: getDataRequest,
        onCompleted: it => it?.data?.resultList,
    });
    const [filteredData, setFilteredData] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [searchVisible, setSearchVisible] = useState(false);
    const inputRef = useRef(null);
    const { width } = useScreenSize();
    const [drawerWidth, setDrawerWidth] = useState(0);
    const theme = useTheme<SkycellThemeInterface>();
    const classes = useGeneratedStyles(generateStyles);

    useEffect(() => {
        if (!readOnly) {
            const toggle = () => setSearchVisible(false);

            document.addEventListener('click', toggle);
            return () => {
                document.removeEventListener('click', toggle);
            };
        }
    }, []);

    useEffect(() => {
        setDrawerWidth(inputRef?.current?.scrollWidth || 0);
    }, [width, inputRef]);

    useEffect(() => {
        if (!searchString) {
            setFilteredData(data);
        } else {
            const searchStringLower = searchString.toLowerCase();
            const filtered = data.filter(item => {
                const { [filterName]: itemString = '' } = item || {};

                const itemStringToCheck = containsSpecificLetters(searchStringLower)
                    ? itemString.toLowerCase()
                    : replaceSpecificLetters(itemString.toLowerCase());

                return itemStringToCheck.includes(searchStringLower);
            });

            setFilteredData(filtered);
        }
    }, [searchString, data.length]);

    useEffect(() => {
        if (value && data) {
            setSearchString(data?.find(it => it?.id === value)?.[filterName]);
        }
    }, [value, data]);

    const handleTextFieldInput = useCallback((event) => {
        setSearchString(event.target.value);
    }, []);

    const handleTextFieldClick = useCallback((event: MouseEvent) => {
        event.stopPropagation();

        if (!readOnly) {
            setSearchVisible(true);
        }
    }, []);

    const itemClicked = useCallback((id, object) => {
        onChange(id, object);
        setSearchVisible(false);
    }, []);

    const clearSearchString = useCallback((e) => {
        e.stopPropagation();
        setSearchString('');
        setSearchVisible(false);
        onChange(null);
    }, []);

    return (
        <div className={[
            classes.searchContainer,
            font === 'small' ? classes.smallFont : '',
            font === 'medium' ? classes.mediumFont : '',
        ].join(' ')}
        >
            <TextField
                className={className}
                label={label}
                style={{
                    width: '100%',
                    pointerEvents: readOnly ? 'none' : 'unset',
                }}
                InputLabelProps={{ shrink: true }}
                size="medium"
                onInput={handleTextFieldInput}
                onClick={handleTextFieldClick}
                InputProps={{
                    ref: inputRef,
                    readOnly: !!value || readOnly,
                    disableUnderline: readOnly,
                    style: {
                        color: theme.palette.common.black,
                    },
                    endAdornment: !readOnly && (
                        <IconButton
                            className={classes.iconButton}
                            onClick={clearSearchString}
                        >
                            <Clear className={classes.icon} />
                        </IconButton>
                    ),
                }}
                value={searchString}
                placeholder=""
                variant="standard"
                required={mandatory}
            />

            {
                !readOnly && (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        className={classes.dummyHolder}
                    >
                        <ul
                            className={[
                                classes.itemsList,
                                listLoading ? classes.listLoading : '',
                                searchVisible ? classes.visible : '',
                            ].join(' ')}
                            style={{ width: drawerWidth || 'auto' }}
                        >
                            {
                                filteredData.filter(item => {
                                    if (!filterList) return true;
                                    return Object.keys(filterList)
                                        .map(key => item[key] === filterList[key])
                                        .reduce((a, b) => a && b, true);
                                }).map((item) => {
                                    const handleClick = () => itemClicked(item?.id, item);

                                    return (
                                        <li
                                            key={`${label}_${item.id}`}
                                            className={classes.listItem}
                                        >
                                            <div onClick={handleClick}>
                                                {highlight(item?.[filterName], searchString, '#f0debf', true)}
                                            </div>
                                        </li>
                                    );
                                })
                            }
                            {
                                data.length === 0 && (
                                    <li className={[classes.listItem, classes.appearingLi].join(' ')}>
                                        No Results
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                )
            }
        </div>

    );
};

export default SelectFieldSearchClient;
