import React from 'react';
import ReactDOMServer from 'react-dom/server';
import useClasses from 'hooks/useClasses';
import styles from './CustomMapHintTooltip.style';

const CustomTooltip = () => {
    const classes = useClasses(styles);

    return (
        <div className={classes.staticTextStyle} />
    );
};

const getTooltipAsHTML = () => {
    return ReactDOMServer.renderToString(<CustomTooltip />);
};

export default getTooltipAsHTML;
