import React, { useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import TitleHeader from 'TitleHeader';
import BackToLink from 'shared-components/BackToLink';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import useClasses from 'hooks/useClasses';
import { ProductReleaseSeparate } from './lib';
import Temperature from './components/Temperature';
import TemperatureReadout from './components/TemperatureReadout';
import PartsDeliveryInfo from './components/PartsDeliveryInfo';
import ApprovalSummary from './components/ApprovalSummary';
import DeliveryPointInfo from './components/DeliveryPointInfo';
import styles from './ProductReleaseDetails.style';

type Props = {
    productRelease?: ProductReleaseSeparate,
    setRequiredOrderDataUpdate: (requiredOrderDataUpdate: boolean) => void,
    shipment: ShipmentDetailsInterface,
    shipmentNumber: string,
}

const ProductReleaseDetails = ({
    productRelease,
    setRequiredOrderDataUpdate,
    shipment = null,
    shipmentNumber,
} : Props) => {
    const classes = useClasses(styles);
    const { checkScreenType } = useScreenSize();
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { t } = useCustomTranslation();

    const {
        containerOpenedOn = '',
        containerSerialNumber,
        palletCode = null,
        palletCode1 = null,
        palletCode2 = null,
        temperatureRangeMin,
        temperatureRangeMax,
        temperatureExcursionOn,
        temperatureReadout,
        sealPartsInfo,
        packagingPartsInfo,
        palletPartsInfo,
        pallet1PartsInfo,
        pallet2PartsInfo,
        order,
        id,
        sealApprovalsIcon,
        containerApprovalsIcon,
        palletApprovalsIcon,
        pallet1ApprovalsIcon,
        pallet2ApprovalsIcon,
        containerApprovalsIconTooltipInfo,
        sealApprovalsIconTooltipInfo,
        temperatureReadoutTooltipInfo,
        palletApprovalsIconTooltipInfo,
        pallet1ApprovalsIconTooltipInfo,
        pallet2ApprovalsIconTooltipInfo,
        qmApproval,
    } = productRelease;

    const {
        deliveryPointsInfo,
    } = order;

    const {
        shipmentSensorDataTimeRange,
        shipmentEnd,
        shipmentStart,
        endGeolocation = null,
        startGeolocation = null,
    } = shipment || {};

    const title = `${t('COMMON.PACKAGING')} ${containerSerialNumber}`;
    const palletsTitleInfo = palletCode1
        ? ` | ${t('ASSET_TYPE_LABEL.PALLET')}${palletCode2 ? 's ' : ''}\
        ${palletCode1}${palletCode2 ? `, ${palletCode2}` : ''}`
        : '';

    useEffect(() => {
        setBreadcrumbData('PRODUCT_RELEASE_TITLE', `${t('COMMON.SHIPMENT')} ${shipmentNumber} | ${title}`);
    }, []);

    const partsInfoTitles = useMemo(() => {
        return {
            seal: [
                t('PRODUCT_RELEASE.SEAL_ORIGIN'),
                t('PRODUCT_RELEASE.SEAL_DESTINATION'),
                t('PRODUCT_RELEASE.SEAL_APPROVALS'),
            ],
            packaging: [
                t('PRODUCT_RELEASE.PACKAGING_ORIGIN'),
                t('PRODUCT_RELEASE.PACKAGING_DESTINATION'),
                t('PRODUCT_RELEASE.PACKAGING_APPROVALS'),
            ],
            pallet: [
                t('PRODUCT_RELEASE.PALLET_ORIGIN'),
                t('PRODUCT_RELEASE.PALLET_DESTINATION'),
                t('PRODUCT_RELEASE.PALLET_APPROVALS'),
            ],
            pallet1: [
                t('PRODUCT_RELEASE.PALLET_1_ORIGIN'),
                t('PRODUCT_RELEASE.PALLET_1_DESTINATION'),
                t('PRODUCT_RELEASE.PALLET_1_APPROVALS'),
            ],
            pallet2: [
                t('PRODUCT_RELEASE.PALLET_2_ORIGIN'),
                t('PRODUCT_RELEASE.PALLET_2_DESTINATION'),
                t('PRODUCT_RELEASE.PALLET_2_APPROVALS'),
            ],
        };
    }, []);

    return (
        <div style={{ padding: '12px' }}>
            <TitleHeader>{`${t('PRODUCT_RELEASE.APPROVAL_DOCUMENTATION')} ${title}${palletsTitleInfo}`}</TitleHeader>
            <div className={classes.root}>
                <Grid
                    container
                    spacing={2}
                    className={classes.gridContainer}
                >
                    <Grid item lg={4} md={6} xs={12}>
                        <DeliveryPointInfo
                            option="LoadingInfo"
                            point={deliveryPointsInfo.handoverPoint}
                            leaseDate={shipmentStart}
                            shipmentDate={shipmentStart}
                            geolocation={startGeolocation}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <DeliveryPointInfo
                            option="UnloadingInfo"
                            point={deliveryPointsInfo.collectionPoint}
                            leaseDate={shipmentEnd}
                            shipmentDate={shipmentEnd}
                            geolocation={endGeolocation}
                        />
                    </Grid>
                    <Grid item lg={4} md={12} xs={12}>
                        <ApprovalSummary
                            orderNumber={shipmentNumber}
                            serialNumber={containerSerialNumber}
                            palletCode={palletCode}
                            palletCode1={palletCode1}
                            palletCode2={palletCode2}
                            temperatureIcon={temperatureReadout.icon}
                            sealApprovalsIcon={sealApprovalsIcon}
                            containerApprovalsIcon={containerApprovalsIcon}
                            palletApprovalsIcon={palletApprovalsIcon}
                            pallet1ApprovalsIcon={pallet1ApprovalsIcon}
                            pallet2ApprovalsIcon={pallet2ApprovalsIcon}
                            containerApprovalsIconTooltipInfo={containerApprovalsIconTooltipInfo}
                            sealApprovalsIconTooltipInfo={sealApprovalsIconTooltipInfo}
                            temperatureReadoutTooltipInfo={temperatureReadoutTooltipInfo}
                            palletApprovalsIconTooltipInfo={palletApprovalsIconTooltipInfo}
                            pallet1ApprovalsIconTooltipInfo={pallet1ApprovalsIconTooltipInfo}
                            pallet2ApprovalsIconTooltipInfo={pallet2ApprovalsIconTooltipInfo}
                            qmApproval={qmApproval}
                            productReleaseId={id}
                            dataUpdateTrigger={setRequiredOrderDataUpdate}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={classes.gridContainer}
                >
                    <Grid item lg={8} xs={12}>
                        <Temperature
                            serialNumber={containerSerialNumber}
                            shipmentNumber={shipmentNumber}
                            dateTimeFrom={shipmentSensorDataTimeRange?.from}
                            dateTimeTo={shipmentSensorDataTimeRange?.to}
                            leaseEndExpected={shipmentEnd}
                            leaseStart={shipmentStart}
                            containerOpenedOn={containerOpenedOn}
                            temperatureRangeMin={temperatureRangeMin}
                            temperatureRangeMax={temperatureRangeMax}
                            temperatureExcursionOn={temperatureExcursionOn}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <TemperatureReadout approvalInfo={temperatureReadout} />
                    </Grid>
                </Grid>
                {
                    sealPartsInfo.comparisonMatchingInfo.length > 0 && (
                        <PartsDeliveryInfo
                            mobileView={checkScreenType.down(ScreenType.MD)}
                            packagingPartsInfo={sealPartsInfo}
                            titles={partsInfoTitles.seal}
                        />
                    )
                }
                {
                    packagingPartsInfo.comparisonMatchingInfo.length > 0 && (
                        <PartsDeliveryInfo
                            mobileView={checkScreenType.down(ScreenType.MD)}
                            packagingPartsInfo={packagingPartsInfo}
                            titles={partsInfoTitles.packaging}
                        />
                    )
                }
                {
                    palletPartsInfo.comparisonMatchingInfo.length > 0 && (
                        <PartsDeliveryInfo
                            mobileView={checkScreenType.down(ScreenType.MD)}
                            packagingPartsInfo={palletPartsInfo}
                            titles={partsInfoTitles.pallet}
                        />
                    )
                }
                {
                    pallet1PartsInfo.comparisonMatchingInfo.length > 0 && (
                        <PartsDeliveryInfo
                            mobileView={checkScreenType.down(ScreenType.MD)}
                            packagingPartsInfo={pallet1PartsInfo}
                            titles={partsInfoTitles.pallet1}
                        />
                    )
                }
                {
                    pallet2PartsInfo.comparisonMatchingInfo.length > 0 && (
                        <PartsDeliveryInfo
                            mobileView={checkScreenType.down(ScreenType.MD)}
                            packagingPartsInfo={pallet2PartsInfo}
                            titles={partsInfoTitles.pallet2}
                        />
                    )
                }
            </div>
            <div className={classes.row}>
                <BackToLink />
                <BackToLink
                    title={t('PRODUCT_RELEASE.BACK_TO_SHIPMENT')}
                    to={`/track-and-trace/shipments/${shipmentNumber}`}
                />
                <BackToLink
                    title={t('PRODUCT_RELEASE.BACK_TO_PRODUCT_RELEASES')}
                    to="/product-release"
                />
            </div>
        </div>
    );
};

export default ProductReleaseDetails;
