import React from 'react';
import Card from 'shared-components/Card';
import useClasses from 'hooks/useClasses';

const styles = {
    contentClass: {
        width: '100%',
        height: '100%',
    },
};
const PlaceBookings = () => {
    const classes = useClasses(styles);

    return (
        <Card fullHeight zeroSidePadding zeroVerticalPadding contentClass={classes.contentClass}>
            <iframe
                title="Place Booking"
                width="100%"
                height="100%"
                src="https://skycellbooking.dev.skymind.com/lanes"
                frameBorder="0"
                allowFullScreen
            />
        </Card>
    );
};

export default PlaceBookings;
