import { useQuery } from 'react-query';
import { useRef } from 'react';

const useCachedQueryRequest = ({
    key,
    request,
    onCompleted = it => it?.data,
    options = {},
}) => {
    const previousDataRef = useRef();

    return useQuery({
        queryKey: [
            key, options,
        ],
        queryFn: () => request()?.then(onCompleted),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 1000 * 60,
        initialData: previousDataRef.current,
        onSuccess: (data) => {
            previousDataRef.current = data;
        },
        ...options,
    });
};

export default useCachedQueryRequest;
