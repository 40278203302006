import React from 'react';
import useClasses from 'hooks/useClasses';
import styles from './OfferingsSelection.style';

type Props = {
    label: string,
    src: string,
}

const LabelComponent = ({
    label,
    src,
} : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.container}>
            <img
                alt={label}
                className={classes.icon}
                src={src}
            />
            <div>{label}</div>
        </div>
    );
};

export default LabelComponent;
