import React, { ReactNode } from 'react';
import TableRow from '@mui/material/TableRow';

import useClasses from 'hooks/useClasses';
import styles from './LinkedTableRow.style';

type Props = {
    children: ReactNode | ReactNode[],
    lastRowRef?: (node: any) => void,
    onClick?: (...args: any) => any,
    linkedTableRowProps?: any,
    isCursorPointer?: boolean,
    className?: string,
}

const LinkedTableRow = ({
    children = null,
    lastRowRef = null,
    linkedTableRowProps = null,
    onClick = null,
    isCursorPointer = false,
    className = '',
}: Props) => {
    const classes = useClasses(styles);

    return (
        <TableRow
            {...linkedTableRowProps}
            ref={lastRowRef}
            className={[
                isCursorPointer ? classes.linkedTableRowWithPointer : classes.linkedTableRow,
                className,
            ].join(' ')}
            onClick={onClick}
            hover
        >
            {children}
        </TableRow>
    );
};

export default LinkedTableRow;
