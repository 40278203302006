import React, {
    useState,
    useEffect, useContext,
} from 'react';
import { Company, User } from 'dataTypes/SecureBackend/apiResponse';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import UserInfoCard from 'Administration/Users/components/UserInfoCard';
import { LayoutContext } from 'Contexts/LayoutContext';
import {
    extractOnlyNotNullFields,
    isReadyAddUserRequestBody,
} from 'Administration/lib';
import useClasses from 'hooks/useClasses';
import NotificationChannelSettingsCard from './components/NotificationChannelSettingsCard';
import styles from './MyProfile.style';

const initialUser: User = {
    businessPhone: '',
    companyId: null,
    companyName: '',
    email: '',
    fullName: {
        firstName: '',
        lastName: '',
    },
    id: '',
    jobCategory: '',
    jobTitle: '',
    mobilePhone: '',
    userRoles: [],
};

type Props = {
    admin?: boolean,
}
const MyProfile = ({
    admin = false,
}:Props) => {
    const classes = useClasses(styles);
    const { Get: getCompany } = useSecureBackendEndpoints('companies').requests;
    const { Get: getUsers } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}users/email`).requests;
    const { FlexibleRequest: updateInfo } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}users`)
        .statusNotificationRequests;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { userInfo: currentUserInfo = null } = useCurrentUserContext();

    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [companyResponse, setCompanyResponse] = useState<Company>(null);
    const [userResponse, setUserResponse] = useState<User>(initialUser);
    const [userInfo, setUserInfo] = useState<AddUserInfo>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);
    const [loading, setLoading] = useState(false);
    const {
        setMenuTabs,
        setCustomPageTitle,
    } = useContext(LayoutContext);

    const requestToSaveUserInfo = async () => {
        const {
            status = null,
        } = await updateInfo('PATCH', `/${userResponse.id}/overview-info`, extractOnlyNotNullFields(userInfo));

        if (status === 200) {
            setRequiredUpdateData(true);
            setCustomPageTitle(`${userInfo.firstName} ${userInfo.lastName}`);
        }
    };

    const requestToSaveRole = async () => {
        const { status = null } = await updateInfo('PATCH', `/${userResponse.id}/roles`, roles);

        if (status === 200) {
            setRequiredUpdateData(true);
        }
    };

    useEffect(() => {
        if (userResponse.id === initialUser.id || requiredUpdateData) {
            setLoading(true);
            (async () => {
                try {
                    const userResponse: User = await (await getUsers(currentUserInfo.email)).data || null;

                    if (userResponse) {
                        setUserResponse(userResponse);
                        setRoles(userResponse.userRoles);
                        setBreadcrumbData('customBreadcrumb',
                            `${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`);
                        setCustomPageTitle(
                            `${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`,
                        );
                    }
                } catch (error) {
                    setUserResponse(initialUser);
                } finally {
                    setLoading(false);
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData, setCustomPageTitle]);

    useEffect(() => {
        setMenuTabs([
            {
                title: 'My Profile',
                link: '/my-profile',
                iconSrc: '',
                activePage: true,
            },
            {
                title: 'Notifications',
                link: '/notifications',
                iconSrc: '',
                activePage: false,
            },
        ]);
        setCustomPageTitle(currentUserInfo.name);
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, []);

    useEffect(() => {
        return () => setCustomPageTitle('');
    }, []);
    useEffect(() => {
        if (userResponse.companyId && !companyResponse) {
            setLoading(true);
            (async () => {
                try {
                    const companyResponse: Company = await (await getCompany(userResponse.companyId)).data || null;

                    if (companyResponse) {
                        setCompanyResponse(companyResponse);
                    }
                } catch (error) {
                    setCompanyResponse(null);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [userResponse]);

    return (
        <>
            <div className={classes.pageContent}>
                <UserInfoCard
                    currentBusinessPhone={userResponse.businessPhone}
                    currentCompany={companyResponse}
                    currentEmail={userResponse.email}
                    currentFirstName={userResponse.fullName.firstName}
                    currentLastName={userResponse.fullName.lastName}
                    currentJobCategory={userResponse.jobCategory}
                    currentJobTitle={userResponse.jobTitle}
                    currentMobilePhone={userResponse.mobilePhone}
                    currentEditedCard={currentEditedCard}
                    disabledSaveButton={!isReadyAddUserRequestBody(userInfo)}
                    setCurrentEditedCard={setCurrentEditedCard}
                    setUserInfo={setUserInfo}
                    saveInfo={requestToSaveUserInfo}
                    type="editable"
                    admin={admin}
                />
                <CompanyRolesCard
                    currentRoles={userResponse.userRoles}
                    currentEditedCard={currentEditedCard}
                    setCurrentEditedCard={setCurrentEditedCard}
                    setRoles={setRoles}
                    roles={roles}
                    saveInfo={requestToSaveRole}
                    availableRoles={companyResponse?.companyRoles || []}
                    type="editable"
                />
                { /*
                isCurrentUserAdmin && (
                    <UserAccountCard
                        admin={admin}
                        disableUserButtonDisabled={false}
                        resetPasswordButtonDisabled={false}
                        selectedUserInfo={userResponse}
                        setRequiredUpdateData={setRequiredUpdateData}
                        companyUsers={companyUsers}
                    />
                )
           */ }
            </div>
            <div>
                <NotificationChannelSettingsCard
                    setCurrentEditedCard={setCurrentEditedCard}
                    currentEditedCard={currentEditedCard}
                    loading={!userResponse.notificationSettings || loading}
                    setLoading={setLoading}
                    notificationSettings={userResponse.notificationSettings || []}
                    setRequiredUpdateData={setRequiredUpdateData}
                />

            </div>
        </>
    );
};

export default MyProfile;
