import Switch from '@mui/material/Switch';
import useClasses from 'hooks/useClasses';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import { Milestone } from 'shared-components/dataTypes';
import { styles } from './PackagingViewOptions.style';

type Props = {
    packagings: GenericPackaging[],
    showTempRange: boolean,
    setShowTempRange: Dispatch<SetStateAction<boolean>>,
    showMeasuredOnly: boolean,
    setShowMeasuredOnly: Dispatch<SetStateAction<boolean>>,
    showMeasuredOnlyDisabled: boolean,
    showMarkers: boolean,
    setShowMarkers: Dispatch<SetStateAction<boolean>>,
    showExpTemp?: boolean,
    laneId?: number,
    setShowExpTemp?: Dispatch<SetStateAction<boolean>>,
    showAmbientTemp: boolean,
    milestones: Milestone[] | null,
    showDoors: boolean,
    setShowAmbientTemp: Function,
    setShowDoors: Function,
    showInUTC?: boolean,
    setShowInUTC?: Dispatch<SetStateAction<boolean>>,
    status?: string,
}
const PackagingViewOptions = ({
    showExpTemp,
    status,
    showTempRange,
    setShowTempRange,
    showMeasuredOnly,
    showMeasuredOnlyDisabled,
    setShowMeasuredOnly,
    milestones = [],
    showMarkers,
    setShowMarkers,
    laneId = null,
    setShowExpTemp,
    showAmbientTemp,
    showInUTC,
    setShowInUTC,
    showDoors,
    setShowAmbientTemp,
    setShowDoors,
}:Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const hasLaneManagement = useMemo(() => hasAccess(userRoles.LANE_MANAGEMENT), [hasAccess]);
    const handleChangeTemperatureRange = useCallback(() => setShowTempRange(prev => !prev), []);
    const handleChangeMeasuredOnly = useCallback(() => setShowMeasuredOnly(prev => !prev), []);
    const handleChangeShowInUTC = useCallback(() => setShowInUTC(prev => !prev), []);
    const handleChangeExpectedTemperature = useCallback(() => setShowExpTemp(prev => !prev), []);
    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);
    const handleChangeAmbient = useCallback(() => setShowAmbientTemp(), []);
    const handleChangeDoors = useCallback(() => setShowDoors(), []);

    return (
        <div className={classes.root}>
            <div className={classes.options}>
                <div className={classes.title}>
                    {t('TRACK_AND_TRACE.CHART_OPTIONS')}
                </div>
                <div className={classes.switches}>
                    {hasLaneManagement && laneId !== null
                    && milestones.length !== 0 && status === 'IN_TRANSIT'
                        ? (
                            <UncontrolledTooltip
                                description={t('COMMON.EXPECTED_TEMPERATURE')}
                                shrinked
                            >
                                <div className={classes.switch}>
                                    <label htmlFor="showExpTemp">{t('COMMON.EXPECTED_TEMPERATURE')}</label>
                                    <Switch
                                        id="showExpTemp"
                                        size="medium"
                                        color="primary"
                                        checked={showExpTemp}
                                        onChange={handleChangeExpectedTemperature}
                                    />
                                </div>
                            </UncontrolledTooltip>
                        )
                        : ''}
                    <UncontrolledTooltip
                        description={t('LABEL_TOOLTIPS.MEASURED_ONLY')}
                        shrinked
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showMeasuredOnly">{t('COMMON.MEASURED_ONLY')}</label>
                            <Switch
                                id="showMeasuredOnly"
                                size="medium"
                                color="primary"
                                disabled={showMeasuredOnlyDisabled}
                                checked={showMeasuredOnly && !showExpTemp}
                                onChange={handleChangeMeasuredOnly}
                            />
                        </div>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        description={t('LABEL_TOOLTIPS.SHOW_IN_UTC')}
                        shrinked
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showInUTC">{t('SENSOR_DATA.SHOW_IN_UTC')}</label>
                            <Switch
                                id="showInUTC"
                                size="medium"
                                color="primary"
                                checked={showInUTC}
                                onChange={handleChangeShowInUTC}
                            />
                        </div>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        description={t('LABEL_TOOLTIPS.TEMPERATURE_RANGE')}
                        shrinked
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showTempRange">{t('COMMON.TEMPERATURE_RANGE')}</label>
                            <Switch
                                id="showTempRange"
                                size="medium"
                                color="primary"
                                checked={showTempRange}
                                onChange={handleChangeTemperatureRange}
                            />
                        </div>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        description={t('LABEL_TOOLTIPS.MARKERS')}
                        shrinked
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showMarkers">{t('SENSOR_DATA.MARKERS')}</label>
                            <Switch
                                id="showMarkers"
                                size="medium"
                                color="primary"
                                checked={showMarkers}
                                onChange={handleChangeMarkers}
                            />
                        </div>
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        description={t('LABEL_TOOLTIPS.DOOR')}
                        shrinked
                    >
                        <div className={classes.switch} style={{ display: 'none' }}>
                            <label htmlFor="showMarkers">{t('SENSOR_DATA.DOOR')}</label>
                            <Switch
                                id="showMarkers"
                                size="medium"
                                color="primary"
                                checked={showDoors}
                                onChange={handleChangeDoors}
                            />
                        </div>
                    </UncontrolledTooltip>
                    <div className={classes.switch}>
                        <label htmlFor="showMarkers">{t('COMMON.AMBIENT_TEMPERATURE')}</label>
                        <Switch
                            id="showMarkers"
                            size="medium"
                            color="primary"
                            checked={showAmbientTemp}
                            onChange={handleChangeAmbient}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackagingViewOptions;
