import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme:SkycellThemeInterface) => ({
    root: {
        margin: '0 12px 12px 12px',
        paddingBottom: '36px',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '72px',
        },
    },
    notificationSettingsTable: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit,minmax(250px, 1fr))',
        marginTop: '12px',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(auto-fit,minmax(450px, 1fr))',
        },
    },
    tablePart: {

    },
    tableRow: {
        display: 'grid',
        borderBottom: `1px solid ${theme.palette.secondary[400]}`,
        height: '34px',
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        font: 'normal normal normal 14px/17px Roboto',
        color: '#747474',
    },
    channelCell: {
        justifyContent: 'center',
    },
    help: {
        display: 'flex',
        position: 'absolute',
        bottom: '10px',
        fontSize: '12px',
        color: theme.palette.secondary[500],
        letterSpacing: '0.4px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '600px',
        },
    },
    tooltipIcon: {
        backgroundColor: theme.palette.primary[200],
        color: 'white',
        borderRadius: '50%',
        minWidth: '18px ',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3px',
        fontSize: '12px',
    },
    tableTitleCell: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '70px',
    },
    checkboxStyle: {
        padding: '0',
        color: theme.palette.common.black,
        '&.Mui-disabled': {
            color: theme.palette.secondary[600],
            opacity: '0.7',
        },
    },
    editCheckbox: {
        '&:checked': {
            color: theme.palette.secondary[600],
        },
    },
    blockedCheckbox: {
        opacity: '0.8',
    },
    tableHeaderRow: {
        borderBottom: `1px solid ${theme.palette.secondary[600]}`,
        '& $tableCell': {
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
    mandatoryRow: {
        color: theme.palette.secondary[400],
    },
    centered: {
        justifyContent: 'center',
    },
    selectRoot: {
        width: '100%',
        '&:before': {
            content: 'none',
        },
    },
    select: {
        border: `1px solid ${theme.palette.secondary[400]}`,
        borderRadius: '4px',
        padding: '3px 8px',

    },
});
