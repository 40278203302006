/* eslint-disable max-len */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TimeRange } from 'dataTypes/common';
import icons from 'shared-components/icons';
import StandardLink from 'shared-components/StandardLink';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip/QuickHelpTooltip';

import useClasses from 'hooks/useClasses';
import {
    EntitySelectorItem,
    initialEntitySelectorItem,
} from '../../dataTypes';

import RenderOptionItem from './RenderOptionItem';
import styles from './EntitySelector.style';

type Props = {
    assetsChoosed?: boolean,
    assetsCount?: number,
    entitySelectorItem: any,
    filteredOptions: EntitySelectorItem[],
    loggersCount?: number,
    loggersChoosed?: boolean,
    // packagingsCount: number,
    // packagingsChoosed: boolean,
    sampleForSearch: string,
    setSampleForSearch: (sampleForSearch: string) => void,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    shipmentsActivePackagingSerialNumber?: string,
    setChooseAssets: (assetsChoosed: boolean) => void,
    setChooseShipments: (shipmentsChoosed: boolean) => void,
    // setChoosePackagings: (packagingsChoosed: boolean) => void,
    setChooseLoggers: (loggersChoosed: boolean) => void,
    // shipmentsChoosed: boolean,
    // shipmentsCount: number,
    setTimeRange: (timeRange: TimeRange) => void,
}

const EntitySelector = ({
    // assetsChoosed = true,
    assetsCount = 0,
    entitySelectorItem = initialEntitySelectorItem,
    filteredOptions = [],
    sampleForSearch = '',
    setSampleForSearch,
    setEntitySelectorItem,
    // shipmentsActivePackagingSerialNumber = '',
    // shipmentsChoosed = true,
    // setChooseShipments,
    // packagingsChoosed = true,
    // setChoosePackagings,
    // loggersChoosed = true,
    // setChooseLoggers,
    // setChooseAssets,
    loggersCount = 0,
    // packagingsCount = 0,
    // shipmentsCount = 0,
    setTimeRange,
} : Props) => {
    const classes = useClasses(styles);
    const filterOptions = () => filteredOptions;
    const getOptionLabel = (option: EntitySelectorItem) => option.entityNumber;
    const checkValue = (option: EntitySelectorItem, value: EntitySelectorItem) => {
        return option.entityNumber.toLowerCase() === value.entityNumber.toLowerCase()
        || entitySelectorItem.entityNumber.toLowerCase() === value.entityNumber.toLowerCase();
    };
    const { t } = useCustomTranslation();
    const handleChangeOnInput : any = (event) => setSampleForSearch(event.target.value || '');
    const handleChangeOnSelect : any = (event, value: EntitySelectorItem = initialEntitySelectorItem) => {
        setTimeRange({ from: null, to: null });
        setEntitySelectorItem(value || initialEntitySelectorItem);
        setSampleForSearch(value ? value.entityNumber : '');
    };

    const { entityType = null, entityNumber = '',
        additionalData } = entitySelectorItem;
    const { packagingsInThisShipment = [] } = additionalData || {};

    // const shipmentsCheckboxHidden = useMemo(() => {
    //     return shipmentsCount === 0;
    // }, [shipmentsCount]);
    // const packagingsCheckboxHidden = useMemo(() => {
    //     return packagingsCount === 0;
    // }, [packagingsCount]);
    // const loggersCheckboxHidden = useMemo(() => {
    //     return loggersCount === 0;
    // }, [loggersCount]);
    // const assetsCheckboxHidden = useMemo(() => {
    //     return assetsCount === 0;
    // }, [assetsCount]);

    // const shipmentsCheckboxDisabled = useMemo(() => {
    //     return; // (shipmentsCount === 0
    //     // || ((!packagingsChoosed || packagingsCount === 0)
    //     (!loggersChoosed || loggersCount === 0)
    //     && (!assetsChoosed || assetsCount === 0);
    // }, [
    //     assetsChoosed,
    //     assetsCount,
    //     loggersChoosed,
    //     loggersCount,
    //     // packagingsChoosed,
    //     // packagingsCount,
    //     // shipmentsCount,
    // ]);
    /*
    const packagingsCheckboxDisabled = useMemo(() => {
        return packagingsCount === 0
            || ((!shipmentsChoosed || shipmentsCount === 0)
                && (!loggersChoosed || loggersCount === 0)
                && (!assetsChoosed || assetsCount === 0));
    }, [
        assetsChoosed,
        assetsCount,
        loggersChoosed,
        loggersCount,
        packagingsCount,
        shipmentsChoosed,
        shipmentsCount,
    ]);
    */
    // const loggersCheckboxDisabled = useMemo(() => {
    //     return loggersCount === 0
    //     // || ((!shipmentsChoosed || shipmentsCount === 0)
    //             // && (!packagingsChoosed || packagingsCount === 0)
    //             && (!assetsChoosed || assetsCount === 0);
    // }, [
    //     assetsChoosed,
    //     assetsCount,
    //     loggersCount,
    //     // packagingsChoosed,
    //     // packagingsCount,
    //     // shipmentsChoosed,
    //     // shipmentsCount,
    // ]);
    // const assetsCheckboxDisabled = useMemo(() => {
    // return assetsCount === 0
    // || ((!shipmentsChoosed || shipmentsCount === 0)
    // && (!packagingsChoosed || packagingsCount === 0)
    //       && (!loggersChoosed || loggersCount === 0);
    // }, [
    //     assetsCount,
    //     loggersChoosed,
    //     loggersCount,
    //     packagingsChoosed,
    // packagingsCount,
    // shipmentsChoosed,
    // shipmentsCount,
    // ]);

    const translatedPT = (pt) => t(`PACKAGING_CODE_LABEL.${pt}`);
    // const hasAccess = useHasAccess();

    return (
        <>
            <Autocomplete
                autoSelect
                classes={{
                    inputRoot: classes.textFieldTextAutocomplete,
                    listbox: classes.listBox,
                }}
                className={classes.autocompleteClass}
                clearOnEscape
                filterOptions={filterOptions}
                id="EntitySelector"
                options={[{ entityNumber: sampleForSearch, entityType: null }, ...filteredOptions]}
                size="small"
                value={entitySelectorItem}
                getOptionLabel={getOptionLabel}
                groupBy={() => 'all'}
                isOptionEqualToValue={checkValue}
                onChange={handleChangeOnSelect}
                renderInput={(params) => (
                    <QuickHelpTooltip
                        tooltipInfo={{
                            order: 1,
                            text: t('QUICK_HELP.SENSOR_DATA.SEARCH_FOR_SHIPMENT'),
                            uid: 'searchQuickHelp',
                        }}
                    >
                        <TextField
                            {...params}
                            label={t('COMMON.SEARCH')}
                            placeholder={t('SENSOR_DATA.START_TYPING')}
                            onChange={handleChangeOnInput}
                            size="small"
                            value={sampleForSearch}
                            variant="standard"
                        />
                    </QuickHelpTooltip>

                )}
                renderOption={(props, option) => {
                    return (
                        <li {...props} style={{ borderBottom: '1px solid #0000001C' }}>
                            <RenderOptionItem
                                option={option}
                                sampleForSearch={sampleForSearch}
                                searchMode
                            />
                        </li>
                    );
                }}
                noOptionsText={t('SENSOR_DATA.NO_RESULT')}
                popupIcon={null}
                renderGroup={(params) => {
                    return (
                        <>
                            <div>{params.children}</div>
                            {
                                !sampleForSearch
                                && loggersCount + assetsCount > filteredOptions.length
                                    ? (
                                        <div style={{ textAlign: 'center' }}>
                                            {t('SENSOR_DATA.PLEASE_START_TYPING_TO_SEE_MORE')}
                                        </div>
                                    )
                                    : null
                            }
                        </>
                    );
                }}
            />

            {
                entityType
                && entityType !== 'assets'
                && entityType !== 'loggers'
                && (
                    <div className={classes.currentItemInfoContainer}>
                        <div className={classes.currentItemInfoTitle}>
                            {`${t('SENSOR_DATA.CURRENT')} ${entityType}`}
                        </div>
                        <RenderOptionItem
                            option={entitySelectorItem}
                        />
                        {
                            entityType === 'shipment' && (
                                <StandardLink
                                    className={classes.standardLinkShipmentDetails}
                                    label={t('SENSOR_DATA.SHIPMENT_DETAILS')}
                                    linkTo={`/track-and-trace/shipments/${entityNumber}`}
                                    noWrap
                                />
                            )
                        }
                        {
                            entityType === 'shipment' && (
                                <div>
                                    <div className={classes.currentItemInfoTitle}>
                                        {t('SENSOR_DATA.PACKAGINGS_IN_THIS_SHIPMENT')}
                                    </div>
                                    {
                                        packagingsInThisShipment.map(packaging => {
                                            return (
                                                <div
                                                    key={packaging.serialNumber}
                                                    className={classes.packaging}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <img
                                                            alt="packaging"
                                                            src={icons.container_grey}
                                                            style={{
                                                                width: '24px',
                                                                height: '20px',
                                                                marginRight: '7px',
                                                            }}
                                                        />
                                                        <div>
                                                            {`${packaging.serialNumber} | ${translatedPT(packaging.packagingType)}`}
                                                        </div>
                                                    </div>
                                                    {
                                                        // eslint-disable-next-line max-len
                                                        // packaging.serialNumber === shipmentsActivePackagingSerialNumber && hasAccess(userRoles.PRODUCT_RELEASE) && (
                                                        //     <StandardLink
                                                        //         className={classes.standardLinkShipmentDetails}
                                                        //         label="Product Release"
                                                        //         noWrap
                                                        //         linkTo={`/product-release/${
                                                        //             entitySelectorItem.entityNumber
                                                        //         }/${packaging.productReleaseId}`}
                                                        //     />
                                                        // )
                                                    }
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};

export default EntitySelector;
