import React from 'react';
import icons from 'shared-components/icons';

import useClasses from 'hooks/useClasses';
import styles from './TooltipBody.style';

type Props = {
    destinationAirportCode: string,
    originAirportCode: string,
    small?: boolean,
    classes?: {
    root: string,
    headerInfoGroup: string, }
}

const AirportsInfo = ({
    destinationAirportCode = '',
    originAirportCode = '',
    small = false,
    classes: customClasses = {
        root: '',
        headerInfoGroup: '',
    },
} : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={[classes.airportInfo, customClasses.root].join(' ')}>
            <div className={[classes.headerInfoGroup, customClasses.headerInfoGroup].join(' ')}>
                <img
                    className={classes.headerInfo}
                    src={small ? icons.airport_icon : icons.plane_icon_origin}
                    alt="origin airport"
                />
                <div className={classes.headerInfoWithCodeStyle}>{originAirportCode}</div>
            </div>
            <div className={[classes.headerInfoGroup, customClasses.headerInfoGroup].join(' ')}>
                <img
                    className={classes.headerInfo}
                    src={small ? icons.arrow_small_blue : icons.direction_arrow}
                    alt="direction arrow"
                />
            </div>
            <div className={[classes.headerInfoGroup, customClasses.headerInfoGroup].join(' ')}>
                <div className={classes.headerInfoWithCodeStyle}>{destinationAirportCode}</div>
                {
                    !small && (
                        <img
                            className={classes.headerInfo}
                            src={icons.plane_icon_destination}
                            alt="destination airport"
                        />
                    )
                }
            </div>
        </div>
    );
};

export default AirportsInfo;
