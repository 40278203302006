import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const fadeInOpacityKeyframes = keyframes({
        '0%': {
            opacity: 0.4,
        },
        '100%': {
            opacity: 1,
        },
    });
    const line = css({
        height: '22px',
        transition: '200ms ease',
        animation: `${fadeInOpacityKeyframes} 300ms forwards ease-in-out`,
    });

    const nthChildStyles = Array.from({ length: 7 }, (_, i) => {
        const background = i === 0 ? theme.palette.primary.darkBlue : theme.palette.primary[(i + 1) * 100];

        return {
            [`:nth-child(7n+${i + 1})`]: {
                [`.${line}`]: {
                    background,
                },
            },
        };
    }).reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const root = css({
        display: 'grid',
        gridGap: '8px',
        gridAutoRows: '20px 20px 31px',
        flex: 1,
        transition: '200ms ease',
        flexDirection: 'column',
        alignItems: 'center',
        ...nthChildStyles,
    });

    const fadeInKeyframes = keyframes({
        '0%': {
            transform: 'scale(0.5)',
            opacity: 0,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    });

    const shrinked = css({
        gridAutoRows: '20px 31px',
        cursor: 'pointer',
        '&:hover': {
            filter: 'grayscale(1)',
        },
    });

    const lineTbd = css({
        background: `${theme.palette.secondary[400]}!important`,
    });

    const time = css({
        textAlign: 'center',
        color: theme.palette.secondary[600],
    });

    const iconWrapper = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    const icon = css({
        maxHeight: '30px',
        width: 'auto',
        maxWidth: '100%',
        minWidth: '5%',
        animation: `${fadeInKeyframes} 200ms forwards ease-in-out`,
    });

    const tooltipDescription = css({
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
    });

    const tooltipBody = css({
        maxWidth: 'unset!important',
        width: 'auto!important',
    });

    const divider = css({
        width: '75px',
        height: '1px',
        marginRight: '40px',
        marginLeft: '40px',
        background: `linear-gradient(90deg, ${theme.palette.secondary[600]} 4px, ${theme.palette.common.white} 4px)`,
        backgroundSize: '8px',
    });

    return {
        root,
        shrinked,
        line,
        lineTbd,
        time,
        iconWrapper,
        icon,
        tooltipDescription,
        tooltipBody,
        divider,
    };
};

export default generateStyles;
