import React, { useContext, useMemo } from 'react';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';

import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import tableColumns from './tableColumns';
import styles from '../../TemperatureSimulation.style';

type Props = {
   type: 'winter' | 'summer',
}
const SimulationSummary = ({
    type,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const {
        packagingTypes,
        profileData,
        lane,
    } = useContext(LaneTemperatureContext);
    const selectedPackagingTypeCodes = useMemo<string[]>(() => {
        return lane?.packagingTypes?.map(it => it.code) || [];
    }, [lane]);

    const tableData = useMemo(() => {
        return selectedPackagingTypeCodes
            ?.map(code => packagingTypes.find(pt => pt.type === code))
            ?.filter(it => it);
    }, [
        packagingTypes,
        selectedPackagingTypeCodes,
    ]);
    const tableColumnsPrepared = useMemo(() => {
        return tableColumns(profileData, type, t);
    }, [profileData, type, t]);

    return (
        <Card
            title={t('COMMON.SUMMARY')}
            titleClass={classes.simulationSummaryTitle}
            zeroSidePadding
        >
            <Table
                data={tableData}
                columns={tableColumnsPrepared}
                embed
            />
        </Card>
    );
};

export default SimulationSummary;
