import useGetAirports from 'hooks/useGetAirports';
import React, {
    useCallback,
} from 'react';
import TextField from '@mui/material/TextField';
import { ArrowForward } from '@mui/icons-material';
import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import styles from './AirportOptions.style';

type Props = {
    availableDestinationAirports: string[],
    availableOriginAirports: string[],
    selectedDestinationAirports: string[],
    selectedOriginAirports: string[],
    setDestinationAirports: Function,
    setOriginAirports: Function,
}

const AirportOptions = ({
    availableDestinationAirports = [],
    availableOriginAirports = [],
    selectedDestinationAirports = [],
    selectedOriginAirports = [],
    setDestinationAirports,
    setOriginAirports,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const airportsData = useGetAirports();

    const getAirportLabel = useCallback((code) => `${code} | ${airportsData
        .find(ap => ap?.code === code)?.city || ''}`, [airportsData]);

    const handleChangeOrigin = useCallback((event, newSelectedList) => {
        setOriginAirports(newSelectedList);
    }, [setOriginAirports]);
    const handleChangeDestination = useCallback((event, newSelectedList) => {
        setDestinationAirports(newSelectedList);
    }, [setDestinationAirports]);

    return (
        <div className={classes.container}>
            <div className={classes.selectorRows}>
                <div className={classes.selectorCol}>
                    <span>
                        {t('COMMON.FROM')}
                        <ArrowForward className={classes.arrow} />
                    </span>
                    <Autocomplete
                        onChange={handleChangeOrigin}
                        multiple
                        size="small"
                        options={availableOriginAirports}
                        value={selectedOriginAirports.sort()}
                        clearIcon={false}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                InputProps={{
                                    ...(params.InputProps),
                                    startAdornment: null,
                                }}
                                className={classes.multipleSelectionInput}
                                placeholder="Select"
                            />
                        )}
                    />
                    <div className={classes.chips}>
                        {
                            selectedOriginAirports.map(airportCode => {
                                const handleDelete = () => (
                                    setOriginAirports(selectedOriginAirports.filter(it => it !== airportCode)));

                                return (
                                    <UncontrolledTooltip
                                        description={getAirportLabel(airportCode)}
                                        key={airportCode}
                                        placement="right-end"
                                        position="absolute"
                                        shrinked
                                        whiteSpace="nowrap"
                                    >
                                        <Chip
                                            classes={{
                                                label: classes.chipLabel,
                                                root: classes.chipRoot,
                                                deleteIcon: classes.deleteIcon,
                                            }}
                                            size="small"
                                            label={getAirportLabel(airportCode)}
                                            onDelete={handleDelete}
                                            onClick={handleDelete}
                                        />
                                    </UncontrolledTooltip>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={classes.selectorCol}>
                    <span>{t('COMMON.TO')}</span>
                    <Autocomplete
                        onChange={handleChangeDestination}
                        multiple
                        size="small"
                        options={availableDestinationAirports}
                        value={selectedDestinationAirports.sort()}
                        clearIcon={false}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                InputProps={{
                                    ...(params.InputProps),
                                    startAdornment: null,
                                }}
                                className={classes.multipleSelectionInput}
                                placeholder="Select"
                            />
                        )}
                    />
                    <div className={classes.chips}>
                        {
                            selectedDestinationAirports.map(airportCode => {
                                const handleDelete = () => (
                                    setDestinationAirports(
                                        selectedDestinationAirports.filter(it => it !== airportCode),
                                    ));

                                return (
                                    <UncontrolledTooltip
                                        description={getAirportLabel(airportCode)}
                                        key={airportCode}
                                        placement="right-end"
                                        position="absolute"
                                        shrinked
                                        whiteSpace="nowrap"
                                    >
                                        <Chip
                                            classes={{
                                                label: classes.chipLabel,
                                                root: classes.chipRoot,
                                                deleteIcon: classes.deleteIcon,
                                            }}
                                            size="small"
                                            label={getAirportLabel(airportCode)}
                                            onDelete={handleDelete}
                                            onClick={handleDelete}
                                        />
                                    </UncontrolledTooltip>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AirportOptions;
