import React, { ReactChild } from 'react';
import useClasses from '../hooks/useClasses';
import CardDashboard from './CardDashboard';
import styles from './Dashboard.style';

type Props = {
    widgets: {
        id: string,
        title: string,
        info: string,
        iconSrc: string,
        link: string,
        component?: ReactChild,
        externalLink?: string,

    }[],
}

const Dashboard = ({ widgets } : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.container}>
            {
                widgets.map((widget) => {
                    return (
                        <CardDashboard
                            key={widget.id}
                            {...widget}
                        />
                    );
                })
            }
        </div>
    );
};

export default Dashboard;
