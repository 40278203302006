import React, { useState } from 'react';
import { OperatingHours } from 'dataTypes/SecureBackend/apiResponse';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import styles from './OperatingHoursCard.style';

type Props ={
    currentEditedCard?: string,
    currentOperatingHours: OperatingHours[],
    type?: 'editable' | 'readOnly',
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setData?: (operatingHours: OperatingHours[]) => void,
}

const cardName = 'OperatingHoursCard';

const OperatingHoursCard = ({
    currentEditedCard = null,
    currentOperatingHours = [],
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setData = () => {},
    type = 'readOnly',
} : Props) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useCustomTranslation();
    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setEditMode(false);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('SERVICE_PROVIDERS.OPERATING_HOURS')}
        >
            {
                (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            currentOperatingHours={currentOperatingHours}
                            setData={setData}
                        />
                    )
                    : (
                        <ReadMode currentOperatingHours={currentOperatingHours} />
                    )

            }
        </CardWithEditableContent>
    );
};

export default OperatingHoursCard;
