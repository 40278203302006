const styles = (theme) => ({
    companyLogo: {
        height: '60px',
    },
    contactInfo: {
        marginLeft: '6px',
    },
    mainOverviewInfoContainer: {
        display: 'grid',
        gridGap: '24px',
        gridTemplateColumns: '1fr 1fr',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        // @ts-ignore
        color: theme.palette.common.contrastGray600,
        opacity: 1,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    groupTitle: {
        marginTop: '24px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    groupTitleTop: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    iconContainer: {
        display: 'flex',
    },
    icon: {
        marginRight: '11px',
    },
    operatingHours: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    operatingDay: {
        textTransform: 'capitalize',
    },
    questionnairePublishedContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    questionnairePublishedIcon: {
        color: theme.palette.primary[400],
    },
    questionnairePublishedMessage: {
        marginLeft: '5px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
});

export default styles;
