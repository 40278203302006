import React, { MouseEventHandler, useState, useMemo, useCallback } from 'react';
import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import { LatLng } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import { OverlayView } from '@react-google-maps/api';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { MapElement } from 'TrackAndTrace/Assets/lib';
import useGetCommonData from 'hooks/useGetCommonData';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { Asset, GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import moment from 'moment';
import useGetGatewaysByGatewayNumbers, {
    extractLatestSensorDataWithUniqueGatewayNumbers,
} from 'hooks/useGetGatewaysByGatewayNumbers/useGetGatewaysByGatewayNumbers';
import TooltipContents from 'TrackAndTrace/Assets/components/AssetTooltip/TooltipContents';
import { CircularProgress } from '@mui/material';
import useFilter, { PAGES } from 'Contexts/useFilter';

interface Area extends MapElement{
    area?: string;
}

type Props = {
        area: Area,
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    zoom: number,
    position: LatLng,
}

const styles = (theme: SkycellThemeInterface) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '40px',
        minWidth: '100%',
        width: 'max-content',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '4px 8px',
        gap: '10px',
        color: 'black',
        fontSize: '14px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '66px',
        width: '100%',
        zIndex: 1000,
    },
    infoIcon: {
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    loggerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        position: 'relative',
        borderRadius: '4px',
        zIndex: 100,
        backgroundColor: theme.palette.common.white,
        minWidth: '100%',
        width: 'max-content',
    },
    airportCount: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        padding: '4px 8px',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        color: 'black',
        fontSize: '26px',
        userSelect: 'none',
        height: '50px',
        width: '100%',
    },
    headerText: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        textDecoration: 'underline',
        minWidth: '100%',
        width: 'max-content',
        fontSize: '32px',
        padding: '8px 30px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        color: theme.palette.common.black,
    },
    body: {},
    division: {
        padding: '14px 14px 30px 14px',
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
    },
});

const AreaTooltip = ({
    area,
    onMouseLeave,
    position,
}: Props) => {
    const { getFilter } = useFilter(PAGES.ASSETS);
    const filter = getFilter();
    const classes = useClasses(styles);
    const [hoveredAssetId, setHoveredAssetId] = useState<number | null>(null);
    const theme = useTheme();

    const {
        data: rawAssets,
        status: assetRequestStatus,
    } = useGetCommonData<Asset[]>('assets/search', {
        postProcess: it => it.resultList,
        enabled: true,
        query: {
            ...(filter?.rawAssetsQueryObj || {}),
            gatewayImeiMacs: area.code,
            page: 0,
            pageSize: 5,
            q: filter.query,
        },
        queryWrap: false,
    });

    const {
        Get: getLoggerSensorData,
    } = useSecureBackendEndpoints('assets').requests;

    const {
        data: sensorData,
        status: sensorDataStatus,
    } = useCachedQueryRequest({
        key: `${hoveredAssetId}_sensor_data`,
        request: getLoggerSensorData.bind(null, `${
            hoveredAssetId
        }/sensor-data?from=${
            moment().utc().subtract(7, 'day').format('YYYY-MM-DDTHH:mm')
        }&to=${moment().utc().format('YYYY-MM-DDTHH:mm')}`),
        options: {
            enabled: !!hoveredAssetId,
            retry: false,
        },
    });

    const uniqueLastSensorData = useMemo(() => {
        return extractLatestSensorDataWithUniqueGatewayNumbers(sensorData);
    }, [sensorData]);

    const {
        gateways = [],
    } = useGetGatewaysByGatewayNumbers({
        query: {
            gatewayImeiMac: uniqueLastSensorData.map(it => it.gatewayNumber),
        },
        enabled: uniqueLastSensorData.length !== 0,
    });

    const lastGateways = useMemo<{
        gateway: GatewayInfoDTO,
        t: string,
    }[]>(() => {
        if (hoveredAssetId && uniqueLastSensorData.length !== 0 && gateways) {
            return uniqueLastSensorData.map(it => ({
                gateway: gateways?.find(g => g?.gatewayImeiMac?.toLowerCase() === it?.gatewayNumber?.toLowerCase()),
                t: it?.sensorData?.t,
            }));
        } else {
            return [];
        }
    }, [uniqueLastSensorData, hoveredAssetId, gateways]);

    const getLatestStoredGateway = useCallback<(asset:Asset) => {
        gateway: GatewayInfoDTO,
        t: string,
    }[]>((asset) => {
            return [{
                gateway: {
                    latitude: asset.lastMeasuredData?.geolocation?.latitude,
                    longitude: asset.lastMeasuredData?.geolocation?.longitude,
                    iataCode: asset.lastMeasuredData?.iataCode,
                    area: asset.lastMeasuredData?.area || asset.lastMeasuredData?.locationName,
                },
                t: asset.lastMeasuredData?.temperatureGeolocationTimestamp,
            }];
        }, [rawAssets]);

    return (
        <OverlayView
            mapPaneName={OverlayView.FLOAT_PANE}
            zIndex={100}
            position={position}
        >
            <div
                className={classes.root}
                style={{
                    transform: `translateY(${area.count > 5 ? -74 : 0}px)`,
                }}
                onMouseLeave={onMouseLeave}
            >
                { area.count > 5 && (
                    <div className={classes.info}>
                        <div className={classes.infoIcon}>
                            <InfoIcon style={{
                                color: theme.palette.primary.deepBlue,
                                fontSize: '30px',
                            }}
                            />
                        </div>
                        <div
                            style={{
                                whiteSpace: 'wrap',
                            }}
                        >
                            5 most recent of
                            {' '}
                            {area.count}
                            {' '}
                            assets shown.
                            {' '}
                            <br />
                            View full list in table view.
                        </div>
                    </div>
                )}
                {assetRequestStatus === 'PENDING' && (
                    <div style={{ backgroundColor: theme.palette.common.white }}>
                        <CircularProgress size={16} />
                    </div>
                )}

                {
                    rawAssets.map((asset) => (
                        <TooltipContents
                            key={`asset_on_map_${asset.id}_${asset.assetNumber}`}
                            classes={classes}
                            asset={asset}
                            setHoveredAssetId={setHoveredAssetId}
                            hoveredAssetId={hoveredAssetId}
                            sensorDataStatus={sensorDataStatus}
                            lastGateways={lastGateways}
                            getLatestStoredGateway={getLatestStoredGateway}
                        />
                    ))
                }
            </div>
        </OverlayView>

    );
};

export default AreaTooltip;
