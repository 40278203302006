import moment from 'moment';

const getUtcMoment = (dateTimeUtc?: string | number): moment.Moment => {
    return moment.utc(dateTimeUtc);
};

const getUtcToLocalMoment = (dateTimeUtc: string | number): moment.Moment => {
    return getUtcMoment(dateTimeUtc).local();
};

export const getFormattedDateUtc = (dateTimeUtc: string | number): string => {
    return getUtcMoment(dateTimeUtc).format('YYYY-MM-DD HH:mm');
};

export const getFormattedDateUtcToLocal = (dateTimeUtc: string | number): string => {
    return getUtcToLocalMoment(dateTimeUtc).format('YYYY-MM-DD HH:mm');
};

export const getFormattedDate = (dateTimeUtc: string | number, inLocalTimeZone = false): string => {
    if (inLocalTimeZone) {
        return getFormattedDateUtcToLocal(dateTimeUtc);
    }
    return getFormattedDateUtc(dateTimeUtc);
};

export const dateToNumber = (dateTimeUtc: string | number, inLocalTimeZone = false): number => {
    if (inLocalTimeZone) {
        return getUtcToLocalMoment(dateTimeUtc).valueOf();
    }
    return getUtcMoment(dateTimeUtc).valueOf();
};

export const nowDateToNumber = (): number => {
    return getUtcMoment().valueOf();
};
