import { ApexOptions } from 'apexcharts';
import { useCallback, useMemo } from 'react';
import { interpolateExtremums } from './lib';

type Props = {
    enabled: boolean,
    temperatureData: any,
    temperatureRangeMin: any,
    temperatureRangeMax: any,
    tempIndex?: number,
    inLocalTimeZone?: boolean,
}

const useExcursionSeries = ({
    enabled,
    temperatureData,
    temperatureRangeMin,
    temperatureRangeMax,
    tempIndex = 0,
    inLocalTimeZone = false,
}: Props) => {
    // 1 out of Max, -1 out of Min, 0 in range
    const rangeType = useCallback((temp) => {
        if (temp < temperatureRangeMin - 0.1) return -1;
        if (temp > temperatureRangeMax + 0.1) return 1;
        return 0;
    }, [temperatureRangeMax, temperatureRangeMin]);

    return useMemo<ApexOptions['series']>(() => {
        if (enabled) {
            const tempDataWithIndexes = temperatureData.map(({ t, d }, i) => ({ t, d, i }));
            const bottomInterpolation = interpolateExtremums({
                data: tempDataWithIndexes
                    .filter(({ d }) => d[tempIndex] !== null && (d[tempIndex] < temperatureRangeMin - 0.1)),
                tempIndex,
                temperatureData,
                extremumTemp: temperatureRangeMin - 0.1,
                rangeChecker: (currentT) => rangeType(currentT) === -1,
                inLocalTimeZone,
            });
            const topInterpolation = interpolateExtremums({
                data: tempDataWithIndexes
                    .filter(({ d }) => d[tempIndex] !== null && (d[tempIndex] > temperatureRangeMax + 0.1)),
                tempIndex,
                temperatureData,
                extremumTemp: temperatureRangeMax + 0.1,
                rangeChecker: (currentT) => rangeType(currentT) === 1,
                inLocalTimeZone,
            });

            return [bottomInterpolation.length > 0 ? {
                name: 'Excursion ',
                type: 'rangeArea',
                color: '#B92C2C',
                opacity: 0.2,
                data: bottomInterpolation,
                interpolated: true,
                stroke: {
                    width: 2,
                },
            } : null,
            topInterpolation.length > 0 ? {
                name: 'Excursion',
                type: 'rangeArea',
                color: '#B92C2C',
                opacity: 0.2,
                data: topInterpolation,
                interpolated: true,
                stroke: {
                    width: 2,
                },
            } : null].filter(it => it);
        } else return [];
    }, [
        enabled,
        temperatureData,
        temperatureRangeMin,
        rangeType,
        temperatureRangeMax,
    ]);
};

export default useExcursionSeries;
