export const styles = (theme) => ({
    required: {
        background: theme.palette.primary[30],
        '&:hover': {
            background: theme.palette.primary[50],
        },
    },
    classic: {
        background: theme.palette.secondary[30],
    },
    invalid: {
    // @ts-ignore
        background: `${theme.palette.common.red}2e`,
    },
    input: {
        '& input': {
            paddingLeft: '5px',
            padding: '4px',
        },
        '& *': {
            color: theme.palette.secondary[600],
            border: 'none',
        },
        '& .MuiSelect-select': {
            fontSize: '16px',
        },
    },
    maskInput: {
        padding: '4px',
        outline: 'none',
        border: 'none',
        color: theme.palette.secondary[600],
        fontSize: '16px',
        height: '31px',
    },
    alignEnd: {
        '& input': {
            textAlign: 'end',
        },
        width: '75px',
    },
    timeInput: {
        width: '90px',
        '& input': {
            textAlign: 'center',
        },
    },

});
