import React from 'react';
import { url } from 'theme/shared/variables';

export const KnowledgeBase = () => {
    const getPrefix = () => {
        if (url.includes('localhost')) return '.dev';

        const [, prefix] = url.split('.');

        if (prefix === 'skymind') return '';

        return `.${prefix}`;
    };

    const KnBaseUrl = `https://knowledge${getPrefix()}.skymind.com/`;

    return (
        <iframe src={KnBaseUrl} title="Knowledge Base" />
    );
};
