import React, { CSSProperties, ReactNode } from 'react';
import useClasses from 'hooks/useClasses';
import styles from '../SideFilter.style';

type Props = {
    children: ReactNode,
    style?: CSSProperties,
}

const BlockWithOverflow = ({
    children = null,
    style = {},
} : Props) => {
    const classes = useClasses(styles);

    return <div style={style} className={classes.blockWithOverflow}>{children}</div>;
};

export default BlockWithOverflow;
