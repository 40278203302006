import { css } from '@emotion/css';
import { Theme } from '@emotion/react';

export const generateStyles = (theme: Theme) => {
    const modalWindow = css({
        width: '562px',
        minHeight: '200px',
        background: theme.palette.common.white,
        position: 'relative',
        borderRadius: '4px',
        padding: '28px 35px 19px 35px',
        zIndex: 101,
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        flexDirection: 'column',
        '.MuiSelect-iconStandard': {
            color: '#4B4B4B',
        },
    });

    const modalWrapper = css({
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.black,
        position: 'relative',
    });

    const modalTitle = css({
        fontSize: '16px',
        marginBottom: '17px',
        color: theme.palette.secondary[800],
        fontWeight: 'bold',
    });

    const closeIcon = css({
        position: 'absolute',
        top: '10px',
        right: '8px',
        fontSize: '23px',
        color: theme.palette.secondary[600],
        cursor: 'pointer',
    });

    const backdrop = css({
        zIndex: 100,
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        background: '#0000001F',
    });

    const buttonHolder = css({
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    });

    const sendButton = css({
        borderRadius: '0px',
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        fontSize: '14px',
        textTransform: 'none',
        padding: '12px 18px',
        letterSpacing: '0.43px',
        fontWeight: 400,
        '&:hover': {
            background: theme.palette.primary[400],
        },
    });

    const addAccessRequestButton = css({

    });
    const error = css({
        color: theme.palette.error.main,
        fontSize: '14px',
        marginBottom: '5px',
        marginTop: '5px',
    });

    return {
        modalWrapper,
        modalWindow,
        modalTitle,
        closeIcon,
        error,
        backdrop,
        buttonHolder,
        sendButton,
        addAccessRequestButton,
    };
};
