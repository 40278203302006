import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import { TextField } from '@mui/material';
import SelectField from 'shared-components/SelectField';
import {
    CommonTableItem,
    TABLE_INPUT_TYPE,
} from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable/CommonTableTypes';
import { styles } from './TableInput.style';
import MaskedTimeInputWithTooltip from './inputs/MaskedTimeInputWithTooltip';
import NumberSpinnerInput from './inputs/NumberSpinnerInput';

type Props = {
    onChange: (value: string | number, id?: string) => void,
    value: any,
    description: CommonTableItem,
    proposedValue: any
}

export const validValue = (value, type: TABLE_INPUT_TYPE) => {
    switch (type) {
    case TABLE_INPUT_TYPE.TIME_24:
        return value !== null && value !== undefined && value > 0;
    default:
        return value !== null && value !== undefined && value !== '';
    }
};
const TableInput = ({
    onChange,
    value,
    description,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    proposedValue,
}: Props) => {
    const classes = useClasses(styles);
    const { required } = description;
    const inputClass = [required ? classes.required : classes.classic, classes.input,
        description.required && !validValue(value, description.inputType) ? classes.invalid : '',
        [
            TABLE_INPUT_TYPE.TIME_24,
            TABLE_INPUT_TYPE.TEMPERATURE,
        ].includes(description.inputType) ? classes.alignEnd : '',
        description.inputType === TABLE_INPUT_TYPE.TIME_24 ? classes.timeInput : '',
    ].join(' ');
    const [proposedDurationTooltip, setProposedDurationTooltip] = useState(false);

    switch (description.inputType) {
    case TABLE_INPUT_TYPE.TEXT:
        return (
            <TextField
                className={inputClass}
                inputProps={{
                    style: {
                        padding: '4px 10px',
                        outline: 'none',
                        boxShadow: 'none',
                    },
                }}
                value={value || ''}
                onChange={ev => onChange(ev.target.value)}
                InputProps={{
                    disableUnderline: true,
                }}
            />
        );
    case TABLE_INPUT_TYPE.TEMPERATURE:
        return (
            <NumberSpinnerInput
                value={value}
                inputClass={inputClass}
                onChange={onChange}
                proposedValue={proposedValue}
            />

        );
    case TABLE_INPUT_TYPE.TIME_24:
        return (
            <MaskedTimeInputWithTooltip
                value={value}
                proposedValue={proposedValue}
                proposedDurationTooltip={proposedDurationTooltip}
                setProposedDurationTooltip={setProposedDurationTooltip}
                onChange={onChange}
                inputClass={inputClass}
            />
        );
    case TABLE_INPUT_TYPE.SELECT:
        return (
            <SelectField
                className={inputClass}
                items={description.selectData}
                onChange={onChange}
                value={value}
            />
        );
    default:
        return <div />;
    }
};

export default TableInput;
