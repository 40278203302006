import useClasses from 'hooks/useClasses';
import React from 'react';
import useGetParentCompany from 'hooks/useGetParentCompany';
import Capabilities from 'shared-components/CompanyInfoComponents/Capabilities';
import useCustomTranslation from 'hooks/useCustomTranslation';
import CompanyInfoWithLocations from './components/CompanyInfoWithLocations';

const styles = {
    capabilitiesContainer: { marginTop: '30px' },
};

function ParentCompany() {
    const classes = useClasses(styles);
    const provider = useGetParentCompany();
    const { t } = useCustomTranslation();

    if (provider) {
        return (
            <div style={{ padding: '12px' }}>
                <CompanyInfoWithLocations companyInfo={provider} />
                <div className={classes.capabilitiesContainer}>
                    {
                        provider.capabilities.totalStatistics.all > 0 && (
                            <Capabilities
                                companyInfo={provider}
                                titlePrefix={`${t('COMMON.CAPABILITIES')} | `}
                            />
                        )
                    }
                </div>
            </div>
        );
    }

    return null;
}

export default ParentCompany;
