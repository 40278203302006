const styles = () => ({
    input: {
        border: 'none',
        outline: 'none',
        width: '100%',
    },
    fieldWrapper: {
        '& .flag-dropdown': {
            display: 'none',
        },
        '& .special-label': {
            display: 'none',
        },
        '& input.form-control': {
            paddingLeft: '2px',
        },
        '& input.invalid-number': {
            background: '#FFE5E5 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: 1,
        },

        position: 'relative',

        '&:after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '1px',
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.42)',
            transition: 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },

        '&:hover:after': {
            height: '2px',
            backgroundColor: 'rgba(0, 0, 0, 0.87)',
        },
    },
    disabled: {
        pointerEvents: 'none',
        '& *::before': {
            content: '""',
        },
        '& *::after': {
            content: '""',
        },
    },
    noMargin: {
        margin: 0,
    },
});

export default styles;
