import useClasses from 'hooks/useClasses';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useContext, useMemo } from 'react';
import { PICTURES } from 'shared-components/constants';
import generateStyles from 'LaneManagement/AddLane/AddLane.style';
import useCustomTranslation from 'hooks/useCustomTranslation';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import Card from 'shared-components/Card';
import { Milestone } from 'shared-components/dataTypes';
import { styles } from './TransportCard.style';
import FlowElement from './FlowElement';

type Props = {
    stepsRaw: Milestone[],
    shrink?: boolean,
    className?: string,
    offsetPadding?: [number, number]
}

export const sumDurations: (arg: any[]) => number = (durations) => {
    return Math.floor(durations?.flat()?.map((it) => it?.duration).reduce((a, b) => a + b, 0));
};

const TransportCard = ({
    stepsRaw,
    shrink = false,
    className = '',
    offsetPadding = [0, 0],
}: Props) => {
    const rootClasses = useGeneratedStyles(generateStyles);
    const classes = useClasses(styles);

    const {
        editingCard,
    } = useContext(LaneCardsContext);

    const { t } = useCustomTranslation();
    const steps: Milestone[] = useMemo(() => {
        const filteredSteps: any[] = stepsRaw?.filter(step => step?.activities?.length !== 0) || [];

        return filteredSteps;
    }, [stepsRaw]);
    const hasUnloadingStep = useMemo(() => !!steps?.find((it) => it.type === 'UNLOADING'), [steps]);

    const transports: any[] = useMemo(() => {
        return steps.map((step) => step?.activities) || [];
    }, [steps]);

    const sum = useMemo(() => {
        const durations = Math.floor(sumDurations(transports.filter((it) => it?.length !== 0)) / 60);

        return durations + (!hasUnloadingStep ? 180 : 0);
    }, [transports, steps, hasUnloadingStep]);
    const transportTitle = useMemo(() => {
        const correctedSum = sum + (!hasUnloadingStep ? -180 : 0);
        const rawHours = Math.floor(correctedSum / 60);
        const hours = rawHours !== 0 ? `${rawHours}${t('TIME.H')} ` : '';
        const minutes = correctedSum % 60 !== 0 ? `${correctedSum % 60}${t('TIME.M')}` : '';

        const sumText = `${hours}${minutes}`.trim();
        const sumTextBraced = correctedSum === 0 ? '' : `(${sumText})`;

        return transports.length === 0 ? t('COMMON.IN_TRANSIT') : `${t('COMMON.IN_TRANSIT')} ${sumTextBraced}`;
    }, [transports, sum, hasUnloadingStep]);

    return (
        <Card
            title={shrink ? '' : transportTitle}
            className={shrink ? classes.noShadow : ''}
            contentClass={className}
            disabled={!!editingCard}
        >
            {
                transports.length === 0
                    ? (
                        <div className={[rootClasses.cardBody, classes.transportCard].join(' ')}>
                            <img className={classes.picture} src={PICTURES.reading_robot} alt="" />
                            <span className={classes.text}>
                                {t('LANE_MANAGEMENT.TRANSPORT_DESCRIPTION')}
                            </span>
                        </div>
                    )
                    : (
                        <div
                            className={[rootClasses.cardBody, classes.transportFlow].join(' ')}
                            style={{
                                paddingLeft: offsetPadding[0],
                                paddingRight: offsetPadding[1],
                            }}
                        >
                            {
                                steps.map(step => {
                                    return (
                                        <FlowElement
                                            key={`step_transport_${step.index}`}
                                            step={step}
                                            shrink={shrink}
                                            sum={Math.floor(sum / 60)}
                                        />
                                    );
                                })
                            }
                            {
                                (!hasUnloadingStep && !shrink) && (
                                    <FlowElement
                                        sum={sum}
                                        tbd
                                        shrink={shrink}
                                    />
                                )
                            }
                        </div>
                    )
            }
        </Card>
    );
};

export default TransportCard;
