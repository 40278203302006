/* eslint-disable camelcase */

import { getGeocode } from 'use-places-autocomplete';
import { GOOGLE_LOCATION_INFO } from 'shared-components/constants';
import {
    AllOptionsForField,
    ClientSideFilterType,
    ColumnFilterOptions,
    ColumnDataType,
    ColumnsType,
    ColumnFilterType,
    EnumFilterOption,
} from './dataTypes';

export const getLinkPathByTemplate = (rowLinkTemplate: string, data: object) => {
    if (!rowLinkTemplate || !rowLinkTemplate.includes(':')) {
        return rowLinkTemplate;
    }

    const [link = '', params = ''] = rowLinkTemplate.split('?');

    const updatedLink = link.split('/').map((segment) => {
        return segment.includes(':')
            ? data[segment.replace(':', '')]
            : segment;
    }).join('/');

    const updatedParams = params.split('=').map((segment) => {
        return segment.includes(':')
            ? data[segment.replace(':', '')]
            : segment;
    }).join('=');

    return `${updatedLink}?${updatedParams}`;
};

export const getBodyCellValue = (dataType: ColumnDataType, value: any): any => {
    if (dataType === 'stretchedCell') {
        return value?.data || value;
    }

    return value;
};

const getAllOptionsForField = (data = [], filterKey: string | number): AllOptionsForField => {
    return data.reduce((options, item) => {
        return item[filterKey]
            ? [...options, item[filterKey]]
            : options;
    }, []);
};

const getOptionsCountInfo = (fieldValues: (string | number)[]): { [key: string | number]: number} => {
    return fieldValues.reduce((valueList, value) => {
        const count = valueList[value] ? valueList[value] + 1 : 1;

        return { ...valueList, [value]: count };
    }, {});
};

const getEnumFieldCollection = (fieldValues: (string | number)[]): EnumFilterOption[] => {
    const fieldCollection = getOptionsCountInfo(fieldValues);

    return Object.keys(fieldCollection).map(value => ({ value, count: fieldCollection[value] }))
        .sort((a, b) => (a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1));
};

const getEnumListFieldCollection = (fieldValues: (string | number)[][]): EnumFilterOption[] => {
    const fetchedFieldValues = fieldValues.reduce((concatedData, currentArr) => {
        return Array.isArray(currentArr)
            ? concatedData.concat(currentArr)
            : concatedData;
    }, []);

    const fieldCollection = getOptionsCountInfo(fetchedFieldValues);

    return Object.keys(fieldCollection).map(value => ({ value, count: fieldCollection[value] }))
        .sort((a, b) => (a.value < b.value ? -1 : 1));
};

export const getMilliseconds = (stringDate: string): number => {
    if (/\d{4}-\d{2}-\d{2}/.test(stringDate)) {
        return new Date(stringDate).getTime();
    } else if (/\d{2}\.\d{2}\.\d{4}/.test(stringDate)) {
        const [date] = stringDate.match(/\d{2}\.\d{2}\.\d{4}/);
        const [dd, mm, yyyy] = date.split('.');

        return new Date(`${yyyy}-${mm}-${dd}`).getTime();
    }

    return null;
};

const getDateFieldCollection = (fieldValues: (string)[]): number[] => {
    const availableMilliseconds = fieldValues.reduce((valueList, value: string) => {
        const milliseconds = getMilliseconds(value);

        return milliseconds === null
            ? valueList
            : [...valueList, milliseconds];
    }, []);

    if (availableMilliseconds.length === 0) {
        return [null, null];
    }

    const min = Math.min(...availableMilliseconds);
    const max = Math.max(...availableMilliseconds);

    return [min, max];
};

const getNumberFieldCollection = (fieldValues: (number)[]): number[] => {
    if (fieldValues.length === 0) {
        return [null, null];
    }
    const min = Math.min(...fieldValues);
    const max = Math.max(...fieldValues);

    return [min, max];
};

export const getColumnFilterOptions = (columns: ColumnsType[], data = []): ColumnFilterOptions => {
    return columns.reduce((collection, column) => {
        const allOptionsForField = getAllOptionsForField(data, column.filterKey);

        if (column.filterType === 'enum') {
            return { ...collection, [column.filterKey]: getEnumFieldCollection(allOptionsForField) };
        } else if (column.filterType === 'enumList') {
            return { ...collection, [column.filterKey]: getEnumListFieldCollection(allOptionsForField) };
        } else if (column.filterType === 'date') {
            return { ...collection, [column.filterKey]: getDateFieldCollection(allOptionsForField) };
        } else if (column.filterType === 'number') {
            return { ...collection, [column.filterKey]: getNumberFieldCollection(allOptionsForField) };
        }
        return collection;
    }, {});
};

const getAirportsCollection = (options: string[]): string[] => {
    return [...new Set(options)].sort();
};

export const getSideFilterOptions = (columns: ColumnsType[], data = []): ColumnFilterOptions => {
    return columns.reduce((collection, column) => {
        const allOptionsForField = getAllOptionsForField(data, column.sideFilterKey);

        switch (column.sideFilterType) {
        case 'enum':
            return { ...collection, [column.sideFilterKey]: getEnumFieldCollection(allOptionsForField) };
        case 'enumList':
            return { ...collection, [column.sideFilterKey]: getEnumListFieldCollection(allOptionsForField) };
        case 'date':
            return { ...collection, [column.sideFilterKey]: getDateFieldCollection(allOptionsForField) };
        case 'number':
            return { ...collection, [column.sideFilterKey]: getNumberFieldCollection(allOptionsForField) };
        case 'airportDestination':
        case 'airportOrigin':
            return { ...collection, [column.sideFilterKey]: getAirportsCollection(allOptionsForField) };
        default:
            return collection;
        }
    }, {});
};

const getColumnDataType = (columns: ColumnsType[], checkedField: string): ColumnFilterType => {
    const currentColumn = columns.find(column => column.filterKey === checkedField);

    const { filterType = null } = currentColumn || {};

    return filterType;
};

const getSideFilterDataType = (columns: ColumnsType[], checkedField: string): ColumnFilterType => {
    const currentColumn = columns.find(column => column.sideFilterKey === checkedField) || null;

    const { sideFilterType = null } = currentColumn || {};

    return sideFilterType;
};

export const getFilterFieldCount = (filter = {}) => (
    Object.keys(filter).reduce((count, field) => {
        return Array.isArray(filter[field])
            ? count + filter[field].length
            : count + 1;
    }, 0)
);

export const getFilteredData = (
    data = [], columns: ColumnsType[], clientSideFilter: ClientSideFilterType, columnFilter = false,
) => {
    return data.filter(item => {
        return Object.keys(clientSideFilter).every(checkedField => {
            const dataType = columnFilter
                ? getColumnDataType(columns, checkedField)
                : getSideFilterDataType(columns, checkedField);

            if (dataType === 'enum' || dataType === 'airportOrigin' || dataType === 'airportDestination') {
                return clientSideFilter[checkedField].includes(item[checkedField]) || !item[checkedField];
            } else if (dataType === 'enumList' && Array.isArray(item[checkedField])) {
                return item[checkedField].length === 0
                 || item[checkedField].some(enumListItem => clientSideFilter[checkedField].includes(enumListItem));
            } else if (dataType === 'stringList' && Array.isArray(item[checkedField])) {
                const [textFieldSample = null] = clientSideFilter[checkedField] || [];
                const checkedValue = item[checkedField].join();

                if (checkedValue && textFieldSample) {
                    return checkedValue.toLowerCase().includes(textFieldSample);
                }
            } else if (dataType === 'text') {
                const { [checkedField]: checkedValue = null } = item;
                const [textFieldSample = null] = clientSideFilter[checkedField] || [];

                if (checkedValue && textFieldSample) {
                    return checkedValue?.toLowerCase()?.includes(textFieldSample);
                }
            } else if (dataType === 'date' || dataType === 'number') {
                const [min = null, max = null] = clientSideFilter[checkedField];

                if (min === null && max === null) {
                    return true;
                }

                const value = dataType === 'date'
                    ? getMilliseconds(item[checkedField])
                    : item[checkedField];

                if (value === null) {
                    return false;
                } else if (min && max) {
                    return value >= min && value <= max;
                } else if (min) {
                    return value >= min;
                } else if (max) {
                    return value <= max;
                }
            }
            return false;
        });
    });
};

export const fetchCheckboxOptions = (options: EnumFilterOption[]): string[] => {
    return options.map(({ value }) => value);
};

export type PlaceInfo = {
    city: string,
    country: string,
}

export const fetchPlaceInfo: (lat, lng) => Promise<PlaceInfo> = async (lat, lng) => {
    if (lat && lng) {
        try {
            const response = await getGeocode({ location: { lat, lng } }) || [];

            const point = response.find((item) => (
                item.geometry.location.lat() === lat && item.geometry.location.lng() === lng
            )) || (response.length > 0 ? response[0] : null);

            if (point) {
                const { address_components = [] } = point;
                const cityComponent = address_components.find((item) => (
                    item.types.includes(GOOGLE_LOCATION_INFO.locality))) || null;
                const countryComponent = address_components.find((item) => (
                    item.types.includes(GOOGLE_LOCATION_INFO.country))) || null;

                const { long_name: city = '' } = cityComponent;
                const { long_name: country = '' } = countryComponent;

                return { city, country };
            }
        } catch (error) {
            global.console.error(error);
        }
    }
    return { city: '', country: '' };
};
