import React, {
    useState,
    useEffect, useCallback, useMemo,
} from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { AddPharmaSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import PharmaSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/PharmaSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import { isReadyPharmaSiteRequestBody } from 'Administration/lib';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from '../../Sites.style';

const AddPharmaSite = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { company } = useCurrentUserContext();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddPharmaSiteRequestBody>(null);
    const [geofenceSide, setGeofenceSide] = useState(null);
    const [geofenceEnabled, setGeofenceEnabled] = useState(false);

    const {
        Create: createPharma,
    } = useSecureBackendEndpoints(`pharma-companies/${company.id}/pharma-sites`).statusNotificationRequests;
    const {
        Create: createServiceProvider,
    } = useSecureBackendEndpoints(`service-provider-companies/${company.id}/service-provider-sites`)
        .statusNotificationRequests;

    const { t } = useCustomTranslation();

    const handleClickCreate = useCallback(async () => {
        const requestBody = {
            ...addCompanyRequestBody,
            geofenceSide,
        };

        try {
            const response = company.companyType === 'PHARMA' ? (await createPharma(requestBody))
                : (await createServiceProvider(requestBody));

            const { status = null } = response;

            if (status === 201) {
                navigate('/my-company/sites');
            }
        } catch (error) {
            global.console.log(error);
        }
    }, [addCompanyRequestBody, company, geofenceSide]);

    const handleClickCancel = useCallback(() => {
        navigate('/my-company/sites');
    }, [navigate]);

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            const object = {
                ...companyInfo,
                address,
                contactInfo,
            };

            object[company.companyType === 'PHARMA' ? 'pharmaCompanyId' : 'serviceProviderCompanyId'] = company.id;
            setAddCompanyRequestBody(object);
        } else {
            setAddCompanyRequestBody(null);
        }
    }, [
        address,
        contactInfo,
        companyInfo,
        company,
    ]);

    const readyToSubmission = useMemo(() => {
        if (geofenceEnabled && geofenceSide <= 0) { return false; }
        return isReadyPharmaSiteRequestBody(addCompanyRequestBody);
    }, [addCompanyRequestBody, geofenceEnabled, geofenceSide]);

    return (
        <>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyCardContentData}>
                    <PharmaSiteCard
                        currentCompanyType={company.companyType}
                        type="newMyCompany"
                        currentCompanyLogo={company.logoImagePath}
                        setFilter={setCompanyInfo}
                    />
                    <AddressCard
                        type="new"
                        setFilter={setAddress}
                        geofenceSide={geofenceSide}
                        setGeofenceSide={setGeofenceSide}
                        geofenceEnabled={geofenceEnabled}
                        setGeofenceEnabled={setGeofenceEnabled}
                    />
                    <ContactInformationCard
                        type="new"
                        setFilter={setContactInfo}
                    />
                </div>
            </div>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyButtonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        autoFocus
                        className={classes.addEditCompanyApproveButton}
                        disabled={!readyToSubmission}
                        onClick={handleClickCreate}
                    >
                        {t('MY_COMPANY.CREATE')}
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        className={classes.addEditCompanyRejectButton}
                        onClick={handleClickCancel}
                    >
                        {t('COMMON.CANCEL')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddPharmaSite;
