import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import Switch from '@mui/material/Switch';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import { CustomAnnotation } from 'shared-components/dataTypes';
import Card from 'shared-components/Card';
import ApexTemperatureChart from 'shared-components/ApexTemperatureChart';
import NoSensorInfo from 'shared-components/NoSensorInfo';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import useClasses from 'hooks/useClasses';
import styles from './Temperature.style';

const requestOptions = {
    loggers: [{ loggerTypeCode: 'CARTASENSE' }],
    dataTypes: [
        'TEMPERATURE',
        // 'DOOR',
    ],
    positions: [
        'INTERNAL',
        'AMBIENT',
    ],
};

type Props = {
    leaseEnd?: string,
    leaseStart: string,
    leaseEndExpected: string,
    dateTimeFrom: string,
    dateTimeTo: string,
    shipmentNumber?: string,
    containerOpenedOn?: string,
    serialNumber?: string,
    shipmentEnd?: string,
    temperatureExcursionOn?: string,
    temperatureRangeMax?: number,
    temperatureRangeMin?: number,
}

const Temperature = ({
    leaseEnd = null,
    leaseStart = null,
    leaseEndExpected = null,
    dateTimeTo = '',
    dateTimeFrom = '',
    containerOpenedOn = '',
    serialNumber = '',
    shipmentEnd = null,
    shipmentNumber = '',
    temperatureExcursionOn = null,
    temperatureRangeMin = null,
    temperatureRangeMax = null,
} : Props) => {
    const classes = useClasses(styles);
    const [showMarkers, setShowMarkers] = useState(false);
    const [showTempRange, setShowTempRange] = useState(true);
    const [isCurrentTime, setDateTimeRangeLimit] = useState(leaseEndExpected !== null);
    const { t } = useCustomTranslation();

    const customAnnotations = useMemo((): CustomAnnotation[] => {
        if (!containerOpenedOn && !leaseStart) {
            return [];
        }

        const containerOpenedOnAnnotation: CustomAnnotation[] = containerOpenedOn
            ? [{
                title: t('ORDER_DETAILS.CONTAINER_OPENED_ON'),
                anchor: 'end',
                offsetX: 50,
                date: containerOpenedOn,
            }]
            : [];

        const leaseStartTimestampAnnotation: CustomAnnotation[] = leaseStart
            ? [{
                title: t('COMMON.LEASE_START'),
                date: leaseStart,
            }]
            : [];

        return [
            ...containerOpenedOnAnnotation,
            ...leaseStartTimestampAnnotation,
        ];
    }, [containerOpenedOn, leaseStart]);

    const dateTimeToOptions = useMemo(() => {
        if (dateTimeTo) {
            return dateTimeTo;
        }

        return (leaseEndExpected && !isCurrentTime)
            ? leaseEndExpected
            : moment().format('YYYY-MM-DDTHH:mm');
    }, [isCurrentTime]);

    const handleChange = (event) => {
        setDateTimeRangeLimit(event.target.checked);
    };

    const handleChangeTemperatureRange = useCallback(() => setShowTempRange(prev => !prev), []);
    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);

    return (
        <Card fullHeight title={t('SENSOR_DATA.TEMPERATURE_UTC_TIME')}>
            <div className={classes.switchesContainer}>
                {
                    !dateTimeTo && leaseEnd === null && shipmentEnd === null && (
                        <div className={classes.switchContainerNested}>
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime
                                        ? classes.inactiveOption : classes.activeOption}`
                                }
                            >
                                {t('COMMON.EXPECTED_LEASE_END')}
                            </div>
                            <Switch
                                checked={isCurrentTime}
                                classes={{
                                    track: classes.switch_track,
                                    switchBase: classes.switch_base,
                                }}
                                color="primary"
                                onChange={handleChange}
                                disabled={leaseEndExpected === null}
                                size="small"
                            />
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime
                                        ? classes.activeOption : classes.inactiveOption}`
                                }
                            >
                                {t('SENSOR_DATA.NOW')}
                            </div>
                        </div>
                    )
                }
                <SwitchWithLabel
                    color="secondary"
                    title={t('COMMON.TEMPERATURE_RANGE')}
                    value={showTempRange}
                    onChange={handleChangeTemperatureRange}
                    // setValue={setShowTempRange}
                />
                <SwitchWithLabel
                    color="secondary"
                    title={t('SENSOR_DATA.MARKERS')}
                    value={showMarkers}
                    onChange={handleChangeMarkers}
                    // setValue={setShowMarkers}
                />
            </div>
            {
                dateTimeFrom
                    ? (
                        <ApexTemperatureChart
                            customAnnotations={customAnnotations}
                            dateTimeFrom={dateTimeFrom}
                            dateTimeTo={dateTimeToOptions}
                            requestOptions={requestOptions}
                            serialNumber={serialNumber}
                            shipmentNumber={shipmentNumber}
                            showMarkers={showMarkers}
                            showTempRange={showTempRange}
                            temperatureExcursionOn={temperatureExcursionOn}
                            temperatureRangeMin={temperatureRangeMin}
                            temperatureRangeMax={temperatureRangeMax}
                            height={350}
                        />
                    )
                    : (
                        <NoSensorInfo
                            key="noSensorInfo"
                            text={t('PRODUCT_RELEASE.YOUR_PACKAGING_LEASE_HAS_NOT_YET_STARTED')}
                        />
                    )
            }
        </Card>
    );
};

export default Temperature;
