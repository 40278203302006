import React, {
    createContext,
    // useEffect,
    useState,
    ReactChild, useCallback,
} from 'react';

interface CacheContextInterface {
    getCacheValue: <T>(key: string) => T,
    setCacheValue: (key: string, value: object | string | number) => void,
}

export const CacheContext = createContext<CacheContextInterface>({
    getCacheValue: null,
    setCacheValue: () => false,
});

type Props = {
    children: ReactChild,
}

type Cache = { [key: string]: any }

const CacheContextProvider = ({ children }: Props) => {
    const [cache, setCache] = useState<Cache>({});

    const getCacheValue: <T>(key: string) => T = useCallback((key) => {
        const { [key]: value = null } = cache;

        return value;
    }, [cache]);

    const setCacheValue = useCallback((key: string, value: object | string | number) => {
        setCache(prev => ({ ...prev, [key]: value }));
    }, []);

    return (
        <CacheContext.Provider
            value={{
                getCacheValue,
                setCacheValue,
            }}
        >
            { children }
        </CacheContext.Provider>
    );
};

export default CacheContextProvider;
