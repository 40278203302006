import React from 'react';
import useClasses from 'hooks/useClasses';
import styles from '../SideFilter.style';

type Props = {
    title: string,
}

const Title = ({ title } : Props) => {
    const classes = useClasses(styles);

    return <div className={classes.titleComponent}>{title}</div>;
};

export default Title;
