export const STATUS = {
    COMPLETE: 'COMPLETE',
    NOT_COMPLETE: 'NOT_COMPLETE',
    NOT_RECOVERABLE: 'NOT_RECOVERABLE',
    OVERRIDDEN: 'OVERRIDDEN',
};

export type PathParams = {
    id: string,
}
