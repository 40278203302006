import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const PADDING = 32;
const WIDTH = 450;

export const styles = (theme: SkycellThemeInterface) => ({
    modalContents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: 'auto',
        position: 'relative',
        width: WIDTH - PADDING * 2,

    },
    modalContentsSuccess: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        position: 'relative',
        width: WIDTH - PADDING * 2,

    },
    root: ({ open, shrinkFrom, screenSize, contentHeight }) => ({
        backgroundColor: '#ffffff',
        borderRadius: open ? '12px' : 0,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: 'auto',
        textAlign: 'center',
        position: 'fixed',
        transition: !open ? 'all 0.5s ease 0.1s, opacity 0.9s linear 0.5s' : 'all 0.5s ease, opacity 0.2s linear',
        zIndex: '10001',
        display: 'flex',
        left: !open ? shrinkFrom?.left : screenSize.width / 2,
        top: !open ? shrinkFrom?.top : screenSize.height / 2,
        width: !open ? shrinkFrom?.width : WIDTH,
        height: !open ? shrinkFrom?.height : contentHeight + PADDING * 2,
        flexDirection: 'column',
        overflow: 'hidden',
        opacity: open ? 1 : 0,
        padding: !open ? 0 : `${PADDING}px ${PADDING}px ${PADDING}px ${PADDING + 6}px`,
        transform: open ? 'translate(-50%, -50%)' : 'translate(0, 0)',
        pointerEvents: open ? 'auto' : 'none',
        alignItems: 'flex-start',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            backgroundColor: !open ? theme.palette.common.white : 'transparent',
            zIndex: '10001',
            transition: 'background-color 0.5s ease',
            pointerEvents: 'none',
        },
    }),
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: '48px',
        marginBottom: '12px',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '16px',
        fontWeight: '400',
        color: theme.palette.secondary[600],
        textTransform: 'uppercase',
        marginBottom: '8px',
        letterSpacing: '0.49px',
    },
    subTitle: {
        display: 'flex',
        fontSize: '24px',
        textAlign: 'left',
        fontWeight: '700',
        letterSpacing: '0.31px',
        color: theme.palette.primary.deepBlue,
        marginBottom: '18px',
    },
    text: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1rem',
        color: theme.palette.common.black,
        marginBottom: '24px',
        textAlign: 'left',
        whiteSpace: 'pre-line',
    },
    successText: {
        textAlign: 'center!important',
    },
    boldTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: '700',
        color: theme.palette.common.black,
        marginBottom: '24px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        gap: '16px',
    },
    centeredButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    skipButton: {
        background: theme.palette.common.white,
        color: theme.palette.primary.deepBlue,
        height: 'auto',
        padding: '12px 21px',
        textTransform: 'none',
        borderRadius: '0',
        border: `1px solid ${theme.palette.primary.deepBlue}`,
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            color: theme.palette.common.white,
            opacity: 0.8,
        },
    },
    getStartedButton: {
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        height: 'auto',
        padding: '12px 21px',
        textTransform: 'none',
        borderRadius: '0',
        border: `1px solid ${theme.palette.primary.deepBlue}`,
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            color: theme.palette.common.white,
            opacity: 0.8,
        },
    },
    closeButton: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        width: '16px',
        height: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '24px',
        color: '#6c757d',
    },
});
