import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import TemperatureCheckCell from './components/TemperatureCheckCell';
import ShipmentEvidenceCheckTableIcons from './components/ShipmentEvidenceCheckTableIcons';
import QMApprovalCell from './components/QMApprovalCell';
import { RequiredTableData } from './lib';

const bodyCellLinkTemplate = '/product-release/:shipmentNumber/:id';

const getTableColumnsOpen = (dataUpdateTrigger: (requiredDataUpdate: boolean)
    => void, t): ColumnsType<RequiredTableData>[] => ([
    {
        Header: ' ',
        accessor: (row: RequiredTableData) => (
            <div
                style={{
                    backgroundColor: row.cellColor,
                    height: '100%',
                    width: '13px',
                }}
            />
        ),
        dataType: 'custom',
        customCellStyle: { padding: '0' },
    },
    {
        Header: t('COMMON.SHIPMENT'),
        accessor: 'shipmentNumber',
        dataType: 'highlightedText',
        sideFilterKey: 'shipmentNumber',
        sideFilterType: 'enum',
        bodyCellLinkTemplate,
    },
    {
        Header: t('COMMON.PACKAGING'),
        accessor: 'packagingSerialNumber',
        dataType: 'highlightedText',
        bodyCellLinkTemplate,
    },
    {
        Header: t('PRODUCT_RELEASE.ENDED_ON'),
        accessor: 'shipmentEnd',
        dataType: 'date',
        bodyCellLinkTemplate,
    },
    {
        Header: t('COMMON.FROM'),
        accessor: 'shipmentFrom',
        dataType: 'text',
        sideFilterKey: 'shipmentFrom',
        sideFilterType: 'airportOrigin',
        bodyCellLinkTemplate,
    },
    {
        Header: t('COMMON.TO'),
        accessor: 'shipmentTo',
        dataType: 'text',
        sideFilterKey: 'shipmentTo',
        sideFilterType: 'airportDestination',
        bodyCellLinkTemplate,
    },
    {
        Header: t('COMMON.DESTINATION'),
        accessor: 'destinationLocation',
        dataType: 'text',
        bodyCellLinkTemplate,
    },
    {
        Header: `${t('PRODUCT_RELEASE.TEMPERATURE_CHECK') }, °C`,
        accessor: (row: RequiredTableData) => (
            <TemperatureCheckCell
                temperatureMax={row.temperatureMax}
                temperatureMin={row.temperatureMin}
                temperatureRangeMax={row.temperatureRangeMax}
                temperatureRangeMin={row.temperatureRangeMin}
                temperatureStatus={row.temperatureStatus}
            />
        ),
        customCellStyle: { padding: '0 0 0 12px' },
        dataType: 'custom',
        bodyCellLinkTemplate,
    },
    {
        Header: t('COMMON.RANGE_C'),
        accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
        dataType: 'custom',
        sortType: (rowA, rowB) => {
            const { original: originalA } = rowA || {};
            const { original: originalB } = rowB || {};
            const { temperatureRange: temperatureRangeA = '' } = originalA || {};
            const { temperatureRange: temperatureRangeB = '' } = originalB || {};

            return temperatureRangeA.localeCompare(temperatureRangeB);
        },
    },
    {
        Header: t('PRODUCT_RELEASE.SHIPMENT_EVIDENCE_CHECK'),
        accessor: (row: RequiredTableData) => (
            <ShipmentEvidenceCheckTableIcons
                containerStatusIcon={row.containerStatusIcon}
                palletStatus1Icon={row.palletStatus1Icon}
                palletStatus2Icon={row.palletStatus2Icon}
                sealStatusIcon={row.sealStatusIcon}
            />
        ),
        customCellStyle: { padding: '0 0 0 12px' },
        dataType: 'custom',
        bodyCellLinkTemplate,
    },
    {
        Header: t('PRODUCT_RELEASE.QM_APPROVAL'),
        accessor: (row: RequiredTableData) => (
            <QMApprovalCell
                dataUpdateTrigger={dataUpdateTrigger}
                evidenceId={row.id}
                orderNumber={row.shipmentNumber}
            />
        ),
        customCellStyle: { padding: '0 0 0 12px' },
        dataType: 'custom',
    },
]);

export default getTableColumnsOpen;
