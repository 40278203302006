import React, {
    useState,
    useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { PharmaSite, ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';
import { AddPharmaSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import PharmaSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/PharmaSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import GeofencesCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import styles from '../../Sites.style';

type PathParams = {
    siteId: string,
}
const EditPharmaSite = () => {
    const classes = useClasses(styles);
    const { company } = useCurrentUserContext();
    const hasAccess = useHasAccess();
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { siteId } = useParams() as unknown as PathParams;
    const { Get: getPharmaSite } = useSecureBackendEndpoints('pharma-sites').requests;
    const { Get: getGeofence } = useSecureBackendEndpoints('geofences').requests;
    const { Get: getServiceProviderSite } = useSecureBackendEndpoints('service-provider-sites').requests;
    const { FlexibleRequest: editPharmaSite } = useSecureBackendEndpoints('pharma-sites').statusNotificationRequests;
    const {
        FlexibleRequest: editServiceProviderSite,
    } = useSecureBackendEndpoints('service-provider-sites').statusNotificationRequests;
    const [geofences, setGeofences] = useState<Geofence[]>([]);
    const [siteInfo, setSiteInfo] = useState(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddPharmaSiteRequestBody>(null);
    const [availableData, setAvailableData] = useState<PharmaSite>(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const handleClickEdit = async () => {
        try {
            const { status = null } = company.companyType === 'PHARMA'
                ? (await editPharmaSite('PATCH', `/${siteId}/overview-info`, addCompanyRequestBody))
                : (await editServiceProviderSite('PATCH', `/${siteId}/overview-info`, addCompanyRequestBody));

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && siteInfo) {
            setAddCompanyRequestBody({
                ...siteInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        siteInfo,
    ]);
    useEffect(() => {
        setBreadcrumbData('SITE_NAME', siteInfo?.name || '');
    }, [siteInfo]);

    useEffect(() => {
        if (requiredUpdateData) {
            (async () => {
                try {
                    const rawResponse = company.companyType === 'PHARMA'
                        ? (await getPharmaSite(siteId)) : (await getServiceProviderSite(siteId));

                    const response: PharmaSite = rawResponse?.data || null;

                    if (response) {
                        setAvailableData(response);
                        setContactInfo(response.contactInfo);
                        setSiteInfo({
                            name: response.name,
                        });
                        setAddress({
                            addressLine1: response.address.addressLine1,
                            addressLine2: response.address.addressLine2,
                            addressLine3: response.address.addressLine3,
                            city: response.address.city,
                            countryCode: response.address.country.code,
                            geolocation: response.address.geolocation,
                            zipCode: response.address.zipCode,
                        });
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setRequiredUpdateData(false);
                }

                if (hasAccess(userRoles.SITE_MANAGEMENT) || hasAccess(userRoles.CUSTOMER_ADMIN)) {
                    try {
                        const geofenceResponse = await getGeofence(`site/${siteId}`);

                        setGeofences(geofenceResponse?.data || []);
                    } catch (error) {
                        global.console.log(error);
                    }
                }
            })();
        }
    }, [company.companyType, requiredUpdateData]);

    return (
        availableData
            ? (
                <div className={classes.editCompanyPageContent}>
                    <div className={classes.addEditCompanyCardContentData}>
                        <PharmaSiteCard
                            currentCompanyLogo={availableData.logoImagePath}
                            currentSiteName={availableData?.name}
                            currentEditedCard={currentEditedCard}
                            type="editable"
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setSiteInfo}
                            currentCompanyType="PHARMA"
                        />
                        <AddressCard
                            currentAddressLine1={availableData.address.addressLine1}
                            currentAddressLine2={availableData.address.addressLine2}
                            currentAddressLine3={availableData.address.addressLine3}
                            currentCity={availableData.address.city}
                            currentZipCode={availableData.address.zipCode}
                            currentCountry={availableData.address.country.name}
                            currentCountryCode={availableData.address.country.code}
                            currentGeolocation={availableData.address.geolocation}
                            currentEditedCard={currentEditedCard}
                            type="editable"
                            setFilter={setAddress}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                        />
                        <ContactInformationCard
                            currentContactPerson={availableData.contactInfo.contactPerson}
                            currentPhoneNumber={availableData.contactInfo.phoneNumber}
                            currentEmail={availableData.contactInfo.email}
                            currentEditedCard={currentEditedCard}
                            type="editable"
                            setFilter={setContactInfo}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                        />
                        {
                            (hasAccess(userRoles.SITE_MANAGEMENT) || hasAccess(userRoles.CUSTOMER_ADMIN)) && (
                                <GeofencesCard
                                    currentEditedCard={currentEditedCard}
                                    setCurrentEditedCard={setCurrentEditedCard}
                                    currentGeofences={geofences}
                                    setGeofences={setGeofences}
                                    siteId={siteId}
                                />
                            )
                        }
                    </div>
                </div>
            )
            : null
    );
};

export default EditPharmaSite;
