import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import CloseIcon from '@mui/icons-material/Close';
import { LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import { useTheme } from '@emotion/react';
import { getQuickHelpAsset } from 'shared-components/icons';
import { ExtractedLoggerData, extractLoggerData } from 'TrackAndTrace/Loggers/lib';
import generateStyles from './SearchPreviewTable.style';
import tableColumns from './tableColumns';

type Props = {
    isTableView: boolean,
    loggersFromPreviewTable: ExtractedLoggerData[],
    setLoggersFromPreviewTable: Dispatch<SetStateAction<ExtractedLoggerData[]>>,
    changeHighlightedText: (newHighlightedText: any) => void,
    setQuery?: Dispatch<SetStateAction<string>>,
    query?: string,
    core?:string,
}

const SearchPreviewTable = ({
    isTableView,
    changeHighlightedText,
    loggersFromPreviewTable = null,
    setLoggersFromPreviewTable,
    query = '',
    setQuery,
    core = 'track-and-trace',
} : Props) => {
    const textRef = useRef(null);
    const { t } = useCustomTranslation();
    const theme = useTheme();

    const classes = generateStyles(theme, {
        searchBoxLeft: textRef.current?.getBoundingClientRect()?.x || 0,
    });
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedLoggerData[]>([]);
    const [lastQueryTyped, setLastQueryTyped] = useState('');

    const {
        data: rawLoggers,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            query: {
                page: 0,
                pageSize: 10,
                q: lastQueryTyped,
            },
            enabled: lastQueryTyped?.length > 2 && !isTableView,
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (query && query !== lastQueryTyped) {
                setLastQueryTyped(query);
            }

            setOpen(query.length > 2);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [query, lastQueryTyped, isTableView]);

    const queryChanged = (event) => {
        setQuery(event.target.value);
        if (event.target.value) {
            changeHighlightedText(event.target.value.replace(/[\\/#!$%^&*;:{}=\-_`~()]/g, ''));
            setOpen(event.target.value.length !== 0);
        } else if (!event.target.value && isTableView) {
            setLoggersFromPreviewTable([]);
        }
    };

    const handleFocus = () => {
        setOpen(prev => prev || query.length > 0 || loggersFromPreviewTable?.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);
    const handleClickShowResults = () => {
        setOpen(false);
    };
    const handleClickClearSearch = useCallback(() => {
        setLoggersFromPreviewTable([]);
        setOpen(false);
        setQuery('');
    }, []);

    useEffect(() => {
        if (rawLoggers?.resultList) {
            const extractedAssetData = extractLoggerData(rawLoggers.resultList.slice(0, 10));

            const processedAssetData = extractedAssetData.map(obj => {
                return { ...obj, loggerFamilyLabel: t(`LOGGER_TYPE_LABEL.${obj.loggerFamilyLabel}`) };
            });

            global.console.log('extractedAssetData', processedAssetData);
            setData(processedAssetData);
            if (isTableView) {
                setLoggersFromPreviewTable(processedAssetData);
            }
        }
    }, [rawLoggers]);

    const columns = useMemo(() => tableColumns(
        t,
        core === 'track-and-trace',
    ), [t, core]);

    return (
        <div style={{
            display: 'flex',
        }}
        >
            <QuickHelpTooltip
                tooltipInfo={{
                    text: t('ONBOARDING.LOGGERS.SEARCH_DESC'),
                    title: t('ONBOARDING.LOGGERS.SEARCH_TITLE'),
                    image: getQuickHelpAsset('logger_search.gif'),
                    order: 1,
                    position: 'right',
                    uid: 'searchBySerial',
                }}
            >
                <TextField
                    className={classes.input}
                    id="TextFieldInput"
                    onInput={queryChanged}
                    value={query}
                    variant="outlined"
                    placeholder={t('TRACK_AND_TRACE.SEARCH_LOGGER')}
                    ref={textRef}
                    onFocus={handleFocus}
                    onClick={handleClick}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: theme.palette.common.black }} />
                            </InputAdornment>
                        ),
                        className: classes.input,
                    }}
                />
            </QuickHelpTooltip>
            <Button
                className={classes.clearSearchBtn}
                onClick={() => handleClickClearSearch()}
                disabled={!query && loggersFromPreviewTable.length === 0}
            >
                <CloseIcon />
            </Button>
            {
                !isTableView && (
                    <PreviewTable
                        data={data}
                        columns={columns}
                        maskForHighlight={query}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewTable;
