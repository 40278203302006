import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    dashWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    dot: {
        position: 'absolute',
        left: '50%',
        zIndex: 10,
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.deepBlue,
        transform: 'translate(-50%, -50%)',
    },
    dotFirst: {
        width: '16px',
        height: '16px',
    },
    line: {
        position: 'absolute',
        left: '50%',
        top: 1,
        zIndex: 9,
        transform: 'translateX(-50%)',
    },
});

export default styles;
