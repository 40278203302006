import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useMemo, useState } from 'react';
import { BUTTON_MAP } from 'LaneManagement/AddLane/Cards/AddLocation/BUTTON_MAP';
import ControlledTooltip from 'shared-components/ControlledTooltip';
import LocationInfo from 'LaneManagement/AddLane/Cards/AddLocation/LocationInfo';
import useCustomTranslation from 'hooks/useCustomTranslation';
import generateStyles from './FlowElement.style';
import { ProcessedMilestone } from '../PredictedTransportCard';

type Props = {
    step?: ProcessedMilestone,
    sum?: number,
    shrink?:boolean,
    tbd?: boolean,
    hint?: string, // for dev purposes
    active?: boolean,
}

const FlowElement = ({
    step,
    sum,
    shrink = false,
    tbd = false,
    active = false,
    hint,
}: Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const stepTime = useMemo(() => {
        if (tbd) return 180 * 60;
        return Math.floor(step.end - step.start) / 1000;
    }, [step, tbd]);

    const { t } = useCustomTranslation();
    const showTooltip = useMemo(() => {
        return (step?.pickupLocation || step?.deliveryLocation) && shrink && !tbd && tooltipOpen;
    }, [step, tooltipOpen]);

    const stepWidth = useMemo(() => {
        return ((stepTime / sum) * 100).toFixed(1);
    }, [stepTime, sum, tbd]);

    const tooltipTitle = useMemo(() => {
        if (!shrink || tbd) return '';
        return (
            <span className={classes.tooltipDescription}>
                {
                    step.pickupLocation && (
                        <LocationInfo
                            singleLocation={step.pickupLocation}
                            minWidth
                            short
                        />
                    )
                }
                {
                    step.deliveryLocation
                    && step.pickupLocation
                    && <span className={classes.divider} />
                }
                {
                    step.deliveryLocation && (
                        <LocationInfo
                            singleLocation={step.deliveryLocation}
                            minWidth
                            short
                        />
                    )
                }
                {
                    hint && (
                        <>
                            <br />
                            <code>{hint}</code>
                        </>
                    )
                }
            </span>
        );
    }, [
        step?.pickupLocation,
        step?.deliveryLocation,
        tbd,
        hint,
    ]);
    const time = useMemo(() => {
        const rawHours = Math.floor(stepTime / 60);
        const hours = rawHours !== 0 ? `${rawHours}${t('TIME.H')} ` : '';
        const minutes = stepTime % 60 !== 0 ? `${stepTime % 60}${t('TIME.M')}` : '';

        return `${hours}${minutes}`;
    }, [stepTime]);

    return (
        <div
            className={[classes.root, shrink ? classes.shrinked : ''].join(' ')}
            style={{
                flex: `${stepWidth}%`,
            }}
            onMouseEnter={setTooltipOpen.bind(null, true)}
            onMouseLeave={setTooltipOpen.bind(null, false)}
        >
            {
                !shrink && (
                    <div className={classes.time}>
                        {tbd ? 'tbd' : time}
                    </div>
                )
            }
            <ControlledTooltip
                className={classes.tooltipBody}
                borderColor="blue"
                description={tooltipTitle}
                placement="bottom"
                open={showTooltip}
            >
                <div className={[classes.line, tbd ? classes.lineTbd : '', active && classes.active].join(' ')} />
            </ControlledTooltip>
            <div className={classes.iconWrapper}>
                {
                    !tbd && (
                        <img
                            className={classes.icon}
                            src={BUTTON_MAP().find(it => it.milestoneType === step?.type)?.icon}
                            alt="stepIcon"
                        />
                    )
                }
            </div>
        </div>
    );
};

export default FlowElement;
