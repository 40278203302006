import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme:SkycellThemeInterface) => ({
    select: {
        color: theme.palette.common.black,
    },
    disabled: {
        '-webkit-text-fill-color': `${theme.palette.common.black}!important`,
    },
    caretIcon: {
        display: 'none',
    },
    removeIcon: {
        height: '20px',
        width: '30px',
        '& *': {
            fontSize: '16px',
        },
    },
    textField: {
        '& > div': {
            paddingRight: '1px!important',
        },
    },
    withPlaceholder: {
        color: theme.palette.secondary[500],
    },

});
