const styles = () => ({
    noBorder: {
        border: 'none !important',
    },
    clickedRowExternalClassName: {
        border: '2px solid #61C6E9 !important',
        backgroundColor: '#EEFAFF',
    },
    tableHeader: {
        boxShadow: 'none !important',
    },
});

export default styles;
