import { userRoles } from 'hooks/useHasAccess';
import icons from 'shared-components/icons';
import { Tab } from 'Contexts/LayoutContext';
import { TFunction } from 'i18next';
import { ONBOARDING_TYPE } from '../Contexts/QuickHelpContext/QuickHelpContext';

interface MenuTab extends Tab {
    joinToPath?: boolean,
    hide?: boolean
}

export type StandardItem = {
    title: string,
    pageTitle?: string,
    id: string,
    info: string,
    hide?: boolean,
    iconSrc: string,
    link: string,
    /*  Roles that are necessary will be checked with Array.prototype.every.
        Leave necessaryRoles empty if you use sufficientRoles.
        Roles that are sufficient will be checked with Array.prototype.some */
    necessaryRoles?: string[],
    sufficientRoles?: string[],
    customPagesMask?: RegExp,
    tabs?: MenuTab[],
    customPagesTabs?: MenuTab[],
}
type Item = StandardItem & {
    children?: StandardItem[],
}

const menuItems: (t?: TFunction) => Item[] = (t) => [
    {
        title: t('MENU_ITEMS.ASSET_MANAGEMENT'),
        pageTitle: t('MENU_ITEMS.TRACK_AND_TRACE_ASSETS'),
        id: 'assetsManagement',
        info: t('MENU_ITEMS.SENSOR_DATA_DESCRIPTION'),
        iconSrc: icons.asset_management,
        link: 'asset-management',
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        children: [
            {
                title: 'Assets',
                id: 'assetManagementAssets',
                info: t('MENU_ITEMS.SENSOR_DATA_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'asset-management/assets',
                necessaryRoles: [userRoles.ASSET_MANAGEMENT],
            },
            {
                title: t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS'),
                id: 'loggers',
                info: t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'asset-management/loggers',
                sufficientRoles: [
                    userRoles.LOGGER_MANAGEMENT,
                    userRoles.ASSET_MANAGEMENT,
                ],
            },

        ],
    },
    {
        title: t('MENU_ITEMS.TRACK_N_TRACE'),
        id: 'trackAndTrace',
        info: t('MENU_ITEMS.TRACK_N_TRACE_DESCRIPTION'),
        iconSrc: icons.orders,
        link: 'track-and-trace',
        sufficientRoles: [
            userRoles.SHIPMENT_MANAGEMENT,
            userRoles.LOGGER_MANAGEMENT,
            userRoles.TEMPERATURE_CHECK,
        ],
        customPagesMask: /\/shipments\/\d*/,
        children: [
            {
                title: t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS'),
                id: 'shipments',
                info: t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'track-and-trace/generic-shipments',
                sufficientRoles: [userRoles.SHIPMENT_MANAGEMENT, userRoles.TEMPERATURE_CHECK],
            },
            {
                title: t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS'),
                id: 'loggers',
                info: t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'track-and-trace/loggers',
                sufficientRoles: [
                    userRoles.LOGGER_MANAGEMENT,
                    userRoles.ASSET_MANAGEMENT,
                ],
            },
        ],
    },
    {
        title: t('COMMON.PRODUCT_RELEASE'),
        id: 'productRelease',
        info: t('MENU_ITEMS.PRODUCT_RELEASE_DESCRIPTION'),
        iconSrc: icons.approvals,
        link: 'product-release',
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
    },
    {
        title: t('MENU_ITEMS.INVOICES'),
        id: 'invoices',
        info: t('MENU_ITEMS.INVOICES_DESCRIPTION'),
        iconSrc: icons.finance,
        link: 'invoices',
        necessaryRoles: ['do not show'],
    },
    {
        title: t('COMMON.LANE_MANAGEMENT'),
        id: 'laneManagement',
        info: t('MENU_ITEMS.LANE_MANAGEMENT_DESCRIPTION'),
        iconSrc: icons.lanes,
        link: 'lane-management',
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        children: [
            {
                title: t('MENU_ITEMS.LANES'),
                id: 'lanes',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'lane-management/lanes',
                necessaryRoles: [userRoles.LANE_MANAGEMENT],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_LANE'),
                        link: 'add',
                        iconSrc: icons.addCircle,
                        iconStyle: {
                            width: '15px',
                            height: '15px',
                            marginRight: '8px',
                        },
                        joinToPath: true,
                    },
                ],
                customPagesMask: /\/lane-management\/lanes\/\d*\//,
                customPagesTabs: [
                    {
                        title: t('MENU_ITEMS.DEFINITION'),
                        link: 'definition',
                    },
                    {
                        title: t('MENU_ITEMS.TEMPERATURE_SIMULATION'),
                        link: 'temperature-simulation',
                    },
                    {
                        title: t('MENU_ITEMS.RISK_ASSESSMENT'),
                        link: 'risk-assessment',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.SIMULATION_VS_OPERATIONS'),
                        link: 'simulation-operations',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.DOCUMENTS'),
                        link: 'documents',
                        hide: true,
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.LANE_COMPARISON'),
                id: 'laneComparison',
                info: t('MENU_ITEMS.LANE_COMPARISON_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'lane-management/lane-comparison',
                necessaryRoles: [userRoles.LANE_MANAGEMENT],
            },
            {
                title: t('MENU_ITEMS.SERVICE_PROVIDERS'),
                id: 'serviceProviders',
                info: t('MENU_ITEMS.SERVICE_PROVIDERS_DESCRIPTION'),
                iconSrc: icons.service_providers,
                link: 'lane-management/service-providers',
                necessaryRoles: [userRoles.LANE_MANAGEMENT],
                customPagesMask: /\/service-providers\//,
                customPagesTabs: [
                    {
                        title: t('COMMON.OVERVIEW'),
                        link: 'overview',
                    },
                    {
                        title: t('COMMON.CAPABILITIES'),
                        link: 'capabilities',
                        hide: true,
                    },
                    {
                        title: t('ADMINISTRATION.PARENT_COMPANY'),
                        link: 'parent-company',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.GALLERY'),
                        link: 'gallery',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.DOCUMENTS'),
                        link: 'documents',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.LANES'),
                        link: 'lanes',
                        hide: true,
                    },
                ],
            },
            {
                title: t('COMMON.PRODUCTS'),
                id: 'laneProducts',
                hide: true,
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'lane-management/products',
                necessaryRoles: [userRoles.LANE_MANAGEMENT],
            },
            {
                title: t('MENU_ITEMS.LANE_COMPARISONS'),
                id: 'laneComparisons',
                hide: true,
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'lane-management/lane-comparisons',
                necessaryRoles: [userRoles.LANE_MANAGEMENT],
            },
        ],
    },
    {
        title: t('MENU_ITEMS.DOCUMENTS'),
        id: 'documents',
        info: t('MENU_ITEMS.DOCUMENTS_DESCRIPTION'),
        iconSrc: icons.document,
        link: 'documents',
        necessaryRoles: ['dont show'],
    },
    {
        title: t('MENU_ITEMS.INSIGHTS'),
        id: 'insights',
        info: t('MENU_ITEMS.INSIGHTS_DESCRIPTION'),
        iconSrc: icons.statistics,
        link: 'insights',
        sufficientRoles: [userRoles.INSIGHTS, userRoles.ASSET_MANAGEMENT],
        customPagesMask: /\/shipments\/\d*/,
        children: [
            {
                title: t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS'),
                id: 'shipmentsReport',
                info: t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'insights/shipments',
                necessaryRoles: [userRoles.INSIGHTS],
            },
            {
                title: t('MENU_ITEMS.ASSET_INVENTORY'),
                id: 'assetInventory',
                info: t('MENU_ITEMS.ASSET_INVENTORY_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'insights/asset-inventory',
                sufficientRoles: [userRoles.INSIGHTS, userRoles.ASSET_MANAGEMENT],
            },
            {
                title: t('MENU_ITEMS.LANE_RISK'),
                id: 'laneRisk',
                info: t('MENU_ITEMS.LANE_RISK_DESCRIPTION'),
                iconSrc: icons.orders,
                link: 'insights/lane-risk',
                necessaryRoles: [userRoles.INSIGHTS],
            },
        ],
    },
    {
        title: t('MENU_ITEMS.BOOKINGS'),
        id: 'bookings',
        info: t('MENU_ITEMS.BOOKING_DESCRIPTION'),
        iconSrc: icons.bookings,
        link: 'bookings',
        necessaryRoles: [userRoles.BOOKING_MANAGEMENT],
        children: [
            {
                title: t('MENU_ITEMS.PLACE_BOOKING'),
                id: 'placeBooking',
                info: 'Place Booking',
                iconSrc: icons.lanes,
                link: 'bookings/new',
                necessaryRoles: [userRoles.BOOKING_MANAGEMENT],
            },
            {
                title: t('MENU_ITEMS.MANAGE_BOOKINGS'),
                id: 'manageBookings',
                info: 'Manage ManageBookings',
                iconSrc: icons.lanes,
                link: 'bookings',
                necessaryRoles: [userRoles.BOOKING_MANAGEMENT],
            },
        ],
    },
    {
        title: t('MENU_ITEMS.MY_COMPANY'),
        id: 'myCompany',
        info: t('MENU_ITEMS.MY_COMPANY_DESCRIPTION'),
        iconSrc: icons.my_company,
        link: 'my-company',
        sufficientRoles: [
            userRoles.BASIC_ACCESS,
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
            userRoles.ASSET_MANAGEMENT,
            userRoles.ASSET_PAIRING,
            userRoles.INSIGHTS,
            userRoles.INTELLIGENT_MONITORING,
            userRoles.LANE_MANAGEMENT,
            userRoles.LOGGER_MANAGEMENT,
            userRoles.PRODUCT_RELEASE,
            userRoles.SHIPMENT_MANAGEMENT,
            userRoles.TEMPERATURE_CHECK,
            userRoles.BOOKING_MANAGEMENT,
        ],
        children: [
            {
                title: t('MENU_ITEMS.COMPANY_DETAILS'),
                id: 'companyDetails',
                info: 'Company Details',
                iconSrc: icons.lanes,
                link: 'my-company/company',
                sufficientRoles: [
                    userRoles.BASIC_ACCESS,
                    userRoles.CUSTOMER_ADMIN,
                    userRoles.SITE_MANAGEMENT,
                    userRoles.ASSET_MANAGEMENT,
                    userRoles.ASSET_PAIRING,
                    userRoles.INSIGHTS,
                    userRoles.INTELLIGENT_MONITORING,
                    userRoles.LANE_MANAGEMENT,
                    userRoles.LOGGER_MANAGEMENT,
                    userRoles.PRODUCT_RELEASE,
                    userRoles.SHIPMENT_MANAGEMENT,
                    userRoles.TEMPERATURE_CHECK,
                    userRoles.BOOKING_MANAGEMENT,
                ],
                tabs: [
                    {
                        title: t('COMMON.OVERVIEW'),
                        link: 'overview',
                    },
                    {
                        title: t('COMMON.CAPABILITIES'),
                        link: 'capabilities',
                        hide: true,
                    },
                    {
                        title: t('COMMON.QUESTIONNAIRE'),
                        link: 'questionnaire',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.MONITORING'),
                        link: 'monitoring',
                        hide: true,
                    },
                ],
            },
            {
                title: t('COMMON.SITES'),
                id: 'myCompanySites',
                info: 'Company Sites',
                iconSrc: icons.lanes,
                link: 'my-company/sites',
                necessaryRoles: [
                    userRoles.SITE_MANAGEMENT,
                ],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_SITE'),
                        link: 'new',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                        onboarding: {
                            title: t('ONBOARDING.MY_COMPANY_SITES.ADD_SITE_TITLE'),
                            text: t('ONBOARDING.MY_COMPANY_SITES.ADD_SITE_DESC'),
                            order: 1,
                            position: 'auto',
                            padding: 0,
                            core: ONBOARDING_TYPE.SITE,
                            uid: 'addMyCompanySiteOnboarding',
                        },
                    },
                ],
                customPagesMask: /\/my-company\/sites\/\d*\//,
                customPagesTabs: [
                    {
                        title: t('COMMON.OVERVIEW'),
                        link: 'overview',
                    },
                    {
                        title: t('COMMON.CAPABILITIES'),
                        link: 'capabilities',
                        hide: true,
                    },
                    {
                        title: t('COMMON.QUESTIONNAIRE'),
                        link: 'questionnaire',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.GALLERY'),
                        link: 'gallery',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.DOCUMENTS'),
                        link: 'documents',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.INFORMATION_SHARING'),
                        link: 'information-sharing',
                        hide: true,
                    },
                    {
                        title: t('MENU_ITEMS.LANES'),
                        link: 'lanes',
                        hide: true,
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.USERS'),
                id: 'users',
                info: 'Company Users',
                iconSrc: icons.lanes,
                link: 'my-company/users',
                necessaryRoles: [
                    userRoles.CUSTOMER_ADMIN,
                ],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_USER'),
                        link: 'new',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                        onboarding: {
                            title: t('ONBOARDING.MY_COMPANY_USERS.ADD_USER_TITLE'),
                            text: t('ONBOARDING.MY_COMPANY_USERS.ADD_USER_DESC'),
                            order: 2,
                            position: 'auto',
                            padding: 0,
                            core: ONBOARDING_TYPE.USER,
                            uid: 'addUserOnboarding',
                        },
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.ACCESS_REQUESTS'),
                id: 'myCompanyAccessRequests',
                info: t('MENU_ITEMS.ACCESS_REQUESTS'),
                iconSrc: icons.no_icon,
                link: 'my-company/access-requests',
                necessaryRoles: [
                    userRoles.ASSET_MANAGEMENT,
                ],
                customPagesMask: /\/my-company\/access-requests\/\d*\//,
                tabs: [
                    {
                        title: 'Outgoing Requests',
                        link: 'outgoing-requests',
                    },
                    {
                        title: 'Incoming Requests',
                        link: 'incoming-requests',
                        hide: true,
                    },
                ],
            },

        ],
    },
    {
        title: t('COMMON.ADMINISTRATION'),
        id: 'administration',
        info: t('MENU_ITEMS.ADMINISTRATION_DESCRIPTION'),
        iconSrc: icons.administration,
        link: 'administration',
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        children: [
            {
                title: t('MENU_ITEMS.COMPANIES'),
                id: 'companies',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'administration/companies',
                necessaryRoles: [userRoles.SKYCELL_ADMIN],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_COMPANY'),
                        link: 'add',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.SERVICE_PROVIDER_SITES'),
                id: 'serviceProviderSites',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'administration/service-provider-sites',
                necessaryRoles: [userRoles.SKYCELL_ADMIN],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_SERVICE_PROVIDER_SITE'),
                        link: 'add',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                    },
                ],
            },
            {
                title: t('COMMON.PHARMA_SITES'),
                id: 'pharmaSites',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'administration/pharma-sites',
                necessaryRoles: [userRoles.SKYCELL_ADMIN],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_PHARMA_SITE'),
                        link: 'add',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.USERS'),
                id: 'users',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'administration/users',
                necessaryRoles: [userRoles.SKYCELL_ADMIN],
                tabs: [
                    {
                        title: t('MENU_ITEMS.ADD_USER'),
                        link: 'add',
                        iconSrc: icons.addCircle,
                        joinToPath: true,
                    },
                ],
            },
            {
                title: t('MENU_ITEMS.SHIPMENTS'),
                id: 'shipments',
                info: 'Something just for test',
                iconSrc: icons.lanes,
                link: 'administration/shipments',
                necessaryRoles: [userRoles.SKYCELL_ADMIN],
            },
        ],
    },
];

export default menuItems;
