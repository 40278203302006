import React, { CSSProperties, ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import { ColumnDataType } from 'shared-components/Table/dataTypes';

import useClasses from 'hooks/useClasses';
import DataTypes from './DataTypes';
import styles from './BodyCell.style';

type Props = {
    bodyCellProps?: any,
    bodyData: string | number | ReactNode,
    colSpan?: number,
    customCellStyle?: CSSProperties,
    dataType?: ColumnDataType,
    maskForHighlight?: string,
    onClick?: (...args: any) => any,
    className?: string,
}

const BodyCell = ({
    bodyData = null,
    bodyCellProps = null,
    colSpan,
    dataType = 'text',
    customCellStyle = {},
    maskForHighlight = '',
    onClick = null,
    className = '',
} : Props) => {
    const classes = useClasses(styles);

    return (
        <TableCell
            {...bodyCellProps}
            className={[classes.bodyCell, className].join(' ')}
            colSpan={colSpan}
            onClick={onClick}
            style={customCellStyle}
            width={colSpan ? 0 : null}
        >
            <DataTypes
                data={bodyData}
                dataType={dataType}
                maskForHighlight={maskForHighlight}
            />
        </TableCell>
    );
};

export default BodyCell;
