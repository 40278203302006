import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const CELL_LINE_PADDING = 7;
const CELL_LINE_OFFSET = 11;
const styles = (theme: SkycellThemeInterface) => ({
    headerCell: {
        padding: '0!important',
        font: 'normal normal bold 16px/19px Roboto!important',
        letterSpacing: '0.01em!important',
        color: `${theme.palette.common.black}!important`,
        fontSize: '14px!important',
        opacity: 1,
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:not(:last-child)::after': {
            content: '""',
            height: `calc(100% - ${CELL_LINE_PADDING * 2}px);`,
            width: '2px;',
            background: theme.palette.common.dividerColor,
            position: 'absolute;',
            right: CELL_LINE_OFFSET,
            top: CELL_LINE_PADDING,
        },
    },
    cellContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px!important',
        paddingBottom: '8px!important',
        paddingRight: '20px!important',
        '&:first-child': {
            paddingLeft: '8px',
        },
    },
    headerData: {
        display: 'inline-block',
        wordBreak: 'keep-all',
        whiteSpace: 'nowrap',

    },
});

export default styles;
