import React, {
    CSSProperties, useCallback, useContext,
    useEffect, useMemo,
    useState,
} from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import CurrentTemperature from 'shared-components/CurrentTemperature';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import { LayoutContext } from '../Contexts/LayoutContext';
import {
    EntitySelectorItem,
} from './dataTypes';
import EntityController from './components/EntityController';
import Options from './components/Options';
import useBreadcrumbsContext from '../hooks/useBreadcrumbsContext';
import styles from './SensorData.style';
import { SensorDataItem } from '../dataTypes/SecureBackend/apiResponse';

type Props = {
    entitySelectorItem: EntitySelectorItem,
    requestBody: SensorDataRequestBody,
    setRequestBody: (requestBody: SensorDataRequestBody) => void,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    setTimeRange: (timeRange: TimeRange) => void,
    timeRange: TimeRange,
    loggerList: EntitySelectorItem[],
    isCurrentTime: boolean,
    setIsCurrentTime: (isCurrentTime?: boolean) => void,
    loggersFetched: boolean,
    core?: string,
}

const LoggerSensorData = ({
    entitySelectorItem,
    requestBody,
    setRequestBody,
    setEntitySelectorItem,
    setTimeRange,
    timeRange,
    loggerList = [],
    isCurrentTime,
    setIsCurrentTime,
    loggersFetched,
    core = 'track-and-trace',
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [localTimezone, setLocalTimezone] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const { setBreadcrumbData } = useBreadcrumbsContext();

    const { entityType, additionalData } = entitySelectorItem ?? {};
    const {
        lastMeasuredTemp,
        lastMeasuredTempInternal = null,
        lastMeasuredTempInternalTimestamp = null,
        batteryLevel,
    } = additionalData ?? {};

    useEffect(() => {
        setBreadcrumbData('entityType', t(({
            logger: t('TRACK_AND_TRACE.LOGGER'),
        })[entityType]));
    },
    [entitySelectorItem]);
    const {
        availableHeight,
    } = useContext(LayoutContext);

    const loadingWrapperStyle: CSSProperties = useMemo(() => (loggersFetched ? {} : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: Number(availableHeight.replaceAll('px', '')) - 20,
    }), [loggersFetched, availableHeight]);

    const [currentData, setCurrentData] = useState<{
        data: SensorDataItem[],
        labels: {
            dataTypes: string[],
            loggerTypes: string[],
            positions: string[],
        },
    }>(null);
    const getSensorData = useCallback<(serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void>((serialNumber: string, data: SensorDataItem[], labels) => {
            setCurrentData({
                data,
                labels,
            });
        }, []);

    const mapAvailable = useMemo(() => currentData?.data?.length > 0
        && currentData?.labels?.dataTypes?.includes('LOCATION_LATITUDE'), [currentData]);

    return (
        <>
            <div className={classes.contentContainer}>
                <div style={loadingWrapperStyle}>
                    <EntityController
                        entitySelectorItem={entitySelectorItem}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                        isLoading={!loggersFetched}
                        core={core}
                        getSensorData={getSensorData}
                    />
                </div>
                <div className={classes.contentRightColumn}>
                    <Options
                        entitySelectorItem={entitySelectorItem}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setTimeRange={setTimeRange}
                        loggerList={loggerList}
                        disabled={entityType === null}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        setRequestBody={setRequestBody}
                        setLocalTimezone={setLocalTimezone}
                        setShowMap={setShowMap}
                        mapAvailable={mapAvailable}
                        timeRange={timeRange}
                        isCurrentTime={isCurrentTime}
                        setIsCurrentTime={setIsCurrentTime}
                    />
                    <CurrentTemperature
                        lastMeasuredTempInternal={
                            lastMeasuredTemp || lastMeasuredTempInternal
                        }
                        lastMeasuredOnUtcInternal={
                            lastMeasuredTempInternalTimestamp
                        }
                        energyLevel={typeof batteryLevel === 'number' ? Number((batteryLevel * 100).toFixed(0)) : null}
                    />
                </div>
            </div>
        </>
    );
};

export default LoggerSensorData;
