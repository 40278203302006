import React from 'react';
import { TFunction } from 'i18next';
import { AccessRequestDTO } from 'MyCompany/AccessRequests/lib';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import StatusChip from 'shared-components/StatusChip/StatusChip';

const tableColumns: (t:TFunction, query: string) => ColumnsType<AccessRequestDTO>[] = (t) => [
    {
        Header: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
        accessor: 'dataOwnerCompanyName',
        dataType: 'text',
        id: 'dataOwnerCompanyName',
    },
    {
        Header: t('COMMON.SITE_NAME'),
        accessor: 'siteName',
        dataType: 'highlightedText',
        id: 'siteName',
    },
    {
        Header: t('COMMON.GEOFENCE_NAME'),
        accessor: 'geofenceName',
        dataType: 'highlightedText',
        id: 'geofenceName',
    },
    {
        Header: t('COMMON.STATUS'),
        accessor: ({ status }) => <StatusChip status={status} t={t} />,
        dataType: 'custom',
        disableSortBy: true,
        id: 'status',
    },
];

export default tableColumns;
