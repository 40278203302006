import React, { useCallback, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import CustomModal from 'shared-components/CustomModal';
import useClasses from 'hooks/useClasses';
import styles from './CompanyRolesCard.style';

type Props = {
    roleEnums: string[],
}

const RoleDetails = ({ roleEnums = [] } : Props) => {
    const classes = useClasses(styles);
    const [open, setOpen] = useState(false);
    const userRoleLabels = useGetTranslationGroup('USER_POLICY');
    const userRoleDescription = useGetTranslationGroup('USER_POLICY_DESCRIPTION');
    const { t } = useCustomTranslation();
    const handleClick = useCallback(() => setOpen(!open), []);
    const handleClose = useCallback(() => setOpen(false), []);

    return (
        <>
            <div
                className={classes.tooltipIconContainer}
                onClick={handleClick}
            >
                <span className={classes.tooltipIcon}>?</span>
                <div>{t('MY_COMPANY.ROLE_DETAILS')}</div>
            </div>
            <CustomModal
                className={classes.modalData}
                onClose={handleClose}
                open={open}
                title={t('MY_COMPANY.ROLE_DETAILS')}
            >
                {
                    roleEnums.map(roleEnum => {
                        return (
                            <div className={classes.dataRow} key={roleEnum}>
                                <div>{userRoleLabels[roleEnum]}</div>
                                <div>
                                    {userRoleDescription[`${roleEnum}_DESCRIPTION`]}
                                </div>
                            </div>
                        );
                    })
                }
            </CustomModal>
        </>
    );
};

export default RoleDetails;
