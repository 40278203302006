import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import GroupMarker from 'TrackAndTrace/Assets/components/GroupMarker';
import AirportTooltip from 'TrackAndTrace/Assets/components/AirportTooltip';
import LegendPanel from 'TrackAndTrace/Assets/components/LegendPanel';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import TooltipPolygonMarker from 'TrackAndTrace/commonComponents/TooltipPolygonMarker';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';
import useSupercluster from 'use-supercluster';
import { Feature } from 'geojson';
import { getQuickHelpAsset } from 'shared-components/icons';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import { ActiveAsset, MapElement } from 'TrackAndTrace/Assets/lib';
import { isWithinBounds } from 'TrackAndTrace/lib';
import AreaMarker from 'TrackAndTrace/Assets/components/AreaMarker';
import AreaTooltip from 'TrackAndTrace/Assets/components/AreaTooltip';
import Supercluster from 'supercluster';
import CustomPolygon from '../components/CustomPolygon';

type Props = {
    showAirportsInfo: boolean,
    mapElements: MapElement[],
    geofences: Geofence[],
    zoom: number,
    setZoom: (zoom: number) => void,
    isAirportShowingMode: boolean,
}

const MapView = ({
    mapElements,
    showAirportsInfo,
    geofences = [],
    zoom,
    setZoom,
    isAirportShowingMode,
}: Props) => {
    const { t } = useCustomTranslation();
    const { enableTooltips, currentStep } = useContext(QuickHelpContext);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);
    const [activeAsset, setActiveAsset] = useState<ActiveAsset>({
        assetNumber: null,
        timestamp: Date.now(),
    });

    const availableSpace = useAvailableHeight();
    const [mapContainerRef, setMapContainerRef] = useState(null);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });
    const [hoveredGroupGeoKey, setHoveredGroupGeoKey] = useState<string | null>(null);
    const airportsInPassedBoundsZone = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    const closeActiveTooltip = useCallback(() => {
        const delay = 700;
        const now = Date.now();

        if (activeAsset.assetNumber !== null && activeAsset.timestamp + delay < now) {
            setActiveAsset({
                assetNumber: null,
                timestamp: now,
            });
        }
        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeAsset, activeAirportInfo]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    const showedAirportInfo = useMemo(() => {
        if (airportsInPassedBoundsZone.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airportsInPassedBoundsZone.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airportsInPassedBoundsZone]);

    const mapGeoElements = useMemo<Array<Supercluster.PointFeature<object>>>(() => mapElements
        .map((mapElement, index) => ({
            geometry: {
                coordinates: [mapElement?.geolocation?.longitude, mapElement?.geolocation?.latitude],
                type: 'Point',
            },
            id: `${mapElement?.code}_${index}`,
            properties: {
                category: 'mapElements',
                cluster: false,
                code: mapElement?.code,
            },
            type: 'Feature',
        })), [mapElements]);
    const {
        clusters,
        supercluster,
    } = useSupercluster({
        points: mapGeoElements,
        bounds: bounds as [number, number, number, number],
        zoom,
        options: {
            radius: 200,
            maxZoom: Math.max(Math.round(zoom), 1),
        },
    });

    const withoutGeolocationCount = 0;

    const filteredGeofences = useMemo(() => {
        if (zoom < 8) return [];

        const expansionInKm = 20;
        const latitudeAdjust = expansionInKm / 111;
        const midLatitude = (bounds[1] + bounds[3]) / 2;
        const longitudeAdjust = expansionInKm / (111 * Math.cos((midLatitude * Math.PI) / 180));

        const expandedBounds = {
            sw: { lat: bounds[1] - latitudeAdjust, lng: bounds[0] - longitudeAdjust },
            ne: { lat: bounds[3] + latitudeAdjust, lng: bounds[2] + longitudeAdjust },
        };

        return geofences.filter(geofence => {
            return geofence.coordinates.some(point => isWithinBounds(point, expandedBounds));
        });
    }, [bounds, geofences, zoom]);

    const isTour = useMemo(() => currentStep === 3 && enableTooltips, [currentStep, enableTooltips]);

    useEffect(() => {
        setHoveredGroupGeoKey(null);
    }, [mapElements]);
    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    order: 3,
                    text: t('ONBOARDING.ASSET_MANAGEMENT.MAP_ELEMENTS_DESC'),
                    title: t('ONBOARDING.ASSET_MANAGEMENT.MAP_ELEMENTS'),
                    backgroundOpacity: 0.2,
                    padding: 0,
                    radius: '50%',
                    childOffsetPercent: [50, 50],
                    customRectSize: [95, 95],
                    offsetPx: [65, 67],
                    positionDelta: [-20, 0],
                    explicitChildRef: mapContainerRef,
                    position: 'left',
                    image: getQuickHelpAsset('asset_details.gif'),
                    uid: 'onboardingMapElementsAssets',
                }}
            >
                <BaseMap
                    onContainerRefLoad={(ref) => {
                        setMapContainerRef(ref);
                    }}
                    mapContainerStyle={{
                        width: '100%',
                        height: `calc(${availableSpace} - 60px)`,
                    }}
                    gMap={gMap}
                    setGMap={setGMap}
                    setBounds={setBounds}
                    setZoom={setZoom}
                    onClick={closeActiveTooltip}
                >
                    {
                        isTour && (
                            <TooltipPolygonMarker itemType="Asset" />
                        )
                    }
                    {
                        !isTour && (
                            <>
                                {
                                    isAirportShowingMode && clusters.map((cluster) => {
                                        let child: Feature[];

                                        try {
                                            child = cluster.id ? supercluster.getChildren(cluster.id as number)
                                                : [cluster];
                                        } catch (error) {
                                            child = [cluster];
                                        }
                                        const mapElementCodes: string[] = child.map(it => it?.properties?.code);
                                        const grouped: MapElement[] = mapElements
                                            .filter(el => mapElementCodes.includes(el.code));

                                        const geokey = (cluster?.id || grouped[0]?.code
                                            || `${Math.random()}`).toString(); // Ensure unique key

                                        return (
                                            <React.Fragment key={`${geokey}_Fragment`}>
                                                {
                                                    hoveredGroupGeoKey === geokey && (
                                                        <AirportTooltip
                                                            key={`${geokey}_tooltip`}
                                                            mapElements={grouped}
                                                            position={{
                                                                lng: cluster.geometry.coordinates[0],
                                                                lat: cluster.geometry.coordinates[1],
                                                            }}
                                                            onMouseLeave={() => setHoveredGroupGeoKey(null)}
                                                        />
                                                    )
                                                }
                                                <GroupMarker
                                                    key={geokey}
                                                    mapElements={grouped}
                                                    position={{
                                                        lng: cluster.geometry.coordinates[0],
                                                        lat: cluster.geometry.coordinates[1],
                                                    }}
                                                    onHover={() => setHoveredGroupGeoKey(geokey)}
                                                    isSelected={hoveredGroupGeoKey === geokey}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                }

                                {
                                    !isAirportShowingMode && mapElements.map((mapElement, index) => (
                                        <React.Fragment key={`${mapElement.code}_${index}_Fragment`}>
                                            {
                                                hoveredGroupGeoKey === mapElement.code && (
                                                    <AreaTooltip
                                                        key={`${mapElement.code}_${index}_tooltip`}
                                                        area={mapElement}
                                                        position={{
                                                            lng: mapElement.geolocation.longitude,
                                                            lat: mapElement.geolocation.latitude,
                                                        }}
                                                        onMouseLeave={() => setHoveredGroupGeoKey(null)}
                                                        zoom={zoom}
                                                    />
                                                )
                                            }
                                            <AreaMarker
                                                key={`${mapElement.code}_${index}`}
                                                isSelected={hoveredGroupGeoKey === mapElement.code}
                                                mapElement={mapElement}
                                                onHover={() => setHoveredGroupGeoKey(mapElement.code)}
                                            />
                                        </React.Fragment>
                                    ))
                                }
                            </>
                        )
                    }

                    {
                        filteredGeofences.map(({ coordinates, name, type }, index) => {
                            const latLngCoordinates = coordinates
                                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));

                            const geokey = `geofence-${coordinates
                                .map(({ latitude, longitude }) => `${latitude}-${longitude}`).join('-')}_${index}`;

                            return (
                                <CustomPolygon
                                    key={`${geokey}_geofence_polygon`}
                                    map={gMap}
                                    isNameVisible={zoom > 15}
                                    systemGeofence={type === 'AIRPORT'}
                                    name={name}
                                    paths={latLngCoordinates}
                                    position={latLngCoordinates[0]}
                                />
                            );
                        })
                    }
                    {
                        showAirportsInfo && airportsInPassedBoundsZone.map((airport, index) => {
                            const handleClickAirportsInfo = () => {
                                setActiveAirportInfo(prev => ({
                                    code: prev.code === airport.code ? null : airport.code,
                                    timestamp: Date.now(),
                                }));
                            };

                            return (
                                <AirportMarker
                                    key={`${airport.code}_${index}_airportMarker`}
                                    airportInfo={airport}
                                    isOpen={activeAirportInfo.code === airport.code}
                                    onClick={handleClickAirportsInfo}
                                />
                            );
                        })
                    }
                    {
                        showedAirportInfo !== null && (
                            <AirportInfoTooltip
                                airportInfo={showedAirportInfo}
                                cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                            />
                        )
                    }
                    <LegendPanel withoutGeolocationCount={withoutGeolocationCount} showWithoutGeolocation={false} />
                </BaseMap>
            </QuickHelpTooltip>
        </div>
    );
};

export default MapView;
