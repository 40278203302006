import React, {
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { RequestStatus } from 'dataTypes/common';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import loadGoogleCharts from 'utils/loadGoogleCharts';
import StatusInfo from 'shared-components/StatusInfo';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import { ProductReleaseSeparate, fetchProcessedData } from './lib';
import ProductReleaseDetails from './ProductReleaseDetails';

type PathParams = {
    shipmentNumber: string,
    productReleaseId: string,
}

const ProductReleaseDetailsContainer = () => {
    loadGoogleCharts();
    const {
        shipmentNumber,
        productReleaseId,
    } = useParams<PathParams>();
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { t } = useCustomTranslation();
    const { FlexibleRequest: getProductRelease } = useSecureBackendEndpoints('skyship/shipment').requests;
    const { Get: getShipment } = useSecureBackendEndpoints('shipment').requests;

    const packagingPictureSides = useGetTranslationGroup('PACKAGING_PICTURE_SIDES');
    const [requiredOrderDataUpdate, setRequiredOrderDataUpdate] = useState(false);
    const [productRelease, setProductRelease] = useState<ProductReleaseSeparate>(null);
    const [shipment] = useState<ShipmentDetailsInterface>(null);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [shipmentRequestStatus, setShipmentRequestStatus] = useState<RequestStatus>('INIT');

    useEffect(() => {
        setBreadcrumbData('excludedPathsList', [`/product-release/${shipmentNumber}`]);
    }, []);

    useEffect(() => {
        if (status !== 'PENDING' && Object.keys(packagingPictureSides).length > 0) {
            (async () => {
                try {
                    setStatus('PENDING');
                    const link = `${shipmentNumber}/product-release/${productReleaseId}`;
                    const response = (await getProductRelease('GET', link)).data;

                    if (response) {
                        setProductRelease(fetchProcessedData(response, packagingPictureSides, [], t));
                        setRequiredOrderDataUpdate(false);
                    }
                    setStatus('SUCCESS');
                } catch (error) {
                    setBreadcrumbData('PRODUCT_RELEASE_TITLE', '');

                    setStatus('FAILURE');
                    global.console.log(error);
                }
            })();
        }
    }, [packagingPictureSides, requiredOrderDataUpdate]);

    useEffect(() => {
        if (shipmentRequestStatus !== 'PENDING') {
            (async () => {
                try {
                    setShipmentRequestStatus('PENDING');
                    const response = (await getShipment(shipmentNumber)).data;

                    if (response) {
                        // setShipment(getShipmentData(response));
                    }
                    setShipmentRequestStatus('SUCCESS');
                } catch (error) {
                    setShipmentRequestStatus('FAILURE');
                    global.console.log(error);
                }
            })();
        }
    }, [requiredOrderDataUpdate]);

    return status === 'SUCCESS' && productRelease
        ? (
            <ProductReleaseDetails
                productRelease={productRelease}
                setRequiredOrderDataUpdate={setRequiredOrderDataUpdate}
                shipment={shipment}
                shipmentNumber={shipmentNumber}
            />
        )
        : <StatusInfo status={status} />;
};

export default ProductReleaseDetailsContainer;
