import useClasses from 'hooks/useClasses';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddCircle from '@mui/icons-material/AddCircle';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { ServiceProviderSite } from 'dataTypes/SecureBackend/apiResponse';
import { fetchTableData } from 'ServiceProviders/lib';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from 'MyCompany/Sites/Sites.style';
import tableColumns from './tableColumns';

type Props = {
    className?: string,
    serviceProviderId?: number | unknown,
}

const ServiceProviderSites = forwardRef<HTMLDivElement, Props>(({ className = '', serviceProviderId }: Props, ref) => {
    const { GetAll: getServiceProviderSites } = useSecureBackendEndpoints(
        `service-provider-companies/${serviceProviderId}/service-provider-sites`)
        .requests;
    const [data, setData] = useState([]);
    const { t } = useCustomTranslation();

    const classes = useClasses(styles);

    useEffect(() => {
        (async () => {
            try {
                const response: ServiceProviderSite[] = (await getServiceProviderSites()).data;

                setData(fetchTableData(response));
            } catch (error) {
                setData([]);
                global.console.log(error);
            }
        })();
    }, []);

    return (
        <Card
            className={className}
            title={t('COMMON.SITES')}
            zeroSidePadding
            ref={ref}
        >
            <Table
                data={data}
                columns={tableColumns(t)}
                rowLinkTemplate="/my-company/sites/:id/overview"
                tableMaxHeight="300px"
                embed
                classNames={{
                    bodyCell: classes.cell,
                    headerCell: classes.hcell,
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '11px 13px 0',
                }}
            >
                <Link to="/my-company/sites/new">
                    <AddCircle fontSize="small" color="action" />
                </Link>
            </div>
        </Card>
    );
});

export default ServiceProviderSites;
