import React, {
    ChangeEvent,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
} from 'react';
import {
    Checkbox,
    FormControlLabel, CircularProgress,
} from '@mui/material';
import useClasses from 'hooks/useClasses';
import OptionItemTooltip from './OptionItemTooltip';
import styles from '../SideFilter.style';
import UncontrolledTooltip from '../../ControlledTooltip/UncontrolledTooltip';

type Props = {
    count?: number,
    disabled?: boolean,
    checked?: boolean,
    label: string,
    setCheckboxGroupOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    showTooltip?: boolean,
    tooltipDescription?: string | ReactNode,
    optionKey: string,
    optionsGroupKey: string,
    countsLoading?: boolean,
    shrinkNames?: boolean,
}

const CheckboxOption = ({
    count = null,
    checked = false,
    disabled = false,
    label = null,
    setCheckboxGroupOptions,
    showTooltip = false,
    tooltipDescription = '',
    optionKey,
    optionsGroupKey,
    countsLoading = false,
    shrinkNames = false,
} : Props) => {
    const classes = useClasses(styles);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCheckboxGroupOptions(prev => {
            const { [optionsGroupKey]: selectedOptions } = prev;

            const updatedSelectedOptions = event.target.checked
                ? selectedOptions?.includes(optionKey) ? selectedOptions : [...selectedOptions || [], optionKey]
                : selectedOptions?.filter(option => option !== optionKey);

            return { ...prev, [optionsGroupKey]: updatedSelectedOptions };
        });
    }, []);

    if (showTooltip) {
        return (
            <OptionItemTooltip
                description={tooltipDescription}
                descriptionTitle={label}
            >
                <div className={classes.checkboxRow}>
                    <FormControlLabel
                        disabled={disabled}
                        label={(
                            <UncontrolledTooltip
                                description={label}
                                enabled={label?.length >= 21 && shrinkNames}
                                shrinked
                                placement="right"
                            >
                                <div className={classes.formControlLabelStyle}>{label}</div>
                            </UncontrolledTooltip>
                        )}
                        classes={{
                            label: classes.labelEllipsis,
                        }}
                        className={classes.formControlLabelStyle}
                        control={(
                            <Checkbox
                                className={classes.checkboxStyle}
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                                checked={checked}
                                onChange={handleChange}
                                size="small"
                            />
                        )}
                    />
                    {count !== null && !countsLoading && <div className={classes.count}>{count}</div>}
                    {countsLoading && <div className={classes.count}><CircularProgress size={15} /></div>}
                </div>
            </OptionItemTooltip>
        );
    }

    return (
        <div className={classes.checkboxRow}>
            <FormControlLabel
                disabled={disabled}
                label={(
                    <UncontrolledTooltip
                        description={label}
                        enabled={label?.length >= 21 && shrinkNames}
                        shrinked
                        placement="right"
                    >
                        <div className={classes.formControlLabelStyle}>
                            {
                                (label?.length >= 21 && shrinkNames) ? label.slice(0, 21).concat('...') : label
                            }
                        </div>
                    </UncontrolledTooltip>
                )}
                classes={{
                    label: classes.labelEllipsis,
                }}
                className={classes.formControlLabelStyle}
                control={(
                    <Checkbox
                        className={classes.checkboxStyle}
                        classes={{
                            root: classes.root,
                            checked: classes.checked,
                        }}
                        checked={checked}
                        onChange={handleChange}
                        size="small"
                    />
                )}
            />
            {count !== null && !countsLoading && <div className={classes.count}>{count}</div>}
            {countsLoading && <div className={classes.count}><CircularProgress size={15} /></div>}
        </div>
    );
};

export default CheckboxOption;
