import React from 'react';
import { Button } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import { PICTURES } from '../constants';
import styles from './ErrorScreen.style';

type Props = {
    text?: string,
    description?: string,
    onAction?: any,
}

const ErrorScreen = ({
    text,
    description,
    onAction = null,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>{text || t('COMMON.CONTACT_ADMIN')}</div>
            {
                description && (
                    <div className={classes.text}>
                        {description}
                    </div>
                )
            }
            <img
                className={classes.picture}
                src={PICTURES.noAccessRightsWithout404Bubble}
                alt="ErrorImage"
            />
            {
                onAction && (
                    <Button
                        className={classes.button}
                        onClick={onAction}
                    >
                        {
                            t('COMMON.RETRY')
                        }
                    </Button>
                )
            }

        </div>
    );
};

export default ErrorScreen;
