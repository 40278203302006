/* eslint-disable max-len */
import React, {
    useCallback,
    useMemo,
    useState,
    useEffect,
} from 'react';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOutMapOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomInMapOutlined';
import {
    CompositePolyline,
    LatLngTimeTimestamp,
    TimeRange,
} from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';

import Card from 'shared-components/Card';
import Map from 'shared-components/Map';
import ApexTemperatureChart, {
    ZoomedDataLimits,
    getPolylinePath,
    defaultPolylineOptions,
} from 'shared-components/ApexTemperatureChart';
import icons from 'shared-components/icons';
import { TEMPERATURE_RANGES_VALUES } from 'shared-components/constants';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import NoSensorInfo from 'shared-components/NoSensorInfo';
import RectangleContentLoader from 'shared-components/RectangleContentLoader';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './ShipmentPackagingUnit.style';

type Props = {
    isActivePackaging?: boolean,
    isOnlyPackagingInTheShipment?: boolean,
    isSelectedAnyPackaging?: boolean,
    requestBody: SensorDataRequestBody,
    serialNumber: string,
    shipmentNumber: string,
    setShipmentsActivePackagingSerialNumber: (serialNumber: string) => void,
    showMap?: boolean,
    temperatureRange: string,
    timeRange: TimeRange,
    localTimezone?: boolean,
}

const ShipmentPackagingUnit = ({
    isActivePackaging,
    isOnlyPackagingInTheShipment = false,
    isSelectedAnyPackaging,
    requestBody,
    shipmentNumber,
    serialNumber,
    setShipmentsActivePackagingSerialNumber,
    showMap = false,
    temperatureRange,
    timeRange,
    localTimezone = false,
}: Props) => {
    const classes = useClasses(styles);
    const [gMap, setGMap] = useState(null);
    const [mouseMoveDataIndex, setMouseMoveDataIndex] = useState(0);
    const [zoomedDataLimits, setZoomedDataLimits] = useState<ZoomedDataLimits>({ min: null, max: null });
    const [showMarkers, setShowMarkers] = useState(false);
    const [showTempRange, setShowTempRange] = useState(true);
    const [showGeolocationContentLoader, setShowGeolocationContentLoader] = useState(false);
    const [coordinates, setCoordinates] = useState<LatLngTimeTimestamp[]>([]);
    const [minimized, setMinimized] = useState(false);
    const { t } = useCustomTranslation();

    const {
        tempRangeMin,
        tempRangeMax,
    } = useMemo(() => {
        if (temperatureRange) {
            return TEMPERATURE_RANGES_VALUES[temperatureRange];
        }

        return { tempRangeMin: null, tempRangeMax: null };
    }, [temperatureRange]);

    useEffect(() => {
        setZoomedDataLimits({ min: null, max: null });
    }, [coordinates]);

    const polylines = useMemo((): CompositePolyline => {
        if (!coordinates || coordinates.length === 0) {
            return {
                path: [],
                options: null,
            };
        }

        const path = getPolylinePath(coordinates, zoomedDataLimits);

        return {
            path,
            options: defaultPolylineOptions,
        };
    }, [coordinates, zoomedDataLimits]);

    const markerData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || mouseMoveDataIndex < 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                position: null,
                icon: null,
            };
        }
        return {
            position: coordinates[mouseMoveDataIndex].location,
            icon: icons.hex_with_cross,
        };
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const tooltipData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                location: null,
                time: '',
            };
        }
        return coordinates[mouseMoveDataIndex];
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const bounds = useMemo(() => {
        const lats = polylines.path.map((item) => item.lat);
        const lngs = polylines.path.map((item) => item.lng);

        return {
            south: lats.length ? Math.min(...lats) : null,
            west: lngs.length ? Math.min(...lngs) : null,
            north: lats.length ? Math.max(...lats) : null,
            east: lngs.length ? Math.max(...lngs) : null,
        };
    }, [polylines, showMap]);

    const mapConfig = useMemo(() => {
        return {
            containerStyle: {
                height: '45vh',
                width: '100%',
            },
            zoom: 7,
            backgroundColor: 'unset',
        };
    }, [markerData.position]);

    const height = useMemo(() => {
        return isActivePackaging
            ? showMap ? 300 : 600
            : 200;
    }, [isActivePackaging, showMap, minimized]);

    useEffect(() => {
        if (gMap && polylines.path.length > 0 && bounds.east && bounds.north && bounds.south && bounds.west) {
            gMap.fitBounds(bounds);
            gMap.setZoom(gMap.getZoom() - 1);
            setTimeout(() => {
                gMap.setZoom(gMap.getZoom() + 1);
            }, 0);
        }
    }, [bounds, gMap]);

    useEffect(() => {
        if (isSelectedAnyPackaging && !isActivePackaging) {
            setMinimized(true);
        } else {
            setMinimized(false);
        }
    }, [isActivePackaging, isSelectedAnyPackaging]);

    useEffect(() => {
        if (showMap && isActivePackaging && !minimized && polylines.path.length === 0) {
            setShowGeolocationContentLoader(true);
            setTimeout(() => setShowGeolocationContentLoader(false), 7000);
        }
    }, [
        showMap,
        isActivePackaging,
        minimized,
        polylines,
    ]);

    const handleLoad = (map) => {
        setGMap(map);
    };

    const handleZoomedButton = () => {
        if (isActivePackaging) {
            setShipmentsActivePackagingSerialNumber(null);
        } else {
            setMinimized(false);
            setShipmentsActivePackagingSerialNumber(serialNumber);
        }
    };

    const handleChangeTemperatureRange = useCallback(() => setShowTempRange(prev => !prev), []);
    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);

    return (
        <>
            <Card
                className={classes.packagingItemCard}
                contentClass={
                    minimized
                        ? classes.minimizedPackagingItemContent
                        : classes.packagingItemContent
                }
            >
                <Typography variant="h3" className={classes.packagingTitle}>
                    {
                        `${t('COMMON.PACKAGING')} ${serialNumber} | ${t('COMMON.TEMPERATURE')} \
                    ${localTimezone ? '' : t('SENSOR_DATA.IN_C_UTC_TIME')}`
                    }
                    <div className={classes.packagingTitle}>
                        {
                            !minimized && (
                                <div className={classes.packagingTitle}>
                                    <SwitchWithLabel
                                        color="secondary"
                                        onChange={handleChangeTemperatureRange}
                                        title={t('COMMON.TEMPERATURE_RANGE')}
                                        tooltipInfo={{
                                            text: t('QUICK_HELP.SENSOR_DATA.SHOW_TEMPERATURE_RANGE'),
                                            uid: serialNumber,
                                        }}
                                        value={showTempRange}
                                        // setValue={setShowTempRange}
                                    />
                                    <SwitchWithLabel
                                        color="secondary"
                                        onChange={handleChangeMarkers}
                                        title={t('SENSOR_DATA.MARKERS')}
                                        value={showMarkers}
                                        // setValue={setShowMarkers}
                                    />
                                </div>

                            )
                        }
                        {
                            !isOnlyPackagingInTheShipment && (
                                <IconButton
                                    onClick={handleZoomedButton}
                                    size="small"
                                >
                                    {
                                        isActivePackaging
                                            ? <ZoomInIcon color="action" fontSize="small" />
                                            : <ZoomOutIcon color="action" fontSize="small" />
                                    }
                                </IconButton>
                            )
                        }
                    </div>
                </Typography>
                {
                    (!isSelectedAnyPackaging || isActivePackaging) && !minimized && timeRange.from !== null && (
                        <ApexTemperatureChart
                            serialNumber={serialNumber}
                            dateTimeFrom={timeRange.from}
                            shipmentNumber={shipmentNumber}
                            dateTimeTo={timeRange.to}
                            temperatureRangeMax={tempRangeMax}
                            temperatureRangeMin={tempRangeMin}
                            showTempRange={showTempRange}
                            showMarkers={showMarkers}
                            requestOptions={requestBody}
                            height={height}
                            setCoordinates={setCoordinates}
                            setMouseMoveDataIndex={showMap ? setMouseMoveDataIndex : () => {}}
                            setZoomedDataLimits={showMap ? setZoomedDataLimits : null}
                            inLocalTimeZone={localTimezone}
                            excursionRange
                        />
                    )
                }
                {
                    (!isSelectedAnyPackaging || isActivePackaging) && !minimized && timeRange.from === null && (
                        <NoSensorInfo
                            picture="noDataFound"
                            // eslint-disable-next-line max-len
                            text={`${t('SENSOR_DATA.NO_GEOLOCATION')}`}
                        />
                    )
                }
            </Card>
            {
                showMap && isActivePackaging && !minimized
                    ? (
                        <Card
                            className={classes.mapCard}
                            title={
                            `${t('COMMON.PACKAGING')} ${serialNumber}\
                            | ${t('SENSOR_DATA.GEOLOCATION')}`
                            }
                        >
                            {
                                polylines.path.length > 0
                                    ? (
                                        <Map
                                            mapConfig={mapConfig}
                                            separatePolyline={polylines}
                                            separateMarker={markerData.position}
                                            separateMarkerIcon={markerData.icon}
                                            separateTooltip={tooltipData}
                                            handleLoad={handleLoad}
                                            showCenterToPosition
                                            inLocalTimeZone={localTimezone}
                                        />
                                    )
                                    : (
                                        showGeolocationContentLoader
                                            ? <RectangleContentLoader height="45vh" />
                                            : (
                                                <NoSensorInfo
                                                    picture="noDataFound"
                                                    // eslint-disable-next-line max-len
                                                    text={`${t('SENSOR_DATA.NO_GEOLOCATION')}`}
                                                />
                                            )
                                    )
                            }
                        </Card>
                    )
                    : null
            }
        </>
    );
};

export default ShipmentPackagingUnit;
