const styles = (theme) => ({
    textField: {
        margin: '0 25px',
        width: 'calc(100% - 50px)',
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    dialogActions: {
        marginTop: '27px',
        marginBottom: '19px',
        justifyContent: 'center',
    },
    approveButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
    },
    rejectButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
    },
    dialogTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    dialogContentText: {
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    qmApprovalDetailContainer: { marginTop: '10px' },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    qmStatusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '4px',
        width: '13px',
        height: '14px',
    },
    fieldTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    fieldInfo: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    decisionContainer: {
        marginLeft: '0',
        marginRight: 'auto',
    },
    byContainer: {
        marginRight: '0',
        marginLeft: 'auto',
    },
    commentContainer: {
        margin: '15px 0',
    },
    line: {
        color: theme.palette.secondary[300],
        opacity: 0.5,
        margin: '0',
    },
});

export default styles;
