import React, {
    useState,
    useEffect,
} from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { AddCompanyRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CompanyCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/CompanyCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import { isReadyCompanyRequestBody } from 'Administration/lib';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import styles from 'Administration/Companies/Companies.style';

const AddCompany = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [roles, setRoles] = useState([]);
    const [logoFormData, setLogoFormData] = useState<FormData>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddCompanyRequestBody>(null);
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const { Create: createCompany } = useSecureBackendEndpoints('admin/companies').statusNotificationRequests;
    const { FlexibleRequest: uploadFile } = useSecureBackendEndpoints('admin/companies').requests;
    const [nameCheckInProgress, setNameCheckInProgress] = useState<boolean>(false);
    const { t } = useCustomTranslation();

    const handleClickCreate = async () => {
        try {
            const response = await createCompany(addCompanyRequestBody);
            const status = response?.status;

            if (status === 201) {
                const id = response.headers.location;

                if (response && logoFormData) {
                    await uploadFile('POST', `/${id.split('/').pop()}/logo`, logoFormData);
                }
                navigate('/administration/companies');
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const handleClickCancel = () => {
        navigate('/administration/companies');
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            setAddCompanyRequestBody({
                ...companyInfo,
                address,
                contactInfo,
                roles: ['CUSTOMER_ADMIN', ...roles],
            });
        } else {
            setAddCompanyRequestBody(null);
        }
    }, [
        address,
        contactInfo,
        companyInfo,
        roles,
    ]);

    useEffect(() => {
        setReadyToSubmission(isReadyCompanyRequestBody(addCompanyRequestBody));
    }, [addCompanyRequestBody]);

    return (
        <>
            <div className={classes.addCompanyPageContent}>
                <div className={[
                    classes.addEditCompanyCardContentData,
                    classes.addForm].join(' ')}
                >
                    <CompanyCard
                        type="new"
                        setFilter={setCompanyInfo}
                        setLogoFormData={setLogoFormData}
                        companyTypeSelectable
                        setNameCheckInProgress={setNameCheckInProgress}
                    />
                    <AddressCard
                        type="new"
                        setFilter={setAddress}
                    />
                    <ContactInformationCard
                        type="new"
                        setFilter={setContactInfo}
                    />
                    <CompanyRolesCard
                        setRoles={setRoles}
                        roles={roles}
                        type="new"
                        autoInclude={['CUSTOMER_ADMIN']}
                    />
                </div>
            </div>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyButtonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        autoFocus
                        disabled={!readyToSubmission || nameCheckInProgress}
                        onClick={handleClickCreate}
                        className={classes.secondApproveButton}
                    >
                        {t('MY_COMPANY.CREATE')}
                    </Button>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={handleClickCancel}
                        className={classes.rejectButton}
                    >
                        {t('COMMON.CANCEL')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddCompany;
