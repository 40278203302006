import useGeneratedStyles from 'hooks/useGeneratedStyles/useGeneratedStyles';
import useHasAccess from 'hooks/useHasAccess';
import React, {
    Dispatch,
    SetStateAction,
    useMemo, useState, useEffect, useCallback, ChangeEvent,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import {
    FacetDTO,
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption, SwitchOptionItem, AirportOptions,
} from 'shared-components/SideFilter';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import { ONBOARDING_TYPE } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { getQuickHelpAsset } from 'shared-components/icons';
import SearchPreviewTable from '../ShipmentClientSideFilter/components/SearchPreviewTable';

type Props = {
    filterOptions: { [optionsGroupKey: string]: string[] },
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    facets: FacetDTO[],
    initialFacets: FacetDTO[],
    countsLoading: boolean,
    query?: string,
    setQuery: Dispatch<SetStateAction<string>>,
    showAirportsInfo: boolean,
    setShowAirportsInfo: (showAirportsInfo: boolean) => void,
    isTableView: boolean,

}

const isOldAirportsDestinationFilterShown = false;

const ShipmentFilterPanel = ({
    filterOptions,
    setFilterOptions,
    facets,
    initialFacets,
    countsLoading,
    setQuery,
    query,
    showAirportsInfo = false,
    setShowAirportsInfo,
    isTableView,
}: Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const hasAccess = useHasAccess();

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                title: t('TRACK_AND_TRACE.TITLE_SHIPMENT_STATUS'),
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'statuses',
                    t,
                    tgroup: 'SHIPMENT_STATUS',
                }),
                optionsGroupKey: 'statuses',
            },
            // {
            //     title: t('COMMON.ENERGY'),
            //     options: getFieldCheckboxData(loadedFacets, 'energyLevelStatuses', t, 'COMMON'),
            //     optionsGroupKey: 'energyLevelStatuses',
            // },
            // {
            //     title: t('TRACK_AND_TRACE.PACKAGING_TYPE'),
            //     options: getFieldCheckboxData({
            //         facets: loadedFacets,
            //         initialFacets,
            //         pluralName: 'packagingTypeCodes',
            //         t,
            //         tgroup: 'PACKAGING_CODE_LABEL',
            //     }),
            //     optionsGroupKey: 'packagingTypeCodes',
            // },
            {
                title: t('TRACK_AND_TRACE.TITLE_PACKAGING_STATUS'),
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'temperatureStatuses',
                        t,
                        tgroup: 'TEMPERATURE_STATUS',
                    }),
                optionsGroupKey: 'temperatureStatuses',
                showFully: true,
            },
            // {
            //     title: t('COMMON.TEMPERATURE_RANGE'),
            //     options: getFieldCheckboxData({
            //         facets: loadedFacets,
            //         initialFacets,
            //         pluralName: 'temperatureRanges',
            //     }),
            //     optionsGroupKey: 'temperatureRanges',
            // },
            {
                title: t('COMMON.ORIGIN_AIRPORT'),
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'originAirports',
                        t,
                    }),
                optionsGroupKey: 'originAirports',
            },
            {
                title: t('COMMON.DESTINATION_AIRPORT'),
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'destinationAirports',
                        t,
                    }),
                optionsGroupKey: 'destinationAirports',
            },
        ];
    }, [initialFacets, facets, hasAccess]);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const availableAirports = useMemo(() => {
        const avO = initialFacets?.find(({ filterName }) => filterName === 'originAirports')
            ?.counts?.map(it => it.filterValue);
        const avD = initialFacets?.find(({ filterName }) => filterName === 'destinationAirports')
            ?.counts?.map(it => it.filterValue);

        return {
            originAirports: avO?.filter(Boolean),
            destinationAirports: avD?.filter(Boolean),
        };
    }, [initialFacets]);

    return (

        <SideFilter
            id="CompanyPageFilter"
            tooltipInfo={{
                uid: 'shipmentClientSideFilterGeneric',
                isMain: true,
                title: t('ONBOARDING.TRACK_AND_TRACE.FILTER'),
                text: t('ONBOARDING.TRACK_AND_TRACE.FILTER_DESC'),
                image: getQuickHelpAsset('filters_shipments.gif'),
                order: 2,
                padding: 0,
                core: ONBOARDING_TYPE.SHIPMENT,
                position: 'right',
            }}
        >
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <SearchPreviewTable
                    setQuery={setQuery}
                    isTableView={isTableView}
                    query={query}
                    tooltipInfo={{
                        title: t('ONBOARDING.TRACK_AND_TRACE.SEARCH'),
                        text: t('ONBOARDING.TRACK_AND_TRACE.SEARCH_DESC'),
                        order: 1,
                        image: getQuickHelpAsset('shipment_filter.gif'),
                        core: ONBOARDING_TYPE.SHIPMENT,
                        position: 'right',
                        uid: 'getBySerialPreviewTooltip',
                    }}
                />
            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions = [] } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: facets.find(it => it.filterName === optionsGroupKey)
                                        ?.counts?.filter(it => it.filterValue !== null)
                                        ?.map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        ?.sort((a, b) => b.count - a.count || a.label.localeCompare(b.label))
                                        ?.map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    count={option.count}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    key={option.value}
                                                    label={option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                    countsLoading={countsLoading}
                                                    shrinkNames={!checkboxGroup.showFully}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            {
                isOldAirportsDestinationFilterShown && (availableAirports?.destinationAirports?.length > 0
                    || availableAirports?.originAirports?.length > 0) && (
                    <FilterBlock>
                        <AccordionOptionsGroup title={t('LANE_MANAGEMENT.ADDRESS.AIRPORT')}>
                            {
                                !isTableView && (
                                    <SwitchOptionItem
                                        label={t('TRACK_AND_TRACE.SHOW_AIRPORTS')}
                                        onChange={handleChangeShowAirports}
                                        tooltipDescription={t('EXPLANATION_TOOLTIPS.SHOW_AIRPORTS')}
                                        showTooltip
                                        defaultChecked={showAirportsInfo}
                                    />
                                )
                            }
                            <AirportOptions
                                availableDestinationAirports={availableAirports.destinationAirports}
                                availableOriginAirports={availableAirports.originAirports}
                                selectedDestinationAirports={filterOptions['destinationAirports']}
                                selectedOriginAirports={filterOptions['originAirports']}
                                setDestinationAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    destinationAirports: value,
                                }))}
                                setOriginAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    originAirports: value,
                                }))}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }
            { !isTableView
                && (
                    <FilterBlock>
                        <AccordionOptionsGroup
                            title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                        >
                            <SwitchOptionItem
                                defaultChecked={showAirportsInfo}
                                label={t('TRACK_AND_TRACE.AIRPORTS')}
                                onChange={handleChangeShowAirports}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )}
        </SideFilter>
    );
};

export default ShipmentFilterPanel;
