import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import { styles } from './HelloModal.style';

type Props = {
    shrinkFrom: DOMRect | null,
    open: boolean,
    isSuccess?: boolean,
    title?: string,
    subTitle?: string,
    text?: string,
    close: () => void,
    start: () => void,
    screenSize: {
        width: number,
        height: number,
        scale: number,
    },
    loading?: boolean,
}
const ConfettiComponent = ({ shouldRun }) => {
    const canvasRef = useRef(null);
    const [confetti, setConfetti] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const createConfettiPiece = () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        r: Math.random() * 4 + 1,
        d: Math.random() * 10,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
    });
    const updateConfettiPiece = (piece, index) => {
        piece.y += Math.cos(piece.d) + 1 + piece.r / 2;
        piece.x += Math.sin(piece.d) * 2;
        if (piece.x > window.innerWidth || piece.x < 0 || piece.y > window.innerHeight) {
            confetti[index] = createConfettiPiece();
            confetti[index].y = 0;
        }
    };

    const drawConfetti = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        confetti.forEach((piece, index) => {
            ctx.beginPath();
            ctx.arc(piece.x, piece.y, piece.r, 0, Math.PI * 2, false);
            ctx.fillStyle = piece.color;
            ctx.fill();
            updateConfettiPiece(piece, index);
        });
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const render = () => {
            drawConfetti(ctx);
            animationFrameId = requestAnimationFrame(render);
        };

        if (isRunning) {
            render();
        } else {
            cancelAnimationFrame(animationFrameId);
        }

        return () => cancelAnimationFrame(animationFrameId);
    }, [confetti, isRunning]);

    const runOnce = () => {
        setConfetti(Array.from({ length: 1000 }, createConfettiPiece));
        setIsRunning(true);
        setOpacity(1);
        setTimeout(() => {
            setIsRunning(false);
        }, 3000);
        setTimeout(() => {
            setOpacity(0);
        }, 2500);
    };

    useEffect(() => {
        if (shouldRun) runOnce();
    }, [shouldRun]);

    return (
        <div>
            <canvas
                ref={canvasRef}
                width={window.innerWidth}
                height={window.innerHeight}
                style={{
                    opacity,
                    transition: 'opacity 0.5s',
                    pointerEvents: 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0 }}
            />
        </div>
    );
};

const HelloModal = ({
    open,
    isSuccess,
    title,
    subTitle,
    text,
    shrinkFrom,
    close,
    start,
    screenSize,
    loading,
}: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const contentHeight = useMemo(
        () => contentRef.current?.scrollHeight || 231, [contentRef.current, screenSize]);
    const classes = useClasses(styles, { open, shrinkFrom, screenSize, contentHeight });
    const { t } = useCustomTranslation();

    return (
        <div
            className={classes.root}
        >
            <UncontrolledTooltip description={isSuccess ? 'Finish' : 'Clear All'} shrinked placement="bottom">
                <div className={classes.closeButton} onClick={close}>×</div>
            </UncontrolledTooltip>

            {
                !isSuccess && (
                    <div ref={contentRef} className={classes.modalContents}>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.subTitle}>{subTitle}</div>
                        <div className={classes.text}>{text}</div>
                        <div className={classes.buttonContainer}>
                            <Button className={classes.getStartedButton} onClick={start}>Get started</Button>
                            <Button className={classes.skipButton} onClick={close}>Skip</Button>
                        </div>
                    </div>
                )
            }
            {
                isSuccess && (
                    <div ref={contentRef} className={classes.modalContentsSuccess}>
                        <div className={classes.icon}>
                            <img
                                src={icons.confetti} alt="celebration" style={{
                                    width: '60px',
                                    height: '60px',
                                }}
                            />
                        </div>
                        <div className={classes.boldTitle}>{t('ONBOARDING.COMMON.SUCCESS')}</div>
                        <div className={[classes.text, classes.successText].join(' ')}>
                            {t('ONBOARDING.COMMON.SUCCESS_DESC')}
                        </div>
                        <div className={classes.centeredButtonContainer}>
                            <Button
                                style={{
                                    fontSize: '18px',
                                    opacity: loading ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'auto',
                                    padding: '12px 21px',
                                }}
                                className={classes.getStartedButton}
                                onClick={close}
                            >
                                Finish
                                {
                                    loading && (
                                        <CircularProgress
                                            size={14}
                                        />
                                    )
                                }
                            </Button>
                            <ConfettiComponent shouldRun={open} />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default HelloModal;
