/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useMemo, useRef } from 'react';

import {
    getNumberWidth,
} from 'shared-components/TemperatureCheck/lib';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import TemperatureCheckIcon from 'shared-components/TemperatureCheckIcon';
import { getTempMaxStyle, getTempMinStyle, getTempRulerStyle } from './lib';
import { TEMPERATURE_STATUS } from '../constants';

type Props = {
    temperatureMax: number,
    temperatureMin: number,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    grid?: boolean,
    temperatureCheckStatus?: string,
    bounds: [number, number],
}

const TemperatureCheckRelative = ({
    temperatureMax,
    temperatureMin,
    temperatureRangeMax,
    temperatureRangeMin,
    temperatureCheckStatus = null,
    bounds = [0, 0],
} : Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const rectRef = useRef(null);

    const itemsCountSeparatedByLines = temperatureRangeMax - temperatureRangeMin === 6 ? 6 : 10;
    const scale = 150 / itemsCountSeparatedByLines;
    const temperatureMinOffset = temperatureMin === null
        ? -50
        : Math.min(((temperatureRangeMin - temperatureMin) * scale), scale * 2);
    const offsetToLeft = -180 - getNumberWidth(temperatureMin) - temperatureMinOffset;
    const temperatureMaxOffset = temperatureMax === null
        ? -50
        : Math.min(((temperatureMax - temperatureRangeMax) * scale), scale * 2);
    const width = 150 + temperatureMinOffset + temperatureMaxOffset;
    const [allMin, allMax] = bounds;

    const boundValues = useMemo<{
        width: number, left: number, right: number,
    }>(() => {
        const rectWidth = 200;
        const minRatio = (allMax - allMin) !== 0
            ? ((temperatureMin - allMin) / (allMax - allMin)) : 0;
        const maxRatio = (allMax - allMin) !== 0
            ? ((temperatureMax - allMin) / (allMax - allMin)) : 1;
        const widthRatio = (temperatureMin && temperatureMax) ? (maxRatio - minRatio) : 1;

        return {
            left: temperatureMin ? minRatio * rectWidth : 0,
            right: temperatureMax ? maxRatio * rectWidth : rectWidth,
            width: rectWidth * widthRatio,
        };
    }, [rectRef, allMin, allMax, temperatureMin, temperatureMax]);
    const mainStyle = useMemo<CSSProperties>(() => ({
        display: 'grid',
        gridTemplateColumns: '60px 200px 40px',
        alignItems: 'center',
        gridGap: '10px',
        position: 'relative',
    }), [offsetToLeft, width, allMin, allMax]);
    const noData = useMemo(() => temperatureCheckStatus === 'NO_DATA', [temperatureCheckStatus]);

    const isPotential = useMemo(() => temperatureCheckStatus === TEMPERATURE_STATUS.POTENTIAL_EXCURSION
        || temperatureCheckStatus === TEMPERATURE_STATUS.PREDICTED_EXCURSION, [temperatureCheckStatus]);

    return (
        <>
            <div
                style={mainStyle}
            >
                <TemperatureCheckIcon temperatureCheckStatus={temperatureCheckStatus || 'NO_DATA'} />
                <div
                    style={{
                        height: '20px',
                        position: 'relative',
                    }}
                >
                    <div
                        style={
                            getTempMinStyle(
                                {
                                    tempMin: temperatureMin,
                                    tempMinRange: temperatureRangeMin,
                                    tempMaxRange: temperatureRangeMax,
                                    noData,
                                    boundValues,
                                    isPotential,
                                    theme,
                                },
                            )
                        }
                    >
                        {temperatureMin === null ? 'n/a' : `${temperatureMin?.toFixed(1)}°C`}
                    </div>
                    <div style={
                        getTempRulerStyle(
                            {
                                boundValues,
                                tempMin: temperatureMin,
                                tempMax: temperatureMax,
                                tempMinRange: temperatureRangeMin,
                                tempMaxRange: temperatureRangeMax,
                                noData,
                                theme,
                                isPotential,
                            },
                        )
                    }
                    />
                    <div style={
                        getTempMaxStyle({
                            tempMax: temperatureMax,
                            tempMinRange: temperatureRangeMin,
                            tempMaxRange: temperatureRangeMax,
                            noData,
                            boundValues,
                            isPotential,
                            theme,
                        })
                    }
                    >
                        {temperatureMax === null ? 'n/a' : `${temperatureMax?.toFixed(1)}°C`}
                    </div>

                </div>
            </div>
        </>
    );
};

export default TemperatureCheckRelative;
