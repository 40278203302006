import React, { useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import Card from 'shared-components/Card';
import {
    CardActions,
    Collapse,
    IconButton,
    useTheme,
    Grid,
} from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';

import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import TempRange from 'shared-components/TempRange';
import StandardLink from 'shared-components/StandardLink/StandardLink';
import useHasAccess from 'hooks/useHasAccess';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { AddressDTO, LaneObject } from 'shared-components/dataTypes';
import useClasses from 'hooks/useClasses';
import DeliveryPointInfo from '../DeliveryPointInfo';
import styles from './Summary.style';

type Props = {
    shipment: ShipmentDetailsInterface,
    title?: string,
    className?: string,
    leaseStart?: string,
    leaseEnd?: string,
    shipmentStart: string,
    shipmentEnd: string,
    startGeolocation?: Geolocation,
    endGeolocation?: Geolocation,
    lane?: LaneObject,
    originLabel?: string,
    destinationLabel?: string,
    originValue?: string | AddressDTO,
    destinationValue?: string | AddressDTO,
    shipperName?: string,
    forwarderName?: string,
}

const Summary = ({
    shipment,
    className = '',
    title = '',
    lane,
    originValue = '',
    destinationValue = '',
    originLabel = '',
    destinationLabel = '',
    shipperName,
    forwarderName,
} : Props) => {
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const { t } = useCustomTranslation();
    const [expand, setExpand] = useState<boolean | null>();
    const theme = useTheme<SkycellThemeInterface>();

    return (
        <Card
            key="summaryCard"
            className={`${classes.card} ${className}`}
        >
            <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={classes.cardHeader}>{title}</div>
                <Grid>
                    <div className={classes.fieldTitle}>{t('TRACK_AND_TRACE.TITLE_SHIPMENT_STATUS')}</div>
                    <div style={{
                        fontSize: '14px',
                        color: theme.palette.common.black }}
                    >
                        {t(`SHIPMENT_STATUS.${shipment?.status}`)}
                    </div>
                </Grid>
                <div className={classes.headerDivider} />
                <Grid>
                    <div className={classes.fieldTitle}>{t('COMMON.TEMPERATURE')}</div>
                    <TempRange tempRange={shipment?.temperatureRange} mode="full" circle />
                </Grid>
                <div className={classes.headerDivider} />
                { lane !== null
                    ? (
                        <Grid>
                            <div className={classes.fieldTitle}>{t('COMMON.LANE')}</div>
                            { hasAccess('LANE_MANAGEMENT')
                                ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <StandardLink
                                            label={lane?.laneNumber}
                                            noWrap
                                            blueIcon
                                            size="medium"
                                            linkTo={`/lane-management/lanes/${lane.id}/definition`}
                                        />
                                        <div style={{
                                            maxWidth: '20ch',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '14px',
                                        }}
                                        >
                                            {' '}
                                            {
                                `(${lane?.originAirportCode} `
                                            }

                                        </div>
                                        <div style={{
                                            maxWidth: '20ch',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '14px',
                                        }}
                                        >
&nbsp;
                                            {`- ${lane?.destinationAirportCode}`}
                                        </div>
                                        )
                                    </div>
                                )
                                : (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <StandardLink
                                            label={lane?.laneNumber}
                                            noWrap
                                            size="medium"
                                        />
                                        <div style={{
                                            maxWidth: '20ch',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '14px',
                                        }}
                                        >
                                            {' '}
                                            {
                                            `(${lane?.originAirportCode} `
                                            }

                                        </div>
                                        <div style={{
                                            maxWidth: '20ch',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '14px',
                                        }}
                                        >
                                        &nbsp;
                                            {`- ${lane?.destinationAirportCode}`}
                                        </div>
                                        )
                                    </div>
                                ) }
                        </Grid>
                    )
                    : <></>}
                <Grid>
                    <CardActions>
                        <IconButton onClick={() => setExpand(!expand)}>
                            { expand
                                ? (
                                    <>
                                        <div className={classes.details}>Hide Details</div>
                                        <ArrowBackIosNew className={classes.arrowSvg} />

                                    </>
                                )
                                : (
                                    <>
                                        <div className={classes.details}>Show Details</div>
                                        <ArrowBackIosNew className={classes.arrowUpSvg} />

                                    </>
                                )}
                        </IconButton>
                    </CardActions>
                </Grid>
            </Grid>
            <Collapse in={expand}>
                <hr style={{ borderTop: 'grey' }} />
                <Grid
                    container style={{
                        marginTop: '25px',
                        display: 'grid',
                        gridTemplateRows: '1fr',
                        gridTemplateColumns: 'auto auto auto auto auto',
                        gridTemplateAreas: 'a b c d e',
                    }}
                >
                    <Grid className="a" style={{ display: 'flex' }}>
                        <div style={{ marginLeft: 25 }}>
                            <div className={classes.fieldTitle}>{t('ORDER_DETAILS.REFERENCE_PO_NUMBER')}</div>
                            <div style={{ color: 'black', fontSize: '14px' }}>
                                {shipment?.customerReference}
                            </div>
                        </div>
                    </Grid>
                    <Grid className="b" style={{ display: 'flex' }}>
                        <div className={classes.collapseDivider} />
                        <div style={{ marginLeft: 25 }}>
                            <div style={{ marginBottom: 25 }}>
                                <div className={classes.fieldTitle}>{t('COMMON.MAWB')}</div>
                                <div style={{ color: 'black', fontSize: '14px' }}>{shipment?.mawbNumber}</div>
                            </div>
                            <div className={classes.fieldTitle}>{t('COMMON.HAWB')}</div>
                            <div style={{ color: 'black', fontSize: '14px' }}>{shipment?.hawbNumber}</div>
                        </div>
                    </Grid>
                    <Grid className="c" style={{ display: 'flex' }}>
                        <div className={classes.collapseDivider} />
                        <div style={{ marginLeft: 25 }}>
                            <div style={{ marginBottom: 25 }}>
                                <div className={classes.fieldTitle}>{t('COMMON.FORWARDER')}</div>
                                <div style={{ color: 'black', fontSize: '14px' }}>
                                    { forwarderName }
                                </div>
                            </div>
                            <div className={classes.fieldTitle}>{t('COMMON.SHIPPER')}</div>
                            <div style={{ color: 'black', fontSize: '14px' }}>
                                { shipperName }
                            </div>
                        </div>
                    </Grid>
                    <Grid className="d" style={{ display: 'flex' }}>
                        <div className={classes.collapseDivider} />
                        <div style={{ marginLeft: 25 }}>
                            <DeliveryPointInfo
                                className={`${classes.handoverPoint} ${classes.panelElement}`}
                                label={shipment?.customerSpecificInformation?.handoverPoint
                                    ? 'Origin Location' : originLabel}
                                value={shipment?.customerSpecificInformation?.handoverPoint
                                    ? shipment?.customerSpecificInformation?.handoverPoint : originValue}
                            />
                        </div>
                    </Grid>
                    <Grid className="e" style={{ display: 'flex' }}>
                        <div className={classes.collapseDivider} />
                        <div style={{ marginLeft: 25 }}>
                            <DeliveryPointInfo
                                className={`${classes.handoverPoint} ${classes.panelElement}`}
                                label={shipment?.customerSpecificInformation?.collectionDropoffPoint
                                    ? 'Destination Location' : destinationLabel}
                                value={shipment?.customerSpecificInformation?.collectionDropoffPoint
                                    ? shipment?.customerSpecificInformation?.collectionDropoffPoint : destinationValue}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Collapse>
        </Card>
    );
};

export default Summary;
