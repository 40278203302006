import { LayoutContext, Tab } from 'Contexts/LayoutContext';
import React, {
    ReactNode, useEffect, useRef,
    useState,
} from 'react';
import Sidebar from 'Sidebar';
import StatusMessages from 'StatusMessages';
import UserInfo from 'Sidebar/components/UserInfo';
import { getSidebarIsOpenLocalStorage, setLocalStorageValue } from 'utils/localStorageUtils';
import { SIDEBAR_IS_OPEN_KEY_LS } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import AppBarButton from './AppBarButton';
import styles from './CommonPage.style';

export type PageDetails = {
    link: string,
    title: string,
    tabs?: Tab[],
} | null

type Props = {
    children?: ReactNode,
    currentPage: PageDetails,
}

const CommonPage = ({
    children = null,
    currentPage,
} : Props) => {
    const classes = useClasses(styles);

    const isMobileDevice = window.screen.width < 820;
    const sidebarIsOpenLS = getSidebarIsOpenLocalStorage();
    const [sidebarIsOpen, setSidebarIsOpen] = useState(!isMobileDevice && sidebarIsOpenLS);
    const appBarRef = useRef(null);
    const [customPageTitle, setCustomPageTitle] = useState<string>('');
    const [availableSpace, setAvailableSpace] = useState<string>(`${window.innerHeight}px`);
    // const [supportIsOpen, setSupportState] = useState(false);
    // const { t } = useCustomTranslation();
    // const {
    //     setEnableTooltips,
    //     setEnableGuide,
    //     hasUserGuide,
    //     quickHelpTooltipsBag,
    // } = useContext(QuickHelpContext);
    const [customMenuTabs, setCustomMenuTabs] = useState<PageDetails['tabs']>([]);

    useEffect(() => {
        if (appBarRef.current) {
            const onResize = () => {
                setAvailableSpace(`${window.innerHeight - appBarRef?.current?.scrollHeight}px`);
            };

            onResize();
            window.addEventListener('resize', onResize);

            return () => window.removeEventListener('resize', onResize);
        }
    }, [appBarRef.current]);
    const iframe = document.querySelector('#jsd-widget');

    // @ts-ignore
    const iframeContent = iframe?.contentDocument;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const button = iframeContent?.querySelector('#help-button');

    const setSidebarState = (sidebarState: boolean) => {
        setSidebarIsOpen(sidebarState);
        setLocalStorageValue(SIDEBAR_IS_OPEN_KEY_LS, sidebarState);
    };

    return (
        <div className={classes.standardBody}>
            <Sidebar
                currentLink={currentPage.link}
                setSidebarState={setSidebarState}
                sidebarIsOpen={sidebarIsOpen}
            />
            <div className={classes.standardBodyContainer}>
                <div
                    ref={appBarRef}
                    className={
                        sidebarIsOpen
                            ? classes.appBarStyleOpenedSideBar
                            : classes.appBarStyle
                    }
                >
                    <div className={classes.appBarText}>{customPageTitle || currentPage.title}</div>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        {
                            (currentPage.tabs || []).concat(customMenuTabs)
                                .filter(tab => !tab.hide)
                                .sort((a) => (a.isAction ? -1 : 1))
                                .map((submenu) => {
                                    return (
                                        <QuickHelpTooltip tooltipInfo={submenu.onboarding}>
                                            <AppBarButton
                                                active={submenu.activePage}
                                                iconSrc={submenu.iconSrc}
                                                key={`${submenu.link || '_'}_${submenu.title}`}
                                                onClick={submenu.onClick || null}
                                                link={submenu.link}
                                                title={submenu.title}
                                                iconStyle={submenu.iconStyle || {}}
                                                buttonClass={[
                                                    submenu.buttonClass,
                                                    !submenu.iconSrc && classes.shiftTab,
                                                ].join(' ')}
                                                isOneButton={
                                                    submenu.isAction
                                                    || (currentPage.tabs || []).concat(customMenuTabs)?.length === 1
                                                }
                                            />
                                        </QuickHelpTooltip>
                                    );
                                })
                        }
                    </div>
                    <div className={classes.headerRightButtons}>
                        <UserInfo />

                        {/* <div
                            className={classes.topRightButton}
                            onClick={handleSupportClick}
                        >
                            <img src={icons.support_icon} alt="support" />
                            <span>{t('COMMON.SUPPORT')}</span>
                        </div>
                        <SupportModal
                            open={supportIsOpen}
                            setOpen={setSupportState}
                            content={iframe?.children}
                        />
                        {
                            quickHelpTooltipsBag.length !== 0 && (
                                <div
                                    className={classes.topRightButton}
                                    onClick={() => setEnableTooltips(prev => !prev)}
                                >
                                    <img src={icons.quick_help_icon} alt="quickHelp" />
                                    <span>{t('COMMON.QUICK_HELP')}</span>
                                </div>
                            )
                        }
                        {
                            hasUserGuide && (
                                <div
                                    className={classes.topRightButton}
                                    onClick={() => setEnableGuide(prev => !prev)}
                                >
                                    <img src={icons.user_guide_icon} alt="userGuide" />
                                    <span>{t('COMMON.USER_GUIDE')}</span>
                                </div>
                            )
                        } */}
                    </div>
                </div>
                <div
                    id="content"
                    className={
                        sidebarIsOpen
                            ? classes.bodyContentBlockWithOpenedSideBar
                            : classes.bodyContentBlock
                    }
                >
                    {/* <Breadcrumbs /> */}
                    <LayoutContext.Provider value={{
                        availableHeight: availableSpace,
                        setMenuTabs: setCustomMenuTabs,
                        menuTabs: customMenuTabs,
                        setCustomPageTitle }}
                    >
                        { children }
                    </LayoutContext.Provider>
                    <StatusMessages />
                </div>
            </div>
        </div>
    );
};

export default CommonPage;
