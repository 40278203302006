export const styles = (theme) => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        font: 'normal normal normal 16px/21px Roboto',
        borderRadius: 3,
        width: 'min-content',
        transition: '300ms ease',
        '&:hover': {
            background: theme.palette.primary[30],
        },
        cursor: 'pointer',
    },
    standardPadding: {
        padding: '1px 4px',
    },
    linkContent: {
        display: 'flex',
        alignItems: 'center',
        // '&:hover': {
        //     background: theme.palette.primary[30],
        // },
    },
    arrowIconContainerMedium: {
        background: theme.palette.secondary[600],
        borderRadius: '50%',
        height: '16px',
        width: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '300ms ease',
        marginRight: '5px',
    },
    arrowIconContainerLarge: {
        background: theme.palette.secondary[600],
        borderRadius: '50%',
        height: '18px',
        width: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '300ms ease',
        marginRight: '5px',
    },
    arrowIconContainerLarger: {
        background: theme.palette.secondary[600],
        borderRadius: '50%',
        height: '21px',
        width: '21px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '300ms ease',
        marginRight: '5px',
    },
    arrowIconContainerHeader: {
        background: theme.palette.secondary[600],
        borderRadius: '50%',
        height: '22px',
        width: '22px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '300ms ease',
        marginRight: '5px',
    },
    arrowIcon: {
        fontSize: '18px!important',
        color: theme.palette.common.white,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    horizontalMargin: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    verticalMargin: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    medium: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
    },
    large: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
    },
    larger: {
        textAlign: 'left',
        font: 'normal normal normal 18px/21px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
    },
    header: {
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        fontSize: '28px',
        color: theme.palette.common.black,
        margin: '1px',
    },
});
