import React from 'react';
import TitleHeader from 'TitleHeader';
import useClasses from 'hooks/useClasses';
import styles from './TitleLine.style';

type Props = {
    title: string
}

const TitleLine = ({
    title = '',
} : Props) => {
    const classes = useClasses(styles);

    return (
        <TitleHeader>
            <div className={classes.container}>
                <div>{title}</div>
            </div>
        </TitleHeader>
    );
};

export default TitleLine;
