import React, { useMemo } from 'react';
import { OperatingHours, weekDaysWithLabelT } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './OperatingHoursCard.style';

const cutTime = (time: string) => time.split(':').slice(0, 2).join(':');

type Props ={
    currentOperatingHours: OperatingHours[],
}

const ReadMode = ({ currentOperatingHours = [] } : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const weekInfo = useMemo(() => {
        if (currentOperatingHours.length === 0) {
            return <div>{t('SERVICE_PROVIDERS.OPERATING_HOURS_NOT_YET_ADDED')}</div>;
        }

        return Object.keys(weekDaysWithLabelT(t)).map(day => {
            const currentDay = currentOperatingHours.find(item => item.dayOfWeek === day) || null;
            const value = currentDay === null
                ? t('SERVICE_PROVIDERS.CLOSED')
                : cutTime(currentDay.from) === '00:00' && cutTime(currentDay.to) === '23:59'
                    ? t('SERVICE_PROVIDERS.24_H_OPEN')
                    : `${cutTime(currentDay.from)} - ${cutTime(currentDay.to)}`;

            return (
                <div className={classes.weekContainer} key={day}>
                    <div>{weekDaysWithLabelT(t)[day]}</div>
                    <div>{value}</div>
                </div>

            );
        });
    }, [currentOperatingHours]);

    return (
        <div className={classes.operatingHoursContainer}>
            <div className={classes.title}>{t('SERVICE_PROVIDERS.OPERATING_HOURS')}</div>
            {weekInfo}
        </div>
    );
};

export default ReadMode;
