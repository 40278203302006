import React from 'react';
import Card from 'shared-components/Card';
import useClasses from 'hooks/useClasses';

const styles = {
    contentClass: {
        width: '100%',
        height: '100%',
    },
};
const ShipmentsReport = () => {
    const classes = useClasses(styles);

    return (
        <Card fullHeight contentClass={classes.contentClass}>
            <iframe
                title="Statistics"
                width="100%"
                height="100%"
                // eslint-disable-next-line max-len
                src="https://app.powerbi.com/reportEmbed?reportId=b16dc922-2946-46e6-a258-3a3dba101551&autoAuth=true&filterPaneEnabled=false&navContentPaneEnabled=false"
                frameBorder="0"
                allowFullScreen
            />
        </Card>
    );
};

export default ShipmentsReport;
