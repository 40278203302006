import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { ENTITY_TYPE } from 'hooks/useGetEntity/useGetEntity';
import { TFunction } from 'i18next';
import { extractLocationName } from 'LaneManagement/Lanes/Lanes';
import moment from 'moment/moment';
import { LaneObject, Milestone } from 'shared-components/dataTypes';

export const sumMilestonesDurations = (milestones: Milestone[], t = word => word) => {
    const sum = milestones
        .map(m => m.activities)
        .flat()
        .map(activity => activity?.duration || 0)
        .reduce((a, b) => a + b, 0);

    if (sum === 0) return 'n/a';
    const rawMinutes = sum / 60;
    const rawHours = Math.floor(rawMinutes / 60);
    const hours = rawHours !== 0 ? `${rawHours}${t('TIME.H')} ` : '';
    const minutes = rawMinutes % 60 !== 0 ? `${rawMinutes % 60}${t('TIME.M')}` : '';

    return `${hours}${minutes}`;
};
export const bakeLanes = (lanes:LaneObject[], t:TFunction, getEntity, entityFromAddressId) => {
    const rawLanes: LaneObject[] = (lanes || [])
        .sort((a, b) => {
            const aDate = moment(a.modifiedOn, 'YYYY-MM-DDTHH:mm:ss').valueOf();
            const bDate = moment(b.modifiedOn, 'YYYY-MM-DDTHH:mm:ss').valueOf();

            return bDate - aDate;
        });

    return rawLanes.map(lane => {
        const {
            loadingLocation,
            unloadingLocation,
            milestones,
            originAirportCode,
            destinationAirportCode,
            skyMindId,
            fourthPartyLogisticsCompanyId,
            skyMindInfo,
            status,
            ...restInfo
        } = lane;

        const fourthPartyLogisticsCompany: Company = getEntity(
            fourthPartyLogisticsCompanyId,
            ENTITY_TYPE.COMPANY,
        );
        const loadingLocationEntity = entityFromAddressId(loadingLocation);
        const unloadingLocationEntity = entityFromAddressId(unloadingLocation);
        const handoverPoint = skyMindInfo?.handoverPoint?.addressLine1;
        const collectionDropoffPoint = skyMindInfo?.collectionDropoffPoint?.addressLine1;

        return {
            ...restInfo,
            fourthPartyLogistics: fourthPartyLogisticsCompany?.name
                || skyMindInfo?.customerExecution?.addressLine1 || ' ',
            isSkycellManaged: skyMindId
                ? 'Yes' : 'No',
            originAirportCode,
            status: t(`LANE_STATUS.${status}`),
            destinationAirportCode,
            loadingLocation: extractLocationName(loadingLocationEntity) || handoverPoint,
            unloadingLocation: extractLocationName(unloadingLocationEntity)
                || collectionDropoffPoint,
            handoverPoint,
            collectionDropoffPoint,
            milestones: sumMilestonesDurations(milestones, t),
        };
    });
};
