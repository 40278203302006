import useClasses from 'hooks/useClasses';
import React, { useCallback, useEffect } from 'react';
import ProposedValueTooltip
    from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable/TableInput/tooltips/ProposedValueTooltip';
import { toHoursMinutes } from 'utils/stringTool';
import { durationToSecondsHM } from 'LaneManagement/AddLane/tools';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable/TableInput/TableInput.style';
import MaskedInput from 'react-text-mask';

type Props = {
    value: any,
    proposedValue: any,
    proposedDurationTooltip: boolean,
    setProposedDurationTooltip: any,
    onChange: Function,
    inputClass: string
}

const MAX_SECONDS = 359999;

export const stripTime = (value, t) => {
    let [hours, minutes] = value
        .replace(t('TIME.M'), '')
        .replace(t('TIME.H'), '')
        .split(' ');

    hours = (Math.min(Number(hours), 99)).toString().padStart(2, '0');
    minutes = (Math.round(Math.min(Number(minutes), 50) / 10) * 10).toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}${t('TIME.H')} ${minutes.toString().padStart(2, '0')}${t('TIME.M')}`;
};

export const setTimeValue = (value, part, direction) => {
    return Math.max(Math.min(value + (direction ? -1 : 1) * (part ? 60 : 5) * 60, MAX_SECONDS), 0);
};

const MaskedTimeInputWithTooltip = ({
    value,
    proposedDurationTooltip,
    setProposedDurationTooltip,
    proposedValue,
    onChange,
    inputClass,
}: Props) => {
    const { t } = useCustomTranslation();

    const classes = useClasses(styles);

    useEffect(() => {
        const event = () => {
            setProposedDurationTooltip(false);
        };

        document.addEventListener('click', event);
        return () => {
            document.removeEventListener('click', event);
        };
    }, []);

    const onUse = useCallback(() => {
        setProposedDurationTooltip(false);
        onChange(proposedValue);
    }, [setProposedDurationTooltip, onChange, proposedValue]);

    return (
        <ProposedValueTooltip
            open={Boolean(proposedValue) && proposedDurationTooltip}
            onUse={proposedValue === value ? null : () => onUse()}
            proposedValue={proposedValue}
            proposedValueText={`${proposedValue ? toHoursMinutes(proposedValue) : '00 00'}h`}
        >

            <MaskedInput
                className={`${inputClass} ${classes.maskInput}`}
                mask={[/[0-9]/, /[0-9]/, t('TIME.H'), ' ', /[0-5]/, /[0-9]/, t('TIME.M')]}
                value={toHoursMinutes(value) || '00 00'}
                defaultValue="00 00"
                onFocus={(event) => {
                    event.stopPropagation();
                    setProposedDurationTooltip(true);
                }}
                onKeyDown={(event) => {
                    const inputTarget: any = event?.target;

                    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                        event.preventDefault();
                        onChange(
                            setTimeValue(value,
                                inputTarget?.selectionStart <= 2,
                                event.key === 'ArrowDown'),
                        );
                    }
                }}
                onBlur={() => setProposedDurationTooltip(false)}
                onChange={(ev) => {
                    const newValue = ev?.target?.value;

                    if (newValue.length === 9) onChange(durationToSecondsHM(stripTime(newValue, t), t));
                }}

            />
        </ProposedValueTooltip>
    );
};

export default MaskedTimeInputWithTooltip;
