import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const fadeIn = keyframes({
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    });

    const fadeInOpacity = keyframes({
        '0%': { opacity: 0.4 },
        '100%': { opacity: 1 },
    });
    const active = css({});
    const line = css({
        height: '22px',
        transition: '200ms ease',
        animation: `${fadeInOpacity} 300ms forwards ease-in-out`,
    });

    const root = css({
        display: 'grid',
        gridGap: '8px',
        gridAutoRows: '20px 20px 31px',
        flex: 1,
        transition: '200ms ease',
        flexDirection: 'column',
        alignItems: 'center',
        [`&:nth-child(4n+1) > .${line}.${active}`]: {
            background: theme.palette.primary[30],
        },
        [`&:nth-child(4n+1) > .${line}`]: {
            background: theme.palette.common.secondShadeOfGray,
        },
        [`&:nth-child(4n+2) > .${line}.${active}`]: {
            background: theme.palette.primary[400],
        },
        [`&:nth-child(4n+2) > .${line}`]: {
            background: theme.palette.common.thirdShadeOfGray,
        },
        [`&:nth-child(4n+3) > .${line}.${active}`]: {
            background: theme.palette.primary[700],
        },
        [`&:nth-child(4n+3) > .${line}`]: {
            background: theme.palette.common.fourthShadeOfGray,
        },
        [`&:nth-child(4n+4) > .${line}.${active}`]: {
            background: theme.palette.primary[400],
        },
        [`&:nth-child(4n+4) > .${line}`]: {
            background: theme.palette.common.thirdShadeOfGray,
        },
    });

    const shrinked = css({
        gridAutoRows: '20px 31px',
        cursor: 'pointer',
        '&:hover': {
            filter: 'grayscale(1)',
        },
    });

    const lineTbd = css({
        background: `${theme.palette.secondary[400]} !important`,
    });

    const time = css({
        textAlign: 'center',
        color: theme.palette.secondary[600],
    });

    const iconWrapper = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    const icon = css({
        height: '25px',
        width: '100%',
        maxWidth: '25px',
        minWidth: '5%',
        animation: `${fadeIn} 200ms forwards ease-in-out`,
    });

    const tooltipDescription = css({
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
    });

    const tooltipBody = css({
        maxWidth: 'unset !important',
        width: 'auto !important',
    });

    const divider = css({
        width: '75px',
        height: '1px',
        marginRight: '40px',
        marginLeft: '40px',
        background: `linear-gradient(90deg, ${theme.palette.secondary[600]} 4px, ${theme.palette.common.white} 4px)`,
        backgroundSize: '8px',
    });

    return {
        root,
        shrinked,
        line,
        lineTbd,
        time,
        iconWrapper,
        icon,
        tooltipDescription,
        tooltipBody,
        divider,
        active,
    };
};

export default generateStyles;
