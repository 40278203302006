import React, { useState, useCallback, useMemo, CSSProperties, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useCustomTitle from 'hooks/useCustomTitle';
import BackToLink from 'shared-components/BackToLink';
import PackagingTemperatureInfo from 'TrackAndTrace/GenericShipmentDetails/components/PackagingTemperatureInfo';
import useAvailableHeight from 'hooks/useAvailableHeight';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { LaneObject } from 'shared-components/dataTypes';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import Card from 'shared-components/Card';
import { Button, CircularProgress, useTheme } from '@mui/material';
import { DownloadComponentIcon } from 'shared-components/ApexTemperatureChart/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import useFetchLoggerNumbers from 'hooks/useFetchLoggerNumbers';
import CustomModal from 'shared-components/CustomModal';
import useCreateJiraTicket from 'hooks/useCreateJiraTicket';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import useGetCommonData from 'hooks/useGetCommonData';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import PackagingViewOptions from './components/PackagingViewOptions/PackagingViewOptions';
import Approval from './components/Approval';
import Summary from './components/Summary';
import { DataCompleteCheckDTO, ShipmentDetailsInterface } from './lib';
import PackagingHealth from './components/PackagingHealth/PackagingHealth';
import ShipmentSteps from './components/ShipmentSteps';
import styles from './GenericShipmentDetails.style';

type Props = {
    shipment?: ShipmentDetailsInterface,
    shipmentId?: string,
    milestones?: Milestones[],
    lane?: LaneObject,
    cargo?: GenericCargo[],
    setRequiredOrderDataUpdate?: (requiredOrderDataUpdate: boolean) => void,
    asAdmin?: boolean,
}

export type HiddenSeries = {
    [position: string]: boolean
}
export type ChartDataToExport = {
    [packagingSerialNumber: string]: {
        base64: string,
        chartAspectRatio: number,
        latestTemp: SensorDataItem[],
        labels: {
            dataTypes: string[],
            loggerTypes: string[],
            positions: string[],
        },
    }
}

const {
    REACT_APP_ENVIRONMENT,
} = process.env;

const downloadFileBlob = (blob: Blob, fileName: string, type: string = 'application/pdf') => {
    const fileURL = window.URL.createObjectURL(new Blob([blob], { type }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
};

const GenericShipmentDetails = ({
    shipmentId,
    milestones,
    lane,
    asAdmin = false,
    cargo,
    shipment,
    setRequiredOrderDataUpdate,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { setTitle } = useCustomTitle();
    const hasAccess = useHasAccess();
    const displayProductReleaseFunctionality = hasAccess(userRoles.PRODUCT_RELEASE);
    const [showTempRange, setShowTempRange] = useState(true);
    const {
        userInfo,
    } = useCurrentUserContext();
    const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
    const prodOverride = useMemo(() => !(process.env.REACT_APP_ENVIRONMENT === 'prod'
        && Number(userInfo?.attributes?.companyId) !== 39), [userInfo]);
    const {
        data: dataCompleteCheck,
    } = useGetCommonData<DataCompleteCheckDTO>(`v2/shipments/${shipmentId}/data-complete-check-result`, {
        query: {},
        enabled: !asAdmin && shipment.status === 'CLOSED',
    });

    const {
        Create: signPdfRequest,
    } = useSecureBackendEndpoints('v1/pdf/sign', {
        axiosResponseType: 'blob',
    }).requests;

    const checkComplete = useMemo(() => {
        if (asAdmin) return {};
        const isAllComplete = dataCompleteCheck?.dataCompleteCheckForCargos?.length > 0
            && dataCompleteCheck.dataCompleteCheckForCargos.every(dcc => dcc.internalDataCompleteStatus === 'COMPLETE');
        const isSomeComplete = dataCompleteCheck?.dataCompleteCheckForCargos?.length > 0
            && dataCompleteCheck.dataCompleteCheckForCargos.some(dcc => dcc.internalDataCompleteStatus === 'COMPLETE');
        const override = dataCompleteCheck?.overrideOptions?.override;

        return {
            allComplete: override !== undefined ? override : isAllComplete,
            someComplete: override !== undefined ? override : (isSomeComplete && !isAllComplete),
        };
    }, [dataCompleteCheck, asAdmin]);

    const [showMarkers, setShowMarkers] = useState(false);
    const [showInUTC, setShowInUTC] = useState<boolean>(false);
    const [showExpTemp, setShowExpTemp] = useState(false);
    const [activePackagingSerialNumber, setActivePackagingSerialNumber] = useState<string>(null);
    const availableHeight = useAvailableHeight();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chartDataToExport, setChartDataToExport] = useState<ChartDataToExport>({});
    const [hiddenSeries, setHiddenSeries] = useState<HiddenSeries>(
        {
            AMBIENT: false,
            DOOR: false,
        },
    );
    const theme = useTheme<SkycellThemeInterface>();
    const createJiraTicket = useCreateJiraTicket();
    const { Get: getCompany } = useSecureBackendEndpoints('companies').requests;
    const [shipperName, setShipperName] = useState<string>(null);
    const [forwarderName, setForwarderName] = useState<string>(null);
    const measurementDataDisabled = useMemo(() => shipment?.status === 'CLOSED' && checkComplete?.allComplete,
        [
            shipment?.status,
            checkComplete,
        ]);
    const { loggerNumbers } = useFetchLoggerNumbers(shipmentId, cargo, asAdmin);
    const [showMeasuredOnly, setShowMeasuredOnly] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (shipment.shipperId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.shipperId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setShipperName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
        if (shipment.forwarderId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.forwarderId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setForwarderName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
    }, [shipment]);

    useEffect(() => {
        setTitle(t('SENSOR_DATA.SHIPMENT_DETAILS'));
    }, [setTitle, t]);

    const onMaximize = useCallback((packagingSerial: string) => {
        setActivePackagingSerialNumber(prevSerial => (packagingSerial === prevSerial ? null : packagingSerial));
    }, []);

    const maximized = useMemo(() => {
        return Boolean(activePackagingSerialNumber);
    }, [activePackagingSerialNumber]);
    const pageStyle = useMemo<CSSProperties>(() => {
        return {
            height: availableHeight,
            display: 'flex',
            flexDirection: 'column',
        };
    }, [availableHeight]);

    const downloadPdfHandler = useCallback(async () => {
        if (shipment?.status === 'CLOSED' && checkComplete.allComplete) {
            setDownloadButtonLoading(true);
            try {
                const unsignedPdfResponse: AxiosResponse<Blob> = await axios
                    .post('https://pdf-report.dev.skymind.com/generate-pdf', {
                        cargo,
                        chartDataToExport,
                        userInfo,
                        shipment,
                        lane,
                        packagingLoggerNumbers: loggerNumbers,
                        shipperName,
                        forwarderName,
                    }, {
                        responseType: 'blob',
                    });

                const unsignedPdfBlob = unsignedPdfResponse.data;

                const formData: FormData = new FormData();

                formData.append('file', unsignedPdfBlob);

                const signed: AxiosResponse<Blob> = await signPdfRequest(formData, null);

                const fileName = `Shipment_${shipment.externalId}_report_${moment()
                    .utc(false)
                    .format('DD_MMM_YYYY_HH.mm')}.pdf`;

                downloadFileBlob(signed.data, fileName);
            } catch (error) {
                global.console.error('Error downloading the file:', error);
            } finally {
                setDownloadButtonLoading(false);
            }
        } else {
            const jiraData = {
                shipmentNumber: shipment?.externalId,
                packagingSerialNumber: Object.keys(dataCompleteCheck?.dataCompleteCheckForCargos || {})
                    .filter(k => {
                        return dataCompleteCheck?.dataCompleteCheckForCargos[k]
                            .internalDataCompleteStatus !== 'COMPLETE';
                    }).join(', '),
                dataCompleteCheckForCargos: (dataCompleteCheck?.dataCompleteCheckForCargos || []).map(it => ({
                    ...it,
                    executionTimestamp: moment(it.executionTimestamp).format('DD/MM/YYYY HH:mm'),
                })),
                shipmentStart: shipment.shipmentStart
                    ? moment(shipment.shipmentStart).format('DD/MM/YYYY HH:mm') : 'Null',
                shipmentEnd: shipment.shipmentEnd
                    ? moment(shipment.shipmentEnd).format('DD/MM/YYYY HH:mm') : 'Null',
                user: userInfo,
                env: process.env.REACT_APP_ENVIRONMENT,

            };

            createJiraTicket(jiraData);
            handleOpenModal();
        }
    }, [
        cargo,
        chartDataToExport,
        userInfo,
        shipment,
        lane,
        checkComplete,
        dataCompleteCheck,
        t,
        shipperName,
        forwarderName,
        loggerNumbers,
    ]);

    useEffect(() => {
        if (
            shipment?.status === 'CLOSED'
            && checkComplete.allComplete
        ) setShowMeasuredOnly(true);
    }, [
        checkComplete,
    ]);
    const pdfButtonEnabled = useMemo(() => shipment?.status === 'CLOSED' && prodOverride,
        [shipment, hasAccess, prodOverride]);

    return (
        <div style={pageStyle}>
            <BackToLink isGeneric to={asAdmin ? '/administration/shipments' : null} />
            <div
                className={
                    [displayProductReleaseFunctionality
                        ? classes.panelsWrapper
                        : classes.panelsWrapperWithoutApprovalCard,
                    maximized ? classes.maximizedPane : '',
                    ].join(' ')
                }
            >
                <CustomModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                >
                    <>
                        <p className={classes.text}>
                            Temperature data is not completely verified,
                            therefore the temperature report can&apos;t be generated yet.
                            Please try again later or contact us at support@skymind.com stating your shipment number.
                        </p>
                        <Button onClick={handleCloseModal}>OK</Button>
                    </>
                </CustomModal>
                {shipment?.status === 'NOT_STARTED' && !hasAccess(userRoles.TEMPERATURE_CHECK)
                    ? (
                        <Card className={`${classes.cardNotStarted} ${classes.panelElement}`}>
                            <img
                                className={classes.icon}
                                alt="icon"
                                src={icons.info_blue}
                            />
                            <p className={classes.text}>
                                This shipment has not yet started.
                                The packaging information and sensor data will show up once the shipment has started.
                            </p>
                            <img
                                className={classes.iconEnd}
                                alt="icon"
                                src={icons.info_blue}
                            />
                        </Card>
                    )
                    : ('')}
                <Summary
                    className={`${classes.summary} ${classes.panelElement}`}
                    title={`${t('COMMON.SHIPMENT')} ${shipment?.externalId}`}
                    shipment={shipment}
                    shipmentEnd={shipment.shipmentEnd}
                    shipmentStart={shipment.shipmentStart}
                    lane={lane}
                    originValue={shipment.originAirport}
                    originLabel="Origin Airport"
                    destinationLabel="Destination Airport"
                    destinationValue={shipment.destinationAirport}
                    shipperName={shipperName}
                    forwarderName={forwarderName}
                />
                {
                    displayProductReleaseFunctionality && false && (
                        <Approval
                            shipmentNumber={shipment?.externalId}
                            packagings={shipment?.packagings}
                            dataUpdateTrigger={setRequiredOrderDataUpdate}
                            className={`${classes.approval} ${classes.panelElement}`}
                            onCsvButton={() => {
                            }}
                            shipmentEnd={shipment.shipmentEnd}
                            shipmentStart={shipment.shipmentStart}
                            skyMindId={shipment?.skyMindId}
                        />
                    )
                }
                {
                    !maximized && (
                        <div className={classes.orderSteps}>
                            {
                                (((REACT_APP_ENVIRONMENT === 'dev'
                                    || REACT_APP_ENVIRONMENT === 'test')
                                    && Number(userInfo?.attributes?.companyId) === 14)
                                || ((REACT_APP_ENVIRONMENT === 'pre'
                                        || REACT_APP_ENVIRONMENT === 'prod')
                                    && (Number(userInfo?.attributes?.companyId) === 39
                                            || Number(userInfo?.attributes?.companyId) === 132)))
                                && (
                                    <Card
                                        className={[classes.card, classes.downloadReportCard].join(' ')}
                                        title={t('TRACK_AND_TRACE.DATA_REPORTS')}
                                        zeroSidePadding
                                        zeroVerticalPadding
                                        titleClass={classes.title}
                                    >
                                        <div className={classes.downloadButtons}>
                                            <UncontrolledTooltip
                                                description={t('TRACK_AND_TRACE.PDF_BUTTON_DISABLED_LABEL')}
                                                shrinked
                                                enabled={!pdfButtonEnabled}
                                            >
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        onClick={downloadPdfHandler}
                                                        style={{
                                                            background: pdfButtonEnabled
                                                                ? theme.palette.primary.deepBlue
                                                                : theme.palette.secondary[300],
                                                        }}
                                                        disabled={!pdfButtonEnabled || downloadButtonLoading}
                                                    >
                                                        {
                                                            downloadButtonLoading
                                                                ? (
                                                                    <CircularProgress
                                                                        size={24}
                                                                        style={{
                                                                            color: theme.palette.common.white,
                                                                            marginRight: '6px',
                                                                            marginLeft: '-6px',
                                                                        }}
                                                                    />
                                                                )
                                                                : (
                                                                    <DownloadComponentIcon
                                                                        color={theme.palette.common.white}
                                                                    />
                                                                )
                                                        }
                                                        PDF
                                                    </Button>
                                                </span>
                                            </UncontrolledTooltip>
                                        </div>
                                    </Card>
                                )
                            }

                            {
                                shipment?.shipmentSteps?.length !== 0 && (
                                    <ShipmentSteps
                                        key={shipment?.externalId}
                                        milestones={milestones}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <div className={classes.packagingDetailsWrapper}>
                    {
                        !maximized && (shipment?.status !== 'NOT_STARTED'
                            || hasAccess(userRoles.TEMPERATURE_CHECK)) && (
                            <PackagingHealth
                                packagings={cargo?.map(item => item.packaging)}
                                cargo={cargo}
                            />
                        )
                    }
                    {
                        (shipment?.status !== 'NOT_STARTED' || hasAccess(userRoles.TEMPERATURE_CHECK))
                        && (
                            <PackagingViewOptions
                                packagings={cargo?.map(item => item.packaging)}
                                showTempRange={showTempRange}
                                laneId={shipment.laneId}
                                status={shipment.status}
                                milestones={lane?.milestones}
                                showMeasuredOnly={showMeasuredOnly}
                                showMeasuredOnlyDisabled={measurementDataDisabled}
                                showMarkers={showMarkers}
                                showInUTC={showInUTC}
                                showExpTemp={showExpTemp}
                                showAmbientTemp={!hiddenSeries.AMBIENT}
                                showDoors={!hiddenSeries.DOOR}
                                setShowMarkers={setShowMarkers}
                                setShowInUTC={setShowInUTC}
                                setShowMeasuredOnly={setShowMeasuredOnly}
                                setShowTempRange={setShowTempRange}
                                setShowExpTemp={setShowExpTemp}
                                setShowAmbientTemp={
                                    () => setHiddenSeries(prev => ({ ...prev, AMBIENT: !prev.AMBIENT }))
                                }
                                setShowDoors={
                                    () => setHiddenSeries(prev => ({ ...prev, DOOR: !prev.DOOR }))
                                }
                            />
                        )
                    }
                    {shipment?.status !== 'NOT_STARTED' || hasAccess(userRoles.TEMPERATURE_CHECK) ? cargo
                        .map((item) => item.packaging)
                        ?.filter((packaging) => !activePackagingSerialNumber
                            || (packaging?.serialNumber === activePackagingSerialNumber))
                        ?.map((packaging, index) => {
                            return (
                                <PackagingTemperatureInfo
                                    key={`packagingTemperatureInfo_${packaging?.serialNumber}`}
                                    cargo={cargo.find(item => item.packaging === packaging)}
                                    packaging={packaging}
                                    timeRange={shipment?.shipmentSensorDataTimeRange}
                                    showExpTemp={showExpTemp}
                                    showMarkers={showMarkers}
                                    showInUTC={showInUTC}
                                    showMeasuredOnly={shipment.status === 'CLOSED' ? true : showMeasuredOnly}
                                    showTempRange={showTempRange}
                                    shipmentNumber={shipment?.externalId}
                                    shipmentStart={shipment?.shipmentStart}
                                    shipmentEnd={shipment?.shipmentEnd}
                                    shipmentId={shipmentId}
                                    progressUpdateEnabled={
                                        (Number(userInfo?.attributes?.companyId) === shipment?.forwarderId)
                                        && (shipment?.milestoneSource === 'LANE')
                                    }
                                    onMaximize={onMaximize}
                                    maximized={maximized}
                                    milestones={milestones}
                                    transport={shipment?.status === 'IN_TRANSIT'}
                                    energyLevel={cargo[index]?.energyLevelCheckResult?.remainingEnergyLevel}
                                    hiddenSeries={hiddenSeries}
                                    setHiddenSeries={setHiddenSeries}
                                    exportTempData={(serialNumber, sd, labels) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [serialNumber]: {
                                                latestTemp: sd,
                                                labels,
                                                base64: prev[serialNumber]?.base64,
                                                chartAspectRatio: prev[serialNumber]?.chartAspectRatio,
                                            },
                                        }));
                                    }}
                                    asAdmin={asAdmin}
                                    setRequiredOrderDataUpdate={setRequiredOrderDataUpdate}
                                    exportPng={(base64, aspectRatio) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [packaging.serialNumber]: {
                                                ...prev[packaging.serialNumber],
                                                base64,
                                                chartAspectRatio: aspectRatio,
                                            },
                                        }));
                                    }}
                                />
                            );
                        }) : ''}
                </div>
            </div>
        </div>
    );
};

export default GenericShipmentDetails;
