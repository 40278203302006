import React from 'react';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';

const LocationHistoryCountry = ({ latitude, longitude }) => {
    const {
        countryName,
    } = useGetTimeZoneInfoByGeolocation({
        latitude,
        longitude,
    }, '0', true);

    return (
        <div>{countryName || ' '}</div>
    );
};

export default LocationHistoryCountry;
