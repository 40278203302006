export const styles = () => ({
    stepsWrapper: {
        display: 'grid',
        gridGap: '14px',
        overflow: 'hidden',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
    },
    reorderLoading: {
        filter: 'blur(2px)',
        transition: '300ms ease',
    },
});
