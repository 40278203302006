import React from 'react';
import { AlertColor } from '@mui/material/Alert';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import useClasses from 'hooks/useClasses';
import styles from '../StatusMessages.style';

type Props = {
    color?: AlertColor | 'info_static'
}

const AlertIcon = ({ color } : Props) => {
    const classes = useClasses(styles);

    switch (color) {
    case 'success':
        return (
            <div className={classes.iconWrapper}>
                <CheckCircle fontSize="inherit" />
            </div>
        );
    case 'info':
        return (
            <div className={classes.iconWrapper}>
                <div className={classes.progressIconWrapper}>
                    <CircularProgress
                        size={20}
                        style={{ color: '#FFFFFF' }}
                        className={classes.progressIcon}
                    />
                </div>
            </div>
        );
    case 'info_static':
        return null;
    default:
        return (
            <div className={classes.iconWrapper}>
                <CancelIcon fontSize="inherit" />
            </div>
        );
    }
};

export default AlertIcon;
