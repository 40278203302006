import { AxiosResponse } from 'axios';

export type Query = { [key: string]: string | number | boolean | string[]}

export const defaultGetAllQuery = { page: 0, pageSize: 1000 };

export const getConfig = (token: string, addedHeaders:object = {}) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
            ...addedHeaders,
        },
    });
};

export const getQueries = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((queryArrObj, key) => {
        return [
            ...queryArrObj,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ];
    }, []);

    return queryArr.length > 0 ? `?${queryArr.join('&')}` : '';
};
export const getQueriesUnwrapped = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((queryArrObj, key) => {
        if (Array.isArray(obj[key])) {
            const arrayQueries = obj[key].map(value => `${key}=${value}`);

            return [...queryArrObj, ...arrayQueries];
        } else {
            return [...queryArrObj, `${key}=${obj[key]}`];
        }
    }, []);

    return `?${queryArr.join('&')}`;
};

export const getErrorReponseMessage = (error: { response?: AxiosResponse } = null, defaultMessage = '') => {
    try {
        const { response = null } = error || {};
        const { data = null } = response || {};

        if (Array.isArray(data) && data.length > 0) {
            const [errorData = null] = data;
            const { message = null } = errorData || {};

            return message || defaultMessage;
        } else if (data !== null && typeof data === 'object') { // typeof null also equals to 'object'
            if (data?.errorMessage) {
                return data.errorMessage;
            }

            const { parameterViolations = [] } = data;
            const collectedMessage = parameterViolations.reduce((data, issueObject, index) => {
                const { message = '', value = '' } = issueObject || {};

                return message && value
                    ? `${data}${index > 0 ? '\n' : ''}${value}: ${message}; `
                    : data;
            }, '');

            return collectedMessage || defaultMessage;
        }

        return defaultMessage;
    } catch (error) {
        return defaultMessage;
    }
};
