import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useMemo } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { TimeRange } from 'dataTypes/common';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './DateRangePickerMini.style';

type Props = {
    setTimeRange: (timeRange: TimeRange | any) => void,
    timeRange: TimeRange,
    maxDateRange?: number,
}

const DateRangePickerMini = ({
    setTimeRange,
    timeRange = {
        from: '',
        to: '',
    },
    maxDateRange = null,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const handleAcceptFrom = (value: Moment) => {
        if (value !== null) {
            setTimeRange(prevState => {
                const diff = moment(prevState.to, 'yyyy-MM-DDTHH:mm').diff(value, 'hours') / 24;

                if (diff <= 0) {
                    setErrorStatus(t('DATE_PICKER.FROM_SHOULD_BE_EARLIER'));

                    return ({
                        from: value.format('yyyy-MM-DDT00:00'),
                        to: value.format('yyyy-MM-DDT23:59'),
                    });
                }
                if (maxDateRange && (diff > maxDateRange)) {
                    setErrorStatus(t('DATE_PICKER.PERIOD_IS_LONGER_THAN_N_D')
                        .replaceAll('#n', maxDateRange.toString()));
                    return ({
                        from: value.format('yyyy-MM-DDT00:00'),
                        to: value.clone().add(maxDateRange - 1, 'days').format('yyyy-MM-DDT23:59'),
                    });
                }
                return ({
                    from: value.format('yyyy-MM-DDT00:00'),
                    to: prevState.to,
                });
            });
        }
    };

    const handleAcceptTo = (value: Moment) => {
        if (value !== null) {
            setTimeRange(prevState => {
                const diff = value.diff(moment(prevState.from, 'yyyy-MM-DDTHH:mm'), 'hours') / 24;

                if (diff <= 0) {
                    setErrorStatus(t('DATE_PICKER.FROM_SHOULD_BE_EARLIER'));

                    return ({
                        from: value.format('yyyy-MM-DDT00:00'),
                        to: value.format('yyyy-MM-DDT23:59'),
                    });
                }
                if (maxDateRange && (diff > maxDateRange)) {
                    setErrorStatus(t('DATE_PICKER.PERIOD_IS_LONGER_THAN_N_D')
                        .replaceAll('#n', maxDateRange.toString()));
                    return ({
                        from: value.clone().subtract(maxDateRange - 1, 'days').format('yyyy-MM-DDT00:00'),
                        to: value.format('yyyy-MM-DDT23:59'),
                    });
                }
                return ({
                    from: prevState.from,
                    to: value.format('yyyy-MM-DDT23:59'),
                });
            });
        }
    };
    const validated = useMemo(() => {
        if (!maxDateRange) return true;
        if (!timeRange.from || !timeRange.to) {
            setErrorStatus('Please, enter a date');
            return false;
        }
        return true;
    }, [timeRange, maxDateRange]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.dateWrapper}>
                <DatePicker
                    slotProps={{
                        dialog: { className: classes.datePicker },
                        textField: { className: [classes.dateText, !validated ? classes.invalid : ''].join(' '),
                        } }}
                    label="From"
                    value={moment(timeRange.from)}
                    format="DD.MM.YYYY"
                    onChange={handleAcceptFrom}
                />
                <DatePicker
                    slotProps={{
                        dialog: { className: classes.datePicker },
                        textField: { className: [classes.dateText, !validated ? classes.invalid : ''].join(' '),
                        } }}
                    label="To"
                    value={moment(timeRange.to)}
                    format="DD.MM.YYYY"
                    onChange={handleAcceptTo}
                />

            </div>
        </LocalizationProvider>
    );
};

export default DateRangePickerMini;
