import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import useGetEntity from 'hooks/useGetEntity';
import LaneFilters from 'LaneManagement/Lanes/LaneFilters';
import { bakeLanes } from 'LaneManagement/Lanes/lib';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Card from 'shared-components/Card';
import { FacetDTO, RawLanesDTO } from 'shared-components/dataTypes';
import { getOptionsFromFacets, nonNullKey, nonNullObject } from 'shared-components/common';
import StandalonePagination from 'shared-components/StandalonePagination';
import StatusInfo from 'shared-components/StatusInfo';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { styles } from 'LaneManagement/Lanes/Lanes.style';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import lanesTableColumns from './lanesTableColumns';

export const extractLocationName = (location) => {
    if (location) {
        const locationData = {
            ...nonNullObject(location),
            type: nonNullKey(location),
        };

        return locationData?.serviceProviderSite
            || locationData?.name
            || locationData?.addressLine1
            || (locationData?.airport ? `${locationData?.airport?.code} | ${locationData?.airport?.name}` : '')
            || (locationData?.seaport ? `${locationData?.seaport?.code} | ${locationData?.seaport?.name}` : '');
    } else {
        return null;
    }
};
const initialLaneFilterOptions = {
    fourthPartyLogisticsCompanyName: [],
    temperatureRanges: [],
    // originAirportCode: [],
    // destinationAirportCode: [],
    // loadingLocationName: [],
    // unloadingLocationName: [],
    status: [],
};

const PAGE_SIZE = 15;
const Lanes = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const [page, setPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [query, setQuery] = useState('');
    const [lanes, setLanes] = useState([]);
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(null);
    const {
        getEntity,
        dataLoaded,
        entityFromAddressId,
    } = useGetEntity();

    const {
        data: rawInitialFacets,
        status: rawInitialFacetsStatus,
    } = useGetCommonData<FacetDTO[]>('lanes/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(initialLaneFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('lanes/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawLanes, status: lanesStatus,
    } = useGetCommonData<RawLanesDTO>('lanes/search', {
        cache: true,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
        },
        enabled: facetsStatus === 'SUCCESS',
        queryWrap: false,
    });

    useEffect(() => {
        if (lanesStatus === 'SUCCESS' && rawLanes?.resultList && dataLoaded) {
            setTotalElements(rawLanes.totalElements);
            setLanes(bakeLanes(rawLanes.resultList, t, getEntity, entityFromAddressId));
        }
    }, [lanesStatus, rawLanes, dataLoaded, getEntity, entityFromAddressId]);
    useEffect(() => {
        if (rawInitialFacets && rawInitialFacetsStatus === 'SUCCESS') {
            setFilterOptions({
                fourthPartyLogisticsCompanyNames: getOptionsFromFacets(rawInitialFacets,
                    'fourthPartyLogisticsCompanyNames'),
                temperatureRanges: getOptionsFromFacets(rawInitialFacets, 'temperatureRanges'),
                // originAirportCode: getOptionsFromFacets(rawInitialFacets, 'originAirportCode'),
                // destinationAirportCode: getOptionsFromFacets(rawInitialFacets, 'destinationAirportCode'),
                // loadingLocationName: getOptionsFromFacets(rawInitialFacets, 'loadingLocationName'),
                // unloadingLocationName: getOptionsFromFacets(rawInitialFacets, 'unloadingLocationName'),
                status: getOptionsFromFacets(rawInitialFacets, 'status'),
            });
        }
    }, [rawInitialFacets, rawInitialFacetsStatus]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const columns = useMemo(() => lanesTableColumns(t), []);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: lanesStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <LaneFilters
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    facets={rawFacets}
                    initialFacets={rawInitialFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    setQuery={setQuery}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                }}
                >
                    {
                        lanesStatus !== 'FAILURE'
                            ? (
                                <Table
                                    noDataText={t('LANE_MANAGEMENT.NO_LANES')}
                                    tableMaxHeight="100%"
                                    columns={columns}
                                    data={lanes}
                                    maskForHighlight={query}
                                    rowLinkTemplate="/lane-management/lanes/:id/definition"
                                    classNames={{
                                        noDataContainer: classes.noDataContainer,
                                        tableContainerClassName: classes.tableContainer,
                                    }}
                                    currentSort={sort}
                                    onSort={updateSort}
                                    title={t('MENU_ITEMS.LANES')}
                                />
                            )
                            : (
                                <Card
                                    zeroVerticalPadding
                                    zeroSidePadding
                                    className={classes.cardContent}
                                    contentClass={classes.cardRoot}
                                >
                                    <StatusInfo status={lanesStatus} />
                                </Card>
                            )
                    }
                    <StandalonePagination
                        detached
                        entity={t('MENU_ITEMS.LANES')}
                    />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>

    );
};

export default Lanes;
