const styles = (theme) => ({
    bodyCell: {
        padding: '16px 13px 16px 13px',
        // minHeight: '30px',
        minHeight: '100%!important',
        color: `${theme.palette.common.black}!important`,
        opacity: 1,
        fontSize: '14px!important',
        letterSpacing: '0.01em!important',
        alignItems: 'center!important',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '16px 13px 16px 13px!important',
        },
        [theme.breakpoints.down('md')]: {
            padding: '16px 8px 16px 8px!important',
        },
        verticalAlign: 'middle',
    },
});

export default styles;
