const styles = (theme) => ({
    container: {
        background: '#00739A 0% 0% no-repeat padding-box',
        opacity: 1,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '4px',
            paddingTop: '4px',
            background: 'transparent',
        },
    },
    a: {
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    company: {
        textAlign: 'left',
        font: 'normal normal 300 32px/38px Roboto',
        letterSpacing: '0.41px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        opacity: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: '15px',
    },
    name: {
        textAlign: 'left',
        font: 'normal normal 500 17px/20px Roboto',
        letterSpacing: '0.43px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        textTransform: 'capitalize',
        opacity: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        // marginRight: '15px',
    },
    userInfoMenuEntriesEmail: {
        display: 'flex',
        // justifyContent: 'space-between',
        width: '100%',
        padding: '0',
        fontSize: '14px',
        textAlign: 'left',
        minWidth: '0px',
        font: 'normal normal normal 17px/20px Roboto',
        letterSpacing: '-0.14px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        textTransform: 'lowercase',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        opacity: 1,
    },
    userInfoMenuEntry: {
        width: '100%!important',
        height: '29px!important',
        // borderTop: '1px solid #FFFFFF',
        borderRadius: '0px!important',
        alignItems: 'center!important',
        // justifyContent: 'start',
        font: 'normal normal normal 14px/17px Roboto!important',
        letterSpacing: '-0.14px!important',
        // @ts-ignore
        color: `${theme.palette.common.menuTextWhite}!important`,
        textTransform: 'capitalize',
        opacity: 1,
        padding: '0!important',
        justifyContent: 'left!important',
    },
    borderTop: {
        borderTop: '1px solid #FFFFFF',
    },
    userInfoMenuEntryIcon: {
        width: '17px',
        margin: '-4px 8px -5px 6px',
        color: 'white',
    },
    userInfoMenuEntryIconLogout: {
        width: '17px',
        margin: '0 8px 0 8px',
    },
    collapse: props => ({
        width: props?.['collapseWidth'] + 20,
        minWidth: '140px',
        top: '30px',
        right: '5px',
        borderRadius: '5px',
        background: '#00739A 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        position: 'absolute',
        zIndex: 5,
    }),
    notificationLabel: {
        marginRight: '5px',
    },
    notificationsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        // marginTop: '7px',
    },

    menu: {
        position: 'relative',
        width: '200px',
        zIndex: 0,
    },

    item: {
        background: '#aacbff',
        boxSizing: 'border-box',
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '20px',
        padding: '10px',
        position: 'relative',
        textAlign: 'center',
        transition: 'background-color .05s ease-in-out',
        zIndex: 1,

        '&:hover': {
            backgroundColor: '#8cb8ff',
            color: 'white',
        },
    },

    open: {
        position: 'absolute',
        top: '100%',
        width: '100%',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
        zIndex: 0,
    },
});

export default styles;
