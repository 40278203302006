const styles = () => ({
    packagingContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '12px',
        marginBottom: '24px',
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
    },
    seasonInfoContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
        gridTemplateRows: 'auto',
        gridGap: '12px',
        marginBottom: '24px',
    },
    simulationSummaryTitle: {
        marginLeft: '8px',
        marginBottom: '16px',
    },
    transportEmbedded: {
        padding: 0,
    },
});

export default styles;
