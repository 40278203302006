import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import CustomTitle from './CustomTitle';

const borderColors = {
    blue: '#C3E9F7',
    red: '#D44848',
};

export type TooltipProps = {
    borderColor?: 'blue' | 'red',
    className?: string,
    children?: ReactElement,
    description: string | ReactElement,
    descriptionTitle?: string,
    iconSrc?: string,
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top',
    position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed',
    shrinked?: boolean,
    enabled?: boolean,
    textAlign?: 'center' | 'left',
    whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces',
    open?: boolean | null,
}

const UncontrolledTooltip = ({
    borderColor = 'blue',
    className = '',
    children = null,
    description,
    descriptionTitle = null,
    iconSrc = null,
    placement = 'bottom-start',
    shrinked = false,
    position = 'static',
    textAlign = 'center',
    whiteSpace = 'normal',
    enabled = true,
    open = null,
} : TooltipProps) => {
    const theme = useTheme<SkycellThemeInterface>();

    if (!enabled) return children;
    return (
        <Tooltip
            classes={{
                tooltip: className,
            }}
            {...(open !== null ? { open } : {})}
            disableFocusListener
            disableTouchListener
            placement={placement}
            style={{
                zIndex: `${10002}!important`,
            }}
            componentsProps={{
                tooltip: {
                    sx: shrinked
                        ? {
                            opacity: 1,
                            color: theme.palette.common.white,
                            fontSize: '13px',
                            backgroundColor: theme.palette.secondary[400],
                            whiteSpace,
                            borderRadius: '25px',
                            padding: '4px 8px',
                            textAlign,
                            position,
                        } : {
                            borderTop: '10px solid transparent',
                            borderBottom: '10px solid transparent',
                            borderColor: borderColors[borderColor],
                            width: whiteSpace === 'pre' ? 'auto' : '288px',
                            background: '#F7F7F7 0% 0% no-repeat padding-box',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '10px',
                            opacity: 1,
                            whiteSpace,
                            maxWidth: whiteSpace === 'pre' ? 'unset' : '300px',
                            position,
                        },
                },
                popper: {
                    sx: {
                        zIndex: 10002,
                    },
                },
            }}
            title={(
                shrinked ? description : (
                    <CustomTitle
                        iconSrc={iconSrc}
                        description={description}
                        descriptionTitle={descriptionTitle}
                    />
                )
            )}
        >
            { children }
        </Tooltip>
    );
};

export default UncontrolledTooltip;
