import React from 'react';
import { commoditiesArr, servicesArr } from 'dataTypes/SecureBackend/apiResponse';
import OfferingsSelection from 'shared-components/CompanyInfoComponents/OfferingsSelection';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './OfferingsCard.style';

type Props = {
    currentCommodities: string[],
    currentServices: string[],
    setOfferings: (value: string[]) => void,
}

const EditMode = ({
    currentCommodities = [],
    currentServices = [],
    setOfferings,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.offeringsCardContainer}>
            <div className={classes.title}>{t('COMMON.SERVICES')}</div>
            <OfferingsSelection
                availableOfferings={servicesArr}
                selectedOfferings={currentServices}
                setOfferings={setOfferings}
            />
            <div className={classes.title}>{t('COMMON.COMMODITIES')}</div>
            <OfferingsSelection
                availableOfferings={commoditiesArr}
                selectedOfferings={currentCommodities}
                setOfferings={setOfferings}
            />
        </div>
    );
};

export default EditMode;
