import useClasses from 'hooks/useClasses';
import React from 'react';
import icons from 'shared-components/icons';

import useCustomTranslation from 'hooks/useCustomTranslation';

const styles = theme => ({
    legendContainer: {
        display: 'flex',
        marginTop: '37px',
        marginBottom: '17px',
    },
    legendTitle: {
        textAlign: 'left',
        font: 'normal normal bold 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    legendLabel: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    legendIcon: {
        height: '21px',
        width: '21px',
        marginLeft: '16px',
        marginRight: '4px',
    },
});

type Props = {
    className?: string,
}

const Legend = ({ className } : Props) => {
    const classes = useClasses(styles);

    const { t } = useCustomTranslation();

    return (
        <div className={`${classes.legendContainer} ${className}`}>
            <div className={classes.legendTitle}>{t('COMMON.LEGEND')}</div>
            <img
                alt="Available"
                className={classes.legendIcon}
                src={icons.checked_blue}
            />
            <div className={classes.legendLabel}>{t('SERVICE_PROVIDERS.AVAILABLE')}</div>
            <img
                alt="Partially Available"
                className={classes.legendIcon}
                src={icons.checked_yellow}
            />
            <div className={classes.legendLabel}>{t('SERVICE_PROVIDERS.PARTIALLY_AVAILABLE')}</div>
            <img
                alt="Not Available"
                className={classes.legendIcon}
                src={icons.warning_round}
            />
            <div className={classes.legendLabel}>{t('SERVICE_PROVIDERS.NOT_AVAILABLE')}</div>
            <img
                alt="Not Answered"
                className={classes.legendIcon}
                src={icons.dots_round_grey}
            />
            <div className={classes.legendLabel}>{t('SERVICE_PROVIDERS.NOT_ANSWERED')}</div>
        </div>
    );
};

export default Legend;
