import React from 'react';
import TemperatureCheck from 'shared-components/TemperatureCheck';

type Props = {
    temperatureStatus?: string,
    temperatureMax: number,
    temperatureMin: number,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
}

const TemperatureCheckCell = ({
    temperatureStatus,
    temperatureMax,
    temperatureMin,
    temperatureRangeMax,
    temperatureRangeMin,
} : Props) => {
    return (
        <div style={{
            paddingRight: '10px',
        }}
        >
            <TemperatureCheck
                temperatureMax={temperatureMax}
                temperatureMin={temperatureMin}
                temperatureRangeMax={temperatureRangeMax}
                temperatureRangeMin={temperatureRangeMin}
                temperatureCheckStatus={temperatureStatus}
                grid={false}
            />
        </div>
    );
};

export default TemperatureCheckCell;
