const styles = (theme) => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        gridGap: '12px',
        padding: '12px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    cardContentData: {
        width: 340,
    },
});

export default styles;
