import DataPaginationContext from 'Contexts/DataPaginationContext';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetCommonData from 'hooks/useGetCommonData';
import { PageWithFilter } from 'Layout';
import AccessRequestFilterPanel from 'MyCompany/AccessRequests/AccessRequestFilters';
import AddAccessRequests from 'MyCompany/AccessRequests/AddAccessRequests';
import {
    AccessRequestDTO,
    AccessRequestsResponse,
    initialAccessRequestsFilterOptions,
} from 'MyCompany/AccessRequests/lib';
import tableColumns from 'MyCompany/AccessRequests/tableColumns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getOptionsFromFacets } from 'shared-components/common';
import { FacetDTO } from 'shared-components/dataTypes';
import StandalonePagination from 'shared-components/StandalonePagination';
import Table from 'shared-components/Table/TableContainer';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { styles } from './AccessRequests.style';

type Props = {
    type: 'outgoing' | 'incoming';
}
const PAGE_SIZE = 10;
const Requests = ({
    type = 'outgoing',
}: Props) => {
    const classes = useClasses(styles);

    const { getFilter, setFilter } = useFilter(PAGES.ACCESS_REQUESTS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [accessRequests, setAccessRequests] = useState<AccessRequestDTO[]>([]);
    const availableHeight = useAvailableHeight();
    const { t } = useCustomTranslation();
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const update = useCallback(() => {
        setLastUpdated(Date.now());
    }, []);

    const {
        data: rawInitialFacets, status: rawInitialFacetsStatus,
    } = useGetCommonData<FacetDTO[]>(`access-request/${type}/facets`,
        {
            cache: true,
            query: {
                initial: true,
                lastUpdated,
            },
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);
    const {
        data: allCompanies = [],
        isLoading: companiesLoading,
    } = useGetCommonData<Company[]>('companies', {
        postProcess: (data) => data.resultList,
        query: {
            page: 0,
            pageSize: 9999,
        },
    });
    const {
        data: companyGeofences,
        isLoading: geofencesLoading,
    } = useGetCommonData('geofences', {
        query: {
            isOwnedByCompany: true,
        },
    });
    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions
        || initialAccessRequestsFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>(`access-request/${type}/facets`,
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawAccessRequests, status: rawAccessRequestsStatus,
    } = useGetCommonData<AccessRequestsResponse>(`access-request/${type}`, {
        cache: true,
        query: {
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort)),
        },
        queryWrap: false,
    });

    useEffect(() => {
        if (rawAccessRequestsStatus === 'SUCCESS' && rawAccessRequests?.resultList) {
            setTotalElements(rawAccessRequests.totalElements);
            setAccessRequests(rawAccessRequests?.resultList);
        }
    }, [rawAccessRequestsStatus, rawAccessRequests]);

    useEffect(() => {
        if (rawInitialFacets && rawInitialFacetsStatus === 'SUCCESS') {
            const newFilterOptions = filter?.filterOptions || rawInitialFacets.reduce((acc, facet) => ({
                ...acc,
                [facet.filterName]: getOptionsFromFacets(rawInitialFacets, facet.filterName),
            }), {});

            if (JSON.stringify(newFilterOptions) !== JSON.stringify(filterOptions)) {
                setFilterOptions(newFilterOptions);
            }
        }
    }, [rawInitialFacets, rawInitialFacetsStatus]);

    const companiesDictionary = useMemo(() => {
        return allCompanies.map(it => ({
            id: it.id,
            label: it.name,
        }));
    }, [allCompanies]);

    const columns = useMemo(() => tableColumns(t, query), [t, query]);

    useEffect(() => {
        if (page === 1) return;
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            sort,
            totalElements,
        });
    }, [
        page,
        totalElements,
        query,
        filterOptions,
        sort,
    ]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: rawAccessRequestsStatus === 'PENDING',
        }}
        >
            <AddAccessRequests
                allCompanies={allCompanies}
                companiesLoading={companiesLoading}
                companyGeofences={companyGeofences}
                geofencesLoading={geofencesLoading}
                onUpdated={update}
            />
            <PageWithFilter>
                <AccessRequestFilterPanel
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    companiesDictionary={companiesDictionary}
                    facets={rawFacets}
                    initialFacets={rawInitialFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    query={query}
                    setQuery={setQuery}
                />
                <div style={{ display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                }}
                >
                    <Table
                        columns={columns}
                        data={accessRequests}
                        tableMaxHeight="100%"
                        maskForHighlight={query}
                        title={t('MENU_ITEMS.ACCESS_REQUESTS')}
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        currentSort={sort}
                        onSort={updateSort}
                    />
                    <StandalonePagination detached entity="Access Requests" />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Requests;
