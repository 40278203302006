import { useTheme } from '@emotion/react';
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import { FacetDTO, FilterPanelCheckboxOptionsGroup } from 'shared-components/dataTypes';
import SideFilter, {
    AccordionOptionsGroup,
    CheckboxOption,
    FilterBlock,
    SwitchOptionItem,
} from 'shared-components/SideFilter';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import { facetSorter } from 'utils/stringTool';
import { getQuickHelpAsset } from 'shared-components/icons';
import { ONBOARDING_TYPE } from 'Contexts/QuickHelpContext/QuickHelpContext';

import { ExtractedLoggerData } from '../../lib';
import SearchPreviewTable from './SearchPreviewTable';

type Props = {
    filterOptions: { [optionsGroupKey: string]: string[] },
    changeHighlightedText: (newHighlightedText: any) => void,
    isTableView: boolean,
    loggersFromPreviewTable: ExtractedLoggerData[],
    setLoggersFromPreviewTable: Dispatch<SetStateAction<ExtractedLoggerData[]>>,
    setShowLoggerTypes: Dispatch<SetStateAction<boolean>>,
    setShowLastConnection: Dispatch<SetStateAction<boolean>>,
    setShowAirportsInfo: Dispatch<SetStateAction<boolean>>,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    showLastConnection?: boolean,
    showAirports?: boolean,
    showLoggerTypes?: boolean,
    query: string,
    setQuery: Dispatch<SetStateAction<string>>,
    facets: FacetDTO[],
    initialFacets: FacetDTO[],
    countsLoading: boolean,
    core?: string,
}

const LoggerFilterPanel = ({
    filterOptions,
    isTableView,
    changeHighlightedText,
    loggersFromPreviewTable,
    setLoggersFromPreviewTable,
    setShowLoggerTypes,
    setShowLastConnection,
    setShowAirportsInfo,
    setFilterOptions,
    showLoggerTypes,
    showLastConnection,
    showAirports,
    facets,
    initialFacets,
    countsLoading,
    query,
    setQuery,
    core = 'track-and-trace',
}: Props) => {
    const theme = useTheme();
    const classes = generateStyles(theme);
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const handleChangeShowLoggerTypes = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowLoggerTypes(event.target.checked);
    }, []);

    const handleChangeShowLastConnection = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowLastConnection(event.target.checked);
    }, []);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                title: t('TRACK_AND_TRACE.LOGGER_TYPE'),
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'loggerTypeCodes',
                    t,
                    tgroup: 'LOGGER_TYPE_LABEL',
                }),
                optionsGroupKey: 'loggerTypeCodes',
            },
            ...(core !== 'track-and-trace' ? [{
                title: t('LOGGERS.PAIRING_STATUS'),
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'pairingStatus',
                    t,
                    tgroup: 'PAIRING_STATUS',
                }),
                optionsGroupKey: 'pairingStatus',
            }] : []),
        ];
    }, [loadedFacets, initialFacets, core]);

    return (
        <SideFilter
            id="LoggersPageFilter" tooltipInfo={{
                uid: 'loggersSideFilterGeneric',
                isMain: true,
                title: t('ONBOARDING.LOGGERS.FILTERS_TITLE'),
                text: t('ONBOARDING.LOGGERS.FILTERS_DESC'),
                image: getQuickHelpAsset('logger_filters.gif'),
                order: 2,
                padding: 0,
                core: ONBOARDING_TYPE.LOGGER,
                position: 'right',
            }}
        >
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>

                <SearchPreviewTable
                    changeHighlightedText={changeHighlightedText}
                    isTableView={isTableView}
                    loggersFromPreviewTable={loggersFromPreviewTable || []}
                    setLoggersFromPreviewTable={setLoggersFromPreviewTable}
                    query={query}
                    setQuery={setQuery}
                    core={core}
                />

            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey, options } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions } = prev;

                            return selectedOptions.length === 0
                                ? { ...prev, [optionsGroupKey]: options.map(option => option.value) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock
                            key={checkboxGroup.title}
                        >
                            <AccordionOptionsGroup
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        .sort((a, b) => facetSorter(a, b, ['NOT_PAIRED']))
                                        .map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    count={option.count}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    key={option.value}
                                                    label={option.label}
                                                    optionsGroupKey={optionsGroupKey}
                                                    optionKey={option.value}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                    countsLoading={countsLoading}
                                                    shrinkNames
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            { !isTableView
            && (
                <FilterBlock>
                    <AccordionOptionsGroup
                        title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                    >
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.LOGGER_TYPE')}
                            onChange={handleChangeShowLoggerTypes}
                            defaultChecked={showLoggerTypes}
                        />
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.LAST_UPDATED')}
                            onChange={handleChangeShowLastConnection}
                            defaultChecked={showLastConnection}
                        />
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.AIRPORTS')}
                            onChange={handleChangeShowAirports}
                            defaultChecked={showAirports}
                        />
                    </AccordionOptionsGroup>
                </FilterBlock>
            )}
        </SideFilter>
    );
};

export default LoggerFilterPanel;
