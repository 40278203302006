import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const appearTitleKeyframes = keyframes({
        from: {
            width: '0%',
        },
        to: {
            width: '100%',
        },
    });

    const appearLinkKeyframes = keyframes({
        from: {
            opacity: 0,
            height: '0px',
        },
        to: {
            opacity: 1,
            height: '32px',
        },
    });

    const floatingMenu = css({
        position: 'absolute',
        left: '100%',
        top: '0px',
        minWidth: '200px',
    });

    const title = css({
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '14px',
        borderRadius: '0 10px 0 0 !important',
        background: theme.palette.primary.darkBlue,
        height: '42px',
        fontSize: '14px',
        textTransform: 'uppercase',
        width: '0%',
        color: theme.palette.common.white,
        animation: `${appearTitleKeyframes} 100ms ease forwards`,
    });

    const floatingItem = css({
        display: 'flex',
        height: '0px',
        paddingLeft: '14px',
        alignItems: 'center',
        fontSize: '14px',
        background: theme.palette.primary.darkBlue,
        color: theme.palette.common.white,
        textDecoration: 'none',
        justifyContent: 'space-between',
        marginLeft: '10px',
        opacity: 0,
        animation: `${appearLinkKeyframes} 50ms ease forwards`,
        '&:last-child': {
            borderRadius: '0 0 10px 10px !important',
        },
        '& span': {
            display: 'flex',
            paddingRight: '6px',
        },
        '&:hover': {
            background: theme.palette.primary.deepBlue,
        },
        '& svg': {
            rotate: '180deg',
            fontSize: '14px',
        },
    });

    const active = css({
        background: theme.palette.primary.darkBlue,
    });

    return {
        floatingMenu,
        title,
        floatingItem,
        active,
    };
};

export default generateStyles;
