import React from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import { GuideRouteMapping } from 'UserGuides/GuideRouteMapping';
import NoUserGuide from 'UserGuides/NoUserGuide';

const UserGuide = () => {
    return (
        <Routes>
            {
                GuideRouteMapping.map((item, index) => (
                    <Route
                        key={`GuideRouteMapping-${index}`}
                        path={item.path as string}
                        element={(
                            <>
                                {
                                    item.element
                                }
                            </>
                        )}
                    />
                ))
            }
            <Route path="*" element={<NoUserGuide />} />
        </Routes>
    );
};

export default UserGuide;
