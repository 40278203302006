import icons from 'shared-components/icons';
import Loading from 'LaneManagement/AddLane/StepsWrapper/steps/Loading';
import Road from 'LaneManagement/AddLane/StepsWrapper/steps/Road';
import Unloading from 'LaneManagement/AddLane/StepsWrapper/steps/Unloading';
import Positioning from 'LaneManagement/AddLane/StepsWrapper/steps/Positioning';
import Sea from 'LaneManagement/AddLane/StepsWrapper/steps/Sea';
import Flight from 'LaneManagement/AddLane/StepsWrapper/steps/Flight';
import GroundHandling from 'LaneManagement/AddLane/StepsWrapper/steps/GroundHandling';
import Warehouse from 'LaneManagement/AddLane/StepsWrapper/steps/Warehouse';
import Customs from 'LaneManagement/AddLane/StepsWrapper/steps/Customs';
import { TFunction } from 'i18next';
import ReactComponent from 'react-styleguidist/lib/client/rsg-components/ReactComponent';

export const BUTTON_MAP: (t?: TFunction) => {
    milestoneType?: string,
    label?: string,
    icon?: string,
    component?: ReactComponent,
}[] = (t) => [
    {
        milestoneType: 'POSITIONING',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.POSITIONING'),
        icon: icons.stepTypes.road,
        component: Positioning,
    },
    {
        milestoneType: 'LOADING',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.LOADING'),
        icon: icons.stepTypes.confirmation,
        component: Loading,
    },
    {
        milestoneType: 'ROAD',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.ROAD'),
        icon: icons.stepTypes.road,
        component: Road,
    },
    {
        milestoneType: 'SEA',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.SEA'),
        icon: icons.stepTypes.sea,
        component: Sea,
    },
    {
        milestoneType: 'AIR',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.AIR'),
        icon: icons.stepTypes.air,
        component: Flight,
    },
    {
        milestoneType: 'GROUND_HANDLING',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.GROUND_HANDLING'),
        icon: icons.ground_handler,
        component: GroundHandling,
    },
    {
        milestoneType: 'WAREHOUSE',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.WAREHOUSE'),
        icon: icons.warehouse,
        component: Warehouse,
    },
    {
        milestoneType: 'CUSTOMS',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.CUSTOMS'),
        icon: icons.customs_grey,
        component: Customs,
    },
    {
        milestoneType: 'UNLOADING',
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.UNLOADING'),
        component: Unloading,
        icon: icons.stepTypes.confirmation,
    },
];

export const allowedButtonsArray = [
    [0, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 1, 1, 1, 0, 1, 1, 0],
    [0, 0, 0, 1, 0, 1, 1, 1, 1],
    [0, 0, 1, 0, 1, 0, 1, 1, 1],
    [0, 0, 0, 0, 1, 1, 0, 1, 0],
    [0, 0, 1, 0, 1, 0, 1, 1, 0],
    [0, 0, 1, 1, 0, 1, 0, 1, 1],
    [0, 0, 1, 1, 1, 1, 1, 0, 1],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
];

export const getAllowedButtons = (lastMilestone, t) => {
    if (!lastMilestone) return [BUTTON_MAP(t)[0], BUTTON_MAP(t)[1]];
    const milestoneIndex = BUTTON_MAP(t).findIndex(it => it.milestoneType === lastMilestone);
    const allowed = allowedButtonsArray[milestoneIndex]
        .map((it, index) => (it === 1 ? BUTTON_MAP(t)[index] : null));

    return allowed.filter(it => !!it);
};
