import React, { CSSProperties, MouseEventHandler, useContext, useMemo } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { useCalculatedRectStyle } from 'Contexts/QuickHelpContext/useCalculatedRectStyle';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

type RectangleProps = {
    tooltipInfo: QuickHelpTooltipInfo,
    index: number,
    mask?: boolean,
    screenSize: {
        width: number,
        height: number,
        scale: number,
    }
}
const TooltipInfoToRectangle = ({
    tooltipInfo,
    index,
    mask = true,
    screenSize,
}: RectangleProps) => {
    const {
        childRef,
        customRectSize,
        childOffsetPercent,
        offsetPx,
        padding,
        radius,
    } = tooltipInfo || {};
    const {
        enableTooltips,
    } = useContext(QuickHelpContext);

    const childRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = childRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipInfo, screenSize]);

    const PADDING = padding !== null && padding !== undefined ? padding : TOOLTIP_PADDING;
    const rectStyle = useCalculatedRectStyle({
        childRect,
        offsetPx,
        customRectSize,
        radius,
        childOffsetPercent,
        padding: enableTooltips ? PADDING : 25,
        shouldUpdate: enableTooltips ? 1 : 2,
        screenSize,
    });

    return (
        <rect
            key={`rect_${tooltipInfo?.uid}_border`}
            x={rectStyle?.left}
            y={rectStyle?.top}
            width={`${rectStyle?.width}px`}
            height={`${rectStyle?.height}px`}
            style={mask ? {
                transition: '100ms ease',
                transitionDelay: `${index * 20}ms`,
                stroke: 'black',
                strokeWidth: 3,
            } : {
                transition: '100ms ease',
                transitionDelay: `${index * 20}ms`,
                stroke: '#EDAE49FF',
                strokeWidth: 3,
                fill: 'transparent',
            }}
            rx={radius || PADDING}
        />
    );
};

type Props = {
    tooltipInfos: QuickHelpTooltipInfo[],
    style: CSSProperties,
    onClick: MouseEventHandler<SVGSVGElement>,
    screenSize: {
        width: number,
        height: number,
        scale: number,
    }
}

export const SvgWithCutouts = ({
    tooltipInfos = [],
    style = {},
    onClick,
    screenSize,
}: Props) => {
    return useMemo(() => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={`${screenSize.width }px`}
                height={`${screenSize.height }px`}
                style={{
                    position: 'absolute',
                    width: `${screenSize.width}px`,
                    height: `${screenSize.height}px`,
                    zIndex: 10000,
                    opacity: 1,
                    ...style,
                }}
                onClick={onClick}
            >
                {
                    tooltipInfos
                        .map((tooltipInfo, index) => (
                            <TooltipInfoToRectangle
                                key={`rect_${tooltipInfo?.uid}`}
                                tooltipInfo={tooltipInfo}
                                index={index}
                                mask={false}
                                screenSize={screenSize}
                            />
                        ))
                }

                <g>
                    <rect
                        x="0"
                        y="0"
                        width={`${screenSize.width }px`}
                        height={`${screenSize.height }px`}
                        fill="rgba(0, 0, 0, 0.3)"
                        mask="url(#cutout-mask)"
                    />
                    <mask id="cutout-mask">
                        <rect
                            x="0" y="0" width="100%" height="100%"
                            fill="white"
                        />
                        {
                            tooltipInfos
                                .map((tooltipInfo, index) => (
                                    <TooltipInfoToRectangle
                                        key={`rect_${tooltipInfo?.uid}`}
                                        tooltipInfo={tooltipInfo}
                                        index={index}
                                        screenSize={screenSize}
                                    />
                                ))
                        }
                    </mask>
                </g>

            </svg>
        );
    }, [screenSize.width, screenSize.height, tooltipInfos, style, onClick]);
};
